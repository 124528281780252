import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import Confirmation from 'components/Objects/Confirmation';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  TextField
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

function PolicyDescription({ 
  description, 
  descriptionExpanded, 
  setDescriptionExpanded,
  isDescriptionEditing,
  setIsDescriptionEditing,
  onSave,
  loading
}) {
  const [editedDescription, setEditedDescription] = useState(description);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSaveClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSave = async () => {
    try {
      await onSave(editedDescription);
      setIsDescriptionEditing(false);
      setShowConfirmation(false);
    } catch (error) {
      setShowConfirmation(false);
    }
  };

  const handleCancel = () => {
    setEditedDescription(description);
    setIsDescriptionEditing(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1, mt: 1 }}>
      <Paper sx={{ borderRadius: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: descriptionExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: descriptionExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Description</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isDescriptionEditing ? (
              <IconButton 
                onClick={() => setIsDescriptionEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              sx={{ 
                transform: descriptionExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {descriptionExpanded && (
          <Box sx={{ p: 2 }}>
            {isDescriptionEditing ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                sx={{
                  backgroundColor: 'var(--background-color)',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'var(--background-color)',
                    '& fieldset': {
                      borderColor: 'var(--title-color)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--title-color)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--title-color)',
                    },
                  },
                }}
              />
            ) : (
              <Box 
                sx={{ 
                  '& a': { color: 'primary.main' },
                  '& p': { mb: 2 },
                  '& br': { display: 'block', content: '""', mb: 2 }
                }}
              >
                <ReactMarkdown 
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {description || 'No description provided.'}
                </ReactMarkdown>
              </Box>
            )}
          </Box>
        )}
      </Paper>
      
      {showConfirmation && (
        <Confirmation
          message="Are you sure you want to save these changes?"
          onConfirm={handleConfirmSave}
          onCancel={handleCancelConfirmation}
        />
      )}
    </Box>
  );
}

PolicyDescription.propTypes = {
  description: PropTypes.string,
  descriptionExpanded: PropTypes.bool.isRequired,
  setDescriptionExpanded: PropTypes.func.isRequired,
  isDescriptionEditing: PropTypes.bool.isRequired,
  setIsDescriptionEditing: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default PolicyDescription; 