import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { 
  CalendarMonth as CalendarIcon, 
  Edit as EditIcon, 
  Save as SaveIcon, 
  Cancel as CancelIcon, 
  ExpandMore as ExpandMoreIcon 
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: 0,
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

const EvidenceSchedule = ({ evidence, setEvidence }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [scheduleExpanded, setScheduleExpanded] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { authenticatedFetch } = useAuth();

  useEffect(() => {
    if (evidence?.review_date) {
      setSelectedDate(evidence.review_date);
    }
  }, [evidence]);

  if (!evidence) return null;

  const formatDate = (date) => {
    if (!date) return 'No review date set';
    return new Date(date).toLocaleDateString();
  };

  const handleConfirm = () => {
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await authenticatedFetch(`/api/evidence/${evidence.id}/review_date`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          review_date: selectedDate,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update review date');
      }

      const data = await response.json();
      console.log('API Response:', data);
      
      const processedEvidence = {
        ...evidence,
        review_date: data.evidence.review_date,
        id: data.evidence.id.toString(),
        controls: evidence.controls || [],
        standards: evidence.standards || [],
        policies: evidence.policies || []
      };
      
      console.log('Processed Evidence:', processedEvidence);

      setEvidence(processedEvidence);
      setIsEditing(false);
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error updating review date:', error);
    }
  };

  const handleCancel = () => {
    setSelectedDate(evidence?.review_date || null);
    setIsEditing(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <StyledCard>
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CalendarIcon sx={{ fontSize: 20 }} />
          <Typography variant="h6">Schedule and History</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {!isEditing ? (
            <IconButton 
              onClick={() => setIsEditing(true)}
              sx={{ color: 'var(--text-color-light)' }}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleConfirm}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
          <IconButton 
            onClick={() => setScheduleExpanded(!scheduleExpanded)}
            sx={{ 
              transform: scheduleExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      <CardContent>
        {scheduleExpanded && (
          <TableContainer>
            <Table sx={{
              '& .MuiTableBody-root tr:last-child td': {
                borderBottom: 'none'
              }
            }}>
              <TableHead>
                <TableRow>
                  <TableCell width="30%" sx={{ fontWeight: 'bold' }}>Type</TableCell>
                  <TableCell width="70%" sx={{ fontWeight: 'bold' }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Next Review</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={selectedDate ? new Date(selectedDate) : null}
                          onChange={(newDate) => {
                            if (newDate) {
                              setSelectedDate(newDate.toISOString().split('T')[0]);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          format="MM/dd/yyyy"
                          slotProps={{
                            popper: {
                              disablePortal: true
                            }
                          }}
                        />
                      </LocalizationProvider>
                    ) : (
                      formatDate(evidence.review_date)
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>
                    {formatDate(evidence.created_at)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{
          zIndex: 99999999
        }}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          Are you sure you want to update the review date?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={handleConfirmUpdate}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
};

export default EvidenceSchedule; 