import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import navigationData from 'constants/navigationData';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText,
  Typography,
  IconButton,
  Divider,
  Paper
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import wolfLogo from '../../graphics/wolfv2.svg';

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  
  const isStandardsPage = location.pathname.includes('/company/standards');
  const isPolicyPage = location.pathname.includes('/governance/policy');
  const isProcessesPage = location.pathname.includes('/company/process');
  const isRiskPage = location.pathname.includes('/governance/risk');
  const isEvidencePage = location.pathname.includes('/governance/evidence');
  const isControlsPage = location.pathname.includes('/governance/controls');

  const getActiveSection = () => {
    if (location.pathname.includes('/governance')) return 'governance';
    if (location.pathname.includes('/company')) return 'company';
    if (location.pathname.includes('/settings') || location.pathname.includes('/profile')) return 'settings';
    return '';
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const isItemActive = (item) => {
    // Check if the current path exactly matches the item's endpoint
    if (location.pathname === item.endpoint) return true;

    // Check for specific page types
    switch (item.name) {
      case 'Evidence':
        return isEvidencePage;
      case 'Control':
        return isControlsPage;
      case 'Standard':
        return isStandardsPage;
      case 'Policy':
        return isPolicyPage;
      case 'Process':
        return isProcessesPage;
      case 'Risk':
        return isRiskPage;
      default:
        return false;
    }
  };

  return (
    <Paper 
      className="sidebar"
      elevation={2}
      sx={{ 
        width: '12rem',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.paper',
        borderRadius: 0
      }}
    >
      <Box sx={{ 
        p: 0.5, 
        pl: 1,
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
        <img 
          src={wolfLogo}
          alt="Valdyr Logo" 
          style={{ 
            width: '36px', 
            height: '36px',
            marginRight: '8px'
          }} 
        />
        <Typography 
          variant="h6" 
          sx={{ 
            cursor: 'pointer',
            fontWeight: 'bold',
            color: 'var(--title-color)',
            fontSize: '2rem',
            marginTop: '1px'
          }}
          onClick={() => navigate('/governance/forest')}
        >
          VALDYR
        </Typography>
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {navigationData.map((category, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Divider />
            <Typography
              variant="subtitle1"
              sx={{
                px: 2,
                py: 1,
                color: 'var(--title-color)',
                fontWeight: 'medium',
                fontSize: '1.1rem',
              }}
            >
              {category.category}
            </Typography>
            <Divider />
            <List dense>
              {category.items.map((item, idx) => {
                const Icon = item.icon;
                return (
                  <ListItem key={idx} disablePadding>
                    <ListItemButton
                      selected={isItemActive(item)}
                      onClick={() => navigate(item.endpoint)}
                      sx={{
                        mx: 1,
                        borderRadius: 1,
                        '&.Mui-selected': {
                          bgcolor: 'primary.main',
                          color: 'primary.contrastText',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                          '& .MuiListItemIcon-root': {
                            color: 'primary.contrastText',
                          }
                        }
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText 
                        primary={item.name} 
                        primaryTypographyProps={{
                          fontSize: '1rem',
                          fontWeight: 500
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        ))}
      </Box>
      
      <Divider />
      
      <Box sx={{ p: 2 }}>
        <ListItemButton
          onClick={handleLogout}
          sx={{
            borderRadius: 1,
            '&:hover': {
              bgcolor: 'error.main',
              color: 'error.contrastText',
              '& .MuiListItemIcon-root': {
                color: 'error.contrastText',
              }
            }
          }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </Box>
    </Paper>
  );
}

export default Sidebar;
