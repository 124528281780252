import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import Calendar from 'react-calendar';
import Loading from 'components/Objects/loading';
import Error from 'components/Objects/Error';
import 'react-calendar/dist/Calendar.css';
import 'styles/Calendar.css';
import { Link } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import { Link as LinkIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const CalendarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100vh - 1.1rem)'
});

const CalendarWrapper = styled(Box)(({ hasReviews }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  '& .calendar-section': {
    flex: hasReviews ? 2 : 1,
    width: hasReviews ? 'calc(100% - 400px)' : '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));

const StyledCalendar = styled(Calendar)({
  width: '100% !important',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: 'none',
  color: 'var(--text-color-dark)',
  '& .react-calendar__navigation': {
    height: '2.8rem',
    display: 'flex',
    alignItems: 'center',
    background: 'var(--button-background-color-xgrey)',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    color: 'var(--text-color-dark)',
    marginBottom: '0rem'
  }
});

const camelCaseToSpaces = (str) => {
  return str
    // Insert space between lower & upper letters
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // Make first character uppercase
    .replace(/^./, str => str.toUpperCase());
};

function CalendarView({ calendarData, viewType = 'month' }) {
  const { authenticatedFetch } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviewDates, setReviewDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reviewItems, setReviewItems] = useState([]);
  const [currentView, setCurrentView] = useState('month');

  useEffect(() => {
    const fetchReviewDates = async () => {
      try {
        const [policiesRes, standardsRes, controlsRes, evidenceRes, processesRes] = await Promise.all([
          authenticatedFetch('/api/policy/review_dates'),
          authenticatedFetch('/api/standard/review_dates'),
          authenticatedFetch('/api/control/review_dates'),
          authenticatedFetch('/api/evidence/review_dates'),
          authenticatedFetch('/api/process/review_dates')
        ]);

        const [policies, standards, controls, evidence, processes] = await Promise.all([
          policiesRes.json(),
          standardsRes.json(),
          controlsRes.json(),
          evidenceRes.json(),
          processesRes.json()
        ]);

        // Combine all review dates
        const allDates = [
          ...(policies || []).map(p => ({ ...p, type: 'policy' })),
          ...(standards || []).map(s => ({ ...s, type: 'standard' })),
          ...(controls || []).map(c => ({ ...c, type: 'control' })),
          ...(evidence || []).map(e => ({ ...e, type: 'evidence' })),
          ...(processes || []).map(p => ({ ...p, type: 'process' }))
        ];

        setReviewDates(allDates);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching review dates:', err);
        setError('Failed to load review dates');
        setLoading(false);
      }
    };

    fetchReviewDates();
  }, [authenticatedFetch]);

  const handleViewChange = () => {
    if (currentView === 'month') {
      setCurrentView('year');
    } else if (currentView === 'year') {
      setCurrentView('decade');
    } else {
      // When in decade view, go back to month view
      setCurrentView('month');
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === currentView) {
      const itemsForDate = reviewDates.filter(item => {
        const reviewDate = new Date(item.review_date);
        if (view === 'month') {
          return reviewDate.toDateString() === date.toDateString();
        } else {
          return reviewDate.getMonth() === date.getMonth() && 
                 reviewDate.getFullYear() === date.getFullYear();
        }
      });

      return itemsForDate.length > 0 ? (
        <div className="calendar-tile-content">
          {itemsForDate.map((item, index) => (
            <div 
              key={`${item.type}-${item.id}-${index}`}
              style={{ 
                backgroundColor: `var(--${item.type}-color)`,
                width: '6px',
                height: '6px',
                borderRadius: '50%',
              }}
              title={`${item.type}: ${camelCaseToSpaces(item.name)}`}
            />
          ))}
        </div>
      ) : null;
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const itemsForDate = reviewDates.filter(item =>
      new Date(item.review_date).toDateString() === date.toDateString()
    );
    setReviewItems(itemsForDate);
  };

  const getItemLink = (item) => {
    switch (item.type) {
      case 'policy':
        const policyName = item.name.replace(/([A-Z])/g, ' $1').trim();
        return `/governance/policy/${encodeURIComponent(policyName)}`;
      case 'standard':
        return `/company/standards/${encodeURIComponent(item.domain_name)}/${encodeURIComponent(item.name)}`;
      case 'control':
        return `/company/standards/${encodeURIComponent(item.domain_name)}/${encodeURIComponent(item.standard_name)}/${encodeURIComponent(item.name)}`;
      case 'evidence':
        return `/governance/evidence/${encodeURIComponent(item.id)}`;
      case 'process':
        return {
          pathname: `/company/process/${encodeURIComponent(item.name)}`,
        };
      default:
        return '#';
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error message={error} onClose={() => setError(null)} />;

  return (
    <CalendarContainer sx={{ width: '100%', margin: 1, borderRadius: 1 }}>
      <CalendarWrapper hasReviews={reviewItems.length > 0} sx={{ borderRadius: 1}}>
        <Box className="calendar-section" sx={{ borderRadius: 1, boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }}>
          <StyledCalendar
            sx={{ borderRadius: 1 }}
            onChange={handleDateClick}
            value={selectedDate}
            tileContent={tileContent}
            view={currentView}
            onClickMonth={currentView === 'year' ? handleDateClick : undefined}
            onClickYear={currentView === 'decade' ? handleDateClick : undefined}
            showNeighboringMonth={currentView === 'month'}
            onDrillUp={handleViewChange}
            onDrillDown={() => {
              if (currentView === 'decade') {
                setCurrentView('year');
              } else if (currentView === 'year') {
                setCurrentView('month');
              }
            }}
          />
        </Box>
        {reviewItems.length > 0 && (
          <Box sx={{ 
            width: '400px',
            ml: 1,
            mb: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Paper sx={{ 
              overflow: 'hidden',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Box sx={{ 
                p: 1,
                borderRadius: 0,
                backgroundColor: 'var(--button-default-color)',
                color: 'var(--text-color-light)'
              }}>
                <Typography variant="h6" align="center">
                  Review Schedule
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="30%">Type</TableCell>
                      <TableCell width="70%">Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviewItems.map((item, index) => (
                      <TableRow key={`${item.type}-${item.id}-${index}`}>
                        <TableCell>
                          <Chip
                            label={item.type}
                            size="small"
                            sx={{
                              backgroundColor: `var(--${item.type}-color)`,
                              color: 'var(--text-color-light)',
                              textTransform: 'capitalize'
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Link 
                            to={getItemLink(item)}
                            style={{ textDecoration: 'none' }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <LinkIcon sx={{ fontSize: 20, color: 'var(--link-color)' }} />
                              <Typography
                                sx={{
                                  color: 'var(--link-color)',
                                  '&:hover': {
                                    color: 'var(--link-color-alt)'
                                  }
                                }}
                              >
                                {camelCaseToSpaces(item.name)}
                              </Typography>
                            </Box>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}
      </CalendarWrapper>
    </CalendarContainer>
  );
}

export default CalendarView; 