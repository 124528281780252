import { AuthenticatedFetch } from '../../contexts/AuthContext';

export const fetchDomains = async (authenticatedFetch) => {
  try {
    const response = await authenticatedFetch('/api/domain/short');
    if (!response.ok) {
      throw new Error('Failed to fetch domains');
    }
    const data = await response.json();

    // Transform the data into the expected format
    return data.reduce((acc, domain) => {
      acc[domain.name] = domain.standards.map(standard => ({
        id: standard.id,
        name: standard.name,
        status: standard.status,
        populated: standard.controls.length > 0,
        review_date: standard.review_date,
        overdue: standard.review_date && new Date(standard.review_date) < new Date(),
        controls: standard.controls.map(control => ({
          id: control.id,
          name: control.name,
          status: control.status,
          review_date: control.review_date,
          overdue: control.review_date && new Date(control.review_date) < new Date()
        }))
      }));
      return acc;
    }, {});
  } catch (error) {
    console.error('Error fetching domains:', error);
    throw error;
  }
};

export const fetchUserProfile = async (authenticatedFetch) => {
  try {
    const response = await authenticatedFetch('/api/user/profile');
    if (response.ok) {
      const data = await response.json();
      return data.user.company_name;
    }
    return '';
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return '';
  }
};

export const fetchStandardDetails = async (authenticatedFetch, domainName, standardName, domains) => {
  try {
    // If we have a direct standard ID, use it
    if (typeof domainName === 'object' && domainName !== null) {
      return {
        ...domainName,
        controls: domainName.controls || [],
        policies: domainName.policies || []
      };
    }

    // Otherwise use the domain/standard name lookup
    const domainStandards = domains[domainName];
    if (!domainStandards) {
      throw new Error('Domain not found');
    }

    const standardObj = domainStandards.find(
      (std) => std.name === standardName
    );

    if (!standardObj) {
      throw new Error('Standard not found');
    }

    const response = await authenticatedFetch(`/api/standard/${standardObj.id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch standard details');
    }
    const data = await response.json();
    
    return {
      ...data.standard,
      name: standardName,
      controls: data.standard.controls || [],
      policies: data.standard.policies || []
    };
  } catch (error) {
    console.error('Error fetching standard details:', error);
    throw error;
  }
};

export const deleteStandard = async (authenticatedFetch, domainName, standardName) => {
  const response = await authenticatedFetch(
    `/api/standard/by-name/${encodeURIComponent(domainName)}/${encodeURIComponent(standardName)}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to delete standard');
  }
};

export const deleteDomain = async (authenticatedFetch, domainName) => {
  const response = await authenticatedFetch(`/api/domain/by-name/${encodeURIComponent(domainName)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to delete domain');
  }
};

export const addStandard = async (authenticatedFetch, domainName, name = '') => {
  const response = await authenticatedFetch('/api/standard/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      domain: domainName
    })
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to add standard');
  }

  return await response.json();
};

export const addDomain = async (authenticatedFetch, name) => {
  const response = await authenticatedFetch('/api/domain/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name })
  });

  if (!response.ok) {
    throw new Error('Failed to add domain');
  }

  return await response.json();
};

export const fetchStandardInstructions = async (authenticatedFetch) => {
  try {
    const response = await authenticatedFetch(
      `/api/chat/get-file?file_path=seed/language_model/standard/3.standards.txt`
    );
    const text = await response.text();
    return text;
  } catch (error) {
    console.error('Error fetching instructions:', error);
    throw error;
  }
};

export const fetchStandardInitialPrompt = async (authenticatedFetch) => {
  try {
    const response = await authenticatedFetch(
      '/api/chat/get-file?file_path=seed/language_model/standard/4.standard_prompt.txt'
    );
    const text = await response.text();
    return text;
  } catch (error) {
    console.error('Error fetching initial prompt:', error);
    throw error;
  }
};

export const queryStandard = async (authenticatedFetch, payload) => {
  const response = await authenticatedFetch('/api/chat/query_standard', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error('Failed to get response from ChatGPT');
  }

  return await response.json();
};

// export const createStandardFromChat = async (authenticatedFetch, data) => {
//   try {
//     const { standard_id, Controls } = data;
    
//     // Generate standard_text from Controls
//     const standard_text = generateStandardText(Controls);
    
//     const response = await authenticatedFetch(`/api/standard/${standard_id}/chat`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         Controls,
//         standard_text
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to update standard from chat');
//     }

//     return await response.json();
//   } catch (error) {
//     console.error('Error in createStandardFromChat:', error);
//     throw error;
//   }
// };

export const getStandardById = async (authenticatedFetch, standardId) => {
  const response = await authenticatedFetch(`/api/standard/${standardId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch standard');
  }
  return await response.json();
};

export const createDomain = async (authenticatedFetch, domainName) => {
  const response = await authenticatedFetch('/api/domain/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: domainName })
  });

  if (!response.ok) {
    throw new Error('Failed to create domain');
  }

  return await response.json();
};

export const editStandard = async (authenticatedFetch, domainName, standardId, newValue) => {
  const response = await authenticatedFetch(`/api/standard/${standardId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: newValue,
      domain_name: domainName
    })
  });

  if (!response.ok) {
    throw new Error('Failed to edit standard');
  }

  return await response.json();
};

export const renameDomain = async (authenticatedFetch, oldName, newName) => {
  const response = await authenticatedFetch(`/api/domain/by-name/${encodeURIComponent(oldName)}/rename`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: newName })
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to rename domain');
  }
};

export const renameStandard = async (authenticatedFetch, domainName, oldName, newName) => {
  const response = await authenticatedFetch(
    `/api/standard/by-name/${encodeURIComponent(domainName)}/${encodeURIComponent(oldName)}/rename`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: newName })
    }
  );

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to rename standard');
  }
};

export const addPolicy = async (authenticatedFetch, policyData) => {
  const response = await authenticatedFetch('/api/policy/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(policyData)
  });

  if (!response.ok) {
    throw new Error('Failed to create policy');
  }

  return await response.json();
};

export const createPolicy = async (authenticatedFetch, policyData) => {
  const response = await authenticatedFetch('/api/policy/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(policyData)
  });

  const data = await response.json();
  
  if (!response.ok) {
    if (data.error === "Validation error" && data.message?.name) {
      throw new Error(data.message.name[0]);
    }
    throw new Error('Failed to create policy');
  }

  return data;
};

export const renameItem = async (authenticatedFetch, renameTarget) => {
  const { type, domain, standard, newName } = renameTarget;

  if (type === 'domain') {
    const response = await authenticatedFetch('/api/domain/rename', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldName: domain,
        newName: newName
      }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to rename domain');
    }
  } else {
    const response = await authenticatedFetch('/api/standard/rename', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        domain: domain,
        oldName: standard,
        newName: newName
      }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to rename standard');
    }
  }
};

export const deleteControl = async (authenticatedFetch, controlId, standardId = null) => {
  const response = await authenticatedFetch(`/api/control/${controlId}`, {
    method: 'DELETE'
  });

  if (!response.ok) {
    throw new Error('Failed to delete control');
  }

  // If standardId is provided, fetch updated standard details
  if (standardId) {
    const standardResponse = await authenticatedFetch(`/api/standard/${standardId}`);
    if (!standardResponse.ok) {
      throw new Error('Failed to fetch standard details');
    }
    return standardResponse.json();
  }
};

export const addControl = async (authenticatedFetch, controlData, standardId) => {
  // Add the control
  const response = await authenticatedFetch('/api/control/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(controlData),
  });

  if (!response.ok) {
    throw new Error('Failed to add control');
  }

  // Fetch all controls for this standard to get the updated list
  const standardResponse = await authenticatedFetch(`/api/standard/${standardId}`);
  if (!standardResponse.ok) {
    throw new Error('Failed to fetch standard details');
  }
  
  const standardData = await standardResponse.json();
  return standardData;
};

export const deletePolicy = async (authenticatedFetch, policyId) => {
  const response = await authenticatedFetch(`/api/policy/${policyId}`, {
    method: 'DELETE'
  });

  if (!response.ok) {
    throw new Error('Failed to delete policy');
  }
};

export const updateDomains = async (authenticatedFetch) => {
  const updatedDomains = await fetchDomains(authenticatedFetch);
  if (!updatedDomains) {
    throw new Error('Failed to update domains');
  }
  return updatedDomains;
};

export const fetchStandardData = async (authenticatedFetch, domainName, standardName) => {
  try {
    const companyName = await fetchUserProfile(authenticatedFetch);
    const domainsData = await fetchDomains(authenticatedFetch);
    
    // Check if domain exists
    if (!domainsData[domainName]) {
      throw new Error('Domain not found');
    }
    
    const standards = domainsData[domainName];
    const standardData = await fetchStandardDetails(authenticatedFetch, domainName, standardName, domainsData);
    
    return {
      companyName,
      domainsData,
      standardData
    };
  } catch (error) {
    console.error('Error in fetchStandardData:', error);
    throw error;
  }
};

export const addPolicyToStandard = async (authenticatedFetch, standardId, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/policies/${policyId}`, {
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error('Failed to add policy to standard');
    }
  } catch (error) {
    console.error('Error adding policy to standard:', error);
    throw error;
  }
};

export const removePolicyFromStandard = async (authenticatedFetch, standardId, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/policies/${policyId}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new Error('Failed to remove policy from standard');
    }

    return await response.json();
  } catch (error) {
    console.error('Error removing policy from standard:', error);
    throw error;
  }
};

export const fetchStandardPolicies = async (authenticatedFetch, standardId) => {
  try {
    // First get the standard details to ensure we have the latest policy list
    const response = await authenticatedFetch(`/api/standard/${standardId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch standard details');
    }
    const standardData = await response.json();
    
    // Now fetch details for each policy
    if (standardData.standard && standardData.standard.policies) {
      const policyIds = standardData.standard.policies;
      const policyPromises = policyIds.map(id => 
        authenticatedFetch(`/api/policy/${id}`).then(res => res.json())
      );
      
      const policies = await Promise.all(policyPromises);
      return policies.map(p => p.policy);
    }
    return [];
  } catch (error) {
    console.error('Error fetching policies:', error);
    throw error;
  }
};

export const fetchStandardDomain = async (authenticatedFetch, standardId) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/domain`);
    if (!response.ok) {
      throw new Error('Failed to fetch standard domain');
    }
    const data = await response.json();
    return data.domain.name;
  } catch (error) {
    console.error('Error fetching standard domain:', error);
    return 'Facilities'; // Default fallback
  }
};

export const fetchStandardComprehensive = async (authenticatedFetch, domainName, standardName, domains) => {
  try {
    const standardDetails = await fetchStandardDetails(authenticatedFetch, domainName, standardName, domains);
    const policies = await fetchStandardPolicies(authenticatedFetch, standardDetails.id);
    
    return {
      standard: {
        id: standardDetails.id,
        name: standardDetails.name,
        title: standardDetails.title,
        description: standardDetails.description,
        policies: policies.map(policy => ({
          id: policy.id,
          name: policy.name,
          title: policy.title,
          description: policy.description,
          healthy: policy.healthy
        })),
        controls: standardDetails.controls.map(control => ({
          id: control.id,
          name: control.name,
          title: control.title,
          description: control.description,
          healthy: control.healthy,
          evidence: control.evidence ? {
            id: control.evidence.id,
            name: control.evidence.name,
            healthy: control.evidence.healthy
          } : null
        }))
      }
    };
  } catch (error) {
    console.error('Error fetching comprehensive standard details:', error);
    throw error;
  }
};

export const fetchStandardHistory = async (authenticatedFetch, standardId) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/history/`);
    if (!response.ok) {
      throw new Error('Failed to fetch standard history');
    }
    
    const data = await response.json();
    
    if (!data || !data.history || !Array.isArray(data.history)) {
      console.error('Invalid history data format received:', data);
      return [];
    }

    // Transform and sort the history records
    return data.history.map(record => ({
      id: record.id,
      version: record.version,
      timestamp: new Date(record.timestamp),
      name: record.name,
      description: record.description,
      standard_text: record.standard_text,
      published: record.published,
      domain_id: record.domain_id,
      company_id: record.company_id,
      controls: (record.controls || []).map(control => ({
        id: control.id,
        name: control.name,
        title: control.title,
        description: control.description,
        text: control.text,
        healthy: control.healthy,
        created_at: control.created_at,
        platform: control.platform,
        test: control.test,
        threshold: control.threshold,
        frequency: control.frequency,
        frequency_unit: control.frequency_unit,
        quantity: control.quantity,
        unit: control.unit,
        risk_formula: control.risk_formula,
        risk_weight: control.risk_weight,
        standard_id: control.standard_id,
        company_id: control.company_id,
        version: control.version,
        evidences: (control.evidences || []).map(evidence => ({
          id: evidence.id,
          name: evidence.name,
          healthy: evidence.healthy,
          frequency: evidence.frequency,
          frequency_unit: evidence.frequency_unit
        }))
      })),
      policies: (record.policies || []).map(policy => ({
        id: policy.id,
        name: policy.name,
        title: policy.title,
        description: policy.description,
        healthy: policy.healthy
      }))
    })).sort((a, b) => b.version - a.version);
  } catch (error) {
    console.error('Error fetching standard history:', error);
    throw error;
  }
};

export const fetchControlHistory = async (authenticatedFetch, controlId) => {
  try {
    const response = await authenticatedFetch(`/api/control/${controlId}/history/`);
    if (!response.ok) {
      throw new Error('Failed to fetch control history');
    }
    
    const data = await response.json();
    
    if (!data || !data.history || !Array.isArray(data.history)) {
      console.error('Invalid history data format received:', data);
      return [];
    }

    // Transform and sort the history records
    return data.history.map(record => ({
      id: record.id,
      version: record.version,
      timestamp: new Date(record.timestamp),
      name: record.name,
      title: record.title,
      description: record.description,
      text: record.text,
      threshold: record.threshold,
      unit: record.unit,
      frequency: record.frequency,
      frequency_unit: record.frequency_unit,
      platform: record.platform,
      test: record.test,
      quantity: record.quantity,
      bucket_url: record.bucket_url,
      published: record.published,
      healthy: record.healthy,
      risk_weight: record.risk_weight,
      risk_formula: record.risk_formula,
      risk_availability: record.risk_availability,
      risk_confidentiality: record.risk_confidentiality,
      risk_integrity: record.risk_integrity,
      standard_id: record.standard_id,
      company_id: record.company_id,
      evidence_ids: record.evidence_ids || [],
      policy_ids: record.policy_ids || []
    })).sort((a, b) => b.version - a.version); // Sort by version descending

  } catch (error) {
    console.error('Error fetching control history:', error);
    throw error;
  }
};

export const renameControl = async (authenticatedFetch, controlId, newName) => {
  const response = await authenticatedFetch(`/api/control/${controlId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: newName.replace(/[^a-zA-Z0-9]/g, ''),  // Clean name for variable use
      title: newName  // Keep original name as title
    })
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to rename control');
  }

  return await response.json();
};

export const deleteControlFromSidebar = async (authenticatedFetch, controlId) => {
  const response = await authenticatedFetch(`/api/control/${controlId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to delete control');
  }
};

export const updateStandardReviewDate = async (authenticatedFetch, standardId, date) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/review_date`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ review_date: date })
    });

    if (!response.ok) {
      throw new Error('Failed to update standard review date');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating standard review date:', error);
    throw error;
  }
};

export const fetchStandardSchedule = async (authenticatedFetch, standardId) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/schedule`);
    if (!response.ok) {
      throw new Error('Failed to fetch standard schedule');
    }
    
    const data = await response.json();
    return {
      review_date: data.review_date,
      created_at: data.created_at,
      last_updated: data.last_updated
    };
  } catch (error) {
    console.error('Error fetching standard schedule:', error);
    throw error;
  }
};

export const updateControlReviewDate = async (authenticatedFetch, controlId, date) => {
  try {
    const response = await authenticatedFetch(`/api/control/${controlId}/review_date`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ review_date: date })
    });

    if (!response.ok) {
      throw new Error('Failed to update control review date');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating control review date:', error);
    throw error;
  }
};

export const fetchControlSchedule = async (authenticatedFetch, controlId) => {
  try {
    const response = await authenticatedFetch(`/api/control/${controlId}/schedule`);
    if (!response.ok) {
      throw new Error('Failed to fetch control schedule');
    }
    
    const data = await response.json();
    return {
      review_date: data.review_date,
      created_at: data.created_at,
      last_updated: data.last_updated
    };
  } catch (error) {
    console.error('Error fetching control schedule:', error);
    throw error;
  }
};

export const updateControlDescription = async (authenticatedFetch, controlId, updatedControl) => {
  try {
    console.log('Sending control update:', updatedControl);
    const response = await authenticatedFetch(`/api/control/${controlId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedControl)
    });

    if (!response.ok) {
      throw new Error('Failed to update control description');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating control description:', error);
    throw error;
  }
};

export const fetchStandardRisks = async (authenticatedFetch, standardId) => {
  try {
    const response = await authenticatedFetch(`/api/standard/${standardId}/risks`);
    if (!response.ok) {
      throw new Error('Failed to fetch standard risks');
    }
    
    const data = await response.json();
    if (!data || !data.risks) {
      console.error('Invalid data format received:', data);
      return [];
    }

    // Transform the object format into an array format for compatibility
    return Object.entries(data.risks).map(([name, risk]) => ({
      id: risk.risk_id,
      name: name,
      domain_name: risk.risk_domain,
      risk_impact: risk.risk_impact || 1,
      risk_frequency: risk.risk_frequency || 1,
      risk_weight: risk.risk_weight || 1,
      risk_description: risk.risk_description || '',
      displayName: name.replace(/([A-Z])/g, ' $1').trim()
    }));
  } catch (error) {
    console.error('Error fetching standard risks:', error);
    return [];
  }
};