// routesConfig.js
import React from 'react';
import Login from 'pages/Login';
import Forest from 'pages/Governance/Forest';
import Audit from 'pages/Governance/Audit';
import Evidence from 'pages/Governance/evidence-section';
import EvidenceDetails from 'pages/Governance/evidence-details';
import StandardSection from 'pages/Company/standard-section';
import StandardDetails from 'pages/Company/standard-details';
import PolicySection from 'pages/Governance/policy-section';
import PolicyDetails from 'pages/Governance/policy-details';
import PrivateRoute from 'utils/PrivateRoute';
import NotFound from 'pages/NotFound';
import Profile from 'pages/Profile';
import OnboardingSection from 'pages/Onboarding/onboarding-section';
import ProcessSection from 'pages/Company/process-section';
import CalendarSection from 'pages/Governance/calendar-section';
import QuerySection from 'pages/Company/query-section';
import RiskSection from 'pages/Governance/risk-section';
import OnboardingSignup from 'pages/Onboarding/onboarding-signup';
import AccountSection from 'pages/Settings/account-section';
import ControlSection from 'pages/Company/control-section';
import ProcessDetails from 'pages/Company/process-details';
import RiskDetails from 'pages/Governance/risk-details';
import Health from 'pages/Health';

const routesConfig = [
  { path: '/', element: (<PrivateRoute><Forest /></PrivateRoute>) },

  // Auth Routes (Protected)
  {
    path: '/profile',
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    )
  },

  // Governance Routes (Protected)
  {
    path: '/governance/forest',
    element: (
      <PrivateRoute>
        <Forest />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/audit',
    element: (
      <PrivateRoute>
        <Audit />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/policy',
    element: (
      <PrivateRoute>
        <PolicySection />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/policy/:policyName',
    element: (
      <PrivateRoute>
        <PolicyDetails />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/evidence',
    element: (
      <PrivateRoute>
        <Evidence />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/evidence/:evidenceName',
    element: (
      <PrivateRoute>
        <EvidenceDetails />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/calendar',
    element: (
      <PrivateRoute>
        <CalendarSection />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/risk',
    element: (
      <PrivateRoute>
        <RiskSection />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/risk/:domainName',
    element: (
      <PrivateRoute>
        <RiskSection />
      </PrivateRoute>
    )
  },
  {
    path: '/governance/risk/:domainName/:riskName',
    element: (
      <PrivateRoute>
        <RiskDetails />
      </PrivateRoute>
    )
  },

  // Company Routes
  {
    path: '/company/standards',
    element: (
      <PrivateRoute>
        <StandardSection />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/standards/:domainName',
    element: (
      <PrivateRoute>
        <StandardSection />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/standards/:domainName/:standardName',
    element: (
      <PrivateRoute>
        <StandardDetails />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/standards/:domainName/:standardName/:controlName',
    element: (
      <PrivateRoute>
        <StandardDetails />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/controls',
    element: (
      <PrivateRoute>
        <ControlSection />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/processes',
    element: (
      <PrivateRoute>
        <ProcessSection />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/process/:processName',
    element: (
      <PrivateRoute>
        <ProcessDetails />
      </PrivateRoute>
    )
  },
  {
    path: '/company/processes/:processType/:processValue',
    element: (
      <PrivateRoute>
        <ProcessSection />
      </PrivateRoute>
    ),
  },
  {
    path: '/company/queries',
    element: (
      <PrivateRoute>
        <QuerySection />
      </PrivateRoute>
    ),
  },
  // Authentication Routes
  { path: '/login', element: <Login /> },
  { path: '/signup', element: <OnboardingSignup /> },

  // Onboarding Route
  {
    path: '/onboarding',
    element: (
      <PrivateRoute>
        <OnboardingSection />
      </PrivateRoute>
    )
  },

  // Settings Route
  {
    path: '/settings/account',
    element: (
      <PrivateRoute>
        <AccountSection />
      </PrivateRoute>
    )
  },

  // Health Route
  {
    path: '/health',
    element: <Health />
  },

  // Catch-all Route
  { path: '*', element: <NotFound /> },
];

export default routesConfig;
