// src/components/Objects/PolicyTextViewer.js

import React from 'react';
import DOMPurify from 'dompurify';
import 'styles/Objects.css';

const stripMetadata = (text) => {
  if (!text) return '';
  return text.replace(/^---[\s\S]*?---/, '').trim();
};

const PolicyTextViewer = ({ content }) => {
  const strippedContent = stripMetadata(content || '');
  const sanitizedContent = DOMPurify.sanitize(strippedContent);

  return (
    <div 
      className="policy-text-viewer"
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );
};

export default PolicyTextViewer;
