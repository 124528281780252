import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ContextSearch from 'components/Objects/context-search';
import { useAuth } from 'contexts/AuthContext';
import SeverityDropdown from 'components/Objects/severity-dropdown';
import { calculateRiskScore, getRiskSeverityClass, getRiskSeverityName } from 'components/Objects/severity-dropdown';
import { TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  Link as LinkIcon
} from '@mui/icons-material';

const RiskStandards = ({ standards = [], isExpanded, onExpandClick, riskId, onAssociationChange }) => {
  const navigate = useNavigate();
  const { authenticatedFetch } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [previewStandards, setPreviewStandards] = useState([]);
  const [pendingStandardsToRemove, setPendingStandardsToRemove] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState(null);
  const [editedDescriptions, setEditedDescriptions] = useState({});
  const [standardImpacts, setStandardImpacts] = useState({});
  const [standardFrequencies, setStandardFrequencies] = useState({});
  const [markedForDeletion, setMarkedForDeletion] = useState(new Set());
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initialValues = {};
    const initialImpacts = {};
    const initialFrequencies = {};
    
    standards?.forEach(standard => {
      initialValues[standard.standard_id] = standard.risk_description || '';
      initialImpacts[standard.standard_id] = standard.risk_impact || 1;
      initialFrequencies[standard.standard_id] = standard.risk_frequency || 1;
    });
    
    setEditedDescriptions(initialValues);
    setStandardImpacts(initialImpacts);
    setStandardFrequencies(initialFrequencies);
  }, [standards]);

  const handleDescriptionChange = (standardId, newDescription) => {
    setEditedDescriptions(prev => ({
      ...prev,
      [standardId]: newDescription
    }));
  };

  const handlePreviewSelect = (standard) => {
    const exists = standards.some(s => s.standard_id === standard.id) || 
                   previewStandards.some(s => s.standard_id === standard.id);
    if (!exists) {
      const newStandard = {
        standard_id: standard.id,
        standard_name: standard.name,
        standard_domain: standard.domain,
        risk_impact: 1,
        risk_frequency: 1,
        risk_weight: 1,
        risk_description: ''
      };
      setPreviewStandards(prev => [...prev, newStandard]);
      setStandardImpacts(prev => ({ ...prev, [standard.id]: 1 }));
      setStandardFrequencies(prev => ({ ...prev, [standard.id]: 1 }));
      setEditedDescriptions(prev => ({ ...prev, [standard.id]: '' }));
    }
  };

  const handlePreviewDelete = (standardToDelete) => {
    setPreviewStandards(prev => prev.filter(s => s.standard_id !== standardToDelete.standard_id));
  };

  const handleStandardRemove = (standard) => {
    setMarkedForDeletion(prev => {
      const newSet = new Set(prev);
      newSet.add(standard.standard_id);
      return newSet;
    });
    setPendingStandardsToRemove(prev => [...prev, standard]);
  };

  const handleCancel = () => {
    setMarkedForDeletion(new Set());
    setPendingStandardsToRemove([]);
    setPreviewStandards([]);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleConfirm = () => {
    // Check if any existing standards were modified
    const hasModifiedStandards = standards.some(standard => {
      const impactChanged = standardImpacts[standard.standard_id] !== standard.risk_impact;
      const frequencyChanged = standardFrequencies[standard.standard_id] !== standard.risk_frequency;
      const descriptionChanged = editedDescriptions[standard.standard_id] !== standard.risk_description;
      return impactChanged || frequencyChanged || descriptionChanged;
    });

    if (previewStandards.length > 0) {
      setConfirmationType('add');
      setShowConfirmation(true);
    } else if (pendingStandardsToRemove.length > 0) {
      setConfirmationType('remove');
      setShowConfirmation(true);
    } else if (hasModifiedStandards) {
      // Add a new confirmation type for modifications
      setConfirmationType('modify');
      setShowConfirmation(true);
    } else {
      setErrorMessage('No changes have been made to save.');
      setShowError(true);
    }
  };

  const handleConfirmAdd = async () => {
    try {
      if (!riskId) {
        throw new Error('Risk ID is missing');
      }

      const addPromises = previewStandards.map(async standard => {
        const response = await authenticatedFetch(`/api/risk/${riskId}/standards/${standard.standard_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            risk_impact: standardImpacts[standard.standard_id] || 1,
            risk_frequency: standardFrequencies[standard.standard_id] || 1,
            risk_description: editedDescriptions[standard.standard_id] || ''
          })
        });

        if (!response.ok) {
          throw new Error('Failed to add standard');
        }

        return {
          standard_id: standard.standard_id,
          standard_name: standard.standard_name,
          standard_domain: standard.standard_domain,
          risk_impact: standardImpacts[standard.standard_id] || 1,
          risk_frequency: standardFrequencies[standard.standard_id] || 1,
          risk_description: editedDescriptions[standard.standard_id] || '',
          risk_weight: (standardImpacts[standard.standard_id] || 1) * (standardFrequencies[standard.standard_id] || 1)
        };
      });

      const addedStandards = await Promise.all(addPromises);
      
      setPreviewStandards([]);
      setIsEditing(false);
      setShowConfirmation(false);

      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error updating standards:', error);
      setErrorMessage('Failed to update standards. Please try again.');
      setShowError(true);
    }
  };

  const handleConfirmRemove = async () => {
    try {
      const removePromises = pendingStandardsToRemove.map(async standard => {
        const response = await authenticatedFetch(`/api/risk/${riskId}/standards/${standard.standard_id}`, {
          method: 'DELETE'
        });

        if (!response.ok) {
          throw new Error('Failed to remove standard');
        }
        return standard.standard_id;
      });
      
      await Promise.all(removePromises);
      
      setPendingStandardsToRemove([]);
      setMarkedForDeletion(new Set());
      setIsEditing(false);
      setShowConfirmation(false);

      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error removing standards:', error);
      setErrorMessage('Failed to remove standards. Please try again.');
      setShowError(true);
    }
  };

  const handleConfirmModify = async () => {
    try {
      if (!riskId) {
        throw new Error('Risk ID is missing');
      }

      const modifyPromises = standards
        .filter(standard => {
          const impactChanged = standardImpacts[standard.standard_id] !== standard.risk_impact;
          const frequencyChanged = standardFrequencies[standard.standard_id] !== standard.risk_frequency;
          const descriptionChanged = editedDescriptions[standard.standard_id] !== standard.risk_description;
          return impactChanged || frequencyChanged || descriptionChanged;
        })
        .map(async standard => {
          const response = await authenticatedFetch(`/api/risk/${riskId}/standards/${standard.standard_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              risk_impact: standardImpacts[standard.standard_id] || standard.risk_impact,
              risk_frequency: standardFrequencies[standard.standard_id] || standard.risk_frequency,
              risk_description: editedDescriptions[standard.standard_id] || standard.risk_description
            })
          });

          if (!response.ok) {
            throw new Error('Failed to modify standard');
          }
        });

      await Promise.all(modifyPromises);
      
      setIsEditing(false);
      setShowConfirmation(false);

      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error modifying standards:', error);
      setErrorMessage('Failed to modify standards. Please try again.');
      setShowError(true);
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationType(null);
  };

  const handleImpactChange = (standardId, value) => {
    setStandardImpacts(prev => ({
      ...prev,
      [standardId]: Math.max(1, Math.min(5, parseInt(value) || 1))
    }));
  };

  const handleFrequencyChange = (standardId, value) => {
    setStandardFrequencies(prev => ({
      ...prev,
      [standardId]: Math.max(1, Math.min(5, parseInt(value) || 1))
    }));
  };

  const renderContextSearch = () => (
    <ContextSearch
      context="standard"
      fetchUrl="/api/standard/"
      onSelect={handlePreviewSelect}
      placeholder="Search for standards..."
      showSecondaryField={true}
      secondaryField="description"
      transformResponse={(response) => {
        return response.standards.map(standard => ({
          id: standard.id,
          name: standard.name,
          domain: standard.domain,
          description: standard.description
        }));
      }}
    />
  );

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: isExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: isExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Standards</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isEditing ? (
              <IconButton 
                onClick={handleEditClick}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleConfirm}
                  sx={{ mr: 1 }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={onExpandClick}
              sx={{ 
                transform: isExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
        {isExpanded && (
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table sx={{ overflow: 'visible' }}>
              <TableHead>
                <TableRow>
                  <TableCell width="20%" sx={{ overflow: 'visible', textAlign: 'center' }}>Name</TableCell>
                  <TableCell width="40%" sx={{ overflow: 'visible', textAlign: 'center' }}>Description</TableCell>
                  <TableCell width="13%" sx={{ overflow: 'visible', textAlign: 'center' }}>Impact</TableCell>
                  <TableCell width="13%" sx={{ overflow: 'visible', textAlign: 'center' }}>Frequency</TableCell>
                  <TableCell width="14%" sx={{ overflow: 'visible', textAlign: 'center' }}>Risk</TableCell>
                  {isEditing && <TableCell sx={{ overflow: 'visible' }}></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {(!standards || standards.length === 0) && !previewStandards.length && (
                  <TableRow>
                    <TableCell colSpan={isEditing ? 6 : 5} align="center">
                      No Standards are associated with this Risk Category
                    </TableCell>
                  </TableRow>
                )}
                {standards?.map((standard, index) => (
                  <TableRow 
                    key={`${standard.standard_id}-${index}`}
                    className={markedForDeletion.has(standard.standard_id) ? 'marked-for-deletion' : ''}
                    sx={{
                      '& td': {
                        color: markedForDeletion.has(standard.standard_id) ? 'text.disabled' : 'inherit',
                        transition: 'all 0.2s ease-in-out'
                      },
                      '& td:last-child': {
                        color: 'inherit'
                      }
                    }}
                  >
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => navigate(`/company/standards/${encodeURIComponent(standard.standard_domain)}/${encodeURIComponent(standard.standard_name)}`)}
                        sx={{ 
                          textAlign: 'left',
                          color: 'var(--link-color)'
                        }}
                      >
                        {standard.standard_name}
                      </Button>
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      {isEditing ? (
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          value={editedDescriptions[standard.standard_id] || ''}
                          onChange={(e) => handleDescriptionChange(standard.standard_id, e.target.value)}
                          disabled={!isEditing}
                          sx={{
                            backgroundColor: 'var(--background-color)',
                            borderRadius: '4px',
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'var(--background-color)',
                              '& fieldset': {
                                borderColor: 'var(--text-color-light)',
                              },
                              '&:hover fieldset': {
                                borderColor: 'var(--text-color-light)',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'var(--text-color-light)',
                              },
                            },
                          }}
                        />
                      ) : (
                        <div 
                          dangerouslySetInnerHTML={{ __html: standard.risk_description || '-' }}
                          style={{ 
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word'
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible', position: 'relative', zIndex: 9999999999999999 }}>
                      <SeverityDropdown
                        value={standardImpacts[standard.standard_id] || standard.risk_impact}
                        onChange={(value) => handleImpactChange(standard.standard_id, value)}
                        isEditing={isEditing}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible', position: 'relative', zIndex: 9999999999999999 }}>
                      <SeverityDropdown
                        value={standardFrequencies[standard.standard_id] || standard.risk_frequency}
                        onChange={(value) => handleFrequencyChange(standard.standard_id, value)}
                        isEditing={isEditing}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Box className={`severity-value ${getRiskSeverityClass(standard.risk_weight)}`}>
                        {standard.risk_weight}
                        <Typography variant="caption" display="block">
                          {getRiskSeverityName(standard.risk_weight)}
                        </Typography>
                      </Box>
                    </TableCell>
                    {isEditing && (
                      <TableCell sx={{ overflow: 'visible' }}>
                        <IconButton
                          onClick={() => handleStandardRemove(standard)}
                          disabled={markedForDeletion.has(standard.standard_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {previewStandards.map((standard, index) => (
                  <TableRow key={`preview-${standard.standard_id}-${index}`}>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => navigate(`/company/standards/${encodeURIComponent(standard.standard_domain)}/${encodeURIComponent(standard.standard_name)}`)}
                        sx={{ 
                          textAlign: 'left',
                          color: 'var(--link-color)'
                        }}
                      >
                        {standard.standard_name}
                      </Button>
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={editedDescriptions[standard.standard_id] || ''}
                        onChange={(e) => handleDescriptionChange(standard.standard_id, e.target.value)}
                        sx={{
                          backgroundColor: 'var(--background-color)',
                          borderRadius: '4px',
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'var(--background-color)',
                            '& fieldset': {
                              borderColor: 'var(--title-color)',
                            },
                            '&:hover fieldset': {
                              borderColor: 'var(--title-color)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'var(--title-color)',
                            },
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible', position: 'relative', zIndex: 9999999999999999 }}>
                      <SeverityDropdown
                        value={standardImpacts[standard.standard_id] || 1}
                        onChange={(value) => handleImpactChange(standard.standard_id, value)}
                        isEditing={true}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible', position: 'relative', zIndex: 9999999999999999 }}>
                      <SeverityDropdown
                        value={standardFrequencies[standard.standard_id] || 1}
                        onChange={(value) => handleFrequencyChange(standard.standard_id, value)}
                        isEditing={true}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Box className={`severity-value ${getRiskSeverityClass(calculateRiskScore(
                        standardImpacts[standard.standard_id] || 1,
                        standardFrequencies[standard.standard_id] || 1
                      ))}`}>
                        {calculateRiskScore(
                          standardImpacts[standard.standard_id] || 1,
                          standardFrequencies[standard.standard_id] || 1
                        )}
                        <Typography variant="caption" display="block">
                          {getRiskSeverityName(calculateRiskScore(
                            standardImpacts[standard.standard_id] || 1,
                            standardFrequencies[standard.standard_id] || 1
                          ))}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <IconButton
                        onClick={() => handlePreviewDelete(standard)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {isEditing && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ 
                      textAlign: 'center', 
                      overflow: 'visible',
                      position: 'relative',
                      zIndex: 99999999
                    }}>
                      <ContextSearch
                        context="standard"
                        fetchUrl="/api/standard/"
                        onSelect={handlePreviewSelect}
                        placeholder="Search for standards..."
                        showSecondaryField={true}
                        secondaryField="description"
                        transformResponse={(response) => {
                          return response.standards.map(standard => ({
                            id: standard.id,
                            name: standard.name,
                            domain: standard.domain,
                            description: standard.description
                          }));
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          {confirmationType === 'add' 
            ? `Are you sure you want to add ${previewStandards.length} standard${previewStandards.length > 1 ? 's' : ''}?`
            : confirmationType === 'remove'
            ? `Are you sure you want to remove ${pendingStandardsToRemove.length} standard${pendingStandardsToRemove.length > 1 ? 's' : ''}?`
            : 'Are you sure you want to save these changes?'
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={
              confirmationType === 'add' 
                ? handleConfirmAdd 
                : confirmationType === 'remove'
                ? handleConfirmRemove
                : handleConfirmModify
            }
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          {errorMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

RiskStandards.propTypes = {
  standards: PropTypes.arrayOf(PropTypes.shape({
    risk_description: PropTypes.string,
    risk_frequency: PropTypes.number.isRequired,
    risk_impact: PropTypes.number.isRequired,
    risk_weight: PropTypes.number.isRequired,
    standard_domain: PropTypes.string.isRequired,
    standard_id: PropTypes.number.isRequired,
    standard_name: PropTypes.string.isRequired
  })),
  isExpanded: PropTypes.bool.isRequired,
  onExpandClick: PropTypes.func.isRequired,
  riskId: PropTypes.string,
  onAssociationChange: PropTypes.func
};

export default RiskStandards;