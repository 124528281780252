import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Error from 'components/Objects/Error';
import ControlDescription from './control-description';
import ControlSchedule from './control-schedule';
import ControlEvidence from './control-evidence';
import ControlProcess from './control-process';
import ControlGraph from './control-graph';
import {
  Box,
  Paper,
  Typography
} from '@mui/material';
import { 
  fetchControlByName,
  fetchControlStandard,
  downloadEvidence,
  deleteControl,
  editControl
} from './control-queries';
import { calculateTimeRemaining } from 'utils/dates';
import { camelCaseToSpaces } from 'utils/jinja';
import {
  Notes as NotesIcon
} from '@mui/icons-material';
import 'styles/Objects.css';
import 'styles/colors.css';
import 'styles/tables.css';

function StandardControl() {
  const [control, setControl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { domainName, standardName, controlName } = useParams();
  const { state } = useLocation();
  const controlId = state?.controlId;
  const [evidenceExpanded, setEvidenceExpanded] = useState(true);
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const [scheduleExpanded, setScheduleExpanded] = useState(true);
  const [processExpanded, setProcessExpanded] = useState(true);
  const [graphExpanded, setGraphExpanded] = useState(true);
  const { authenticatedFetch } = useAuth();
  const navigate = useNavigate();

  const handleAssociationChange = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        console.log('Fetching control with ID:', controlId);
        const response = await authenticatedFetch(`/api/control/${controlId}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to fetch control data: ${errorData.message || response.statusText}`);
        }
        const data = await response.json();
        if (!data || !data.control) {
          throw new Error('Invalid control data received');
        }
        console.log('Received control data:', data);
        setControl({
          ...data.control,
          domainName,
          standardName
        });
      } catch (error) {
        console.error('Error fetching control:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (controlId) {
      console.log('Control ID changed:', controlId);
      fetchData();
    } else {
      console.warn('No control ID provided');
      setError('No control ID provided');
    }
  }, [authenticatedFetch, controlId, refreshTrigger, domainName, standardName]);

  if (error) {
    return <Error message={error} onClose={() => setError(null)} />;
  }

  return (
    <div className="control-details-content">
      {isLoading ? (
        <div>Loading control details...</div>
      ) : control ? (
        <>
          <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
            <Paper sx={{ borderRadius: 0, borderColor: 'divider', mb: 1 }} elevation={0}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                p: 1.75,
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <NotesIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
                  <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
                    {camelCaseToSpaces(control.name)}
                  </Typography>
                  <Typography variant="body2" sx={{ backgroundColor: 'var(--button-neutral-color)', color: 'var(--text-color-dark-alt)', borderRadius: '4px', p: 0.5, pl: 1, pr: 1 }}>
                    {control.domainName}
                  </Typography>
                  <Typography variant="body2" sx={{ backgroundColor: 'var(--button-neutral-color)', color: 'var(--text-color-dark-alt)', borderRadius: '4px', p: 0.5, pl: 1, pr: 1 }}>
                    {control.standardName}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <div className="tables-container">
            <ControlDescription 
              control={control}
              descriptionExpanded={descriptionExpanded}
              setDescriptionExpanded={setDescriptionExpanded}
              setControl={setControl}
              onAssociationChange={handleAssociationChange}
            />

            <ControlEvidence
              control={control}
              evidenceExpanded={evidenceExpanded}
              setEvidenceExpanded={setEvidenceExpanded}
              setControl={setControl}
              onAssociationChange={handleAssociationChange}
            />
            
            <ControlProcess
              control={control}
              processExpanded={processExpanded}
              setProcessExpanded={setProcessExpanded}
              setControl={setControl}
              onAssociationChange={handleAssociationChange}
            />

            <ControlSchedule
              control={control}
              scheduleExpanded={scheduleExpanded}
              setScheduleExpanded={setScheduleExpanded}
              setControl={setControl}
              onAssociationChange={handleAssociationChange}
            />

            <ControlGraph
              controlId={controlId}
              graphExpanded={graphExpanded}
              setGraphExpanded={setGraphExpanded}
              refreshTrigger={refreshTrigger}
            />

          </div>
        </>
      ) : (
        <div>Control not found</div>
      )}
    </div>
  );
}

export default StandardControl; 