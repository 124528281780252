import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ContextSearch from 'components/Objects/context-search';
import { useAuth } from 'contexts/AuthContext';
import { fetchRisks } from 'pages/Governance/risk-queries';
import { fetchStandardRisks } from 'pages/Company/standard-queries';
import { camelToSpace } from 'utils/format';
import { calculateRiskScore, getRiskSeverityClass, getRiskSeverityName } from 'components/Objects/severity-dropdown';
import SeverityDropdown from 'components/Objects/severity-dropdown';
import { TextField } from '@mui/material';

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Link as LinkIcon
} from '@mui/icons-material';

const StandardRisks = ({
  isRisksEditing,
  risksExpanded,
  setIsRisksEditing,
  setRisksExpanded,
  detailedRisks,
  setDetailedRisks,
  handleRiskDeleteClick,
  handleRiskClick,
  standardId,
  setStandard,
  onAssociationChange,
  allRisks
}) => {
  const navigate = useNavigate();
  const [previewRisks, setPreviewRisks] = useState([]);
  const { authenticatedFetch } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingRisksToRemove, setPendingRisksToRemove] = useState([]);
  const [confirmationType, setConfirmationType] = useState(null);
  const [editedDescriptions, setEditedDescriptions] = useState({});
  const [hasDescriptionChanged, setHasDescriptionChanged] = useState(false);
  const [riskImpacts, setRiskImpacts] = useState({});
  const [riskFrequencies, setRiskFrequencies] = useState({});
  const [availableRisks, setAvailableRisks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [markedForDeletion, setMarkedForDeletion] = useState(new Set());
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadRisks = async () => {
      try {
        // Load available risks for dropdown
        const availableResponse = await fetchRisks(authenticatedFetch, true);
        if (availableResponse && availableResponse.domains) {
          const allRisks = availableResponse.domains.reduce((acc, domain) => {
            return [...acc, ...domain.risks.map(risk => ({
              ...risk,
              domain: domain.name.replace(/([A-Z])/g, ' $1').trim(),
              displayName: risk.name.replace(/([A-Z])/g, ' $1').trim()
            }))];
          }, []);
          setAvailableRisks(allRisks);
        }

        // Load risks associated with this standard
        if (standardId) {
          const standardRisks = await fetchStandardRisks(authenticatedFetch, standardId);
          setDetailedRisks(standardRisks);
        }
      } catch (error) {
        console.error('Error loading risks:', error);
      } finally {
        setLoading(false);
      }
    };

    loadRisks();
  }, [authenticatedFetch, standardId]);

  useEffect(() => {
    const initialDescriptions = {};
    const initialImpacts = {};
    const initialFrequencies = {};
    
    detailedRisks?.forEach(risk => {
      initialDescriptions[risk.id] = risk.risk_description || '';
      initialImpacts[risk.id] = risk.risk_impact || 1;
      initialFrequencies[risk.id] = risk.risk_frequency || 1;
    });
    
    setEditedDescriptions(initialDescriptions);
    setRiskImpacts(initialImpacts);
    setRiskFrequencies(initialFrequencies);
  }, [detailedRisks]);

  const handleDescriptionChange = (riskId, newDescription) => {
    setEditedDescriptions(prev => ({
      ...prev,
      [riskId]: newDescription
    }));
    setHasDescriptionChanged(true);
  };

  const handlePreviewSelect = (risk) => {
    const exists = previewRisks.some(r => r.id === risk.id);
    if (!exists) {
      setPreviewRisks(prev => [...prev, risk]);
      setRiskImpacts(prev => ({ ...prev, [risk.id]: 1 }));
      setRiskFrequencies(prev => ({ ...prev, [risk.id]: 1 }));
    }
  };

  const handlePreviewDelete = (riskToDelete) => {
    setPreviewRisks(prev => prev.filter(r => r.id !== riskToDelete.id));
    setRiskImpacts(prev => {
      const { [riskToDelete.id]: _, ...rest } = prev;
      return rest;
    });
    setRiskFrequencies(prev => {
      const { [riskToDelete.id]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleCancel = () => {
    setMarkedForDeletion(new Set());
    setPendingRisksToRemove([]);
    setIsRisksEditing(false);
  };

  const handleRiskRemove = (risk) => {
    setMarkedForDeletion(prev => {
      const newSet = new Set(prev);
      newSet.add(risk.id);
      return newSet;
    });
    setPendingRisksToRemove(prev => [...prev, risk]);
  };

  const handleImpactChange = (riskId, value) => {
    setRiskImpacts(prev => ({
      ...prev,
      [riskId]: Math.max(1, Math.min(5, parseInt(value) || 1))
    }));
  };

  const handleFrequencyChange = (riskId, value) => {
    setRiskFrequencies(prev => ({
      ...prev,
      [riskId]: Math.max(1, Math.min(5, parseInt(value) || 1))
    }));
  };

  const handleConfirm = () => {
    if (!hasDescriptionChanged && previewRisks.length === 0 && pendingRisksToRemove.length === 0) {
      setErrorMessage('No changes have been made to save.');
      setShowError(true);
      return;
    }

    if (previewRisks.length > 0 || hasDescriptionChanged) {
      setConfirmationType('add');
      setShowConfirmation(true);
    } else if (pendingRisksToRemove.length > 0) {
      setConfirmationType('remove');
      setShowConfirmation(true);
    }
  };

  const handleConfirmAdd = async () => {
    try {
      if (!standardId) {
        throw new Error('Standard ID is missing');
      }

      // Handle new risks
      const addPromises = previewRisks.map(async risk => {
        const response = await authenticatedFetch(`/api/standard/${standardId}/risks/${risk.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            risk_impact: riskImpacts[risk.id] || 1,
            risk_frequency: riskFrequencies[risk.id] || 1,
            risk_description: editedDescriptions[risk.id] || ''
          })
        });

        if (!response.ok) {
          throw new Error('Failed to add risk');
        }

        return {
          id: risk.id,
          name: risk.name,
          description: editedDescriptions[risk.id] || '',
          risk_impact: riskImpacts[risk.id] || 1,
          risk_frequency: riskFrequencies[risk.id] || 1
        };
      });

      // Handle existing risks updates
      const updatePromises = detailedRisks.map(async risk => {
        const response = await authenticatedFetch(`/api/standard/${standardId}/risks/${risk.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            risk_impact: riskImpacts[risk.id] || risk.risk_impact,
            risk_frequency: riskFrequencies[risk.id] || risk.risk_frequency,
            risk_description: editedDescriptions[risk.id] || risk.risk_description
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update risk');
        }

        return {
          ...risk,
          risk_impact: riskImpacts[risk.id] || risk.risk_impact,
          risk_frequency: riskFrequencies[risk.id] || risk.risk_frequency,
          risk_description: editedDescriptions[risk.id] || risk.risk_description
        };
      });

      const [addedRisks, updatedRisks] = await Promise.all([
        Promise.all(addPromises),
        Promise.all(updatePromises)
      ]);

      // Combine new and updated risks
      const allRisks = [...updatedRisks, ...addedRisks];
      
      setDetailedRisks(allRisks);
      setPreviewRisks([]);
      setIsRisksEditing(false);
      setShowConfirmation(false);

      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error updating risks:', error);
    }
  };

  const handleConfirmRemove = async () => {
    try {
      const removePromises = pendingRisksToRemove.map(risk => {
        return authenticatedFetch(`/api/standard/${standardId}/risks/${risk.id}`, {
          method: 'DELETE'
        });
      });
      
      await Promise.all(removePromises);
      
      const updatedRisks = detailedRisks.filter(
        risk => !pendingRisksToRemove.find(pr => pr.id === risk.id)
      );
      
      setStandard(prev => ({
        ...prev,
        risks: updatedRisks
      }));
      
      setPendingRisksToRemove([]);
      setIsRisksEditing(false);
      setShowConfirmation(false);

      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error removing risks from standard:', error);
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationType(null);
  };

  const renderContextSearch = () => (
    <ContextSearch
      context="risk"
      onSelect={handlePreviewSelect}
      placeholder="Search for risks..."
      localData={availableRisks}
      transformResponse={(data) => {
        return data.map(risk => ({
          ...risk,
          name: risk.displayName || risk.name, // Use displayName for filtering
          description: risk.description || ''
        }));
      }}
    />
  );

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: risksExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: risksExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Risks</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isRisksEditing ? (
              <IconButton 
                onClick={() => setIsRisksEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleConfirm}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setRisksExpanded(!risksExpanded)}
              sx={{ 
                transform: risksExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {risksExpanded && (
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table sx={{ overflow: 'visible' }}>
              <TableHead>
                <TableRow>
                  <TableCell width="20%" sx={{ overflow: 'visible' }}>Name</TableCell>
                  <TableCell width="40%" sx={{ overflow: 'visible' }}>Description</TableCell>
                  <TableCell width="10%" sx={{ overflow: 'visible' }}>Impact</TableCell>
                  <TableCell width="10%" sx={{ overflow: 'visible' }}>Frequency</TableCell>
                  <TableCell width="10%" sx={{ overflow: 'visible' }}>Score</TableCell>
                  {isRisksEditing && <TableCell width="10%" sx={{ overflow: 'visible' }}>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {detailedRisks?.map((risk) => (
                  <TableRow 
                    key={`detailed-${risk.id}`}
                    sx={{ 
                      overflow: 'visible',
                      ...(markedForDeletion.has(risk.id) && {
                        opacity: 0.5,
                        textDecoration: 'line-through',
                        '& .MuiIconButton-root': {
                          color: 'error.main'
                        }
                      })
                    }}
                  >
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => navigate(`/governance/risk/${encodeURIComponent(camelToSpace(risk.domain_name))}/${encodeURIComponent(camelToSpace(risk.name))}`, {
                          state: { riskId: risk.id }
                        })}
                        sx={{ textAlign: 'left' }}
                      >
                        {camelToSpace(risk.name)}
                      </Button>
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      {isRisksEditing ? (
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          value={editedDescriptions[risk.id] || ''}
                          onChange={(e) => handleDescriptionChange(risk.id, e.target.value)}
                          disabled={!isRisksEditing}
                          sx={{
                            backgroundColor: 'var(--background-color)',
                            borderRadius: '4px',
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'var(--background-color)',
                              '& fieldset': {
                                borderColor: 'var(--title-color)',
                              },
                              '&:hover fieldset': {
                                borderColor: 'var(--title-color)',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'var(--title-color)',
                              },
                            },
                          }}
                        />
                      ) : (
                        <Box 
                          sx={{ 
                            p: 1,
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            backgroundColor: 'var(--background-color)',
                            borderRadius: '4px'
                          }}
                        >
                          {editedDescriptions[risk.id] || risk.risk_description || '-'}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <SeverityDropdown
                        value={riskImpacts[risk.id] || 1}
                        onChange={(value) => handleImpactChange(risk.id, value)}
                        isEditing={isRisksEditing}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <SeverityDropdown
                        value={riskFrequencies[risk.id] || 1}
                        onChange={(value) => handleFrequencyChange(risk.id, value)}
                        isEditing={isRisksEditing}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Box className={`severity-value ${getRiskSeverityClass(calculateRiskScore(riskImpacts[risk.id] || 1, riskFrequencies[risk.id] || 1))}`}>
                        {calculateRiskScore(riskImpacts[risk.id] || 1, riskFrequencies[risk.id] || 1)}
                        <Typography variant="caption" display="block">
                          {getRiskSeverityName(calculateRiskScore(riskImpacts[risk.id] || 1, riskFrequencies[risk.id] || 1))}
                        </Typography>
                      </Box>
                    </TableCell>
                    {isRisksEditing && (
                      <TableCell sx={{ overflow: 'visible' }}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRiskRemove(risk);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                
                {previewRisks.map((risk) => (
                  <TableRow key={`preview-${risk.id}`}>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => navigate(`/governance/risk/${encodeURIComponent(risk.domain_name)}/${encodeURIComponent(camelToSpace(risk.name))}`, {
                          state: { riskId: risk.id }
                        })}
                        sx={{ textAlign: 'left' }}
                      >
                        {camelToSpace(risk.name)}
                      </Button>
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      {isRisksEditing ? (
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          value={editedDescriptions[risk.id] || ''}
                          onChange={(e) => handleDescriptionChange(risk.id, e.target.value)}
                          disabled={!isRisksEditing}
                          sx={{
                            backgroundColor: 'var(--background-color)',
                            borderRadius: '4px',
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'var(--background-color)',
                              '& fieldset': {
                                borderColor: 'var(--title-color)',
                              },
                              '&:hover fieldset': {
                                borderColor: 'var(--title-color)',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'var(--title-color)',
                              },
                            },
                          }}
                        />
                      ) : (
                        <Box 
                          sx={{ 
                            p: 1,
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            backgroundColor: 'var(--background-color)',
                            borderRadius: '4px'
                          }}
                        >
                          {editedDescriptions[risk.id] || risk.risk_description || '-'}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <SeverityDropdown
                        value={riskImpacts[risk.id] || 1}
                        onChange={(value) => handleImpactChange(risk.id, value)}
                        isEditing={isRisksEditing}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <SeverityDropdown
                        value={riskFrequencies[risk.id] || 1}
                        onChange={(value) => handleFrequencyChange(risk.id, value)}
                        isEditing={isRisksEditing}
                      />
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <Box className={`severity-value ${getRiskSeverityClass(calculateRiskScore(riskImpacts[risk.id] || 1, riskFrequencies[risk.id] || 1))}`}>
                        {calculateRiskScore(riskImpacts[risk.id] || 1, riskFrequencies[risk.id] || 1)}
                        <Typography variant="caption" display="block">
                          {getRiskSeverityName(calculateRiskScore(riskImpacts[risk.id] || 1, riskFrequencies[risk.id] || 1))}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ overflow: 'visible' }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePreviewDelete(risk);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                
                {isRisksEditing && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: 'center', overflow: 'visible' }}>
                      {renderContextSearch()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          {confirmationType === 'add' 
            ? `Are you sure you want to modify your risks?`
            : `Are you sure you want to remove ${pendingRisksToRemove.length} risk${pendingRisksToRemove.length > 1 ? 's' : ''}?`
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={confirmationType === 'add' ? handleConfirmAdd : handleConfirmRemove}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          {errorMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

StandardRisks.propTypes = {
  isRisksEditing: PropTypes.bool.isRequired,
  risksExpanded: PropTypes.bool.isRequired,
  setIsRisksEditing: PropTypes.func.isRequired,
  setRisksExpanded: PropTypes.func.isRequired,
  detailedRisks: PropTypes.array,
  setDetailedRisks: PropTypes.func.isRequired,
  handleRiskDeleteClick: PropTypes.func.isRequired,
  handleRiskClick: PropTypes.func.isRequired,
  standardId: PropTypes.number.isRequired,
  setStandard: PropTypes.func.isRequired,
  onAssociationChange: PropTypes.func,
  allRisks: PropTypes.array
};

export default StandardRisks; 