import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Loading from 'components/Objects/loading';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading, user, authenticatedFetch } = useAuth();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  useEffect(() => {
    const checkAccess = async () => {
      if (!user || currentPath === '/signup') {
        return;
      }

      try {
        const response = await authenticatedFetch(`/api/company/check_company/${user.email}`);
        const data = await response.json();
        
        if (!data.exists && currentPath !== '/onboarding') {
          navigate('/onboarding', { replace: true });
        }
      } catch (error) {
        console.error('Error checking company:', error);
      }
    };
    
    if (isAuthenticated) {
      checkAccess();
    } else if (!loading && currentPath !== '/signup') {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated, loading, user, authenticatedFetch, navigate, currentPath]);

  if (loading) {
    return <Loading />;
  }

  if (currentPath === '/signup') {
    return children;
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute; 