// AppContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const AppContext = createContext();

// Export a provider to wrap the application
export const AppProvider = ({ children }) => {
  const [section, setSection] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  const onSearch = (query) => {
    console.log('Searching for:', query);
  };

  const onUserAction = (action) => {
    console.log(`${action} button clicked`);
  };

  return (
    <AppContext.Provider
      value={{ section, currentPage, setSection, setCurrentPage, onSearch, onUserAction }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Export the custom hook to use the context
export const useAppContext = () => {
  return useContext(AppContext);
};
