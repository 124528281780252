import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Error from 'components/Objects/Error';
import {
  Box,
  Typography,
  Paper,
  Card,
  CardContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Assignment as ProcessIcon
} from '@mui/icons-material';
import { camelCaseToSpaces } from 'utils/jinja';
import 'styles/Objects.css';
import 'styles/colors.css';
import ProcessSchedule from './process-schedule';
import ProcessAssociations from './process-associations';
import ProcessContents from './process-contents';
import ProcessGraph from './process-graph';

const TitleBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  gap: theme.spacing(2)
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

function ProcessDetails() {
  const { processName } = useParams();
  const location = useLocation();
  const [processId, setProcessId] = useState(location.state?.processId);
  const [process, setProcess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authenticatedFetch, isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();
  const [graphExpanded, setGraphExpanded] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // First, fetch the process ID if we don't have it
  useEffect(() => {
    if (!isAuthenticated && !loading) {
      navigate('/login');
      return;
    }

    const fetchProcessId = async () => {
      if (!processId && processName) {
        try {
          const response = await authenticatedFetch('/api/process/short');
          if (!response.ok) {
            throw new Error('Failed to fetch processes');
          }
          const data = await response.json();
          const processes = data.data.processes;
          const matchingProcess = processes.find(p => 
            p.value.toLowerCase() === decodeURIComponent(processName).toLowerCase()
          );
          
          if (matchingProcess) {
            setProcessId(matchingProcess.id);
          } else {
            setError('Process not found');
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error fetching process ID:', error);
          setError('Failed to fetch process ID');
          setIsLoading(false);
        }
      }
    };

    fetchProcessId();
  }, [processName, processId, authenticatedFetch, isAuthenticated, loading, navigate]);

  // Then fetch the process details once we have the ID
  useEffect(() => {
    if (processId) {
      const fetchData = async () => {
        try {
          const processResponse = await authenticatedFetch(`/api/process/${processId}`);
          
          if (!processResponse.ok) {
            throw new Error('Failed to fetch process data');
          }

          const processData = await processResponse.json();
          
          if (!processData.process) {
            throw new Error('Process data is missing');
          }

          const processedProcess = {
            ...processData.process,
            id: processData.process.id.toString(),
            history: processData.process.history || []
          };

          setProcess(processedProcess);
        } catch (error) {
          console.error('Error fetching process:', error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [processId, authenticatedFetch]);

  const handleEditClick = () => {
    navigate(`/company/process/${processId}/edit`);
  };

  const handleDeleteClick = async () => {
    if (window.confirm('Are you sure you want to delete this process?')) {
      try {
        const response = await authenticatedFetch(`/api/process/${processId}`, {
          method: 'DELETE'
        });

        if (!response.ok) {
          throw new Error('Failed to delete process');
        }

        navigate('/company/processes');
      } catch (error) {
        console.error('Error deleting process:', error);
        setError('Failed to delete process');
      }
    }
  };

  if (error) {
    return <Error message={error} onClose={() => setError(null)} />;
  }

  return (
    <Box sx={{ pb: 1, width: '100%' }}>
      <TitleBar sx={{ mb: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', padding: 1.75}} elevation={0}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
          <ProcessIcon sx={{ color: 'var(--title-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
            {process?.name || 'Loading...'}
          </Typography>
          <Typography 
            variant="caption" 
            sx={{ 
              backgroundColor: process?.text ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '4px',
              marginLeft: '8px'
            }}
          >
            {process?.text ? 'Populated' : 'Not Populated'}
          </Typography>
        </Box>
      </TitleBar>

      <div>
        {isLoading ? (
          <div>Loading process details...</div>
        ) : process ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2, mx: 1 }}>
            <ProcessContents 
              process={process} 
              setProcess={setProcess}
            />

            {process && (
              <ProcessSchedule 
                process={process}
                setProcess={setProcess}
              />
            )}

            <ProcessGraph 
              graphExpanded={graphExpanded}
              setGraphExpanded={setGraphExpanded}
              processId={parseInt(processId)}
              refreshTrigger={refreshTrigger}
            />

            <ProcessAssociations process={process} />
          </Box>
        ) : (
          <div>Process not found</div>
        )}
      </div>
    </Box>
  );
}

export default ProcessDetails; 