import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

function RandomForceGraph() {
  const [graphData, setGraphData] = useState(null);
  const [zoomHandler, setZoomHandler] = useState(null);

  // Define colors using CSS variables - more muted colors
  const level1_color = '#4a4a4a';  // dark gray
  const level2_color = '#6b6b6b';  // medium gray
  const level3_base = '#8c8c8c';   // base gray for level 3
  const highlight_color = '#a0a0a0';
  const unhealthy_color = '#071826';  // bright pink

  // Function to generate slightly varied colors for level 3
  const getLevel3Color = (nodeId) => {
    const variations = [
      { r: 10, g: 10, b: 30 },    // bluish
      { r: 10, g: 30, b: 10 },    // greenish
      { r: 30, g: 10, b: 10 },    // reddish
      { r: 30, g: 30, b: 0 }      // yellowish
    ];
    
    // Use nodeId to consistently pick a variation
    const variation = variations[nodeId % variations.length];
    
    // Convert base color to RGB
    const r = parseInt(level3_base.slice(1, 3), 16);
    const g = parseInt(level3_base.slice(3, 5), 16);
    const b = parseInt(level3_base.slice(5, 7), 16);
    
    // Apply subtle variation
    const newR = Math.min(255, Math.max(0, r + variation.r));
    const newG = Math.min(255, Math.max(0, g + variation.g));
    const newB = Math.min(255, Math.max(0, b + variation.b));
    
    // Convert back to hex
    return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  };

  const generateRandomData = () => {
    const nodes = [];
    const links = [];
    let nodeId = 1;

    // Generate level 1 (parent) nodes (20-30 nodes)
    const level1Count = Math.floor(Math.random() * 11) + 20;
    
    for (let i = 0; i < level1Count; i++) {
      const parentId = `p${nodeId}`;
      // Randomly mark about 10% of Level1 nodes as unhealthy
      const isUnhealthy = Math.random() < 0.1;
      nodes.push({
        id: parentId,
        name: `Parent ${nodeId}`,
        type: 'Level1',
        group: 1,
        isUnhealthy: isUnhealthy
      });

      // Generate level 2 nodes for each parent (3-6 nodes)
      const level2Count = Math.floor(Math.random() * 4) + 3;
      
      for (let j = 0; j < level2Count; j++) {
        nodeId++;
        const childId = `s${nodeId}`;
        nodes.push({
          id: childId,
          name: `Child ${nodeId}`,
          type: 'Level2',
          group: 2,
          isUnhealthy: isUnhealthy
        });
        links.push({
          source: parentId,
          target: childId,
          value: 1
        });

        // Generate level 3 nodes for each level 2 node (1-3 nodes)
        const level3Count = Math.floor(Math.random() * 3) + 1;
        
        for (let k = 0; k < level3Count; k++) {
          nodeId++;
          const grandchildId = `t${nodeId}`;
          nodes.push({
            id: grandchildId,
            name: `Grandchild ${nodeId}`,
            type: 'Level3',
            group: 3,
            isUnhealthy: isUnhealthy
          });
          links.push({
            source: childId,
            target: grandchildId,
            value: 1
          });
        }
      }
      nodeId++;
    }

    return { nodes, links };
  };

  const preCalculatePositions = (data) => {
    const simulation = d3.forceSimulation(data.nodes)
      .force("link", d3.forceLink(data.links).id(d => d.id)
        .distance(d => {
          const sourceNode = data.nodes.find(n => n.id === (typeof d.source === 'object' ? d.source.id : d.source));
          if (sourceNode && sourceNode.type === "Level1") {
            return 60;
          }
          return 40;
        })
        .strength(0.5))
      .force("charge", d3.forceManyBody()
        .strength(d => {
          if (d.type === "Level1") return -70;
          if (d.type === "Level2") return -40;
          return -20;
        }))
      .force("center", d3.forceCenter(500, 500))
      .force("collision", d3.forceCollide()
        .radius(d => {
          if (d.type === "Level1") return 7.5;
          if (d.type === "Level2") return 6;
          return 4;
        })
        .strength(0.9))
      .force("x", d3.forceX(500).strength(0.03))
      .force("y", d3.forceY(500).strength(0.03));

    // Run the simulation to completion
    for (let i = 0; i < 300; ++i) {
      simulation.tick();
    }

    // Store the final positions in the nodes
    data.nodes.forEach(node => {
      node.initialX = node.x;
      node.initialY = node.y;
    });

    return data;
  };

  useEffect(() => {
    const rawData = generateRandomData();
    const positionedData = preCalculatePositions(rawData);
    setGraphData(positionedData);
  }, []);

  const renderForceGraph = (data) => {
    const container = document.getElementById('random-force');
    const width = container.clientWidth;
    const height = container.clientHeight;

    const color = d => {
      if (d.isUnhealthy) {
        return unhealthy_color;
      }
      if (d.type === 'Level1') return level1_color;
      if (d.type === 'Level2') return level2_color;
      if (d.type === 'Level3') return getLevel3Color(parseInt(d.id.slice(1)));
      return '#999';
    };

    const links = data.links.map(d => ({...d}));
    const nodes = data.nodes.map(d => ({...d}));

    const simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id)
        .distance(d => {
          const sourceNode = nodes.find(n => n.id === (typeof d.source === 'object' ? d.source.id : d.source));
          if (sourceNode && sourceNode.type === "Level1") {
            return 60;
          }
          return 40;
        })
        .strength(0.5))
      .force("charge", d3.forceManyBody()
        .strength(d => {
          if (d.type === "Level1") return -70;
          if (d.type === "Level2") return -40;
          return -20;
        }))
      .force("center", d3.forceCenter(width * 1.5, height / 2))
      .force("collision", d3.forceCollide()
        .radius(d => {
          if (d.type === "Level1") return 7.5;
          if (d.type === "Level2") return 6;
          return 4;
        })
        .strength(0.9))
      .force("x", d3.forceX(width * 4).strength(0.03))
      .force("y", d3.forceY(height / 2).strength(0.03))
      .alphaDecay(0.01)
      .alphaMin(0.0001)
      .alpha(0.3);

    const zoom = d3.zoom()
      .scaleExtent([0.1, 5])
      .on("zoom", zoomed);

    const svg = d3.select("#random-force")
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", [0, 0, width/2, height/2])
      .style("display", "block")
      .call(zoom);

    const g = svg.append("g")
      .attr("width", width)
      .attr("height", height);

    // Set initial positions for nodes first
    data.nodes.forEach(node => {
      node.x = node.initialX;
      node.y = node.initialY;
    });

    // Create links with initial positions
    const link = g.append("g")
      .attr("width", width)
      .attr("height", height)
      .selectAll("line")
      .data(data.links)
      .join("line")
      .attr("stroke", d => {
        const source = typeof d.source === 'object' ? d.source : data.nodes.find(n => n.id === d.source);
        return source.isUnhealthy ? unhealthy_color : "#666";
      })
      .attr("stroke-opacity", d => {
        const source = typeof d.source === 'object' ? d.source : data.nodes.find(n => n.id === d.source);
        return source.isUnhealthy ? 0.5 : 0.3;
      })
      .attr("stroke-width", 0.5)
      .attr("x1", d => {
        const source = typeof d.source === 'object' ? d.source : data.nodes.find(n => n.id === d.source);
        return source.x;
      })
      .attr("y1", d => {
        const source = typeof d.source === 'object' ? d.source : data.nodes.find(n => n.id === d.source);
        return source.y;
      })
      .attr("x2", d => {
        const target = typeof d.target === 'object' ? d.target : data.nodes.find(n => n.id === d.target);
        return target.x;
      })
      .attr("y2", d => {
        const target = typeof d.target === 'object' ? d.target : data.nodes.find(n => n.id === d.target);
        return target.y;
      });

    // Create nodes with initial positions
    const node = g.append("g")
      .selectAll("circle")
      .data(data.nodes)
      .join("circle")
      .attr("r", d => d.type === "Level1" ? 5 : d.type === "Level2" ? 3.75 : 2.5)
      .attr("fill", d => color(d))
      .attr("cx", d => d.x)
      .attr("cy", d => d.y)
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));

    node.append("title")
      .text(d => d.name);

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
    });

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function zoomed(event) {
      const { k } = event.transform;
      g.attr("transform", `scale(${k})`);
    }
  };

  useEffect(() => {
    if (graphData) {
      renderForceGraph(graphData);
    }
  }, [graphData]);

  return (
    <div id="random-force" className="relationship-graph" style={{ 
      width: '100%', 
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'hidden'
    }} />
  );
}

export default RandomForceGraph;
