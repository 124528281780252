import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function Error({ message, onClose }) {
    const okButtonRef = useRef(null);

    useEffect(() => {
        if (okButtonRef.current) {
            setTimeout(() => {
                okButtonRef.current.focus();
            }, 0);
        }
    }, []);

    const handleKeyDown = (e) => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Escape') {
            onClose();
        }
    };

    return (
        <Dialog 
            open={true}
            onClose={onClose}
            onKeyDown={handleKeyDown}
            maxWidth="sm"
            fullWidth
            sx={{
                zIndex: 999999999
            }}
        >
            <DialogTitle sx={{ color: 'error.main' }}>Error</DialogTitle>
            <DialogContent>
                <Typography component="div">
                    <ReactMarkdown 
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                    >
                        {message}
                    </ReactMarkdown>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    ref={okButtonRef}
                    autoFocus
                    sx={{
                        backgroundColor: 'var(--button-default-color)',
                        color: 'var(--text-color-light)',
                        '&:hover': {
                            backgroundColor: 'var(--button-default-color-hover)',
                        },
                    }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Error.propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Error; 