const CONSUMER_EMAIL_DOMAINS = [
  'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com',
  'icloud.com', 'mail.com', 'protonmail.com', 'zoho.com', 'yandex.com',
  'gmx.com', 'live.com', 'me.com', 'mac.com'
];

export const isConsumerEmail = async (email) => {
  const domain = email.split('@')[1];
  
  // First check against known consumer domains
  if (CONSUMER_EMAIL_DOMAINS.includes(domain.toLowerCase())) {
    return true;
  }

  // Then check against disposable email API
  try {
    const response = await fetch(`https://open.kickbox.com/v1/disposable/${domain}`);
    const data = await response.json();
    return data.disposable;
  } catch (error) {
    console.error('Error checking disposable email:', error);
    // If API fails, fall back to just checking known consumer domains
    return CONSUMER_EMAIL_DOMAINS.includes(domain.toLowerCase());
  }
}; 