import React, { useState, useRef } from 'react';
import { useAuth } from 'contexts/AuthContext';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  ExpandMore as ExpandMoreIcon,
  Description as EvidenceIcon,
  Upload as UploadIcon,
  InsertDriveFile as FileIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'var(--title-color)',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'var(--title-color)',
  }
}));

function EvidenceContents({ evidence, setEvidence }) {
  const [isEditing, setIsEditing] = useState(false);
  const [contentsExpanded, setContentsExpanded] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editedText, setEditedText] = useState(evidence?.text || '');
  const [pendingFiles, setPendingFiles] = useState([]);
  const { authenticatedFetch } = useAuth();
  const fileInputRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setPendingFiles(prev => [...prev, file]);
  };

  const handleConfirmUpdate = async () => {
    try {
      // Upload all pending files first
      const uploadedFiles = [];
      for (const file of pendingFiles) {
        const urlResponse = await authenticatedFetch('/api/s3/upload-url', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filename: file.name,
            fileType: file.type,
            evidenceId: evidence.id
          }),
        });

        if (!urlResponse.ok) {
          throw new Error('Failed to get upload URL');
        }

        const { uploadUrl, key } = await urlResponse.json();

        // Upload file to S3
        const uploadResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        if (!uploadResponse.ok) {
          throw new Error('Failed to upload file');
        }

        uploadedFiles.push({ name: file.name, key, type: file.type });
      }

      // Send the update request with just the edited text and the latest file's bucket URL
      const response = await authenticatedFetch(`/api/evidence/${evidence.id}/contents`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: editedText,
          bucket_url: uploadedFiles.length > 0 ? uploadedFiles[0].key : null,
          edit_date: new Date().toISOString()
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update evidence contents');
      }

      const data = await response.json();
      
      const processedEvidence = {
        ...evidence,
        text: data.evidence.text,
        value: data.evidence.value,
        review_date: data.evidence.review_date,
        created_at: data.evidence.created_at,
        edit_date: data.evidence.edit_date,
        bucket_url: data.evidence.bucket_url,
        controls: evidence.controls || [],
        standards: evidence.standards || [],
        policies: evidence.policies || []
      };
      
      setEvidence(processedEvidence);
      setIsEditing(false);
      setShowConfirmation(false);
      setPendingFiles([]);
    } catch (error) {
      console.error('Error updating evidence contents:', error);
    }
  };

  const handleCancel = () => {
    setEditedText(evidence?.text || '');
    setPendingFiles([]);
    setIsEditing(false);
  };

  const extractS3Info = (text) => {
    // If evidence has a bucket_url, use that directly
    if (evidence?.bucket_url) {
      return [{
        bucket_url: evidence.bucket_url,
        filename: evidence.bucket_url.split('/').pop()
      }];
    }
    
    return [];
  };

  const handleDownload = async (bucket_url) => {
    setIsDownloading(true);
    try {
      const response = await authenticatedFetch('/api/s3/download-url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bucket_url }),
      });

      if (!response.ok) {
        throw new Error('Failed to get download URL');
      }

      const { downloadUrl } = await response.json();

      // Open in new tab instead of downloading
      window.open(downloadUrl, '_blank');
    } catch (error) {
      console.error('Error opening file:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const renderContent = () => {
    const s3Files = extractS3Info(evidence?.text || '');
    
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <TableContainer 
          component={Paper} 
          elevation={1}
          sx={{
            '& .MuiTableBody-root tr:last-child td': {
              borderBottom: 'none'
            }
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'var(--background-color)' }}>
                  Evidence Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {isEditing ? (
                    <StyledTextField
                      fullWidth
                      multiline
                      rows={4}
                      value={editedText}
                      onChange={(e) => setEditedText(e.target.value)}
                      variant="outlined"
                    />
                  ) : (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'var(--text-color-dark)',
                        p: 1,
                        fontFamily: 'monospace'
                      }}
                    >
                      {evidence?.text || 'No description available'}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer 
          component={Paper} 
          elevation={1}
          sx={{
            '& .MuiTableBody-root tr:last-child td': {
              borderBottom: 'none'
            }
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'var(--background-color)', width: '50%' }}>
                  File Name
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'var(--background-color)', width: '40%' }}>
                  Upload Date
                </TableCell>
                <TableCell sx={{ width: '10%', backgroundColor: 'var(--background-color)', textAlign: 'right' }}>
                  {isEditing && (
                    <>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                      />
                      <Button
                        variant="contained"
                        startIcon={<UploadIcon />}
                        onClick={() => fileInputRef.current.click()}
                        size="small"
                        sx={{ 
                          backgroundColor: 'var(--button-default-color)',
                          '&:hover': {
                            backgroundColor: 'var(--button-default-color-hover)'
                          }
                        }}
                      >
                        Upload
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingFiles.length > 0 && pendingFiles.map((file, index) => (
                <TableRow key={`pending-${index}`}>
                  <TableCell>
                    <Chip
                      icon={<FileIcon />}
                      label={file.name}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>Pending Upload</TableCell>
                  <TableCell />
                </TableRow>
              ))}

              {s3Files.length > 0 ? (
                s3Files.map((file, index) => (
                  <TableRow key={`s3-${index}`}>
                    <TableCell>
                      <Button
                        variant="outlined"
                        startIcon={<FileIcon />}
                        onClick={() => handleDownload(file.bucket_url)}
                        disabled={isDownloading}
                        size="small"
                        sx={{ 
                          color: 'var(--button-default-color)',
                          borderColor: 'var(--button-default-color)',
                          '&:hover': {
                            borderColor: 'var(--button-default-color-hover)',
                            backgroundColor: 'rgba(var(--button-default-color-rgb), 0.04)'
                          }
                        }}
                      >
                        {file.filename}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {new Date().toLocaleDateString()}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center">No files attached</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <StyledCard>
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EvidenceIcon />
          <Typography variant="h6">Contents</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {!isEditing ? (
            <IconButton 
              onClick={() => setIsEditing(true)}
              sx={{ color: 'var(--text-color-light)' }}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => setShowConfirmation(true)}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
          <IconButton 
            onClick={() => setContentsExpanded(!contentsExpanded)}
            sx={{ 
              transform: contentsExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      {contentsExpanded && renderContent()}

      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          Are you sure you want to update the evidence contents?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
          <Button 
            onClick={handleConfirmUpdate}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
}

export default EvidenceContents; 