import { useNavigate } from 'react-router-dom';
import {
  fetchPolicies,
  fetchPolicyByName,
  deletePolicy,
  updatePolicy,
  createPolicy,
  validatePolicy,
  checkPolicyHealth,
  getPolicyHistory,
  getPolicyStats,
  fetchPolicyData,
  updatePolicyContent,
  renamePolicy,
  updatePolicyDescription
} from './policy-queries';

const stripHtmlTags = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const usePolicyActions = (
  authenticatedFetch,
  setPolicies,
  setErrorMessage,
  setShowErrorDialog,
  setShowConfirmation,
  setDeletionTarget,
  setPolicy,
  setNotFound,
  setLoading,
  setIsDescriptionEditing,
  setShowNavigationConfirmation,
  setPendingNavigation,
  setShowPolicyDeleteConfirmation,
  setPendingPolicyDelete,
  setRenameTarget,
  setHasUnsavedChanges
) => {
  const navigate = useNavigate();
  
  const handleError = (message) => {
    setErrorMessage(message);
    setShowErrorDialog(true);
  };

  const handlePolicyDelete = async (policyId) => {
    try {
      const response = await authenticatedFetch(`/api/policy/${policyId}`, {
        method: 'DELETE'
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        if (data.error === "Validation error" && data.details?.standards_count) {
          setErrorMessage(
            `Cannot delete this policy because it has ${data.details.standards_count} associated standard${data.details.standards_count > 1 ? 's' : ''}:` +
            `<p></p>` +
            data.details.standards.map(s => `• ${s.name}`).join('<p></p>') +
            `<p></p>` +
            'Please remove these standards from the policy before deleting it.'
          );
        } else {
          setErrorMessage(data.message || 'Failed to delete policy. Please try again.');
        }
        setShowErrorDialog(true);
        return;
      }

      const updatedPolicies = await fetchPolicies(authenticatedFetch);
      setPolicies(updatedPolicies);
      navigate('/governance/policy');
    } catch (error) {
      console.error('Error deleting policy:', error);
      setErrorMessage('Failed to delete policy. Please check your connection and try again.');
      setShowErrorDialog(true);
    }
  };

  const handlePolicyEdit = async (policyId, newValue) => {
    try {
      await updatePolicy(authenticatedFetch, policyId, newValue);
      const updatedPolicies = await fetchPolicies(authenticatedFetch);
      setPolicies(updatedPolicies);
    } catch (error) {
      console.error('Error editing policy:', error);
      handleError('Failed to edit policy. Please try again.');
    }
  };

  const handlePolicyCreate = async (policyData) => {
    try {
      const cleanName = stripHtmlTags(policyData.name);
      
      const newPolicy = await createPolicy(authenticatedFetch, {
        name: cleanName,
        description: '',
        policy_text: ''
      });

      const updatedPolicies = await fetchPolicies(authenticatedFetch);
      setPolicies(updatedPolicies);
      
      return newPolicy;
    } catch (error) {
      console.error('Error creating policy:', error);
      handleError(error.message || 'Failed to create policy');
      throw error;
    }
  };

  const handlePolicyContentUpdate = async (policyId, content) => {
    try {
      setHasUnsavedChanges(true);
      await updatePolicyContent(authenticatedFetch, policyId, content);
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error updating policy content:', error);
      handleError('Failed to update policy content. Please try again.');
    }
  };

  const handlePolicyValidation = async (policyId) => {
    try {
      setLoading(true);
      const validationResults = await validatePolicy(authenticatedFetch, policyId);
      setLoading(false);
      return validationResults;
    } catch (error) {
      console.error('Error validating policy:', error);
      handleError('Failed to validate policy. Please try again.');
      setLoading(false);
    }
  };

  const handlePolicyHealthCheck = async (policyId) => {
    try {
      setLoading(true);
      const healthData = await checkPolicyHealth(authenticatedFetch, policyId);
      setLoading(false);
      return healthData;
    } catch (error) {
      console.error('Error checking policy health:', error);
      handleError('Failed to check policy health. Please try again.');
      setLoading(false);
    }
  };

  const handleNavigationWithConfirmation = (to) => {
    if (setHasUnsavedChanges) {
      setShowNavigationConfirmation(true);
      setPendingNavigation(to);
    } else {
      navigate(to);
    }
  };

  const handlePolicyLoad = async (policyName) => {
    try {
      setLoading(true);
      const data = await fetchPolicyData(authenticatedFetch, policyName);
      setPolicy(data.policy);
      setLoading(false);
    } catch (error) {
      console.error('Error loading policy:', error);
      setNotFound(true);
      setLoading(false);
      handleError('Failed to load policy. Please try again.');
    }
  };

  const handlePolicyHistoryFetch = async (policyId) => {
    try {
      setLoading(true);
      const history = await getPolicyHistory(authenticatedFetch, policyId);
      setLoading(false);
      return history;
    } catch (error) {
      console.error('Error fetching policy history:', error);
      handleError('Failed to fetch policy history. Please try again.');
      setLoading(false);
    }
  };

  const handlePolicyStatsFetch = async (policyId) => {
    try {
      setLoading(true);
      const stats = await getPolicyStats(authenticatedFetch, policyId);
      setLoading(false);
      return stats;
    } catch (error) {
      console.error('Error fetching policy stats:', error);
      handleError('Failed to fetch policy statistics. Please try again.');
      setLoading(false);
    }
  };

  const handlePolicyDeleteClick = (policy) => {
    setPendingPolicyDelete(policy);
    setShowPolicyDeleteConfirmation(true);
  };

  const handleConfirmPolicyDelete = async (policy) => {
    try {
      if (!policy || !policy.id) {
        throw new Error('No policy selected for deletion');
      }

      await deletePolicy(authenticatedFetch, policy.id);
      const updatedPolicies = await fetchPolicies(authenticatedFetch);
      setPolicies(updatedPolicies);
      setShowPolicyDeleteConfirmation(false);
      setPendingPolicyDelete(null);
    } catch (error) {
      console.error('Error deleting policy:', error);
      handleError(error.message || 'Failed to delete policy');
    }
  };

  const handleCancelPolicyDelete = () => {
    setShowPolicyDeleteConfirmation(false);
    setPendingPolicyDelete(null);
  };

  const handleConfirmDelete = async (deletionTarget) => {
    if (!deletionTarget) return;
    
    try {
      await handlePolicyDelete(deletionTarget.id);
    } catch (error) {
      console.error('Error deleting:', error);
      handleError(error.message || 'Failed to delete. Please try again.');
    }
  };

  const onConfirmDelete = (deletionTarget) => {
    handleConfirmDelete(deletionTarget);
    setShowConfirmation(false);
    setDeletionTarget(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setDeletionTarget(null);
  };

  const handlePolicyRename = async (policyId, newTitle) => {
    try {
      const updatedPolicy = await renamePolicy(authenticatedFetch, policyId, newTitle);
      setPolicies(prevPolicies => 
        prevPolicies.map(policy => 
          policy.id === policyId ? updatedPolicy.policy : policy
        )
      );
      setRenameTarget(null);
    } catch (error) {
      console.error('Error renaming policy:', error);
      handleError(error.message || 'Failed to rename policy');
    }
  };

  const handleConfirmRename = async (renameTarget) => {
    if (!renameTarget) return;
    
    try {
      await handlePolicyRename(renameTarget.id, renameTarget.newTitle);
      setRenameTarget(null);
    } catch (error) {
      console.error('Error in rename confirmation:', error);
      handleError(error.message || 'Failed to rename policy');
    }
  };

  const handleCancelRename = () => {
    setRenameTarget(null);
  };

  const handleDescriptionEdit = async (policyId, newDescription) => {
    try {
      const response = await authenticatedFetch(`/api/policy/${policyId}/description`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ description: newDescription }),
      });

      if (!response.ok) {
        throw new Error('Failed to update description');
      }
    } catch (error) {
      console.error('Error updating description:', error);
      throw error;
    }
  };

  const handleStandardDelete = async (policyId, standardId) => {
    try {
      const response = await authenticatedFetch(`/api/policies/${policyId}/standards/${standardId}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete standard association');
      }

      // Update policy state with filtered standards
      setPolicy(prev => ({
        ...prev,
        standards: prev.standards.filter(s => s.id !== standardId)
      }));
    } catch (error) {
      console.error('Error deleting standard:', error);
      setErrorMessage('Failed to delete standard association');
      setShowErrorDialog(true);
    }
  };

  return {
    handlePolicyDelete,
    handlePolicyEdit,
    handlePolicyCreate,
    handlePolicyContentUpdate,
    handlePolicyValidation,
    handlePolicyHealthCheck,
    handleNavigationWithConfirmation,
    handlePolicyLoad,
    handlePolicyHistoryFetch,
    handlePolicyStatsFetch,
    handlePolicyDeleteClick,
    handleConfirmPolicyDelete,
    handleCancelPolicyDelete,
    onConfirmDelete,
    handleCancelDelete,
    handleError,
    handlePolicyRename,
    handleConfirmRename,
    handleCancelRename,
    handleDescriptionEdit,
    handleStandardDelete,
  };
};

export const onConfirmDelete = async (deletionTarget, handleDeletePolicy, setShowConfirmation, setDeletionTarget) => {
  if (!deletionTarget) return;
  
  try {
    await handleDeletePolicy(deletionTarget.id);
    setShowConfirmation(false);
    setDeletionTarget(null);
  } catch (error) {
    console.error('Error deleting:', error);
  }
};

export const handleCancelDelete = (setShowConfirmation, setDeletionTarget) => {
  setShowConfirmation(false);
  setDeletionTarget(null);
};
