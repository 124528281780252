import React, { useState } from 'react';
import RichTextEditor from '../../components/Editor/RichTextEditor';
import { replaceJinjaVariables, formatRichTextWithTags } from 'utils/jinja';
import { useNavigate } from 'react-router-dom';
import { updateControlDescription } from './standard-queries';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Link as LinkIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';

const StandardControlsTable = ({
  isControlsEditing,
  controlsExpanded,
  setIsControlsEditing,
  setControlsExpanded,
  newControlName,
  setNewControlName,
  newControlDescription,
  setNewControlDescription,
  onAddControl,
  standard,
  handleControlDeleteClick,
  handleControlClick,
  companyName,
  onAssociationChange,
  onControlsChange,
  onDomainsUpdate,
  domainName,
  standardName
}) => {
  const navigate = useNavigate();
  const { authenticatedFetch } = useAuth();
  const [editedDescriptions, setEditedDescriptions] = useState({});
  const [isEditing, setIsEditing] = useState({});
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [pendingSave, setPendingSave] = useState(null);

  const handleCancel = () => {
    setEditedDescriptions({});
    setIsEditing({});
    setIsControlsEditing(false);
  };

  const handleConfirm = () => {
    if (Object.keys(editedDescriptions).length > 0) {
      setShowSaveConfirmation(true);
    } else {
      setIsControlsEditing(false);
    }
  };

  const handleProcessClick = (event) => {
    const target = event.target;
    if (target.classList.contains('mce-variable') && target.dataset.processType) {
      const processType = target.dataset.urlType;
      const processValue = target.dataset.processValue;
      if (processType && processValue) {
        navigate(`/company/processes/${processType}/${encodeURIComponent(processValue)}`);
      }
    }
  };

  const handleDescriptionChange = (controlId, value) => {
    // Strip HTML tags from the value
    const strippedValue = value.replace(/<[^>]*>/g, '');
    
    setEditedDescriptions(prev => ({
      ...prev,
      [controlId]: strippedValue
    }));
  };

  const handleSaveDescription = async (control) => {
    setPendingSave(control);
    setShowSaveConfirmation(true);
  };

  const handleConfirmSave = async () => {
    try {
      const control = pendingSave;
      const newDescription = editedDescriptions[control.id];
      
      if (newDescription !== undefined) {
        const updates = {
          ...control,
          text: newDescription  // Using plain text instead of HTML
        };

        await updateControlDescription(authenticatedFetch, control.id, updates);
        
        // Update local state
        Object.assign(control, updates);
        setIsEditing(prev => ({
          ...prev,
          [control.id]: false
        }));
        
        if (onControlsChange) {
          onControlsChange([...standard.controls]);
        }
      }
    } catch (error) {
      console.error('Error saving control description:', error);
    }
    setShowSaveConfirmation(false);
    setPendingSave(null);
  };

  const handleCancelSave = () => {
    setShowSaveConfirmation(false);
    setPendingSave(null);
  };

  const handleControlAdd = async () => {
    const result = await onAddControl();
    if (result && result.domains) {
      // Update the domains data in the parent component
      if (onDomainsUpdate) {
        onDomainsUpdate(result.domains);
      }
    }
    // Clear the form
    setNewControlName('');
    setNewControlDescription('');
  };

  const handleControlDelete = async (control) => {
    await handleControlDeleteClick(control);
    if (onAssociationChange) {
      onAssociationChange();
    }
    if (onControlsChange) {
      const updatedControls = standard.controls.filter(c => c.id !== control.id);
      onControlsChange(updatedControls);
    }
  };

  const handleControlLinkClick = (control) => {
    const controlName = control.title;
    navigate(
      `/company/standards/${encodeURIComponent(domainName)}/${encodeURIComponent(standardName)}/${encodeURIComponent(controlName)}`,
      { state: { controlId: control.id } }
    );
  };

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: controlsExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: controlsExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Controls</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isControlsEditing ? (
              <IconButton 
                onClick={() => setIsControlsEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                >
                  Done
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setControlsExpanded(!controlsExpanded)}
              sx={{ 
                transform: controlsExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {controlsExpanded && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Name</TableCell>
                  <TableCell width="65%">Description</TableCell>
                  {isControlsEditing && <TableCell width="10%">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {isControlsEditing && (
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={newControlName}
                        onChange={(e) => setNewControlName(e.target.value)}
                        size="small"
                        placeholder="Enter control name"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: 'var(--title-color)'
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={newControlDescription}
                        onChange={(e) => setNewControlDescription(e.target.value)}
                        size="small"
                        multiline
                        rows={2}
                        placeholder="Enter control description"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: 'var(--title-color)'
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={handleControlAdd}
                        disabled={!newControlName || !newControlDescription}
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                {standard.controls.map((control) => (
                  <TableRow key={control.id}>
                    <TableCell>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => handleControlLinkClick(control)}
                        sx={{ 
                          textAlign: 'left',
                          color: 'var(--link-color)'
                        }}
                      >
                        {control.title}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {isControlsEditing ? (
                        <TextField
                          fullWidth
                          value={editedDescriptions[control.id] || control.text}
                          onChange={(e) => handleDescriptionChange(control.id, e.target.value)}
                          size="small"
                          multiline
                          rows={2}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: 'var(--title-color)'
                              }
                            }
                          }}
                        />
                      ) : (
                        <div
                          onClick={handleProcessClick}
                          dangerouslySetInnerHTML={{ 
                            __html: replaceJinjaVariables(control.text, null, companyName, control) 
                          }}
                        />
                      )}
                    </TableCell>
                    {isControlsEditing && (
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton
                            onClick={() => handleSaveDescription(control)}
                            color="primary"
                            size="small"
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleControlDelete(control);
                            }}
                            color="error"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Dialog
        open={showSaveConfirmation}
        onClose={handleCancelSave}
      >
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          {pendingSave && `Are you sure you want to save the changes to "${pendingSave.title}"?`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSave}>Cancel</Button>
          <Button 
            onClick={handleConfirmSave}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StandardControlsTable; 