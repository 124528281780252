// File: ./app.valdyr.io/frontend/src/pages/Policy/PolicyAudit.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import 'styles/Onboarding.css';
import OnboardingActivation from './onboarding-activation';
import RandomForceGraph from 'components/Graph/graph-random-force';

function OnboardingSection() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [userDomain, setUserDomain] = useState('');

  useEffect(() => {
    if (!user?.email) {
      navigate('/login');
      return;
    }

    const domain = user.email.split('@')[1];
    setUserDomain(domain);
  }, [user, navigate]);

  return (
    <div className="onboarding-container">
      <div className="onboarding-background">
        <RandomForceGraph />
      </div>
      <div className="onboarding-content">
        {userDomain && <OnboardingActivation userDomain={userDomain} />}
      </div>
    </div>
  );
}

export default OnboardingSection;
