import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { ConfirmButton } from 'components/Objects/buttons';
import Loading from 'components/Objects/loading';
import TierSelection from './tier-selection';
import TermsOfService from './TermsOfService';

function OnboardingActivation({ userDomain }) {
  const { authenticatedFetch } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(true);

  const handleTermsAccept = () => {
    setShowTerms(false);
    initiateTrial();
  };

  const initiateTrial = async () => {
    setIsLoading(true);
    try {
      const customerResponse = await authenticatedFetch('/api/stripe/get-customer-id');
      if (!customerResponse.ok) throw new Error('Failed to get customer ID');
      const { customer_id: customerId } = await customerResponse.json();

      const trialEnd = Math.floor(Date.now() / 1000) + (14 * 24 * 60 * 60);
      const selectedTier = 2; // Professional tier

      // Get allowances for Professional tier
      const allowances = {
        policy_allowance: 999999999,
        standard_allowance: 999999999,
        control_allowance: 999999999,
        prompt_allowance: 999999999,
        user_allowance: 999999999
      };

      // Create company
      const companyResponse = await authenticatedFetch('/api/company/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name_formal: userDomain,
          name_informal: userDomain.split('.')[0],
          name_plural: `${userDomain.split('.')[0]} Team`,
          service_tier: selectedTier,
          is_trial_active: true,
          trial_end_date: new Date(trialEnd * 1000).toISOString(),
          policy_allowance: allowances.policy_allowance,
          standard_allowance: allowances.standard_allowance,
          control_allowance: allowances.control_allowance,
          prompt_allowance: allowances.prompt_allowance,
          user_allowance: allowances.user_allowance,
          stripe_customer_id: customerId,
          paying_customer: false
        }),
      });

      if (!companyResponse.ok) throw new Error('Failed to create company');

      // Activate trial
      const trialResponse = await authenticatedFetch('/api/stripe/activate-trial', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tierName: 'Professional',
          trialEnd
        })
      });

      if (!trialResponse.ok) throw new Error('Failed to activate trial');

      // Import the Facilities policy template
      const importResponse = await authenticatedFetch('/api/policy/import-default', {
        method: 'POST'
      });

      if (!importResponse.ok) {
        console.error('Failed to import policy template');
      }

      // Generate default risks
      try {
        const riskResponse = await authenticatedFetch('/api/risk/seed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!riskResponse.ok) {
          throw new Error('Failed to generate default risks');
        }
      } catch (error) {
        console.error('Error generating default risks:', error);
        // We don't throw here to allow the onboarding to continue even if risk generation fails
      }

      navigate('/governance/forest', { replace: true });
    } catch (error) {
      console.error('Error in trial initiation:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (showTerms) {
    return <TermsOfService onAccept={handleTermsAccept} />;
  }

  return (
    <div className="onboarding-modal centered get-started">
      <div className="modal-content">
        <h1><span className="valdyr">VALDYR</span><span className="io">.io</span></h1>
        <h2>Setting up your account...</h2>
        {isLoading && (
          <div className="loading-container">
            <Loading />
            <div className="loading-text">We are provisioning your account. Please wait a moment...</div>
          </div>
        )}
        {error && <p className="error-text">{error}</p>}
      </div>
    </div>
  );
}

export default OnboardingActivation; 