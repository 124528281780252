import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { API_BASE_URL } from '../constants/api';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY);
  }
  return stripePromise;
};

export const getAuthToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No authenticated user found');
  }
  return user.getIdToken();
};

export const STRIPE_PRICES = {
  BASIC: 'price_1QfPRJIw2cLupDGXNQ0ewcU6',
  ESSENTIALS: 'price_1QfPRIIw2cLupDGXLFBcm08u',
  PROFESSIONAL: 'price_1QfPRGIw2cLupDGXEuh9Wy2Y',
  ENTERPRISE: 'price_1QfPREIw2cLupDGXObMJMj6P'
};

export const initializeStripe = async () => {
  try {
    const stripe = await getStripe();
    if (!stripe) {
      throw new Error('Stripe failed to initialize');
    }
    return stripe;
  } catch (error) {
    console.error('Error initializing Stripe:', error);
    throw error;
  }
};

export const createSubscription = async (priceId, customerId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/stripe/create-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        customerId,
      }),
    });

    if (!response.ok) {
      throw new Error('Subscription creation failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export const createPaymentIntent = async (amount, currency = 'usd', tierName, customerDomain) => {
  try {
    const token = await getAuthToken();
    const amountInCents = Math.round(amount * 100);
    
    const response = await fetch(`${API_BASE_URL}/api/stripe/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        amount: amountInCents,
        currency,
        payment_method_types: ['card'],
        tierName,
        customerDomain
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Payment intent creation failed');
    }

    const data = await response.json();
    return data.clientSecret;
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
};

export const handlePayment = async (stripe, elements, clientSecret, additionalParams = {}) => {
  try {
    if (!stripe || !elements) {
      throw new Error('Stripe not initialized');
    }

    // Submit the form first
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error('Form submission error:', submitError);
      return { error: submitError };
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        payment_method_data: {
          ...additionalParams.payment_method_data
        }
      },
      redirect: 'if_required'
    });

    if (error) {
      console.error('Payment confirmation error:', error);
      return { error };
    }

    return { paymentIntent };
  } catch (error) {
    console.error('Payment failed:', error);
    throw error;
  }
};

export const getSubscriptionStatus = async (subscriptionId) => {
  try {
    const token = await getAuthToken();
    const response = await fetch(`${API_BASE_URL}/api/stripe/subscription-details`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch subscription details');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/stripe/cancel-subscription/${subscriptionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to cancel subscription');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
};

export const updateSubscription = async (subscriptionId, newPriceId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/stripe/update-subscription/${subscriptionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        newPriceId,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update subscription');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating subscription:', error);
    throw error;
  }
};

export const appearance = {
  variables: {
    colorText: 'var(--text-color-light)',
  },
  rules: {
    '.Label': {
      color: 'var(--text-color-light)',
    },
    '.Tab': {
      color: 'var(--text-color-light)',
    },
    '.Input': {
      color: 'var(--text-color-light)',
    }
  }
}; 