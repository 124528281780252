import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function StandardChoice({ onBuildFromScratch, onGenerateWithAI, standardId, domainName, standardName, authenticatedFetch }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  const validateInputs = () => {
    if (!standardId) {
      setError('Standard ID is missing');
      return false;
    }
    if (!domainName?.trim()) {
      setError('Domain name is missing');
      return false;
    }
    if (!standardName?.trim()) {
      setError('Standard name is missing');
      return false;
    }
    return true;
  };

  const handleGenerateClick = async () => {
    setError('');
    if (!validateInputs()) return;
    
    setIsGenerating(true);
    try {
      const response = await authenticatedFetch(`/api/standard/${standardId}/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          domain_name: domainName,
          standard_name: standardName
        }),
      });

      if (response.ok) {
        window.location.reload();
        return;
      }

      const errorText = await response.text();
      throw new Error(errorText || 'Failed to generate standard');
    } catch (error) {
      console.error('Error generating standard:', error);
      setError(typeof error === 'string' ? error : error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          padding: 2
        }
      }}
    >
      <DialogTitle>Create New Standard</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          Choose how you would like to create your standard
        </Typography>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button 
            variant="outlined" 
            onClick={onBuildFromScratch}
            sx={{ minWidth: '200px' }}
          >
            Build from Scratch
          </Button>
          <Button 
            variant="contained" 
            onClick={handleGenerateClick}
            disabled={isGenerating}
            sx={{ minWidth: '200px' }}
          >
            {isGenerating ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Generate with AI'
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default StandardChoice; 