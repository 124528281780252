import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccountTree as ProcessIcon } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { camelCaseToSpaces } from 'utils/jinja';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    color: 'var(--title-color)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

const ItemChip = styled(Typography)(({ theme }) => ({
  backgroundColor: 'var(--background-color)',
  padding: '6px 12px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  display: 'inline-block',
  color: 'var(--text-color-dark)',
}));

function ProcessCard({ process, onClick }) {
  return (
    <StyledCard 
      onClick={onClick}
      sx={{ cursor: 'pointer', width: 'calc(100% - .5rem)', marginBottom: 1 }}
    >
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {process.status === "Overdue" && (
            <AccessTimeIcon sx={{ color: 'var(--unhealthy-color)', backgroundColor: 'white', borderRadius: '50%', p: 0.1 }} />
          )}
          <Typography variant="h6" sx={{ color: 'var(--title-color)', fontSize: '16px' }}>
            {process.name && camelCaseToSpaces(process.name)}
          </Typography>
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            backgroundColor: process.text ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
            color: 'white',
            padding: '2px 8px',
            borderRadius: '4px',
            marginLeft: 'auto'
          }}
        >
          {process.text ? 'Populated' : 'Not Populated'}
        </Typography>
      </Box>
      <CardContent>
        {/* Process Details Section */}
        {process.text && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'var(--text-color-dark)',
              backgroundColor: 'var(--background-color)',
              p: 2,
              borderRadius: 1,
              mb: 2,
              fontFamily: 'monospace'
            }}
          >
            {process.text.replace(/<[^>]*>/g, '').substring(0, 200)}
            {process.text.length > 200 && '...'}
          </Typography>
        )}

        {/* Associated Controls Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Controls
          </Typography>
          {process.controls && process.controls.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {process.controls.map((control, index) => (
                <ItemChip key={index}>
                  {control && camelCaseToSpaces(control)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No controls associated
            </Typography>
          )}
        </Box>

        {/* Associated Standards Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Standards
          </Typography>
          {process.standards && process.standards.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {process.standards.map((standard, index) => (
                <ItemChip key={index}>
                  {standard}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No standards associated
            </Typography>
          )}
        </Box>

        {/* Associated Policies Section */}
        <Box>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Policies
          </Typography>
          {process.policies && process.policies.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {process.policies.map((policy, index) => (
                <ItemChip key={index}>
                  {policy && camelCaseToSpaces(policy)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No policies associated
            </Typography>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
}

export default ProcessCard; 