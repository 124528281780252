import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { StackedBarChart as RiskIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import RiskCard from './risk-card';
import { fetchRisks } from './risk-queries';
import Loading from 'components/Objects/loading';
import Error from 'components/Objects/Error';
import { camelToSpace } from '../../utils/format';
import DOMPurify from 'dompurify';

const sanitize = DOMPurify.sanitize;

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    backgroundColor: 'var(--background-color)',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--title-color)',
      borderWidth: '2px',
    }
  }
}));

const DomainSelect = styled(Select)(({ theme }) => ({
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  }
}));

const DomainHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
  lineHeight: '1.6',
  fontSize: '1.5rem',
  fontWeight: 500
}));

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 2rem)',
  flex: 1,
  padding: '1rem',
});

function RiskSection() {
  const { authenticatedFetch } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('all');
  const [risks, setRisks] = useState({ domains: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadRisks = async () => {
      try {
        setLoading(true);
        const risksData = await fetchRisks(authenticatedFetch, true);
        setRisks(risksData);
        setError(null);
      } catch (err) {
        console.error('Error loading risks:', err);
        setError('Failed to load risks. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadRisks();
  }, [authenticatedFetch]);

  const filterRisks = (domain) => {
    return domain.risks.filter(risk =>
      risk.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      risk.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const getFilteredDomains = () => {
    if (selectedDomain === 'all') {
      return risks.domains;
    }
    return risks.domains?.filter(domain => domain.name === selectedDomain) || [];
  };

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflowX: 'hidden' }}>
      <Paper elevation={0} sx={{ width: '100%', mb: 1}}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          p: 1.42, 
          backgroundColor: 'white', 
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <RiskIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
            <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
              Risks
            </Typography>
            <FormControl size="small" sx={{ minWidth: 200, position: 'relative' }}>
              <InputLabel>Domain</InputLabel>
              <DomainSelect
                value={selectedDomain}
                onChange={(e) => setSelectedDomain(e.target.value)}
                label="Domain"
                MenuProps={{
                  disablePortal: true,
                  slotProps: {
                    paper: {
                      sx: {
                        zIndex: 9999,
                        position: 'absolute',
                        marginTop: 0.5,
                      }
                    }
                  }
                }}
              >
                <MenuItem value="all">All Domains</MenuItem>
                {risks.domains?.map((domain) => (
                  <MenuItem key={domain.name} value={domain.name}>
                    {camelToSpace(domain.name)}
                  </MenuItem>
                ))}
              </DomainSelect>
            </FormControl>
            <SearchField
              placeholder="Search risks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'var(--text-color-neutral)' }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{ width: '300px' }}
            />
          </Box>
        </Box>
      </Paper>
      <ContentContainer>
        {getFilteredDomains()?.map(domain => {
          const filteredDomainRisks = filterRisks(domain);
          if (filteredDomainRisks.length === 0) return null;

          return (
            <Box key={domain.name} sx={{ mb: 2 }}>
              <DomainHeader sx={{ borderBottom: '2px solid var(--border-color)', marginBottom: '1rem' }}>
                <Typography variant="h6" sx={{ color: 'var(--title-color)', fontWeight: 500, fontSize: '1.5rem' }}>
                  {camelToSpace(domain.name)}
                </Typography>
              </DomainHeader>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
                gap: 2,
              }}>
                {filteredDomainRisks.map(risk => (
                  <RiskCard sx={{ marginTop: '1rem' }} key={risk.id} risk={risk} />
                ))}
              </Box>
            </Box>
          );
        })}
      </ContentContainer>
    </Box>
  );
}

export default RiskSection; 