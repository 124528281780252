import nunjucks from 'nunjucks';

// Process type mapping for URLs
const processTypes = {
  'quantity': 'quantities',
  'unit': 'units',
  'frequency': 'frequencies',
  'platform': 'platforms',
  'test': 'tests',
  'evidence': 'evidence',
  'threshold': 'thresholds'
};

// Configure Nunjucks
nunjucks.configure({ autoescape: false });

// Helper function to convert CamelCase to spaces
const camelCaseToSpaces = (str) => {
  if (!str) return '';
  return str.replace(/([A-Z])/g, ' $1').trim();
};

// Helper function to determine variable type based on control field
const getVariableType = (content, controls) => {
  if (!content || !controls) {
    const templateMatch = content?.match(/\{\{\s*([^}]+)\s*\}\}/);
    if (templateMatch) {
      const [controlName, fieldType] = templateMatch[1].trim().split('.');
      if (controlName.toLowerCase() === 'company_name') {
        return 'company_name';
      }
      if (fieldType === 'Evidence') {
        return 'evidence';
      }
    }
    return content ? 'text' : '';
  }

  // Check if this is a template variable
  const templateMatch = content.match(/\{\{\s*([^}]+)\s*\}\}/);
  if (templateMatch) {
    const variablePath = templateMatch[1].trim();
    const [controlName, fieldType] = variablePath.split('.');
    
    // Find the control that matches this variable
    const control = controls.find(c => c.name === controlName);
    if (control) {
      // Map the field type to the corresponding process type
      switch (fieldType) {
        case 'Quantity':
          return 'quantity';
        case 'Unit':
          return 'unit';
        case 'Frequency':
          return 'frequency';
        case 'Frequency_Unit':
          return 'frequency_unit';
        case 'Platform':
          return 'platform';
        case 'Test':
          return 'test';
        case 'Evidence':
          return 'evidence';
        case 'Threshold':
          return 'threshold';
        case 'Text':
          return 'text';
        default:
          return 'text';
      }
    }
  }

  // For non-template content, check if it matches any control values
  for (const control of controls) {
    if (content === control.quantity) return 'quantity';
    if (content === control.unit) return 'unit';
    if (content === control.frequency) return 'frequency';
    if (content === control.frequency_unit) return 'frequency_unit';
    if (content === control.platform) return 'platform';
    if (content === control.test) return 'test';
    if (content === control.evidence) return 'evidence';
    if (content === control.threshold) return 'threshold';
  }

  return 'text';
};

export const replaceJinjaVariables = (text, controls, companyName, singleControl = null) => {
  if (!text) return '';
  
  // For control descriptions, we only want to use that control's context
  if (singleControl) {
    const context = {
      'Company_Name': companyName,
      [singleControl.name]: {
        'Quantity': singleControl.quantity,
        'Unit': singleControl.unit,
        'Frequency': singleControl.frequency,
        'Frequency_Unit': singleControl.frequency_unit,
        'Platform': singleControl.platform,
        'Test': singleControl.test,
        'Evidence': singleControl.evidence,
        'Threshold': singleControl.threshold,
        'Text': singleControl.text,
        'Title': camelCaseToSpaces(singleControl.name),
      }
    };

    let renderedText = nunjucks.renderString(text, context);
    
    // Clean up HTML entities and style variables
    renderedText = renderedText.replace(/&lt;br&gt;/g, '<br>');
    renderedText = renderedText.replace(/\*\*(.*?)\*\*/g, (match, content) => {
      const type = getVariableType(content, [singleControl]);
      const value = content.trim();
      
      // Special handling for frequency - combine with its unit
      if (type.toLowerCase() === 'frequency') {
        const combinedValue = `${singleControl.frequency} ${singleControl.frequency_unit}`;
        const urlType = processTypes['frequency'];
        return `<span class="mce-variable" data-var="${type}" data-process-type="frequency" data-process-value="${combinedValue}" data-url-type="${urlType}" style="cursor: pointer;">${value}</span>`;
      }
      
      // Skip frequency_unit as it's handled with frequency
      if (type.toLowerCase() === 'frequency_unit') {
        const combinedValue = `${singleControl.frequency} ${singleControl.frequency_unit}`;
        const urlType = processTypes['frequency'];
        return `<span class="mce-variable" data-var="${type}" data-process-type="frequency" data-process-value="${combinedValue}" data-url-type="${urlType}" style="cursor: pointer;">${value}</span>`;
      }
      
      // Handle all other process types
      if (Object.keys(processTypes).includes(type.toLowerCase())) {
        const urlType = processTypes[type.toLowerCase()];
        return `<span class="mce-variable" data-var="${type}" data-process-type="${type.toLowerCase()}" data-process-value="${value}" data-url-type="${urlType}" style="cursor: pointer;">${value}</span>`;
      }
      
      return `<span class="mce-variable" data-var="${type}">${value}</span>`;
    });
    
    renderedText = renderedText.replace(/^\*\*(.*)\*\*$/gm, '$1');
    
    return renderedText;
  }

  // For standard descriptions
  const context = {
    'Company_Name': companyName
  };

  // Build context from all controls
  if (controls && Array.isArray(controls)) {
    controls.forEach(control => {
      context[control.name] = {
        'Quantity': control.quantity,
        'Unit': control.unit,
        'Frequency': control.frequency,
        'Frequency_Unit': control.frequency_unit,
        'Platform': control.platform,
        'Test': control.test,
        'Evidence': control.evidence,
        'Threshold': control.threshold,
        'Text': control.text,
        'Title': camelCaseToSpaces(control.name),
      };
    });
  }

  // First render any nested templates in control texts
  if (controls && Array.isArray(controls)) {
    controls.forEach(control => {
      if (control.text) {
        // For each control's text, process it with variables
        let processedText = control.text;
        processedText = processedText.replace(/\*\*(.*?)\*\*/g, (match, content) => {
          const templateMatch = content.match(/\{\{\s*([^}]+)\s*\}\}/);
          if (templateMatch) {
            const [controlName, fieldType] = templateMatch[1].trim().split('.');
            const type = fieldType.toLowerCase();
            const value = context[controlName][fieldType];
            
            if (type === 'frequency' || type === 'frequency_unit') {
              const combinedValue = `${context[controlName]['Frequency']} ${context[controlName]['Frequency_Unit']}`;
              const urlType = processTypes['frequency'];
              return `<span class="mce-variable" data-var="${type}" data-process-type="frequency" data-process-value="${combinedValue}" data-url-type="${urlType}" style="cursor: pointer;">${value}</span>`;
            }
            
            if (Object.keys(processTypes).includes(type)) {
              const urlType = processTypes[type];
              return `<span class="mce-variable" data-var="${type}" data-process-type="${type}" data-process-value="${value}" data-url-type="${urlType}" style="cursor: pointer;">${value}</span>`;
            }
            
            return `<span class="mce-variable" data-var="${type}">${value}</span>`;
          }
          return content;
        });
        context[control.name]['Text'] = processedText;
      }
    });
  }

  let renderedText = nunjucks.renderString(text, context);
  renderedText = renderedText.replace(/&lt;br&gt;/g, '<br>');
  renderedText = renderedText.replace(/\*\*([^*]+)\*\*/g, '$1');
  return renderedText;
};

export const formatRichTextWithTags = (text, control, companyName) => {
  if (!text) return '';
  
  // Create context
  const context = {
    'Company_Name': companyName,
    [control.name]: {
      'Quantity': control.quantity,
      'Unit': control.unit,
      'Frequency': control.frequency,
      'Frequency_Unit': control.frequency_unit,
      'Platform': control.platform,
      'Test': control.test,
      'Evidence': control.evidence,
      'Threshold': control.threshold,
      'Text': control.text,
      'Title': camelCaseToSpaces(control.name),
    }
  };

  // First replace the variables with spans before rendering
  let processedText = text.replace(/\*\*\{\{\s*([^}]+)\s*\}\}\*\*/g, (match, content) => {
    const [controlName, fieldType] = content.trim().split('.');
    const type = fieldType.toLowerCase();
    const value = context[controlName][fieldType];
    
    // Special handling for frequency and frequency_unit
    if (type === 'frequency') {
      return `<span class="mce-variable" contenteditable="false" data-var="${fieldType}" data-process-type="frequency" data-process-value="${control.frequency}" data-url-type="frequencies">${control.frequency}</span>`;
    }
    
    if (type === 'frequency_unit') {
      return `<span class="mce-variable" contenteditable="false" data-var="${fieldType}" data-process-type="frequency_unit" data-process-value="${control.frequency_unit}" data-url-type="frequencies">${control.frequency_unit}</span>`;
    }
    
    // Map process types to URL types
    const urlTypes = {
      'quantity': 'quantities',
      'unit': 'units',
      'platform': 'platforms',
      'test': 'tests',
      'evidence': 'evidence',
      'threshold': 'thresholds'
    };
    
    const processType = type;
    const urlType = urlTypes[processType];
    
    return `<span class="mce-variable" contenteditable="false" data-var="${fieldType}" data-process-type="${processType}" data-process-value="${value}" data-url-type="${urlType}">${value}</span>`;
  });

  // Then render with nunjucks
  let renderedText = nunjucks.renderString(processedText, context);
  
  // Clean up HTML entities and ensure proper spacing
  renderedText = renderedText.replace(/&lt;br&gt;/g, '<br>');
  renderedText = renderedText.replace(/>\s+</g, '>&nbsp;<');
  
  return renderedText;
};

export { camelCaseToSpaces };