import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from 'components/Objects/loading';
import 'styles/Standards.css';
import { useAuth } from '../../contexts/AuthContext';
import Confirmation from 'components/Objects/Confirmation';
import Error from 'components/Objects/Error';
import { replaceJinjaVariables, camelCaseToSpaces } from 'utils/jinja';
import 'simplebar-react/dist/simplebar.min.css';
import StandardChoice from 'components/Objects/StandardChoice';

import { fetchDomains, 
  fetchStandardDetails, 
  deleteStandard, 
  deleteDomain, 
  addStandard, 
  fetchUserProfile,
  addPolicy,
  createPolicy,
  renameItem,
  deleteControl,
  addControl,
  deletePolicy,
  updateDomains,
  fetchStandardPolicies,
  fetchStandardRisks,
  fetchStandardControls,
  fetchStandardData
} from './standard-queries';
import StandardPolicies from './standard-policies';
import StandardControlsTable from './standard-controls-table';
import { useStandardActions } from './standard-actions';
import StandardControl from './control-details';
import StandardGraph from './standard-graph';
import StandardSchedule from './standard-schedule';
import StandardRisks from './standard-risks';
import {
  Box,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Article as ArticleIcon
} from '@mui/icons-material';
import StandardText from './standard-text';

function StandardDetails() {
  const { authenticatedFetch } = useAuth();
  const { domainName, standardName, controlName, standardId } = useParams();
  const [domains, setDomains] = useState({});
  const [standard, setStandard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [addingPolicy, setAddingPolicy] = useState(false);
  const [newPolicyName, setNewPolicyName] = useState('');
  const [newPolicyDescription, setNewPolicyDescription] = useState('');
  const [showPolicyConfirmation, setShowPolicyConfirmation] = useState(false);
  const [pendingPolicy, setPendingPolicy] = useState(null);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [functionsExpanded, setFunctionsExpanded] = useState(true);
  const [policiesExpanded, setPoliciesExpanded] = useState(true);
  const [controlsExpanded, setControlsExpanded] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showNavigationConfirmation, setShowNavigationConfirmation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [pendingEditAction, setPendingEditAction] = useState({ type: '', domain: '', standard: '' });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletionTarget, setDeletionTarget] = useState(null);
  const [renameTarget, setRenameTarget] = useState(null);
  const [isControlsEditing, setIsControlsEditing] = useState(false);
  const [showControlDeleteConfirmation, setShowControlDeleteConfirmation] = useState(false);
  const [pendingControlDelete, setPendingControlDelete] = useState(null);
  const [newControlName, setNewControlName] = useState('');
  const [newControlDescription, setNewControlDescription] = useState('');
  const [showPolicyInput, setShowPolicyInput] = useState(false);
  const [isPoliciesEditing, setIsPoliciesEditing] = useState(false);
  const [detailedPolicies, setDetailedPolicies] = useState([]);
  const [pendingPolicyDelete, setPendingPolicyDelete] = useState(null);
  const [showPolicyDeleteConfirmation, setShowPolicyDeleteConfirmation] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);

  const [showControlAddConfirmation, setShowControlAddConfirmation] = useState(false);
  const [pendingControlAdd, setPendingControlAdd] = useState(null);

  const [newDomain, setNewDomain] = useState(null);

  const [graphExpanded, setGraphExpanded] = useState(true);

  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const [historyExpanded, setHistoryExpanded] = useState(true);

  const [scheduleExpanded, setScheduleExpanded] = useState(true);

  const [isRisksEditing, setIsRisksEditing] = useState(false);
  const [risksExpanded, setRisksExpanded] = useState(true);
  const [detailedRisks, setDetailedRisks] = useState([]);

  const [detailedControls, setDetailedControls] = useState([]);

  const [textExpanded, setTextExpanded] = useState(true);

  const handleAssociationChange = async () => {
    try {
      if (standard?.id) {
        // Reload risks
        const risks = await fetchStandardRisks(authenticatedFetch, standard.id);
        setDetailedRisks(risks);
        
        // Reload policies
        const policies = await fetchStandardPolicies(authenticatedFetch, standard.id);
        setDetailedPolicies(policies);
        
        // Reload standard data using ID
        const response = await authenticatedFetch(`/api/standard/${standard.id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch standard details');
        }
        const updatedStandard = await response.json();
        if (updatedStandard.standard?.controls) {
          setDetailedControls(updatedStandard.standard.controls);
        }
      }
    } catch (error) {
      console.error('Error reloading associations:', error);
    }
  };

  const { 
    handleControlClick, 
    handlePolicyClick,
    onConfirmDelete,
    handleCancelDelete,
    handleError,
    handleAddPolicy,
    fetchStandard,
    handleConfirmNavigation,
    handleCancelNavigation,
    handleConfirmEdit,
    handleConfirmRename,
    handleCancelRename,
    handleControlDeleteClick,
    handleConfirmControlDelete,
    handleCancelControlDelete,
    handlePolicyDeleteClick,
    handleConfirmPolicyDelete,
    handleCancelPolicyDelete,
    stripHtmlTags,
    convertHtmlToJinjaTemplate,
    handleAddControl,
    fetchPolicyDetails,
    onAddControl,
    handleConfirmControlAdd,
    handleCancelControlAdd,
    handleStandardEdit,
    handleStandardDelete,
    handlePolicySubmit,
    handleDomainAdd,
    handleDomainEdit,
    handleDomainsUpdate
  } = useStandardActions(
    authenticatedFetch, 
    setDomains,
    setErrorMessage,
    setShowErrorDialog,
    setShowConfirmation,
    setDeletionTarget,
    setAddingPolicy,
    setShowPolicyConfirmation,
    setPendingPolicy,
    setStandard,
    setCompanyName,
    setNotFound,
    setLoading,
    setInitialLoading,
    setHasUnsavedChanges,
    setShowNavigationConfirmation,
    setPendingNavigation,
    setRenameTarget,
    setShowControlDeleteConfirmation,
    setPendingControlDelete,
    setShowPolicyDeleteConfirmation,
    setPendingPolicyDelete,
    setDetailedPolicies,
    setShowControlAddConfirmation,
    setPendingControlAdd,
    setNewControlName,
    setNewControlDescription,
    setIsControlsEditing,
    pendingControlAdd,
    setNewDomain,
    handleAssociationChange
  );

  useEffect(() => {
    fetchStandard(domainName, standardName, initialLoading);
  }, [domainName, standardName]);

  useEffect(() => {
    const updateView = async () => {
      setIsTransitioning(true);
      setIsTransitioning(false);
    };

    updateView();
  }, [loading, standard]);

  useEffect(() => {
    const fetchPoliciesForStandard = async () => {
      if (standard?.id) {
        try {
          const policies = await fetchStandardPolicies(authenticatedFetch, standard.id);
          setDetailedPolicies(policies);
        } catch (error) {
          console.error('Error fetching policies:', error);
        }
      }
    };

    fetchPoliciesForStandard();
  }, [standard]);

  const handleStandardAdd = async (domainName) => {
    try {
      console.log('Adding standard to domain:', domainName);
      const result = await addStandard(authenticatedFetch, domainName);
      
      if (result) {
        // Update the domains data
        await handleDomainsUpdate();
        
        // If we have the new standard data, we can navigate to it
        if (result.name) {
          const domainNameEncoded = encodeURIComponent(domainName);
          const standardNameEncoded = encodeURIComponent(result.name);
          navigate(`/company/standards/${domainNameEncoded}/${standardNameEncoded}`);
        }
      }
    } catch (error) {
      console.error('Error adding standard:', error);
      setErrorMessage('Failed to add standard. Please try again.');
      setShowErrorDialog(true);
    }
  };

  const handleStandardUpdate = () => {
    setRefreshTrigger(prev => prev + 1);
    handleAssociationChange();
  };

  const handleRiskClick = (risk) => {
    navigate(`/governance/risk/${encodeURIComponent(risk.name)}`);
  };

  const handleRiskDeleteClick = async (risk) => {
    try {
      await authenticatedFetch(`/api/standard/${standardId}/risks/${risk.id}`, {
        method: 'DELETE'
      });
      
      setStandard(prev => ({
        ...prev,
        risks: prev.risks.filter(r => r.id !== risk.id)
      }));
      
      handleStandardUpdate();
    } catch (error) {
      console.error('Error deleting risk:', error);
      setErrorMessage('Failed to delete risk. Please try again.');
      setShowErrorDialog(true);
    }
  };

  const handleBuildFromScratch = async () => {
    setIsEditing(true);
    
    // Create the initial text with the standard name in H1
    const initialText = `<h1>${camelCaseToSpaces(standardName)}</h1>`;
    
    try {
      // Update the standard text in the backend
      const response = await authenticatedFetch(`/api/standard/${standard.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ standard_text: initialText })
      });

      if (!response.ok) {
        throw new Error('Failed to update standard text');
      }

      // Update the local state
      setStandard(prev => ({
        ...prev,
        standard_text: initialText
      }));
      
    } catch (error) {
      console.error('Error updating standard text:', error);
      setErrorMessage('Failed to initialize standard text');
      setShowErrorDialog(true);
    }
  };

  const handleGenerateWithAI = async (result) => {
    if (result.standard_id) {
      try {
        const response = await authenticatedFetch(`/api/standard/${result.standard_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch standard data');
        }
        
        const standardData = await response.json();
        if (standardData.standard) {
          setStandard(standardData.standard);
          setDetailedControls(standardData.standard.controls || []);
          handleStandardUpdate();
        }
      } catch (error) {
        console.error('Error fetching updated standard:', error);
        setErrorMessage('Failed to load generated standard');
        setShowErrorDialog(true);
      }
    }
  };

  if (initialLoading) return <Loading />;
  if (notFound) return <div>Standard not found.</div>;
  if (!standard) return <div className="no-standard">No standard found.</div>;

  const breadcrumbs = ['Company', 'Standards', domainName, standardName];


  return (
    <div className="page-content">
      <div className="main-content">
        {controlName ? (
          <StandardControl />
        ) : (
          isTransitioning ? (
            <div className="standard-loading">
              <Loading />
            </div>
          ) : (
            <div>
              {standard && (
                <>
                  <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
                    <Paper sx={{ borderRadius: 0, borderColor: 'divider', mb: 1 }} elevation={0}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        p: 1.75,
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <ArticleIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
                          <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
                            {camelCaseToSpaces(standardName)}
                          </Typography>
                          <Typography variant="body2" sx={{ backgroundColor: 'var(--button-neutral-color)', color: 'var(--text-color-dark-alt)', borderRadius: '4px', p: 0.5, pl: 1, pr: 1 }}>
                            {domainName}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                  <div className="tables-container">
                    <StandardGraph
                      graphExpanded={graphExpanded}
                      setGraphExpanded={setGraphExpanded}
                      domainName={domainName}
                      standardName={standardName}
                      domains={domains}
                      refreshTrigger={refreshTrigger}
                    />
                    <StandardText
                      standardId={standard?.id}
                      textExpanded={textExpanded}
                      setTextExpanded={setTextExpanded}
                      standardText={standard?.standard_text}
                      onTextUpdate={(newText) => {
                        setStandard({
                          ...standard,
                          standard_text: newText
                        });
                      }}
                    />
                    <StandardPolicies
                      isPoliciesEditing={isPoliciesEditing}
                      policiesExpanded={policiesExpanded}
                      setIsPoliciesEditing={setIsPoliciesEditing}
                      setPoliciesExpanded={setPoliciesExpanded}
                      handleAddPolicy={handleAddPolicy}
                      detailedPolicies={detailedPolicies}
                      handlePolicyDeleteClick={handlePolicyDeleteClick}
                      handlePolicyClick={handlePolicyClick}
                      standardId={standard.id}
                      setStandard={setStandard}
                      onAssociationChange={handleAssociationChange}
                    />
                    <StandardRisks
                      isRisksEditing={isRisksEditing}
                      risksExpanded={risksExpanded}
                      setIsRisksEditing={setIsRisksEditing}
                      setRisksExpanded={setRisksExpanded}
                      detailedRisks={detailedRisks}
                      setDetailedRisks={setDetailedRisks}
                      handleRiskDeleteClick={handleRiskDeleteClick}
                      handleRiskClick={handleRiskClick}
                      standardId={standard?.id}
                      setStandard={setStandard}
                      onAssociationChange={handleAssociationChange}
                    />
                    <StandardControlsTable
                      isControlsEditing={isControlsEditing}
                      controlsExpanded={controlsExpanded}
                      setIsControlsEditing={setIsControlsEditing}
                      setControlsExpanded={setControlsExpanded}
                      newControlName={newControlName}
                      setNewControlName={setNewControlName}
                      newControlDescription={newControlDescription}
                      setNewControlDescription={setNewControlDescription}
                      onAddControl={() => onAddControl(newControlName, newControlDescription)}
                      standard={standard}
                      handleControlDeleteClick={handleControlDeleteClick}
                      handleControlClick={handleControlClick}
                      domainName={domainName}
                      standardName={standardName}
                      companyName={companyName}
                      onAssociationChange={handleAssociationChange}
                    />
                    <StandardSchedule
                      standard={standard}
                      scheduleExpanded={scheduleExpanded}
                      setScheduleExpanded={setScheduleExpanded}
                      setStandard={setStandard}
                      onAssociationChange={handleAssociationChange}
                    />
                  </div>
                </>
              )}
            </div>
          )
        )}
      </div>

      {showPolicyConfirmation && pendingPolicy && (
        <Confirmation
          message={`Are you sure you want to create policy "${stripHtmlTags(pendingPolicy.name)}"?`}
          onConfirm={() => handleAddPolicy(pendingPolicy, standard)}
          onCancel={() => {
            setShowPolicyConfirmation(false);
            setPendingPolicy(null);
          }}
          autoFocus={false}
        />
      )}
      
      {showErrorDialog && (
        <Error
          message={errorMessage}
          onClose={() => setShowErrorDialog(false)}
        />
      )}

      {showNavigationConfirmation && (
        <Confirmation
          message="You have unsaved changes. Are you sure you want to leave?"
          onConfirm={handleConfirmNavigation}
          onCancel={handleCancelNavigation}
        />
      )}

      {!showConfirmation && !showNavigationConfirmation && renameTarget && (
        <Confirmation
          message={
            renameTarget.type === 'domain'
              ? `Are you sure you want to rename the domain "${renameTarget.domain}" to "${renameTarget.newName}"?`
              : `Are you sure you want to rename the standard "${renameTarget.standard}" to "${renameTarget.newName}"?`
          }
          onConfirm={handleConfirmRename}
          onCancel={handleCancelRename}
        />
      )}

      {showConfirmation && deletionTarget && (
        <Confirmation
          message={
            deletionTarget.type === 'domain'
              ? `Are you sure you want to delete the domain "${deletionTarget.domain}"?`
              : `Are you sure you want to delete the standard "${deletionTarget.standard}" from domain "${deletionTarget.domain}"?`
          }
          onConfirm={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}

      {showControlDeleteConfirmation && pendingControlDelete && (
        <Confirmation
          message={`Are you sure you want to delete the control "${camelCaseToSpaces(pendingControlDelete.name)}"?`}
          onConfirm={() => handleConfirmControlDelete(pendingControlDelete)}
          onCancel={handleCancelControlDelete}
        />
      )}

      {showPolicyDeleteConfirmation && pendingPolicyDelete && (
        <Confirmation
          message={`Are you sure you want to delete the policy "${pendingPolicyDelete.name}"?`}
          onConfirm={() => handleConfirmPolicyDelete(pendingPolicyDelete)}
          onCancel={handleCancelPolicyDelete}
        />
      )}

      {showControlAddConfirmation && pendingControlAdd && (
        <Confirmation
          message={`Are you sure you want to add control "${stripHtmlTags(pendingControlAdd.name)}"?`}
          onConfirm={() => handleConfirmControlAdd(standard, domainName, standardName)}
          onCancel={handleCancelControlAdd}
        />
      )}
    </div>
  );
}

export default StandardDetails; 