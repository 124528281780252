import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Print as PrintIcon
} from '@mui/icons-material';
import RichTextEditor from '../../components/Editor/RichTextEditor';
import Confirmation from 'components/Objects/Confirmation';
import { useAuth } from 'contexts/AuthContext';

const StandardText = ({ standardId, textExpanded, setTextExpanded, standardText, onTextUpdate }) => {
  const [isTextEditing, setIsTextEditing] = useState(false);
  const [editedText, setEditedText] = useState(standardText);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [textHeight, setTextHeight] = useState(200); // Default height
  const textContentRef = useRef(null);
  const { authenticatedFetch } = useAuth();

  const measureTextHeight = () => {
    if (textContentRef.current) {
      const height = textContentRef.current.offsetHeight;
      setTextHeight(Math.max(200, height)); // Minimum height of 200px
    }
  };

  const handleEditClick = () => {
    measureTextHeight();
    setIsTextEditing(true);
  };

  // Add useEffect to measure height when content changes or expands
  useEffect(() => {
    if (textExpanded && !isTextEditing) {
      measureTextHeight();
    }
  }, [textExpanded, standardText]);

  const handleSaveClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSave = async () => {
    try {
      const response = await authenticatedFetch(`/api/standard/${standardId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ standard_text: editedText })
      });

      if (!response.ok) {
        throw new Error('Failed to update standard text');
      }

      onTextUpdate(editedText);
      setIsTextEditing(false);
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error updating standard text:', error);
    }
  };

  const handleCancel = () => {
    setEditedText(standardText);
    setIsTextEditing(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleExportPDF = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Standard Export</title>
          <style>
            body { 
              font-family: Arial, sans-serif;
              margin: 40px;
              line-height: 1.6;
            }
          </style>
        </head>
        <body>
          <div>${standardText}</div>
        </body>
      </html>
    `);
    
    printWindow.document.close();
    printWindow.focus();
    
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 250);
  };

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper sx={{ borderRadius: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: textExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: textExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Standard Text</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isTextEditing ? (
              <>
                <IconButton 
                  onClick={handleExportPDF}
                  sx={{ color: 'var(--text-color-light)' }}
                >
                  <PrintIcon />
                </IconButton>
                <IconButton 
                  onClick={handleEditClick}
                  sx={{ color: 'var(--text-color-light)' }}
                >
                  <EditIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setTextExpanded(!textExpanded)}
              sx={{ 
                transform: textExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {textExpanded && (
          <Box>
            {isTextEditing ? (
              <RichTextEditor
                value={editedText}
                onChange={setEditedText}
                showToolbar={true}
                height={textHeight}
                context={{
                  type: 'standard',
                  standardId: standardId,
                  standardText: standardText
                }}
              />
            ) : (
              <Box 
                ref={textContentRef}
                sx={{ 
                  p: 2,
                  '& a': { color: 'primary.main' },
                  '& p': { mb: 2 },
                  '& br': { display: 'block', content: '""', mb: 2 }
                }}
                dangerouslySetInnerHTML={{ __html: standardText || 'No text provided.' }}
              />
            )}
          </Box>
        )}
      </Paper>
      {showConfirmation && (
        <Confirmation
          message="Are you sure you want to save these changes?"
          onConfirm={handleConfirmSave}
          onCancel={handleCancelConfirmation}
        />
      )}
    </Box>
  );
};

StandardText.propTypes = {
  standardId: PropTypes.number.isRequired,
  textExpanded: PropTypes.bool.isRequired,
  setTextExpanded: PropTypes.func.isRequired,
  standardText: PropTypes.string,
  onTextUpdate: PropTypes.func.isRequired
};

export default StandardText; 