import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { camelCaseToSpaces } from 'utils/jinja';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useAuth } from 'contexts/AuthContext';
import Error from 'components/Objects/Error';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'relative',
    '& .edit-button': {
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',
    },
    '&:hover .edit-button': {
      opacity: 1,
    },
    '& .delete-button': {
      right: theme.spacing(1),
    }
  }
}));

const ItemChip = styled(Typography)(({ theme }) => ({
  backgroundColor: 'var(--background-color)',
  padding: '6px 12px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  display: 'inline-block',
  color: 'var(--text-color-dark)',
}));

function PolicyCard({ policy, handleDeleteClick, onPoliciesUpdate }) {
  const navigate = useNavigate();
  const { authenticatedFetch } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(policy.title);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRenameConfirmation, setShowRenameConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const handleClick = () => {
    if (!isEditing) {
      navigate(`/governance/policy/${encodeURIComponent(policy.title)}`);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await authenticatedFetch(`/api/policy/${policy.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 400 && data.error === "Validation error") {
          setErrorMessage(
            `Cannot delete policy with associated standards. Please remove the following standards first:\n` +
            data.details.standards.map(s => s.name).join(', ')
          );
          setShowErrorDialog(true);
          setShowDeleteConfirmation(false);
        } else {
          setErrorMessage(data.message || 'Failed to delete policy');
          setShowErrorDialog(true);
        }
        return;
      }
      
      if (handleDeleteClick) {
        await handleDeleteClick(data);
      }

      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting policy:', error);
      setErrorMessage('Failed to delete policy');
      setShowErrorDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    setShowRenameConfirmation(true);
  };

  const handleConfirmRename = async () => {
    try {
      // Convert edited title to proper format
      const formattedTitle = editedTitle
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      
      // Create camelCase name
      const formattedName = formattedTitle.replace(/\s+/g, '');

      const response = await authenticatedFetch(`/api/policy/rename`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oldTitle: policy.title,
          newTitle: formattedTitle,
          newName: formattedName
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to rename policy');
      }

      setIsEditing(false);
      setShowRenameConfirmation(false);
      window.location.reload();
    } catch (error) {
      console.error('Error renaming policy:', error);
    }
  };

  const handleCancelRename = () => {
    setShowRenameConfirmation(false);
  };

  return (
    <StyledCard 
      onClick={handleClick}
      sx={{ cursor: 'pointer', width: 'calc(100% - 2rem)', marginBottom: 1 }}
    >
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isEditing ? (
            <Box
              component="input"
              sx={{
                fontSize: '18px',
                fontFamily: 'inherit',
                color: 'var(--text-color-light)',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                border: 'none',
                borderRadius: '4px',
                p: 1,
                '&:focus': {
                  outline: 'none',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }
              }}
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <Typography variant="h6" sx={{ color: 'var(--text-color-light)', fontSize: '18px' }}>
              {policy.title && camelCaseToSpaces(policy.title)}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {!isEditing ? (
            <Box className="edit-button">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDelete}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={handleSave}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(false);
                  setEditedTitle(policy.title);
                }}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          )}
          <Typography 
            variant="caption" 
            sx={{ 
              backgroundColor: policy.description ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '4px',
              whiteSpace: 'nowrap'
            }}
          >
            {policy.description ? 'Populated' : 'Not Populated'}
          </Typography>
        </Box>
      </Box>
      <CardContent>
        {/* Policy Description Section */}
        {policy.description && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'var(--text-color-dark)',
              backgroundColor: 'var(--background-color)',
              p: 2,
              borderRadius: 1,
              mb: 2,
              fontFamily: 'monospace'
            }}
          >
            {policy.description.replace(/<[^>]*>/g, '').substring(0, 200)}
            {policy.description.length > 200 && '...'}
          </Typography>
        )}

        {/* Standards Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Standards
          </Typography>
          {policy.standards && policy.standards.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {policy.standards.map((standard) => (
                <ItemChip key={standard.id}>
                  {standard.name && camelCaseToSpaces(standard.name)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No standards associated
            </Typography>
          )}
        </Box>

      </CardContent>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteConfirmation}
        onClose={handleCancelDelete}
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the policy "{policy.title && camelCaseToSpaces(policy.title)}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button 
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{ 
              backgroundColor: 'var(--unhealthy-color)',
              '&:hover': {
                backgroundColor: 'var(--unhealthy-color)',
                opacity: 0.9,
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rename Confirmation Dialog */}
      <Dialog
        open={showRenameConfirmation}
        onClose={handleCancelRename}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Rename</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to rename the policy from "{policy.title && camelCaseToSpaces(policy.title)}" to "{editedTitle && camelCaseToSpaces(editedTitle)}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRename}>Cancel</Button>
          <Button 
            onClick={handleConfirmRename}
            variant="contained"
            color="primary"
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {showErrorDialog && (
        <Error
          message={errorMessage}
          onClose={() => setShowErrorDialog(false)}
        />
      )}
    </StyledCard>
  );
}

PolicyCard.propTypes = {
  policy: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func,
  onPoliciesUpdate: PropTypes.func
};

export default PolicyCard; 