import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';

function ForestForceGraph({ policiesData }) {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState(null);
  const [zoomHandler, setZoomHandler] = useState(null);

  // Define colors using CSS variables
  const policy_color = '#012626';
  const standard_color = '#025959';
  const control_color = '#038C8C';
  const highlight_color = '#D9A9C8';

  const processGraphData = (policies) => {
    const nodes = [];
    const links = [];
    let nodeId = 0;

    // Add policy nodes
    policies.forEach(policy => {
      nodes.push({
        id: policy.id,
        name: policy.title,
        type: 'Policy',
        group: 1
      });

      // Add standard nodes and links to policy
      if (policy.standards && policy.standards.length > 0) {
        policy.standards.forEach(standard => {
          nodes.push({
            id: `s${standard.id}`,
            name: standard.name,
            type: 'Standard',
            domain: standard.domain,
            group: 2
          });
          links.push({ 
            source: policy.id, 
            target: `s${standard.id}`, 
            value: 1 
          });

          // Add control nodes and links to standard
          if (standard.controls && standard.controls.length > 0) {
            standard.controls.forEach(control => {
              nodes.push({
                id: `c${control.id}`,
                name: control.title || control.name,
                type: 'Control',
                group: 3
              });
              links.push({ 
                source: `s${standard.id}`, 
                target: `c${control.id}`, 
                value: 1 
              });
            });
          }
        });
      }
    });

    return { nodes, links };
  };

  const getAncestors = (nodeId, nodes, links) => {
    const ancestors = new Set();
    const stack = [nodeId];
    
    while (stack.length > 0) {
      const currentId = stack.pop();
      links.forEach(link => {
        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
        if (targetId === currentId) {
          const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
          if (!ancestors.has(sourceId)) {
            ancestors.add(sourceId);
            stack.push(sourceId);
          }
        }
      });
    }
    return Array.from(ancestors);
  };

  const getDescendants = (nodeId, nodes, links) => {
    const descendants = new Set();
    const stack = [nodeId];
    
    while (stack.length > 0) {
      const currentId = stack.pop();
      links.forEach(link => {
        const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
        if (sourceId === currentId) {
          const targetId = typeof link.target === 'object' ? link.target.id : link.target;
          if (!descendants.has(targetId)) {
            descendants.add(targetId);
            stack.push(targetId);
          }
        }
      });
    }
    return Array.from(descendants);
  };

  const renderForceGraph = (data) => {
    const container = document.getElementById('forest-force');
    const width = container.clientWidth;
    const height = container.clientHeight;

    // Clear previous content
    d3.select("#forest-force").selectAll("svg").remove();

    const color = d3.scaleOrdinal()
      .domain(['Policy', 'Standard', 'Control'])
      .range([policy_color, standard_color, control_color]);

    const links = data.links.map(d => ({...d}));
    const nodes = data.nodes.map(d => ({...d}));

    const simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id)
        .distance(d => {
          const sourceNode = nodes.find(n => n.id === (typeof d.source === 'object' ? d.source.id : d.source));
          if (sourceNode && sourceNode.type === "Policy") {
            return 15;
          }
          return 10;
        })
        .strength(0.7))
      .force("policy-attraction", d3.forceManyBody()
        .strength(d => d.type === "Policy" ? 10 : 0)
        .distanceMax(200))
      .force("center", d3.forceCenter(400, 200))
      .force("collision", d3.forceCollide()
        .radius(d => {
          if (d.type === "Policy") return 15;
          if (d.type === "Standard") return 12;
          return 8;
        })
        .strength(0.7))
      .alphaDecay(0.01)
      .alphaMin(0.001);

    const zoom = d3.zoom()
      .scaleExtent([0.1, 5])
      .on("zoom", zoomed)
      .filter(event => !event.type.includes('wheel'));

    const svg = d3.select("#forest-force")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width/4, -height/4, width, height])
      .call(zoom);

    const g = svg.append("g");

    const link = g.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value));

    const node = g.append("g")
      .selectAll("circle")
      .data(nodes)
      .join("circle")
      .attr("r", d => d.type === "Policy" ? 10 : d.type === "Standard" ? 7.5 : 5)
      .attr("fill", d => color(d.type))
      .on("click", (event, d) => {
        if (d.type === "Policy") {
          navigate(`/governance/policy/${encodeURIComponent(d.name)}`);
        } else if (d.type === "Control" || d.type === "Standard") {
          // Find the parent standard for controls
          const standardPath = d.type === "Control" 
            ? (() => {
                const standardLink = links.find(link => 
                  (typeof link.target === 'object' ? link.target.id : link.target) === d.id
                );
                if (standardLink) {
                  const standardNode = nodes.find(n => 
                    n.id === (typeof standardLink.source === 'object' ? standardLink.source.id : standardLink.source)
                  );
                  if (standardNode) {
                    return `${standardNode.domain}/${standardNode.name}/${d.name}`;
                  }
                }
                return '';
              })()
            : `${d.domain}/${d.name}`;
          
          const encodedPath = standardPath.split('/').map(segment => 
            encodeURIComponent(segment)).join('/');
          navigate(`/company/standards/${encodedPath}`);
        }
      })
      .on("mouseover", function(event, d) {
        event.stopPropagation();
        d3.select(this).style("cursor", "pointer");

        // Add tooltip
        const tooltip = d3.select("#forest-force")
          .append("div")
          .attr("class", "tooltip")
          .style("position", "absolute")
          .style("background-color", "white")
          .style("color", "black")
          .style("padding", "5px")
          .style("border", "1px solid #ccc")
          .style("border-radius", "4px")
          .style("pointer-events", "none")
          .style("opacity", 0);

        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        
        const [mouseX, mouseY] = d3.pointer(event, container);
        tooltip.html(d.name)
          .style("left", (mouseX + 10) + "px")
          .style("top", (mouseY - 10) + "px");

        const ancestors = getAncestors(d.id, nodes, links);
        const descendants = getDescendants(d.id, nodes, links);
        const relatedNodeIds = new Set([...ancestors, ...descendants, d.id]);

        node.each(function(nodeData) {
          const element = d3.select(this);
          const isRelated = relatedNodeIds.has(nodeData.id);
          element.attr("fill", isRelated ? highlight_color : color(nodeData.type));
        });

        link
          .attr("stroke", linkData => {
            const sourceId = typeof linkData.source === 'object' ? linkData.source.id : linkData.source;
            const targetId = typeof linkData.target === 'object' ? linkData.target.id : linkData.target;
            return relatedNodeIds.has(sourceId) && relatedNodeIds.has(targetId) ? highlight_color : "#999";
          })
          .attr("stroke-opacity", linkData => {
            const sourceId = typeof linkData.source === 'object' ? linkData.source.id : linkData.source;
            const targetId = typeof linkData.target === 'object' ? linkData.target.id : linkData.target;
            return relatedNodeIds.has(sourceId) && relatedNodeIds.has(targetId) ? 0.8 : 0.6;
          })
          .attr("stroke-width", linkData => {
            const sourceId = typeof linkData.source === 'object' ? linkData.source.id : linkData.source;
            const targetId = typeof linkData.target === 'object' ? linkData.target.id : linkData.target;
            return relatedNodeIds.has(sourceId) && relatedNodeIds.has(targetId) ? 2.5 : Math.sqrt(linkData.value);
          });
      })
      .on("mousemove", function(event) {
        const [mouseX, mouseY] = d3.pointer(event, container);
        d3.select(".tooltip")
          .style("left", (mouseX + 10) + "px")
          .style("top", (mouseY + 20) + "px");
      })
      .on("mouseout", function(event, d) {
        // Remove tooltip
        d3.select(".tooltip").remove();

        node.each(function(nodeData) {
          const element = d3.select(this);
          element.attr("fill", d => color(d.type));
        });

        link
          .attr("stroke", "#999")
          .attr("stroke-opacity", 0.6)
          .attr("stroke-width", d => Math.sqrt(d.value));
      });

    node.append("title")
      .text(d => d.name);

    node.call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
    });

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function zoomed(event) {
      g.attr("transform", event.transform);
    }

    const handleZoom = (direction) => {
      const currentTransform = d3.zoomTransform(svg.node());
      const newScale = direction === 'in' 
        ? currentTransform.k * 1.2 
        : currentTransform.k / 1.2;

      const scale = Math.min(Math.max(0.1, newScale), 5);
      
      svg.transition()
        .duration(300)
        .call(zoom.transform, 
          d3.zoomIdentity
            .translate(currentTransform.x, currentTransform.y)
            .scale(scale)
        );
    };

    setZoomHandler(() => handleZoom);
  };

  useEffect(() => {
    if (policiesData) {
      const data = processGraphData(policiesData);
      setGraphData(data);
    }
  }, [policiesData]);

  useEffect(() => {
    if (graphData) {
      renderForceGraph(graphData);
    }
  }, [graphData]);

  return (
    <div id="forest-force" style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className="zoom-controls" style={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        <button 
          onClick={() => zoomHandler && zoomHandler('in')}
          style={{
            padding: '8px',
            borderRadius: '4px',
            background: '#44475A',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            width: '40px',
            height: '40px'
          }}
        >
          +
        </button>
        <button 
          onClick={() => zoomHandler && zoomHandler('out')}
          style={{
            padding: '8px',
            borderRadius: '4px',
            background: '#44475A',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            width: '40px',
            height: '40px'
          }}
        >
          -
        </button>
      </div>
    </div>
  );
}

export default ForestForceGraph; 