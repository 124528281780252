import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const ContextSearch = ({ 
  context,
  fetchUrl,
  onSelect,
  placeholder = "Search...",
  className = "",
  includeCondition = null,
  showSecondaryField,
  secondaryField,
  transformResponse = data => data,
  localData = null
}) => {
  const { authenticatedFetch } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  const fetchItems = async () => {
    if (localData || hasFetchedData) {
      return;
    }

    setLoading(true);
    try {
      const apiUrl = fetchUrl.startsWith('/api') ? fetchUrl : `/api${fetchUrl}`;
      const response = await authenticatedFetch(apiUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch items: ${response.status}`);
      }
      
      const data = await response.json();
      const transformedData = transformResponse(data);
      setItems(transformedData);
      setFilteredItems(transformedData);
      setHasFetchedData(true);
    } catch (error) {
      console.error('Error fetching items:', error);
      setItems([]);
      setFilteredItems([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputClick = () => {
    setIsOpen(true);
    if (!hasFetchedData) {
      fetchItems();
    }
  };

  const handleInputFocus = () => {
    if (!hasFetchedData) {
      fetchItems();
    }
  };

  // Filter items and limit to first 10 results
  useEffect(() => {
    console.group('ContextSearch filter');
    console.log('Current search term:', searchTerm);
    console.log('Available items:', items);
    
    if (items.length > 0) {
      const filtered = items
        .filter(item => {
          // First apply the include condition if it exists
          if (includeCondition) {
            if (!includeCondition(item)) return false;
          }

          const itemName = item?.name || '';
          const itemDescription = item?.description || '';
          const itemSecondary = context === 'standard' ? (item?.standard_text || '') : '';
          const searchTermLower = searchTerm.toLowerCase();
          
          return itemName.toLowerCase().includes(searchTermLower) ||
                 itemDescription.toLowerCase().includes(searchTermLower) ||
                 itemSecondary.toLowerCase().includes(searchTermLower);
        })
        .slice(0, 10);
      
      console.log('Filtered items (limited to 10):', filtered);
      setFilteredItems(filtered);
    }
    console.groupEnd();
  }, [searchTerm, items, context, includeCondition]);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleItemSelect = (item) => {
    onSelect(item);
    setIsOpen(false);
    setSearchTerm('');
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TextField
        fullWidth
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setIsOpen(true);
        }}
        onClick={handleInputClick}
        onFocus={handleInputFocus}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          )
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'grey.100',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
            '&.Mui-focused': {
              backgroundColor: 'background.paper',
            }
          }
        }}
      />
      
      {(isOpen || searchTerm) && (
        <Paper 
          elevation={3}
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            mt: 0.5,
            maxHeight: 300,
            overflow: 'auto',
            zIndex: 1000
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : filteredItems.length > 0 ? (
            <List sx={{ p: 0 }}>
              {filteredItems.map((item) => (
                <ListItem 
                  key={item.id}
                  onClick={() => handleItemSelect(item)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'grey.100'
                    }
                  }}
                >
                  <ListItemText
                    primary={item.displayName || item.name}
                    secondary={item.description}
                    primaryTypographyProps={{
                      sx: { 
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                    secondaryTypographyProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
              No results found
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

ContextSearch.propTypes = {
  context: PropTypes.string.isRequired,
  fetchUrl: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  includeCondition: PropTypes.func,
  showSecondaryField: PropTypes.bool,
  secondaryField: PropTypes.string,
  transformResponse: PropTypes.func,
  localData: PropTypes.array
};

export default ContextSearch; 