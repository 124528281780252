import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import CalendarView from './calendar-view';
import Loading from 'components/Objects/loading';
import Error from 'components/Objects/Error';
import 'styles/Calendar.css';

function CalendarSection() {
  const { authenticatedFetch } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [viewType, setViewType] = useState('month');

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        // Fetch review dates from available endpoints
        const [policies, standards, controls, evidence] = await Promise.all([
          authenticatedFetch('/api/policy/review_dates'),
          authenticatedFetch('/api/standard/review_dates'),
          authenticatedFetch('/api/control/review_dates'),
          authenticatedFetch('/api/evidence/review_dates')
        ]);

        setCalendarData({
          policies,
          standards,
          controls,
          evidence
        });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching calendar data:', err);
        setError('Failed to load calendar data');
        setLoading(false);
      }
    };

    fetchCalendarData();
  }, [authenticatedFetch]);

  if (loading) {
    return (
          <Loading />
    );
  }

  if (error) {
    return (
          <Error message={error} />
    );
  }

  return (
        <CalendarView  
          calendarData={calendarData} 
          viewType={viewType}
        />
  );
}

export default CalendarSection; 