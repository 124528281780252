import React from 'react';

const Health = () => {
  return (
    <div>
      <h1>Site Available</h1>
    </div>
  );
};

export default Health; 