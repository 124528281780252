import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ContextSearch from 'components/Objects/context-search';
import { useAuth } from 'contexts/AuthContext';
import { addStandardToPolicy } from './policy-queries';
import { fetchStandardDomain } from 'pages/Company/standard-queries';
import { replaceJinjaVariables } from 'utils/jinja';

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Link as LinkIcon
} from '@mui/icons-material';

const stripHtmlTags = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

function PolicyStandards({ 
  standards, 
  standardsExpanded, 
  setStandardsExpanded,
  isStandardsEditing,
  setIsStandardsEditing,
  onStandardDelete,
  onStandardAdd,
  policyId,
  setPolicy,
  onAssociationChange
}) {
  const navigate = useNavigate();
  const [previewStandards, setPreviewStandards] = useState([]);
  const { authenticatedFetch } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingStandardsToRemove, setPendingStandardsToRemove] = useState([]);
  const [confirmationType, setConfirmationType] = useState(null);
  const [standardDomains, setStandardDomains] = useState({});

  const handleStandardClick = async (standard) => {
    try {
      if (!standard.domain) {
        console.error('No domain available for standard:', standard);
        return;
      }
      
      navigate(`/company/standards/${encodeURIComponent(standard.domain)}/${encodeURIComponent(standard.name)}`);
    } catch (error) {
      console.error('Error navigating to standard:', error);
    }
  };

  const handlePreviewSelect = (standard) => {
    const exists = previewStandards.some(s => s.id === standard.id);
    if (!exists) {
      setPreviewStandards(prev => [...prev, standard]);
    }
  };

  const handlePreviewDelete = (standardToDelete) => {
    setPreviewStandards(prev => prev.filter(s => s.id !== standardToDelete.id));
  };

  const handleCancel = () => {
    setPreviewStandards([]);
    setPendingStandardsToRemove([]);
    setIsStandardsEditing(false);
  };

  const handleStandardRemove = (standard) => {
    setPendingStandardsToRemove([standard]);
    setConfirmationType('remove');
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    if (previewStandards.length > 0) {
      setConfirmationType('add');
      setShowConfirmation(true);
    } else if (pendingStandardsToRemove.length > 0) {
      setConfirmationType('remove');
      setShowConfirmation(true);
    }
  };

  const handleConfirmAdd = async () => {
    try {
      const addPromises = previewStandards.map(standard => 
        addStandardToPolicy(authenticatedFetch, policyId, standard.id)
          .then(() => onStandardAdd(standard))
      );
      
      await Promise.all(addPromises);
      setPreviewStandards([]);
      setIsStandardsEditing(false);
      setShowConfirmation(false);
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error adding standards to policy:', error);
    }
  };

  const handleConfirmRemove = async () => {
    try {
      const removePromises = pendingStandardsToRemove.map(standard => 
        authenticatedFetch(`/api/policy/${policyId}/standards/${standard.id}`, {
          method: 'DELETE'
        })
      );
      
      await Promise.all(removePromises);
      
      const updatedStandards = standards.filter(
        standard => !pendingStandardsToRemove.find(ps => ps.id === standard.id)
      );
      
      setPolicy(prev => ({
        ...prev,
        standards: updatedStandards
      }));
      
      setPendingStandardsToRemove([]);
      setIsStandardsEditing(false);
      setShowConfirmation(false);
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error removing standards from policy:', error);
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationType(null);
  };

  const getStandardStyle = (standardId) => ({
    textDecoration: pendingStandardsToRemove.find(ps => ps.id === standardId) ? 'line-through' : 'none',
    opacity: pendingStandardsToRemove.find(ps => ps.id === standardId) ? 0.5 : 1
  });

  const processDescription = (description, controls) => {
    if (!description) {
      if (controls && controls.length > 0) {
        const processedText = controls.map(control => 
          replaceJinjaVariables(control.text || '', [control], '')
        ).join('\n\n');
        
        const strippedText = stripHtmlTags(processedText);
        return strippedText.length > 100 ? strippedText.substring(0, 100) + '...' : strippedText;
      }
      return '-';
    }
    
    const processedText = replaceJinjaVariables(description, controls || [], '');
    const strippedText = stripHtmlTags(processedText);
    return strippedText.length > 100 ? strippedText.substring(0, 100) + '...' : strippedText;
  };

  const renderContextSearch = () => (
    <ContextSearch
      context="standard"
      fetchUrl="/api/standard/"
      onSelect={handlePreviewSelect}
      placeholder="Search for standards to add..."
      showSecondaryField={true}
      secondaryField="description"
      transformResponse={(response) => {
        const populatedStandards = response.standards.filter(standard => 
          standard.controls && standard.controls.length > 0
        );
        
        return populatedStandards.map(standard => ({
          id: standard.id,
          name: standard.name,
          description: standard.description,
          healthy: standard.healthy
        }));
      }}
    />
  );

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: standardsExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: standardsExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Standards</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isStandardsEditing ? (
              <IconButton 
                onClick={() => setIsStandardsEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleConfirm}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setStandardsExpanded(!standardsExpanded)}
              sx={{ 
                transform: standardsExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {standardsExpanded && (
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Name</TableCell>
                  <TableCell width="65%">Description</TableCell>
                  {isStandardsEditing && <TableCell width="10%">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {standards?.map((standard) => (
                  <TableRow 
                    key={standard.id}
                    sx={getStandardStyle(standard.id)}
                  >
                    <TableCell>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => handleStandardClick(standard)}
                        sx={{ 
                          textAlign: 'left',
                          color: 'var(--link-color)'
                        }}
                      >
                        {standard.name}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        {processDescription(standard.description, standard.controls)}
                      </Typography>
                    </TableCell>
                    {isStandardsEditing && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStandardRemove(standard);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {isStandardsEditing && previewStandards.map((previewStandard) => (
                  <TableRow key={`preview-${previewStandard.id}`}>
                    <TableCell>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => handleStandardClick(previewStandard)}
                        sx={{ textAlign: 'left' }}
                      >
                        {previewStandard.name}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        {processDescription(previewStandard.description, previewStandard.controls)}
                      </Typography>
                    </TableCell>
                    {isStandardsEditing && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreviewDelete(previewStandard);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {isStandardsEditing && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                      {renderContextSearch()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{
          zIndex: 99999999
        }}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          {confirmationType === 'add' 
            ? `Are you sure you want to add ${previewStandards.length} standard${previewStandards.length > 1 ? 's' : ''}?`
            : `Are you sure you want to remove ${pendingStandardsToRemove.length} standard${pendingStandardsToRemove.length > 1 ? 's' : ''}?`
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={confirmationType === 'add' ? handleConfirmAdd : handleConfirmRemove}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

PolicyStandards.propTypes = {
  standards: PropTypes.array,
  standardsExpanded: PropTypes.bool.isRequired,
  setStandardsExpanded: PropTypes.func.isRequired,
  isStandardsEditing: PropTypes.bool.isRequired,
  setIsStandardsEditing: PropTypes.func.isRequired,
  onStandardDelete: PropTypes.func.isRequired,
  onStandardAdd: PropTypes.func.isRequired,
  policyId: PropTypes.number.isRequired,
  setPolicy: PropTypes.func.isRequired,
  onAssociationChange: PropTypes.func
};

export default PolicyStandards; 