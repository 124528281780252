// File: src/components/Objects/constants.js

export const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://app.valdyr.io';

// Separate URLs if they are on different ports
export const API_ENDPOINTS = {
  DATA_ORG: `${API_BASE_URL}/api/standards/data/organization`,
  DATA_PRODUCT: `${API_BASE_URL}/api/standards/data/technology`,
  DATA_CLIENT: `${API_BASE_URL}/api/standards/data/clients`,
  UPDATE_VARIABLE: `${API_BASE_URL}/api/standards/update_variable`,
  UPDATE_FUNCTION: `${API_BASE_URL}/api/standards/update_function`,
  POLICY_CONFIGURE: `${API_BASE_URL}/api/policy/configure`,
};

// Messages
export const MESSAGES = {
  CONFIRMATION_TEMPLATE:
  'Please confirm that you wish to make changes to your {sectionName} Standard. Changes may affect policy readiness.',
  DATA_NOT_FOUND: 'Data for "{sectionName}" not found.',
  FETCH_FAILED: 'Failed to fetch data for "{sectionName}".',
};

// Other Constants
export const DEFAULT_BREADCRUMBS = ['Home', 'Company', 'Standards'];
