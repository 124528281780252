import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import RandomForceGraph from 'components/Graph/graph-random-force';
import '../../styles/colors.css';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { auth } from '../../utils/firebase';

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
}));

const LoginSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  zIndex: 2
}));

const GraphSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: 800,
  backgroundColor: 'var(--background-color-alt)',
  margin: '0',
  color: 'var(--text-color-dark)'
}));

const ScrollableContent = styled(Box)({
  maxHeight: '60vh',
  overflowY: 'auto',
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  border: '1px solid var(--border-color)',
  borderRadius: '4px',
  '& h3': {
    color: 'var(--title-color)',
    marginTop: '1.5rem',
    marginBottom: '1rem'
  },
  '& p': {
    marginBottom: '1rem',
    lineHeight: 1.6
  },
  '& ul': {
    marginBottom: '1rem',
    paddingLeft: '1.5rem'
  },
  '& li': {
    marginBottom: '0.5rem'
  }
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  gap: '1rem',
  justifyContent: 'center',
  marginTop: '2rem'
});

const AcceptButton = styled(Button)({
  backgroundColor: 'var(--button-default-color)',
  color: 'var(--text-color-light)',
  '&:hover': {
    backgroundColor: 'var(--button-default-color-hover)'
  }
});

const DeclineButton = styled(Button)({
  backgroundColor: 'var(--button-cancel-color)',
  color: 'var(--text-color-light)',
  '&:hover': {
    backgroundColor: 'var(--button-cancel-color-hover)'
  }
});

const StyledLink = styled(Link)({
  color: 'var(--link-color)',
  '&:hover': {
    color: 'var(--link-color-alt)'
  }
});

const FooterText = styled(Typography)({
  color: 'var(--text-color-neutral)',
  fontSize: '1rem',
  textAlign: 'center',
  marginTop: '2rem'
});

function TermsOfService({ onAccept }) {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [showTrialInfo, setShowTrialInfo] = useState(false);

  const handleReject = async () => {
    await auth.signOut();
    navigate('/login');
  };

  const handleAcceptTerms = () => {
    setShowTrialInfo(true);
  };

  const handleStartTrial = () => {
    onAccept();
  };

  if (showTrialInfo) {
    return (
      <StyledContainer maxWidth={false} disableGutters>
        <LoginSection>
          <StyledPaper elevation={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'var(--title-color)' }}>
                Welcome to Your Valdyr.io Trial
              </Typography>
              
              <ScrollableContent>
                <Typography variant="body1" gutterBottom>
                  Thank you for choosing Valdyr.io. You're about to begin your 14-day trial with full access to all features. Here's what you need to know:
                </Typography>

                <Typography variant="h6" gutterBottom>Trial Access</Typography>
                <Typography variant="body1" gutterBottom>
                  <ul>
                    <li>You'll have immediate access to all features</li>
                    <li>Your trial will last for 14 days from activation</li>
                    <li>A member of the Valdyr.io team will reach out to you shortly if they haven't already</li>
                  </ul>
                </Typography>

                <Typography variant="h6" gutterBottom>After Your Trial</Typography>
                <Typography variant="body1" gutterBottom>
                  <ul>
                    <li>Access will be suspended at the end of your 14-day trial</li>
                    <li>All data stored in your vault will be safely preserved for 90 days after your trial ends</li>
                    <li>Upon subscribing to a paid account, your service will be immediately reinstated</li>
                    <li>All work completed during your trial will remain intact and accessible</li>
                  </ul>
                </Typography>
              </ScrollableContent>

              <ButtonContainer>
                <AcceptButton
                  variant="contained"
                  onClick={handleStartTrial}
                  size="large"
                >
                  Start Your Trial
                </AcceptButton>
              </ButtonContainer>

              <FooterText>
                Click <StyledLink href="https://valdyr.io/privacy" target="_blank" rel="noopener noreferrer">here</StyledLink> to view our privacy policy
              </FooterText>
              <FooterText>
                © 2025 VALDYR.io. All rights reserved.
              </FooterText>
            </Box>
          </StyledPaper>
        </LoginSection>
        
        <GraphSection>
          <RandomForceGraph />
        </GraphSection>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <LoginSection>
        <StyledPaper elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'var(--title-color)' }}>
              Terms of Service
            </Typography>
            <Typography variant="body1" gutterBottom>
              By using Valdyr.io, you agree to the following terms:
            </Typography>
            
            <ScrollableContent>
              <Typography variant="h6" gutterBottom>1. Acceptance of Terms</Typography>
              <Typography variant="body1" gutterBottom>
                By accessing and using Valdyr.io ("the Service"), you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use the Service.
              </Typography>

              <Typography variant="h6" gutterBottom>2. Trial Period</Typography>
              <Typography variant="body1" gutterBottom>
                Your access begins with a 14-day trial of our Professional tier service. During this period:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>You have access to all Professional tier features</li>
                  <li>No payment will be required during the trial</li>
                  <li>You can cancel at any time</li>
                  <li>After the trial period, you will need to subscribe to continue using the service</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>3. Data Processing and Storage</Typography>
              <Typography variant="body1" gutterBottom>
                By using Valdyr.io, you acknowledge and agree that:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>Your data will be stored in secure cloud infrastructure</li>
                  <li>We implement industry-standard security measures to protect your data</li>
                  <li>We process your data in accordance with our Privacy Policy</li>
                  <li>You maintain ownership of all your content and data</li>
                  <li>We may collect usage analytics to improve the service</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>4. User Responsibilities</Typography>
              <Typography variant="body1" gutterBottom>
                You agree to:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>Provide accurate and complete information</li>
                  <li>Maintain the security of your account credentials</li>
                  <li>Use the service in compliance with all applicable laws</li>
                  <li>Not attempt to circumvent any service limitations or restrictions</li>
                  <li>Not use the service for any illegal or unauthorized purpose</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>5. Service Availability and Updates</Typography>
              <Typography variant="body1" gutterBottom>
                Valdyr.io strives to maintain high availability but:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>May occasionally be unavailable for maintenance</li>
                  <li>May update features and functionality without notice</li>
                  <li>Does not guarantee 100% uptime</li>
                  <li>May modify these terms with reasonable notice</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>6. Intellectual Property</Typography>
              <Typography variant="body1" gutterBottom>
                You acknowledge that:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>Valdyr.io and its original content are protected by intellectual property laws</li>
                  <li>You may not copy, modify, or reverse engineer any part of the service</li>
                  <li>You retain ownership of your data and content</li>
                  <li>You grant us license to host and process your content to provide the service</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>7. Limitation of Liability</Typography>
              <Typography variant="body1" gutterBottom>
                Valdyr.io and its operators:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>Provide the service "as is" without warranties of any kind</li>
                  <li>Are not liable for any indirect, incidental, or consequential damages</li>
                  <li>Limit total liability to the amount paid for the service in the past 12 months</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>8. Termination</Typography>
              <Typography variant="body1" gutterBottom>
                Either party may terminate this agreement:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <ul>
                  <li>At any time for any reason</li>
                  <li>Immediately upon breach of these terms</li>
                  <li>With reasonable notice for any other reason</li>
                </ul>
              </Typography>

              <Typography variant="h6" gutterBottom>9. Governing Law</Typography>
              <Typography variant="body1" gutterBottom>
                These terms are governed by and construed in accordance with the laws of the United States. Any disputes shall be subject to the exclusive jurisdiction of the courts in the United States.
              </Typography>
            </ScrollableContent>

            <ButtonContainer>
              <AcceptButton
                variant="contained"
                onClick={handleAcceptTerms}
                size="large"
              >
                Accept Terms
              </AcceptButton>
              <DeclineButton
                variant="contained"
                onClick={handleReject}
                size="large"
              >
                Decline
              </DeclineButton>
            </ButtonContainer>

            <FooterText>
              Click <StyledLink href="https://valdyr.io/privacy" target="_blank" rel="noopener noreferrer">here</StyledLink> to view our privacy policy
            </FooterText>
            <FooterText>
              © 2025 VALDYR.io. All rights reserved.
            </FooterText>
          </Box>
        </StyledPaper>
      </LoginSection>
      
      <GraphSection>
        <RandomForceGraph />
      </GraphSection>
    </StyledContainer>
  );
}

export default TermsOfService; 