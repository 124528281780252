// File: ./app.valdyr.io/frontend/src/components/Sidebar/navigationData.js

import {
  Forest as ForestIcon,
  Source as SourceIcon,
  Person as UserIcon,
  Gavel as GavelIcon,
  Search as SearchIcon,
  Checklist as ChecklistIcon,
  Today as CalendarIcon,
  StackedBarChart as RiskIcon,
  Work as BriefcaseIcon,
  PhotoLibrary as EvidenceIcon,
  SquareFoot as SquareFootIcon
} from '@mui/icons-material';

const navigationData = [
  {
    category: 'Governance',
    items: [
      {
        name: 'Forest',
        endpoint: '/governance/forest',
        icon: ForestIcon,
      },
      {
        name: 'Policy',
        endpoint: '/governance/policy',
        icon: GavelIcon,
      },
      {
        name: 'Calendar',
        endpoint: '/governance/calendar',
        icon: CalendarIcon
      },
      {
        name: 'Evidence',
        endpoint: '/governance/evidence',
        icon: EvidenceIcon
      },
      {
        name: 'Risk',
        endpoint: '/governance/risk',
        icon: RiskIcon
      }
    ],
  },
  {
    category: 'Company',
    items: [
      {
        name: 'Standard',
        endpoint: '/company/standards',
        icon: SourceIcon,
      },
      {
        name: 'Control',
        endpoint: '/company/controls',
        icon: SquareFootIcon,
      },
      {
        name: 'Process',
        endpoint: '/company/processes',
          icon: ChecklistIcon,
      },
      {
        name: 'Query',
        endpoint: '/company/queries',
        icon: SearchIcon
      }
    ],
  },
  {
    category: 'Settings',
    items: [
      {
        name: 'Company',
        endpoint: '/settings/account',
        icon: BriefcaseIcon,
      },
      {
        name: 'Profile',
        endpoint: '/profile',
        icon: UserIcon,
      },
    ],
  },
];

export default navigationData;
  