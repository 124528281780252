import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';

function ForestForceGraph({ 
  data, 
  mergeDuplicates = false,
  allowMultipleRoots = false,
  nodeSizes = {
    n0: 30,  // root
    n1: 20,  // first level
    n2: 15,  // second level
    n3: 10,  // third level
    n4: 8    // fourth level
  },
  forceConfig = {
    linkDistance: {
      n0: 30,  // distance from root
      default: 10
    },
    linkStrength: 0.7,
    chargeStrength: {
      n0: 10,  // root node charge
      default: 0
    },
    collideStrength: 0.7,
    centerX: 400,
    centerY: 200,
    rootSpacing: 200  // New parameter for spacing between root nodes
  },
  viewboxConfig = {
    x: -400,
    y: -200,
    width: 800,
    height: 400,
    initialScale: 0.5
  }
}) {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState(null);
  const [zoomHandler, setZoomHandler] = useState(null);

  // Define colors for different hierarchy levels
  const nodeColors = [
    '#012626',  // n0 - Level 0 (root)
    '#025959',  // n1 - Level 1
    '#038C8C',  // n2 - Level 2
    '#038C8C',  // n3 - Level 3
    '#038C8C',  // n4 - Level 4
  ];
  const highlight_color = '#a7659c';

  const processGraphData = (inputData) => {
    const nodes = [];
    const links = [];
    const nodeMap = new Map();
    let rootCount = 0;

    const processNode = (node, depth = 0, parentId = null) => {
      // For multiple roots, don't force depth 0 to be single node
      const actualDepth = allowMultipleRoots ? depth : (depth === 0 && nodes.length > 0 ? 1 : depth);
      
      const nodeKey = mergeDuplicates ? 
        `${actualDepth}_${node.title}_${node.id || ''}` : 
        `n${actualDepth}_${nodes.length}`;
      
      let nodeId = nodeKey;
      
      if (mergeDuplicates) {
        const existingNode = nodeMap.get(nodeKey);
        if (existingNode) {
          if (parentId) {
            links.push({
              source: parentId,
              target: existingNode.id,
              value: 1
            });
          }
          return existingNode.id;
        }
      }

      const newNode = {
        id: nodeId,
        name: node.title,
        url: node.url,
        type: `n${actualDepth}`,
        group: actualDepth,
        isRoot: actualDepth === 0
      };
      
      if (actualDepth === 0) rootCount++;
      
      nodes.push(newNode);
      if (mergeDuplicates) {
        nodeMap.set(nodeKey, newNode);
      }

      if (parentId) {
        links.push({
          source: parentId,
          target: nodeId,
          value: 1
        });
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          processNode(child, actualDepth + 1, nodeId);
        });
      }

      return nodeId;
    };

    if (Array.isArray(inputData)) {
      // Handle array of root nodes
      inputData.forEach(rootNode => processNode(rootNode, 0));
    } else {
      // Handle single root node
      processNode(inputData);
    }

    return { nodes, links, rootCount };
  };

  const getNodeSize = (type) => {
    return nodeSizes[type] || nodeSizes.n4 || 8;
  };

  const getAncestors = (nodeId, nodes, links) => {
    const ancestors = new Set();
    const parentsMap = new Map();
    
    links.forEach(link => {
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      
      if (!parentsMap.has(targetId)) {
        parentsMap.set(targetId, new Set());
      }
      parentsMap.get(targetId).add(sourceId);
    });

    const stack = [nodeId];
    while (stack.length > 0) {
      const current = stack.pop();
      const parents = parentsMap.get(current);
      if (parents) {
        parents.forEach(parent => {
          if (!ancestors.has(parent)) {
            ancestors.add(parent);
            stack.push(parent);
          }
        });
      }
    }
    return ancestors;
  };

  const getDescendants = (nodeId, nodes, links) => {
    const descendants = new Set();
    const childrenMap = new Map();
    
    links.forEach(link => {
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      
      if (!childrenMap.has(sourceId)) {
        childrenMap.set(sourceId, new Set());
      }
      childrenMap.get(sourceId).add(targetId);
    });

    const stack = [nodeId];
    while (stack.length > 0) {
      const current = stack.pop();
      const children = childrenMap.get(current);
      if (children) {
        children.forEach(child => {
          if (!descendants.has(child)) {
            descendants.add(child);
            stack.push(child);
          }
        });
      }
    }
    return descendants;
  };

  useEffect(() => {
    if (data) {
      const processedData = processGraphData(data);
      setGraphData(processedData);
    }
  }, [data]);

  // Rest of the force graph implementation remains similar, but with modified node handling
  const renderForceGraph = (data) => {
    const container = document.getElementById('forest-force');
    const width = container.clientWidth;
    const height = container.clientHeight;

    d3.select("#forest-force").selectAll("svg").remove();

    const color = d3.scaleOrdinal()
      .domain(['n0', 'n1', 'n2', 'n3', 'n4'])
      .range(nodeColors);

    const links = data.links.map(d => ({...d}));
    const nodes = data.nodes.map(d => ({...d}));

    const simulation = d3.forceSimulation(nodes)
      // Basic link force to maintain connections
      .force("link", d3.forceLink(links)
        .id(d => d.id)
        .distance(100)
        .strength(0.5))
      // Charge force to make nodes repel each other
      .force("charge", d3.forceManyBody()
        .strength(d => d.type === "n0" ? -500 : -100)
        .distanceMax(300))
      // Center force to keep the graph centered
      .force("center", d3.forceCenter(forceConfig.centerX, forceConfig.centerY))
      // Collision force to prevent overlap
      .force("collision", d3.forceCollide()
        .radius(d => getNodeSize(d.type) * 1.2)
        .strength(0.7))
      .alphaDecay(0.01)
      .alphaMin(0.001);

    // Remove any existing root-spacing force
    if (allowMultipleRoots && data.rootCount > 1) {
      simulation.force("root-spacing", null);
    }

    const zoom = d3.zoom()
      .scaleExtent([0.1, 5])
      .on("zoom", zoomed)
      .filter(event => !event.type.includes('wheel'));

    const svg = d3.select("#forest-force")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [
        viewboxConfig.x, 
        viewboxConfig.y, 
        viewboxConfig.width, 
        viewboxConfig.height
      ])
      .call(zoom);

    const g = svg.append("g");

    const link = g.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value));

    const node = g.append("g")
      .selectAll("circle")
      .data(nodes)
      .join("circle")
      .attr("r", d => getNodeSize(d.type))
      .attr("fill", d => color(d.type))
      .on("click", (event, d) => {
        if (d.url) {
          navigate(d.url);
        }
      })
      .on("mouseover", function(event, d) {
        event.stopPropagation();
        d3.select(this).style("cursor", "pointer");

        // Add tooltip
        const tooltip = d3.select("#forest-force")
          .append("div")
          .attr("class", "tooltip")
          .style("position", "absolute")
          .style("background-color", "white")
          .style("color", "black")
          .style("padding", "5px")
          .style("border", "1px solid #ccc")
          .style("border-radius", "4px")
          .style("pointer-events", "none")
          .style("opacity", 0);

        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        
        const [mouseX, mouseY] = d3.pointer(event, container);
        tooltip.html(d.name)
          .style("left", (mouseX + 10) + "px")
          .style("top", (mouseY - 10) + "px");

        const ancestors = getAncestors(d.id, nodes, links);
        const descendants = getDescendants(d.id, nodes, links);
        const relatedNodeIds = new Set([...ancestors, ...descendants, d.id]);

        node.each(function(nodeData) {
          const element = d3.select(this);
          const isRelated = relatedNodeIds.has(nodeData.id);
          element.attr("fill", isRelated ? highlight_color : color(nodeData.type));
        });

        link
          .attr("stroke", linkData => {
            const sourceId = typeof linkData.source === 'object' ? linkData.source.id : linkData.source;
            const targetId = typeof linkData.target === 'object' ? linkData.target.id : linkData.target;
            return relatedNodeIds.has(sourceId) && relatedNodeIds.has(targetId) ? highlight_color : "#999";
          })
          .attr("stroke-opacity", linkData => {
            const sourceId = typeof linkData.source === 'object' ? linkData.source.id : linkData.source;
            const targetId = typeof linkData.target === 'object' ? linkData.target.id : linkData.target;
            return relatedNodeIds.has(sourceId) && relatedNodeIds.has(targetId) ? 0.8 : 0.6;
          })
          .attr("stroke-width", linkData => {
            const sourceId = typeof linkData.source === 'object' ? linkData.source.id : linkData.source;
            const targetId = typeof linkData.target === 'object' ? linkData.target.id : linkData.target;
            return relatedNodeIds.has(sourceId) && relatedNodeIds.has(targetId) ? 2.5 : Math.sqrt(linkData.value);
          });
      })
      .on("mousemove", function(event) {
        const [mouseX, mouseY] = d3.pointer(event, container);
        d3.select(".tooltip")
          .style("left", (mouseX + 10) + "px")
          .style("top", (mouseY + 20) + "px");
      })
      .on("mouseout", function(event, d) {
        // Remove tooltip
        d3.select(".tooltip").remove();

        node.each(function(nodeData) {
          const element = d3.select(this);
          element.attr("fill", d => color(d.type));
        });

        link
          .attr("stroke", "#999")
          .attr("stroke-opacity", 0.6)
          .attr("stroke-width", d => Math.sqrt(d.value));
      });

    node.append("title")
      .text(d => d.name);

    node.call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
    });

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function zoomed(event) {
      g.attr("transform", event.transform);
    }

    // Update initial transform
    svg.call(zoom.transform, d3.zoomIdentity
      .translate(viewboxConfig.initialX || width/2, viewboxConfig.initialY || 0)
      .scale(viewboxConfig.initialScale));

    setZoomHandler(() => zoomed);
  };

  useEffect(() => {
    if (graphData) {
      renderForceGraph(graphData);
    }
  }, [graphData]);

  return (
    <div id="forest-force" className="forest-force" />
  );
}

export default ForestForceGraph; 