import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

const RiskDomainDescription = ({ description, isExpanded, onExpandClick }) => {
  if (!description) {
    return null;
  }

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper sx={{ borderRadius: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: isExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: isExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Domain Description</Typography>
          <IconButton 
            onClick={onExpandClick}
            sx={{ 
              transform: isExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        {isExpanded && (
          <Box sx={{ 
            p: 2,
            '& a': { color: 'primary.main' },
            '& p': { mb: 2 },
            '& br': { display: 'block', content: '""', mb: 2 }
          }}>
            <div
              style={{ 
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                fontSize: '16px',
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

RiskDomainDescription.propTypes = {
  description: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  onExpandClick: PropTypes.func.isRequired
};

export default RiskDomainDescription; 