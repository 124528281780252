import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/graphs.css';
import {
  Box,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import ForestForceGraph from '../../components/Graph/graph-generic-force';
import { useNavigate } from 'react-router-dom';
import { camelToSpace } from '../../utils/format';

const ControlGraph = ({
  graphExpanded,
  setGraphExpanded,
  controlId,
  refreshTrigger
}) => {
  const { authenticatedFetch } = useAuth();
  const [error, setError] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const navigate = useNavigate();

  const fetchGraphData = async () => {
    try {
      const response = await authenticatedFetch(`/api/control/${controlId}/relationship`);
      if (!response.ok) throw new Error('Failed to fetch control relationship data');
      const data = await response.json();
      
      // Transform data for generic force graph
      const transformedData = {
        title: camelToSpace(data.control.label),
        type: 'n0',
        children: [
          {
            title: 'Standards',
            type: 'n1',
            category: 'category',
            children: data.control.standards.map(standard => ({
              title: standard.label,
              type: 'n2',
              category: 'standard'
            }))
          },
          {
            title: 'Evidence',
            type: 'n1',
            category: 'category',
            children: data.control.evidence.map(evidence => ({
              title: evidence.label,
              type: 'n2',
              category: 'evidence'
            }))
          },
          {
            title: 'Processes',
            type: 'n1',
            category: 'category',
            children: data.control.processes.map(process => ({
              title: process.label,
              type: 'n2',
              category: 'process'
            }))
          }
        ]
      };
      
      setGraphData(transformedData);
      setError(null);
    } catch (err) {
      console.error('Error fetching control relationship data:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (controlId) {
      fetchGraphData();
    }
  }, [controlId, refreshTrigger]);

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: graphExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: graphExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Relationship Graph</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <IconButton 
              onClick={() => setGraphExpanded(!graphExpanded)}
              sx={{ 
                transform: graphExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
        
        {graphExpanded && (
          <Box sx={{ 
            p: 2,
            height: '40rem',
            position: 'relative'
          }}>
            {error ? (
              <Typography color="error">Error loading graph data: {error}</Typography>
            ) : graphData ? (
              <ForestForceGraph 
                data={graphData} 
                nodeSizes={{
                  n0: 45,  // control (root)
                  n1: 35,  // relationships
                  n2: 25  // nodes
                }}
                forceConfig={{
                  linkDistance: {
                    n0: 100,
                    default: 100
                  },
                  linkStrength: 0.7,
                  chargeStrength: {
                    n0: -675,
                    n1: -525,
                    default: -120
                  },
                  collideStrength: 0.3,
                  centerX: 400,
                  centerY: 300
                }}
                viewboxConfig={{
                  x: 0,
                  y: -200,
                  width: 1600,
                  height: 800,
                  initialScale: 0.5
                }}
                onNodeClick={(node) => {
                  // Handle node clicks if needed
                }}
                onNodeHover={(node) => {
                  return node.title;
                }}
              />
            ) : null}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

ControlGraph.propTypes = {
  graphExpanded: PropTypes.bool.isRequired,
  setGraphExpanded: PropTypes.func.isRequired,
  controlId: PropTypes.number.isRequired,
  refreshTrigger: PropTypes.number
};

export default ControlGraph; 