// src/pages/Evidence/Evidence.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Search as SearchIcon,
  PhotoLibrary as EvidenceIcon
} from '@mui/icons-material';
import { isValid, format } from 'date-fns';
import { calculateRiskScore } from 'components/Objects/severity-dropdown';
import { camelCaseToSpaces } from 'utils/jinja';
import EvidenceCard from './evidence-card';

const TitleBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  gap: theme.spacing(2)
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'var(--white-color)',
    fontSize: '14px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--title-color)',
      borderWidth: '2px',
    }
  }
}));

const ContentContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
  gap: 1,
  width: 'calc(100% - 0.6rem)',
  flex: 1,
  padding: 1,
  marginLeft: '0.5rem',
});

const ItemChip = styled(Typography)(({ theme }) => ({
  backgroundColor: 'var(--background-color)',
  padding: '6px 12px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  display: 'inline-block',
  color: 'var(--text-color-dark)',
}));

function Evidence() {
  const [evidence, setEvidence] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [scheduleFilter, setScheduleFilter] = useState('all');
  const [populatedFilter, setPopulatedFilter] = useState('all');
  const { authenticatedFetch } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvidence = async () => {
      try {
        const response = await authenticatedFetch('/api/evidence/');
        if (!response.ok) {
          throw new Error('Failed to fetch evidence');
        }
        const data = await response.json();
        setEvidence(data.evidence);
      } catch (error) {
        console.error('Error fetching evidence:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvidence();
  }, [authenticatedFetch]);

  const handleEvidenceClick = (evidenceItem) => {
    const encodedName = encodeURIComponent(evidenceItem.name);
    navigate(`/governance/evidence/${encodedName}`, {
      state: { evidenceId: evidenceItem.id }
    });
  };

  const filteredEvidence = evidence.filter((evidenceItem) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = (
      evidenceItem.name?.toLowerCase().includes(searchLower) ||
      evidenceItem.text?.toLowerCase().includes(searchLower) ||
      evidenceItem.controls?.some(control => 
        control.toLowerCase().includes(searchLower)
      ) ||
      evidenceItem.standards?.some(standard => 
        standard.toLowerCase().includes(searchLower)
      ) ||
      evidenceItem.policies?.some(policy => 
        policy.toLowerCase().includes(searchLower)
      )
    );

    const matchesSchedule = 
      scheduleFilter === 'all' || 
      evidenceItem.status === scheduleFilter;

    const matchesPopulated = 
      populatedFilter === 'all' || 
      (populatedFilter === 'Populated' && evidenceItem.text) ||
      (populatedFilter === 'Not Populated' && !evidenceItem.text);

    return matchesSearch && matchesSchedule && matchesPopulated;
  });

  return (
    <Box sx={{ pb: 1, width: '100%' }}>
      <TitleBar sx={{ mb: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', padding: 1.25}} elevation={0}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
          <EvidenceIcon sx={{ color: 'var(--title-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
            Evidence
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
            <InputLabel>Schedule Status</InputLabel>
            <Select
              value={scheduleFilter}
              onChange={(e) => setScheduleFilter(e.target.value)}
              label="Schedule Status"
              MenuProps={{
                disablePortal: true,
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                    }
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Scheduled">Scheduled</MenuItem>
              <MenuItem value="Unscheduled">Unscheduled</MenuItem>
              <MenuItem value="Overdue">Overdue</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
            <InputLabel>Content Status</InputLabel>
            <Select
              value={populatedFilter}
              onChange={(e) => setPopulatedFilter(e.target.value)}
              label="Content Status"
              MenuProps={{
                disablePortal: true,
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                    }
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Populated">Populated</MenuItem>
              <MenuItem value="Not Populated">Not Populated</MenuItem>
            </Select>
          </FormControl>
          <SearchField
            size="small"
            placeholder="Search evidence..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: 300, outline: 'none', border: 'transparent' }}
          />
        </Box>
      </TitleBar>

      <ContentContainer>
        {isLoading ? (
          <Typography>Loading evidence...</Typography>
        ) : filteredEvidence.length === 0 ? (
          <Typography>No evidence found.</Typography>
        ) : (
          filteredEvidence.map((evidenceItem) => (
            <EvidenceCard
              key={evidenceItem.id}
              evidence={evidenceItem}
              onClick={() => handleEvidenceClick(evidenceItem)}
            />
          ))
        )}
      </ContentContainer>
    </Box>
  );
}

export default Evidence;
