import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Stack
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import ForceGraph from 'components/Graph/graph-risk';

// Define colors matching graph-generic-force
const nodeColors = {
  Risk: 'var(--evidence-color)',      // Red for highest risk
  Standard: 'var(--control-color)',    // Orange for medium severity
  Policy: 'var(--standard-color)'      // Green for lowest severity
};

const RiskGraph = ({
  graphExpanded,
  setGraphExpanded,
  riskId,
  refreshTrigger
}) => {
  const [graphData, setGraphData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(new Set(['Risk', 'Standard', 'Policy']));
  const { authenticatedFetch } = useAuth();

  const toggleNodeType = (type) => {
    setSelectedTypes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(type)) {
        newSet.delete(type);
      } else {
        newSet.add(type);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!riskId || !graphExpanded) return;
      
      try {
        const response = await authenticatedFetch(`/api/risk/${riskId}/relationship`);
        const data = await response.json();

        if (data.message === "Success") {
          setGraphData(data.relationships);
          setError(null);
        } else {
          console.error('API returned error:', data);
          setError(data.message || 'Failed to fetch risk data');
        }
      } catch (err) {
        console.error('Error fetching risk data:', err);
        setError('Failed to fetch risk data');
      }
    };

    fetchData();
  }, [riskId, graphExpanded, refreshTrigger]);

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper sx={{ borderRadius: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: graphExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--background-color-blue)',
          color: 'var(--text-color-light)',
          borderRadius: graphExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Relationships</Typography>
          <IconButton 
            onClick={() => setGraphExpanded(!graphExpanded)}
            sx={{ 
              transform: graphExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        {graphExpanded && (
          <Box sx={{ height: '40rem', p: 2 }}>
            {error && (
              <Typography color="error" sx={{ p: 2 }}>
                {error}
              </Typography>
            )}
            {graphData && (
              <Box>
                <ForceGraph 
                  riskData={graphData}
                  nodeColors={nodeColors}
                  selectedTypes={selectedTypes}
                  onToggleNodeType={toggleNodeType}
                />
              </Box>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default RiskGraph;