import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Grid,
  ListSubheader
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  SquareFoot as SquareFootIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import ControlCard from './control-card';

const TitleBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  gap: theme.spacing(2)
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'var(--white-color)',
    fontSize: '14px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--title-color)',
      borderWidth: '2px',
    }
  }
}));

function Control() {
  const [controls, setControls] = useState([]);
  const [scheduleFilter, setScheduleFilter] = useState('all');
  const [populatedFilter, setPopulatedFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStandard, setSelectedStandard] = useState('all');
  const [selectedDomain, setSelectedDomain] = useState('all');
  const [domains, setDomains] = useState([]);
  const { authenticatedFetch } = useAuth();
  const navigate = useNavigate();

  const filteredStandardsByDomain = (domains || []).reduce((acc, domain) => {
    if (domain?.standards && (selectedDomain === 'all' || domain.name === selectedDomain)) {
      acc[domain.name] = domain.standards;
    }
    return acc;
  }, {});

  useEffect(() => {
    const fetchControls = async () => {
      try {
        const response = await authenticatedFetch('/api/control/');
        if (!response.ok) {
          throw new Error('Failed to fetch controls');
        }
        const data = await response.json();
        setControls(data.controls);
      } catch (error) {
        console.error('Error fetching controls:', error);
      }
    };

    fetchControls();
  }, [authenticatedFetch]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await authenticatedFetch('/api/domain/short');
        if (!response.ok) {
          throw new Error('Failed to fetch domains');
        }
        const data = await response.json();
        setDomains(data);
      } catch (error) {
        console.error('Error fetching domains:', error);
      }
    };

    fetchDomains();
  }, [authenticatedFetch]);

  const handleDomainClick = (domainName) => {
    setSelectedDomain(domainName === selectedDomain ? 'all' : domainName);
  };

  const handleControlClick = (control) => {
    navigate(`/company/control/${control.id}`, {
      state: { controlId: control.id }
    });
  };

  const filteredControls = controls.filter(control => {
    const matchesSearch = control.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         control.description?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesSchedule = scheduleFilter === 'all' || 
                           (scheduleFilter === 'Scheduled' && control.nextReviewDate) ||
                           (scheduleFilter === 'Unscheduled' && !control.nextReviewDate) ||
                           (scheduleFilter === 'Overdue' && control.isOverdue);
    
    const matchesPopulated = populatedFilter === 'all' ||
                            (populatedFilter === 'Populated' && control.description) ||
                            (populatedFilter === 'Not Populated' && !control.description);
    
    const matchesStandard = selectedStandard === 'all' || control.standard === selectedStandard;
    
    const matchesDomain = selectedDomain === 'all' || control.domain === selectedDomain;
    
    return matchesSearch && matchesSchedule && matchesPopulated && matchesStandard && matchesDomain;
  });

  // First, let's create a function to group controls
  const groupControlsByDomainAndStandard = (controls) => {
    return controls.reduce((acc, control) => {
      const domain = control.domain;
      const standard = control.standard;
      
      if (!acc[domain]) {
        acc[domain] = {};
      }
      if (!acc[domain][standard]) {
        acc[domain][standard] = [];
      }
      
      acc[domain][standard].push(control);
      return acc;
    }, {});
  };

  return (
    <Box sx={{ pb: 1, width: '100%' }}>
      <TitleBar sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', padding: 1.25 }} elevation={0}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SquareFootIcon sx={{ color: 'var(--title-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
            Controls
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <FormControl size="small" sx={{ minWidth: 200, position: 'relative' }}>
            <InputLabel>Standard</InputLabel>
            <Select
              value={selectedStandard}
              onChange={(e) => setSelectedStandard(e.target.value)}
              label="Standard"
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    maxHeight: '400px',
                    width: '400px',
                  }
                },
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                      '& .MuiList-root': {
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        padding: 1,
                      },
                      '& .MuiListSubheader-root': {
                        gridColumn: '1 / -1',
                        backgroundColor: selectedDomain === 'all' ? 'white' : 'var(--background-color)',
                        lineHeight: '32px',
                        color: 'var(--title-color)',
                        fontWeight: 600,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'var(--button-neutral-color)',
                        }
                      }
                    }
                  }
                }
              }}
            >
              <MenuItem value="all" sx={{ gridColumn: '1 / -1' }}>All Standards</MenuItem>
              {Object.entries(filteredStandardsByDomain).map(([domainName, standards]) => [
                <ListSubheader 
                  key={domainName}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDomainClick(domainName);
                  }}
                  sx={{
                    backgroundColor: selectedDomain === domainName ? 'var(--button-neutral-color)' : 'inherit'
                  }}
                >
                  {domainName}
                </ListSubheader>,
                ...standards.map(standard => (
                  <MenuItem 
                    key={standard.id} 
                    value={standard.name}
                    sx={{ pl: 2 }}
                  >
                    {standard.name}
                  </MenuItem>
                ))
              ]).flat()}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
            <InputLabel>Schedule Status</InputLabel>
            <Select
              value={scheduleFilter}
              onChange={(e) => setScheduleFilter(e.target.value)}
              label="Schedule Status"
              MenuProps={{
                disablePortal: true,
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                    }
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Scheduled">Scheduled</MenuItem>
              <MenuItem value="Unscheduled">Unscheduled</MenuItem>
              <MenuItem value="Overdue">Overdue</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
            <InputLabel>Content Status</InputLabel>
            <Select
              value={populatedFilter}
              onChange={(e) => setPopulatedFilter(e.target.value)}
              label="Content Status"
              MenuProps={{
                disablePortal: true,
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                    }
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Populated">Populated</MenuItem>
              <MenuItem value="Not Populated">Not Populated</MenuItem>
            </Select>
          </FormControl>
          <SearchField
            size="small"
            placeholder="Search controls..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: 300, outline: 'none', border: 'transparent' }}
          />
        </Box>
      </TitleBar>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p:2}}>
        {Object.entries(groupControlsByDomainAndStandard(filteredControls)).map(([domain, standards]) => (
          <Box key={domain}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.5rem',
                color: 'var(--title-color)',
                mb: 2,
                pb: 1,
                borderBottom: '2px solid var(--border-color)'
              }}
            >
              {domain}
            </Typography>
            
            {Object.entries(standards).map(([standard, controls], standardIndex, standardsArray) => (
              <Box 
                key={standard} 
                sx={{ 
                  mb: standardIndex === standardsArray.length - 1 ? 3 : 8,
                  position: 'relative',
                  '&:not(:last-child)::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -32,
                    left: '25%',
                    right: '25%',
                    height: '1px',
                    backgroundColor: 'var(--border-color)',
                  },
                  '&:not(:last-child)': {
                    paddingBottom: 4
                  }
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'var(--title-color)',
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    mb: 1,
                    fontWeight: 500,
                    paddingBottom: 3
                  }}
                >
                  {standard}
                </Typography>
                
                <Grid container spacing={2}>
                  {controls.map((control) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={control.id}>
                      <ControlCard
                        control={control}
                        onClick={() => handleControlClick(control)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Control; 