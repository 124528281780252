import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Stack
} from '@mui/material';
import { 
  AccountTree as AccountTreeIcon,
  ExpandMore as ExpandMoreIcon 
} from '@mui/icons-material';
import { camelCaseToSpaces } from 'utils/jinja';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: 0,
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

const EvidenceAssociations = ({ evidence }) => {
  const [associationsExpanded, setAssociationsExpanded] = useState(true);
  
  if (!evidence) return null;

  return (
    <StyledCard>
      <Box className="card-header">
        <Stack direction="row" spacing={1} alignItems="center">
          <AccountTreeIcon sx={{ fontSize: 20 }} />
          <Typography variant="h6">Associations</Typography>
        </Stack>
        <IconButton 
          onClick={() => setAssociationsExpanded(!associationsExpanded)}
          sx={{ 
            transform: associationsExpanded ? 'rotate(180deg)' : 'none',
            color: 'var(--text-color-light)'
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      {associationsExpanded && (
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TableContainer>
              <Table sx={{
                '& .MuiTableBody-root tr:last-child td': {
                  borderBottom: 'none'
                }
              }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%" sx={{ fontWeight: 'bold' }}>Type</TableCell>
                    <TableCell width="70%" sx={{ fontWeight: 'bold' }}>Associated Items</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Controls</TableCell>
                    <TableCell>
                      {evidence.controls && evidence.controls.length > 0 ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {evidence.controls.map((control, index) => (
                            <Typography
                              key={index}
                              sx={{
                                backgroundColor: 'var(--background-color)',
                                padding: '6px 12px',
                                borderRadius: '4px',
                                fontSize: '0.75rem',
                              }}
                            >
                              {camelCaseToSpaces(control)}
                            </Typography>
                          ))}
                        </Box>
                      ) : (
                        'No controls associated'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Processes</TableCell>
                    <TableCell>
                      {evidence.processes && evidence.processes.length > 0 ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {evidence.processes.map((process, index) => (
                            <Typography
                              key={index}
                              sx={{
                                backgroundColor: 'var(--background-color)',
                                padding: '6px 12px',
                                borderRadius: '4px',
                                fontSize: '0.75rem',
                              }}
                            >
                              {process}
                            </Typography>
                          ))}
                        </Box>
                      ) : (
                        'No processes associated'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Standards</TableCell>
                    <TableCell>
                      {evidence.standards && evidence.standards.length > 0 ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {evidence.standards.map((standard, index) => (
                            <Typography
                              key={index}
                              sx={{
                                backgroundColor: 'var(--background-color)',
                                padding: '6px 12px',
                                borderRadius: '4px',
                                fontSize: '0.75rem',
                              }}
                            >
                              {standard}
                            </Typography>
                          ))}
                        </Box>
                      ) : (
                        'No standards associated'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Policies</TableCell>
                    <TableCell>
                      {evidence.policies && evidence.policies.length > 0 ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {evidence.policies.map((policy, index) => (
                            <Typography
                              key={index}
                              sx={{
                                backgroundColor: 'var(--background-color)',
                                padding: '6px 12px',
                                borderRadius: '4px',
                                fontSize: '0.75rem',
                              }}
                            >
                              {camelCaseToSpaces(policy)}
                            </Typography>
                          ))}
                        </Box>
                      ) : (
                        'No policies associated'
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      )}
    </StyledCard>
  );
};

export default EvidenceAssociations; 