import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { useAuth } from 'contexts/AuthContext';

const RiskDescription = ({ description, isExpanded, onExpandClick, riskId, onDescriptionUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { authenticatedFetch } = useAuth();

  if (!description && !isEditing) {
    return null;
  }

  const handleEditClick = () => {
    setEditedDescription(description);
    setIsEditing(true);
  };

  const handleSave = () => {
    if (editedDescription === description) {
      setErrorMessage('No changes have been made.');
      setShowError(true);
      return;
    }
    setShowConfirmation(true);
  };

  const handleConfirmSave = async () => {
    try {
      const response = await authenticatedFetch(`/api/risk/${riskId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description: editedDescription
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update description');
      }

      setIsEditing(false);
      setShowConfirmation(false);
      if (onDescriptionUpdate) {
        onDescriptionUpdate(editedDescription);
      }
    } catch (error) {
      console.error('Error updating description:', error);
      setErrorMessage('Failed to update description. Please try again.');
      setShowError(true);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedDescription(description);
  };

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper sx={{ borderRadius: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: isExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: isExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Risk Description</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {isExpanded && isEditing && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  size="normal"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  size="normal"
                >
                  Cancel
                </Button>
              </>
            )}
            {isExpanded && !isEditing && (
              <IconButton 
                onClick={handleEditClick}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton 
              onClick={onExpandClick}
              sx={{ 
                transform: isExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
        {isExpanded && (
          <Box sx={{ 
            p: 2,
            '& a': { color: 'primary.main' },
            '& p': { mb: 2 },
            '& br': { display: 'block', content: '""', mb: 2 }
          }}>
            {isEditing ? (
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  sx={{
                    backgroundColor: 'var(--background-color)',
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--background-color)',
                      '& fieldset': {
                        borderColor: 'var(--title-color)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'var(--title-color)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'var(--title-color)',
                      },
                    },
                  }}
                />
              </Box>
            ) : (
              <div
                style={{ 
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  fontSize: '16px',
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Box>
        )}
      </Paper>

      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Changes</DialogTitle>
        <DialogContent>
          Are you sure you want to save these changes?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
          <Button onClick={handleConfirmSave} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          {errorMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

RiskDescription.propTypes = {
  description: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  onExpandClick: PropTypes.func.isRequired,
  riskId: PropTypes.string.isRequired,
  onDescriptionUpdate: PropTypes.func
};

export default RiskDescription; 