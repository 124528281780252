import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Description as EvidenceIcon } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { camelCaseToSpaces } from 'utils/jinja';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4], // Increases elevation by ~2 levels on hover
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    color: 'var(--title-color)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

const ItemChip = styled(Typography)(({ theme }) => ({
  backgroundColor: 'var(--background-color)',
  padding: '6px 12px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  display: 'inline-block',
  color: 'var(--text-color-dark)',
}));

function EvidenceCard({ evidence, onClick }) {
  return (
    <StyledCard 
      onClick={onClick}
      sx={{ cursor: 'pointer', width: 'calc(100% - .5rem)', marginBottom: 1 }}
    >
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {evidence.status === "Overdue" && (
            <AccessTimeIcon sx={{ color: 'var(--unhealthy-color)', backgroundColor: 'white', borderRadius: '50%', p: 0.1 }} />
          )}
          <Typography variant="h6" sx={{ color: 'var(--title-color)', fontSize: '16px' }}>
            {evidence.name}
          </Typography>
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            backgroundColor: evidence.text ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
            color: 'white',
            padding: '2px 8px',
            borderRadius: '4px',
            marginLeft: 'auto'
          }}
        >
          {evidence.text ? 'Populated' : 'Not Populated'}
        </Typography>
      </Box>
      <CardContent>
        {evidence.text && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'var(--text-color-dark)',
              backgroundColor: 'var(--background-color)',
              p: 2,
              borderRadius: 1,
              mb: 2,
              fontFamily: 'monospace'
            }}
          >
            {evidence.text}
          </Typography>
        )}

        {/* Associated Controls Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Controls
          </Typography>
          {evidence.controls && evidence.controls.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {evidence.controls.map((control, index) => (
                <ItemChip key={index}>
                  {camelCaseToSpaces(control)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No controls associated
            </Typography>
          )}
        </Box>

        {/* Associated Standards Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Standards
          </Typography>
          {evidence.standards && evidence.standards.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {evidence.standards.map((standard, index) => (
                <ItemChip key={index}>
                  {standard}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No standards associated
            </Typography>
          )}
        </Box>

        {/* Associated Policies Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Policies
          </Typography>
          {evidence.policies && evidence.policies.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {evidence.policies.map((policy, index) => (
                <ItemChip key={index}>
                  {camelCaseToSpaces(policy)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No policies associated
            </Typography>
          )}
        </Box>

      </CardContent>
    </StyledCard>
  );
}

export default EvidenceCard; 