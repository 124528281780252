import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Loading from 'components/Objects/loading';
import Error from 'components/Objects/Error';
import RiskStandards from './risk-standards';
import RiskDescription from './risk-description';
import RiskDomainDescription from './risk-domain-description';
import {
  Box,
  Typography,
} from '@mui/material';
import {
  Article as ArticleIcon
} from '@mui/icons-material';
import RiskGraph from './risk-graph';

function RiskDetails({ riskId: propRiskId, refreshTrigger }) {
  const { riskId: paramRiskId } = useParams();
  const location = useLocation();
  const { authenticatedFetch } = useAuth();
  const [risk, setRisk] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [graphExpanded, setGraphExpanded] = useState(true);
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const [domainDescriptionExpanded, setDomainDescriptionExpanded] = useState(true);
  const [standardsExpanded, setStandardsExpanded] = useState(true);

  const fetchRiskDetails = async () => {
    setLoading(true);
    try {
      const id = propRiskId || location.state?.riskId || paramRiskId;
      if (!id) {
        setLoading(false);
        return;
      }

      const response = await authenticatedFetch(`/api/risk/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch risk details');
      }

      const data = await response.json();
      setRisk(data.risk);
      setError(null);
    } catch (error) {
      console.error('Error fetching risk details:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRiskDetails();
  }, [authenticatedFetch, location.state?.riskId, paramRiskId, propRiskId]);

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;
  if (!risk) return <div>Risk not found.</div>;

  return (
    <div className="risk-details-container" style={{ marginBottom: '2rem' }}>
      <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', mb: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2, 
          mb: 0,
          backgroundColor: 'white',
          p: 1.75,
        }}>
          <ArticleIcon sx={{ color: 'var(--text-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--text-color)', flexGrow: 1 }}>
            {risk.name.replace(/([A-Z])/g, ' $1').trim()}
          </Typography>
        </Box>
      </Box>
      <RiskDomainDescription 
        description={risk.domain_description}
        isExpanded={domainDescriptionExpanded}
        onExpandClick={() => setDomainDescriptionExpanded(!domainDescriptionExpanded)}
      />
      <RiskDescription 
        description={risk.description}
        isExpanded={descriptionExpanded}
        onExpandClick={() => setDescriptionExpanded(!descriptionExpanded)}
        riskId={risk.id}
        onDescriptionUpdate={(newDescription) => {
          setRisk(prev => ({...prev, description: newDescription}));
        }}
      />
      <RiskStandards 
        standards={risk.risk_standards}
        isExpanded={standardsExpanded}
        onExpandClick={() => setStandardsExpanded(!standardsExpanded)}
        riskId={risk.id}
        onAssociationChange={() => fetchRiskDetails()}
      />
      <RiskGraph
        graphExpanded={graphExpanded}
        setGraphExpanded={setGraphExpanded}
        riskId={risk.id}
        refreshTrigger={refreshTrigger}
      />
    </div>
  );
}

export default RiskDetails; 