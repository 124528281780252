// File: ./src/components/Objects/Confirmation.js

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function Confirmation({ 
    message, 
    onConfirm, 
    onCancel, 
    autoFocus = true,
    confirmText = "Confirm",
    cancelText = "Cancel",
    title = "Confirmation"
}) {
    const confirmButtonRef = useRef(null);

    useEffect(() => {
        if (autoFocus && confirmButtonRef.current) {
            setTimeout(() => {
                confirmButtonRef.current.focus();
            }, 0);
        }
    }, [autoFocus]);

    const handleKeyDown = (e) => {
        e.stopPropagation();
        if (e.key === 'Enter' && !e.repeat) {
            onConfirm();
        } else if (e.key === 'Escape') {
            onCancel();
        }
    };

    return (
        <Dialog 
            open={true}
            onClose={onCancel}
            onKeyDown={handleKeyDown}
            maxWidth="sm"
            fullWidth
            sx={{
                zIndex: 999999999
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography component="div">
                    <ReactMarkdown 
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                    >
                        {message}
                    </ReactMarkdown>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="inherit"
                >
                    {cancelText}
                </Button>
                <Button
                    onClick={onConfirm}
                    ref={confirmButtonRef}
                    autoFocus={autoFocus}
                    sx={{
                        backgroundColor: 'var(--button-default-color)',
                        color: 'var(--text-color-light)',
                        '&:hover': {
                            backgroundColor: 'var(--button-default-color-hover)',
                        },
                    }}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Confirmation.propTypes = {
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    title: PropTypes.string
};

export default Confirmation;
