import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import Loading from 'components/Objects/loading';
import AccountOverview from './account-overview';
import AccountUsers from './account-users';
import '../../styles/Account.css';
import {
  Box,
  Paper,
  Typography
} from '@mui/material';
import {
  Work as BriefcaseIcon,
} from '@mui/icons-material';

function AccountSection() {
  const { authenticatedFetch } = useAuth();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyResponse, usersResponse] = await Promise.all([
          authenticatedFetch('/api/company/'),
          authenticatedFetch('/api/company/company_users')
        ]);

        if (!companyResponse.ok || !usersResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const [companyData, usersData] = await Promise.all([
          companyResponse.json(),
          usersResponse.json()
        ]);

        setCompanyData({
          ...companyData.company,
          users: usersData.users
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error loading data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authenticatedFetch]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
      <div className="main-content">
          <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
            <Paper sx={{ borderRadius: 0, borderColor: 'divider'}} elevation={0}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                p: 1.75,
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <BriefcaseIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
                  <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
                    Company Information - {companyData?.name_formal}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        {companyData && (
          <>
            <AccountOverview companyData={companyData} />
            <AccountUsers companyData={companyData} />
          </>
        )}
      </div>
  );
}

export default AccountSection; 