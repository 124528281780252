import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import {
  Box,
  Typography,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  ExpandMore as ExpandMoreIcon,
  Assignment as ProcessIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import RichTextEditor from 'components/Editor/RichTextEditor';
import Confirmation from 'components/Objects/Confirmation';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

function ProcessContents({ process, setProcess }) {
  const [isEditing, setIsEditing] = useState(false);
  const [contentsExpanded, setContentsExpanded] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editedText, setEditedText] = useState(process?.text || '');
  const [textHeight, setTextHeight] = useState(200); // Default height
  const textContentRef = useRef(null);
  const { authenticatedFetch } = useAuth();

  const measureTextHeight = () => {
    if (textContentRef.current) {
      const height = textContentRef.current.offsetHeight;
      setTextHeight(Math.max(200, height)); // Minimum height of 200px
    }
  };

  const handleEditClick = () => {
    measureTextHeight();
    setIsEditing(true);
  };

  useEffect(() => {
    if (contentsExpanded && !isEditing) {
      measureTextHeight();
    }
  }, [contentsExpanded, process?.text]);

  const handleConfirmUpdate = async () => {
    try {
      const response = await authenticatedFetch(`/api/process/${process.id}/contents`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: editedText,
          edit_date: new Date().toISOString()
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update process contents');
      }

      const data = await response.json();
      
      const processedProcess = {
        ...process,
        text: data.process.text,
        value: data.process.value,
        review_date: data.process.review_date,
        created_at: data.process.created_at,
        edit_date: data.process.edit_date,
        controls: process.controls || [],
        standards: process.standards || [],
        policies: process.policies || []
      };
      
      setProcess(processedProcess);
      setIsEditing(false);
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error updating process contents:', error);
    }
  };

  const handleCancel = () => {
    setEditedText(process?.text || '');
    setIsEditing(false);
  };

  return (
    <StyledCard>
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ProcessIcon />
          <Typography variant="h6">Process Text</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {!isEditing ? (
            <IconButton 
              onClick={handleEditClick}
              sx={{ color: 'var(--text-color-light)' }}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => setShowConfirmation(true)}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
          <IconButton 
            onClick={() => setContentsExpanded(!contentsExpanded)}
            sx={{ 
              transform: contentsExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>

      {contentsExpanded && (
        <Box>
          {isEditing ? (
            <RichTextEditor
              value={editedText}
              onChange={setEditedText}
              showToolbar={true}
              height={textHeight}
              context={{
                type: 'process',
                processId: process.id,
                processText: process.text
              }}
            />
          ) : (
            <Box 
              ref={textContentRef}
              sx={{ 
                p: 2,
                '& a': { color: 'primary.main' },
                '& p': { mb: 2 },
                '& br': { display: 'block', content: '""', mb: 2 }
              }}
              dangerouslySetInnerHTML={{ __html: process?.text || 'No text provided.' }}
            />
          )}
        </Box>
      )}
      {showConfirmation && (
        <Confirmation
          message="Are you sure you want to save these changes?"
          onConfirm={handleConfirmUpdate}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </StyledCard>
  );
}

export default ProcessContents; 