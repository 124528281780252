import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebase';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import RandomForceGraph from 'components/Graph/graph-random-force';
import Logo from '../../graphics/logo.svg';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components (reused from onboarding-signup.js)
const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'var(--background-color)'
}));

const LoginSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  zIndex: 2
}));

const GraphSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: 400,
  backgroundColor: 'var(--background-color-alt)'
}));

const LogoImage = styled('img')({
  width: 80,
  height: 80,
  marginBottom: '1rem'
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'var(--button-default-color)',
  color: 'var(--text-color-light)',
  '&:hover': {
    backgroundColor: 'var(--button-default-color-hover)'
  }
}));

const FooterText = styled(Typography)({
  color: 'var(--text-color-neutral)',
  fontSize: '1rem',
  textAlign: 'center',
  marginTop: '2rem'
});

function VerifyEmail() {
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!isSignInWithEmailLink(auth, window.location.href)) {
        setError('Invalid verification link');
        setVerifying(false);
        return;
      }

      try {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        if (!email) {
          throw new Error('Email is required to complete verification');
        }

        try {
          await signInWithEmailLink(auth, email, window.location.href);
        } catch (firebaseError) {
          // Ignore email-already-in-use error and continue
          if (firebaseError.code !== 'auth/email-already-in-use') {
            throw firebaseError;
          }
        }

        window.localStorage.removeItem('emailForSignIn');
        
        const user = auth.currentUser;
        const idToken = await user.getIdToken();
        
        const verifyResponse = await fetch('/api/auth/verify', {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });

        const userData = await verifyResponse.json();

        if (verifyResponse.ok && userData.user && userData.user.company_id) {
          navigate('/governance/forest');
          return;
        }

        if (verifyResponse.status === 404) {
          const domain = email.split('@')[1];
          const companyName = domain.split('.')[0].charAt(0).toUpperCase() + domain.split('.')[0].slice(1);

          const registerResponse = await fetch('/api/auth/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({
              email: email,
              company_name: companyName,
              firebase_uid: user.uid
            })
          });

          if (!registerResponse.ok) {
            const responseData = await registerResponse.json();
            throw new Error(responseData.message || 'Failed to register user');
          }
        }

        navigate('/onboarding');

      } catch (error) {
        console.error('Error during verification:', error);
        setError(error.message);
        setVerifying(false);
      }
    };

    verifyEmail();
  }, [navigate]);

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <LoginSection>
        <StyledPaper elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <LogoImage src={Logo} alt="Valdyr Logo" sx={{ mb: 6, width: 300, height: 300 }} />
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'var(--title-color)' }}>
              VALDYR.io
            </Typography>
            
            {verifying ? (
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <CircularProgress size={40} sx={{ mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Verifying your email...
                </Typography>
                <Typography variant="body1">
                  Please wait while we complete your registration.
                </Typography>
              </Box>
            ) : error ? (
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <Typography variant="h6" gutterBottom color="error">
                  Verification Error
                </Typography>
                <Typography variant="body1" color="error" sx={{ mb: 3 }}>
                  {error}
                </Typography>
                <StyledButton
                  variant="contained"
                  onClick={() => navigate('/signup')}
                >
                  Return to Sign Up
                </StyledButton>
              </Box>
            ) : null}
            
            <FooterText>
              © 2025 VALDYR.io. All rights reserved.
            </FooterText>
          </Box>
        </StyledPaper>
      </LoginSection>
      
      <GraphSection>
        <RandomForceGraph />
      </GraphSection>
    </StyledContainer>
  );
}

export default VerifyEmail; 