import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/graphs.css';
import {
  Box,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import ForestForceGraph from '../../components/Graph/graph-generic-force';
import { useNavigate } from 'react-router-dom';

const PolicyGraph = ({
  graphExpanded,
  setGraphExpanded,
  policyId,
  refreshTrigger
}) => {
  const { authenticatedFetch } = useAuth();
  const [error, setError] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const navigate = useNavigate();

  const fetchGraphData = async () => {
    try {
      if (!policyId) throw new Error('Policy ID not provided');

      const response = await authenticatedFetch(`/api/policy/${policyId}/relationships`);
      if (!response.ok) throw new Error('Failed to fetch policy data');
      const data = await response.json();
      
      // Transform data for generic force graph
      const transformedData = {
        title: data.policy.name,
        url: `/governance/policy/${encodeURIComponent(data.policy.name)}`,
        children: data.policy.standards?.map(standard => ({
          title: standard.name,
          url: `/company/standards/${encodeURIComponent(standard.domain)}/${encodeURIComponent(standard.name)}`,
          children: standard.controls?.map(control => ({
            title: control.title || control.name,
            url: `/company/standards/${encodeURIComponent(standard.domain)}/${encodeURIComponent(standard.name)}/${encodeURIComponent(control.name)}`,
            children: control.evidence?.map(evidence => ({
              title: evidence.name,
              url: evidence.url || null
            })) || []
          })) || []
        })) || []
      };
      
      setGraphData(transformedData);
      setError(null);
    } catch (err) {
      console.error('Error fetching policy data:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (policyId && graphExpanded) {
      fetchGraphData();
    }
  }, [policyId, graphExpanded, refreshTrigger]);

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: graphExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: graphExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Relationships</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <IconButton 
              onClick={() => setGraphExpanded(!graphExpanded)}
              sx={{ 
                transform: graphExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
        
        {graphExpanded && (
          <Box sx={{ 
            p: 2,
            height: '40rem',
            position: 'relative'
          }}>
            {error ? (
              <Typography color="error">Error loading graph data: {error}</Typography>
            ) : graphData ? (
              <ForestForceGraph 
                data={graphData}
                nodeSizes={{
                  n0: 50,  // policy (root)
                  n1: 30,  // standards 
                  n2: 20,  // controls
                }}
                forceConfig={{
                  linkDistance: {
                    n0: 75,
                    default: 50
                  },
                  linkStrength: 0.5,
                  chargeStrength: {
                    n0: 50,
                    n1: 30,
                    n2: 20,
                    default: -120
                  },
                  collideStrength: 0.1,
                  centerX: 400,
                  centerY: 300
                }}
                viewboxConfig={{
                  x: 0,
                  y: -200,
                  width: 1600,
                  height: 800,
                  initialScale: 0.7
                }}
                onNodeClick={(node) => {
                  if (node.type === 'n1' || node.type === 'n2') {
                    if (node.url) {
                      navigate(node.url);
                    }
                  }
                }}
                onNodeHover={(node) => {
                  return node.title;
                }}
              />
            ) : null}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

PolicyGraph.propTypes = {
  graphExpanded: PropTypes.bool.isRequired,
  setGraphExpanded: PropTypes.func.isRequired,
  policyId: PropTypes.number.isRequired,
  refreshTrigger: PropTypes.number
};

export default PolicyGraph;