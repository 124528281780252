import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from 'components/Objects/loading';
import 'styles/Policies.css';
import { useAuth } from 'contexts/AuthContext';
import Confirmation from 'components/Objects/Confirmation';
import Error from 'components/Objects/Error';
import { fetchPolicyByName, fetchPolicies, renamePolicy, addStandardToPolicy, fetchPolicyById } from './policy-queries';
import { onConfirmDelete, handleCancelDelete, usePolicyActions } from './policy-actions';
import PolicyDescription from './policy-description';
import PolicyText from './policy-text';
import PolicyStandards from './policy-standards';
import PolicyGraph from './policy-graph';
import PolicySchedule from './policy-schedule';
import PolicyRisk from './policy-risk';
import { Box, Paper, Typography } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Link as LinkIcon,
  Gavel as GavelIcon
} from '@mui/icons-material';

function PolicyDetails() {
  const { authenticatedFetch } = useAuth();
  const { policyName } = useParams();
  const [policy, setPolicy] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletionTarget, setDeletionTarget] = useState(null);
  const [renameTarget, setRenameTarget] = useState(null);
  const [textExpanded, setTextExpanded] = useState(true);
  const [standardsExpanded, setStandardsExpanded] = useState(true);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isStandardsEditing, setIsStandardsEditing] = useState(false);
  const [graphExpanded, setGraphExpanded] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [historyExpanded, setHistoryExpanded] = useState(true);
  const [scheduleExpanded, setScheduleExpanded] = useState(true);
  const [risksExpanded, setRisksExpanded] = useState(true);
  const [controlsExpanded, setControlsExpanded] = useState(true);
  const navigate = useNavigate();

  const {
    handlePolicyDelete,
    handlePolicyEdit,
    handlePolicyRename,
    handlePolicyCreate,
    handleError,
    handleDescriptionEdit,
    handleStandardDelete,
  } = usePolicyActions(
    authenticatedFetch,
    setPolicies,
    setErrorMessage,
    setShowErrorDialog,
    setShowConfirmation,
    setDeletionTarget,
    setPolicy,
    setNotFound,
    setLoading,
    setIsDescriptionEditing,
    null,
    null,
    null,
    null,
    setRenameTarget,
    setHasUnsavedChanges
  );

  const handlePoliciesUpdate = async (updatedPolicy = null) => {
    try {
      if (updatedPolicy) {
        // Update single policy in the existing array
        setPolicies(prevPolicies => 
          prevPolicies.map(policy => 
            policy.id === updatedPolicy.id ? updatedPolicy : policy
          )
        );
      } else {
        // Fetch all policies only when needed
        const data = await fetchPolicies(authenticatedFetch);
        setPolicies(data);
      }
    } catch (error) {
      console.error('Error updating policies:', error);
      setErrorMessage('Failed to update policies');
      setShowErrorDialog(true);
    }
  };

  useEffect(() => {
    const fetchPolicy = async () => {
      if (!authenticatedFetch) return;
      
      try {
        let data;
        if (policyName) {
          data = await fetchPolicyByName(authenticatedFetch, policyName);
          // After getting initial data, fetch complete data with risks
          if (data && data.id) {
            data = await fetchPolicyById(authenticatedFetch, data.id);
          }
        }
        setPolicy(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching policy:', error);
        setNotFound(true);
        setLoading(false);
      }
    };

    fetchPolicy();
    handlePoliciesUpdate();
  }, [policyName, authenticatedFetch]);

  const handleDescriptionUpdate = async (newDescription) => {
    try {
      await handleDescriptionEdit(policy.id, newDescription);
      setPolicy(prev => ({
        ...prev,
        description: newDescription
      }));
    } catch (error) {
      setErrorMessage('Failed to update description');
      setShowErrorDialog(true);
    }
  };

  const handleStandardDeleteWrapper = (standard) => {
    handleStandardDelete(policy.id, standard.id);
  };

  const handleStandardAdd = async (standard) => {
    try {
      await addStandardToPolicy(authenticatedFetch, policy.id, standard.id);
      // Update the policy state with the new standard
      setPolicy(prev => {
        // Check if standard already exists to avoid duplicates
        const standardExists = prev.standards.some(s => s.id === standard.id);
        if (standardExists) {
          return prev;
        }
        return {
          ...prev,
          standards: [...prev.standards, standard]
        };
      });
    } catch (error) {
      setErrorMessage('Failed to add standard');
      setShowErrorDialog(true);
    }
  };

  const handleAssociationChange = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  if (loading) return <Loading />;
  if (notFound) return <div>Policy not found.</div>;
  if (!policy) return <div className="no-policy">No policy found.</div>;

  return (
    <div className="page-content">
      <div className="main-content">
        <div>
          <div className="tables-container">
                    <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
                      <Paper sx={{ borderRadius: 0, borderColor: 'divider' }} elevation={0}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          p: 1.75,
                        }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <GavelIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
                            <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
                              {policy.title}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
            <PolicyDescription 
              description={policy.description}
              descriptionExpanded={descriptionExpanded}
              setDescriptionExpanded={setDescriptionExpanded}
              isDescriptionEditing={isDescriptionEditing}
              setIsDescriptionEditing={setIsDescriptionEditing}
              onSave={handleDescriptionUpdate}
              loading={loading}
            />
            <PolicyText 
              policyId={policy?.id}
              textExpanded={textExpanded}
              setTextExpanded={setTextExpanded}
              policyText={policy?.policy_text}
              onTextUpdate={(newText) => {
                setPolicy({
                  ...policy,
                  policy_text: newText
                });
              }}
            />
            {!loading && policy.risks_data && (
              <PolicyRisk 
                risks={policy.risks_data}
                isExpanded={risksExpanded}
                setIsExpanded={setRisksExpanded}
              />
            )}
            <PolicyStandards 
              standards={policy.standards}
              standardsExpanded={standardsExpanded}
              setStandardsExpanded={setStandardsExpanded}
              isStandardsEditing={isStandardsEditing}
              setIsStandardsEditing={setIsStandardsEditing}
              onStandardDelete={handleStandardDeleteWrapper}
              onStandardAdd={handleStandardAdd}
              policyId={policy.id}
              setPolicy={setPolicy}
              onAssociationChange={handleAssociationChange}
            />
            <PolicySchedule
              policy={policy}
              scheduleExpanded={scheduleExpanded}
              setScheduleExpanded={setScheduleExpanded}
              onReviewDateUpdate={async (date) => {
                try {
                  await authenticatedFetch(`/api/policy/${policy.id}/review_date`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ review_date: date })
                  });
                  setPolicy({
                    ...policy,
                    review_date: date
                  });
                } catch (error) {
                  setErrorMessage('Failed to update review date');
                  setShowErrorDialog(true);
                }
              }}
            />
            <PolicyGraph
              graphExpanded={graphExpanded}
              setGraphExpanded={setGraphExpanded}
              policyId={policy.id}
              refreshTrigger={refreshTrigger}
            />
          </div>
        </div>
      </div>

      {showErrorDialog && (
        <Error
          message={errorMessage}
          onClose={() => setShowErrorDialog(false)}
        />
      )}

      {showConfirmation && deletionTarget && (
        <Confirmation
          message={`Are you sure you want to delete the policy "${deletionTarget.name}"?`}
          onConfirm={() => onConfirmDelete(deletionTarget, handlePolicyDelete, setShowConfirmation, setDeletionTarget)}
          onCancel={() => handleCancelDelete(setShowConfirmation, setDeletionTarget)}
        />
      )}
    </div>
  );
}

export default PolicyDetails; 