// File: ./app.valdyr.io/frontend/src/pages/Governance/Forest.js

import React from 'react';

function audit() {
  return (
    <div>
      <h1>Governance - Audit</h1>
      <p>Welcome to the Audit page under the Governance section.</p>
    </div>
  );
}

export default audit;
