import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/graphs.css';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Stack
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Map as MapIcon
} from '@mui/icons-material';
import ForestForceGraph from '../../components/Graph/graph-generic-force';
import { camelToSpace } from '../../utils/format';
import { useNavigate } from 'react-router-dom';

const EvidenceGraph = ({
  graphExpanded,
  setGraphExpanded,
  evidenceId,
  refreshTrigger
}) => {
  const { authenticatedFetch } = useAuth();
  const [error, setError] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const navigate = useNavigate();

  const fetchGraphData = async () => {
    try {
      if (!evidenceId) throw new Error('Evidence ID not provided');

      const response = await authenticatedFetch(`/api/evidence/${evidenceId}/relationships`);
      if (!response.ok) throw new Error('Failed to fetch evidence data');
      const data = await response.json();
      
      console.log('Graph Data from API:', data);
      
      const transformedData = {
        title: data.evidence.name,
        url: `/governance/evidence/${encodeURIComponent(data.evidence.name)}`,
        state: { evidenceId: data.evidence.id },
        children: data.evidence.controls?.map(control => ({
          title: camelToSpace(control.name),
          url: `/company/controls/${encodeURIComponent(control.name)}`,
          state: { controlId: control.id },
          children: control.standards?.map(standard => ({
            title: camelToSpace(standard.name),
            url: `/company/standards/${encodeURIComponent(standard.name)}`,
            state: { standardId: standard.id },
            children: standard.policies?.map(policy => ({
              title: camelToSpace(policy.name),
              url: `/company/policies/${encodeURIComponent(policy.name)}`,
              state: { policyId: policy.id }
            })) || []
          })) || []
        })) || []
      };
      
      console.log('Transformed Graph Data:', transformedData);
      
      setGraphData(transformedData);
      setError(null);
    } catch (err) {
      console.error('Error fetching evidence data:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (evidenceId && graphExpanded) {
      fetchGraphData();
    }
  }, [evidenceId, graphExpanded, refreshTrigger]);

  return (
    <Box>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          pr: 2,
          borderBottom: graphExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: graphExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <MapIcon sx={{ fontSize: 20 }} />
            <Typography variant="h6">Relationships</Typography>
          </Stack>
          <IconButton 
            onClick={() => setGraphExpanded(!graphExpanded)}
            sx={{ 
              transform: graphExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        
        {graphExpanded && (
          <Box sx={{ 
            p: 2,
            height: '40rem',
            position: 'relative'
          }}>
            {error ? (
              <Typography color="error">Error loading graph data: {error}</Typography>
            ) : graphData ? (
              <ForestForceGraph 
                data={graphData} 
                mergeDuplicates={true}
                nodeSizes={{
                  n0: 35,  // evidence (root)
                  n1: 40,  // controls
                  n2: 50,  // standards
                  n3: 55   // policies
                }}
                forceConfig={{
                  linkDistance: {
                    n0: 100,
                    default: 100
                  },
                  linkStrength: 0.7,
                  chargeStrength: {
                    n0: -675,
                    n1: -525,
                    n2: -375,
                    n3: -225,
                    default: -120
                  },
                  collideStrength: 0.3,
                  centerX: 400,
                  centerY: 300
                }}
                viewboxConfig={{
                  x: 0,
                  y: -200,
                  width: 1600,
                  height: 800,
                  initialScale: 0.5
                }}
                onNodeClick={(node) => {
                  if (node.url) {
                    navigate(node.url, {
                      state: node.state
                    });
                  }
                }}
                onNodeHover={(node) => {
                  return node.title;
                }}
              />
            ) : null}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

EvidenceGraph.propTypes = {
  graphExpanded: PropTypes.bool.isRequired,
  setGraphExpanded: PropTypes.func.isRequired,
  evidenceId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null])
  ]).isRequired,
  refreshTrigger: PropTypes.number
};

export default EvidenceGraph; 