import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  Link as LinkIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { camelToSpace } from 'utils/format';
import ContextSearch from 'components/Objects/context-search';
import { debounce } from 'lodash';

const debouncedSearch = debounce(async (searchValue, authenticatedFetch, setSearchResults, control) => {
  if (!searchValue.trim()) {
    setSearchResults([]);
    return;
  }

  try {
    const response = await authenticatedFetch('/api/evidence/');
    if (!response.ok) {
      throw new Error('Failed to fetch evidence');
    }
    const data = await response.json();
    
    const existingEvidenceIds = new Set(control.evidence.map(e => e.id));
    const filteredEvidence = data.evidence.filter(evidence => {
      const searchLower = searchValue.toLowerCase();
      const matchesSearch = (
        evidence.name?.toLowerCase().includes(searchLower) ||
        evidence.text?.toLowerCase().includes(searchLower) ||
        evidence.controls?.some(control => 
          control.toLowerCase().includes(searchLower)
        )
      );
      return matchesSearch && !existingEvidenceIds.has(evidence.id);
    });

    setSearchResults(filteredEvidence);
  } catch (error) {
    console.error('Error searching evidence:', error);
    setSearchResults([]);
  }
}, 300);

function ControlEvidence({ 
  control,
  evidenceExpanded, 
  setEvidenceExpanded,
  setControl,
  onAssociationChange
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [evidenceToDelete, setEvidenceToDelete] = useState(null);
  const { authenticatedFetch } = useAuth();
  const navigate = useNavigate();
  const [newEvidenceName, setNewEvidenceName] = useState('');
  const [newEvidenceDescription, setNewEvidenceDescription] = useState('');
  const [editedValues, setEditedValues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [previewEvidence, setPreviewEvidence] = useState([]);
  const [showExistingConfirmation, setShowExistingConfirmation] = useState(false);
  const [evidenceToAdd, setEvidenceToAdd] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleConfirm = () => {
    if (previewEvidence.length > 0) {
      setShowAddConfirmation(true);
    }
  };

  const handleConfirmUpdate = async () => {
    try {
      // Add API call here to update evidence associations
      setIsEditing(false);
      setShowConfirmation(false);
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error updating evidence:', error);
    }
  };

  const handleCancel = () => {
    setPreviewEvidence([]);
    setIsEditing(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleAddEvidence = () => {
    if (!newEvidenceName.trim()) {
      return; // Don't proceed if name is empty
    }
    setShowAddConfirmation(true);
  };

  const handleConfirmAdd = async () => {
    try {
      // First create the evidence
      const createResponse = await authenticatedFetch('/api/evidence/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          value: newEvidenceName,
          text: newEvidenceDescription || null
        })
      });

      if (!createResponse.ok) {
        throw new Error('Failed to create evidence');
      }

      const { evidence } = await createResponse.json();

      // Then associate it with the control using the new route
      const associateResponse = await authenticatedFetch(`/api/control/${control.id}/evidence/${evidence.id}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });

      if (!associateResponse.ok) {
        throw new Error('Failed to associate evidence with control');
      }

      // Clear the form
      setNewEvidenceName('');
      setNewEvidenceDescription('');
      setShowAddConfirmation(false);

      // Refresh the control data
      if (onAssociationChange) {
        onAssociationChange();
      }

    } catch (error) {
      console.error('Error adding evidence:', error);
    }
  };

  const handleEvidenceLinkClick = (evidence) => {
    const evidenceUrl = `/governance/evidence/${encodeURIComponent(evidence.value)}`;
    navigate(evidenceUrl, {
      state: { evidenceId: evidence.id }
    });
  };

  const handleSaveEvidence = (evidence) => {
    // Implement the logic to save evidence
  };

  const handleEvidenceDelete = (evidence) => {
    setEvidenceToDelete(evidence);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await authenticatedFetch(`/api/control/${control.id}/evidence/${evidenceToDelete.id}`, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to disassociate evidence');
      }

      // Refresh the control data
      if (onAssociationChange) {
        onAssociationChange();
      }

      setShowDeleteConfirmation(false);
      setEvidenceToDelete(null);
    } catch (error) {
      console.error('Error disassociating evidence:', error);
    }
  };

  const handleValueChange = (id, value) => {
    setEditedValues({
      ...editedValues,
      [id]: {
        description: value
      }
    });
  };

  const handleSearchEvidence = (searchValue) => {
    setSearchTerm(searchValue);
    debouncedSearch(searchValue, authenticatedFetch, setSearchResults, control);
  };

  const handleAddExistingEvidence = async (evidence) => {
    try {
      const response = await authenticatedFetch(`/api/control/${control.id}/evidence/${evidence.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to associate evidence');
      }

      // Clear the search
      setSearchTerm('');
      setSearchResults([]);

      // Refresh the control data
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error associating evidence:', error);
    }
  };

  const handlePreviewSelect = (evidence) => {
    setEvidenceToAdd(evidence);
    setShowExistingConfirmation(true);
  };

  const handlePreviewDelete = (evidenceToDelete) => {
    setPreviewEvidence(prev => prev.filter(e => e.id !== evidenceToDelete.id));
  };

  const handleConfirmExisting = async () => {
    try {
      const response = await authenticatedFetch(`/api/control/${control.id}/evidence/${evidenceToAdd.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to associate evidence');
      }

      // Clear the states
      setEvidenceToAdd(null);
      setShowExistingConfirmation(false);

      // Refresh the control data
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error associating evidence:', error);
    }
  };

  const renderContextSearch = () => (
    isEditing ? (
      <Box>
        <ContextSearch
          context="evidence"
          fetchUrl="/evidence/"
          placeholder="Search existing evidence..."
          onSelect={handlePreviewSelect}
          includeCondition={(evidence) => !control.evidence?.some(e => e.id === evidence.id)}
          transformResponse={(data) => data.evidence}
          MenuProps={{
            disablePortal: true,
            slotProps: {
              paper: {
                sx: {
                  zIndex: 99999999,
                  position: 'absolute',
                  marginTop: 0.5,
                }
              }
            }
          }}
        />
      </Box>
    ) : null
  );

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: evidenceExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: evidenceExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Evidence</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isEditing ? (
              <IconButton 
                onClick={() => setIsEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                onClick={handleCancel}
                sx={{ 
                  backgroundColor: 'var(--background-color)',
                  color: 'var(--text-color-dark)',
                  '&:hover': {
                    backgroundColor: 'var(--background-color)',
                    opacity: 0.9
                  }
                }}
              >
                Done
              </Button>
            )}
            <IconButton 
              onClick={() => setEvidenceExpanded(!evidenceExpanded)}
              sx={{ 
                transform: evidenceExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {evidenceExpanded && (
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="30%">Name</TableCell>
                  <TableCell width="70%">Description</TableCell>
                  {isEditing && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {isEditing && (
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        placeholder="Enter evidence name"
                        size="small"
                        value={newEvidenceName || ''}
                        onChange={(e) => setNewEvidenceName(e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: 'var(--title-color)'
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        placeholder="Enter evidence description"
                        size="small"
                        multiline
                        rows={2}
                        value={newEvidenceDescription || ''}
                        onChange={(e) => setNewEvidenceDescription(e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: 'var(--title-color)'
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                          onClick={handleAddEvidence}
                          color="primary"
                          size="small"
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {control.evidence?.length > 0 ? (
                  control.evidence.map((evidence) => (
                    <TableRow key={evidence.id}>
                      <TableCell>
                        <Button
                          startIcon={<LinkIcon />}
                          onClick={() => handleEvidenceLinkClick(evidence)}
                          sx={{
                            textAlign: 'left',
                            color: 'var(--link-color)',
                            textTransform: 'none'
                          }}
                        >
                          {evidence.value}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            fullWidth
                            value={editedValues[evidence.id]?.description || evidence.text || ''}
                            onChange={(e) => handleValueChange(evidence.id, e.target.value)}
                            size="small"
                            multiline
                            rows={2}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'var(--title-color)'
                                }
                              }
                            }}
                          />
                        ) : (
                          <Typography sx={{ 
                            color: evidence.text ? 'inherit' : 'var(--text-color-neutral)'
                          }}>
                            {evidence.text || 'Not populated'}
                          </Typography>
                        )}
                      </TableCell>
                      {isEditing && (
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              onClick={() => handleSaveEvidence(evidence)}
                              color="primary"
                              size="small"
                            >
                              <SaveIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleEvidenceDelete(evidence)}
                              color="error"
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  !isEditing && (
                    <TableRow>
                      <TableCell colSpan={isEditing ? 3 : 2}>
                        <Typography>No evidence associated</Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}
                {isEditing && previewEvidence.map((previewEvidence) => (
                  <TableRow key={`preview-${previewEvidence.id}`}>
                    <TableCell>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => handleEvidenceLinkClick(previewEvidence)}
                        sx={{ textAlign: 'left' }}
                      >
                        {previewEvidence.value}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        {previewEvidence.text || '-'}
                      </Typography>
                    </TableCell>
                    {isEditing && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreviewDelete(previewEvidence);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {isEditing && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ position: 'relative' }}>
                      {renderContextSearch()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          Are you sure you want to update the evidence associations?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={handleConfirmUpdate}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAddConfirmation}
        onClose={() => setShowAddConfirmation(false)}
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>Confirm Add Evidence</DialogTitle>
        <DialogContent>
          Are you sure you want to add "{newEvidenceName}" to "{camelToSpace(control.name)}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddConfirmation(false)}>Cancel</Button>
          <Button 
            onClick={handleConfirmAdd}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>Confirm Remove Evidence</DialogTitle>
        <DialogContent>
          Are you sure you want to remove "{evidenceToDelete?.value}" from "{camelToSpace(control.name)}"? 
          The evidence will still exist but will no longer be associated with this control.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false)}>Cancel</Button>
          <Button 
            onClick={handleConfirmDelete}
            variant="contained" 
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showExistingConfirmation}
        onClose={() => setShowExistingConfirmation(false)}
        sx={{ zIndex: 99999999 }}
      >
        <DialogTitle>Confirm Add Existing Evidence</DialogTitle>
        <DialogContent>
          Are you sure you want to add "{evidenceToAdd?.name}" to "{camelToSpace(control.name)}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowExistingConfirmation(false)}>Cancel</Button>
          <Button 
            onClick={handleConfirmExisting}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ControlEvidence.propTypes = {
  control: PropTypes.shape({
    id: PropTypes.number.isRequired,
    evidence: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired
    }))
  }).isRequired,
  evidenceExpanded: PropTypes.bool.isRequired,
  setEvidenceExpanded: PropTypes.func.isRequired,
  setControl: PropTypes.func.isRequired,
  onAssociationChange: PropTypes.func
};

export default ControlEvidence; 