import { useNavigate } from 'react-router-dom';
import { deleteStandard, deleteDomain, fetchDomains, createPolicy, fetchStandardDetails, fetchStandardData, renameItem, deleteControl, deletePolicy, addControl, editStandard, renameDomain, updateDomains } from './standard-queries';

const stripHtmlTags = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const convertHtmlToJinjaTemplate = (html, cleanName) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const variables = {};
  
  // Get all variable spans
  const variableSpans = doc.querySelectorAll('.mce-variable');
  variableSpans.forEach(span => {
    const varType = span.getAttribute('data-var');
    const value = span.getAttribute('data-value') || span.textContent.trim();
    variables[varType] = value;
  });

  // Convert HTML to Jinja template format
  let templateText = doc.body.innerHTML
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '')
    .trim();
  
  variableSpans.forEach(span => {
    const varType = span.getAttribute('data-var');
    const jinjaVar = `**{{ ${cleanName}.${varType} }}**`;
    templateText = templateText.replace(span.outerHTML, jinjaVar);
  });

  return {
    templateText,
    variables,
    text: templateText,
    quantity: variables.Quantity || '',
    unit: variables.Unit || '',
    frequency: variables.Frequency || '',
    frequency_unit: variables.Frequency_Unit || 'year',
    platform: variables.Platform || '',
    test: variables.Test || '',
    threshold: variables.Threshold || 'Pass',
    evidence_name: variables.Evidence || null
  };
};

export const useStandardActions = (
  authenticatedFetch, 
  setDomains, 
  setErrorMessage,
  setShowErrorDialog,
  setShowConfirmation,
  setDeletionTarget,
  setAddingPolicy,
  setShowPolicyConfirmation,
  setPendingPolicy,
  setStandard,
  setCompanyName,
  setNotFound,
  setLoading,
  setInitialLoading,
  setHasUnsavedChanges,
  setShowNavigationConfirmation,
  setPendingNavigation,
  setRenameTarget,
  setShowControlDeleteConfirmation,
  setPendingControlDelete,
  setShowPolicyDeleteConfirmation,
  setPendingPolicyDelete,
  setDetailedPolicies,
  setShowControlAddConfirmation,
  setPendingControlAdd,
  setNewControlName,
  setNewControlDescription,
  setIsControlsEditing,
  pendingControlAdd,
  setNewDomain,
  handleAssociationChange
) => {
  const navigate = useNavigate();

  const handleError = (message) => {
    setErrorMessage(message);
    setShowErrorDialog(true);
  };

  const handleControlClick = (navigate, domainName, standardName, controlId) => {
    navigate(
      `/company/standards/${encodeURIComponent(domainName)}/${encodeURIComponent(standardName)}/${encodeURIComponent(controlId)}`,
      { replace: true }
    );
  };

  const handlePolicyClick = (policyId) => {
    navigate(`/governance/policy/${policyId}`);
  };

  const handleDeleteDomain = async (domainName, domains) => {
    try {
      const domainStandards = domains[domainName];
      if (!domainStandards) {
        throw new Error('Domain not found');
      }

      if (domainStandards.length > 0) {
        throw new Error('Cannot delete domain that contains standards. Please delete all standards first.');
      }

      await deleteDomain(authenticatedFetch, domainName);
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
      navigate('/company/standards');
    } catch (error) {
      console.error('Error deleting domain:', error);
      throw error;
    }
  };

  const handleDeleteStandard = async (domainName, standardName, domains) => {
    try {
      const domainStandards = domains[domainName];
      if (!domainStandards) {
        throw new Error('Domain not found');
      }

      const standardToDelete = domainStandards.find(std => std.name === standardName);
      if (!standardToDelete) return;

      const response = await deleteStandard(authenticatedFetch, domainName, standardName);
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
      
      // If domain was deleted, navigate to standards page
      if (response.domain_deleted) {
        navigate('/company/standards');
      }
    } catch (error) {
      console.error('Error deleting standard:', error);
      throw error;
    }
  };

  const handleConfirmDelete = async (deletionTarget, domains) => {
    if (!deletionTarget) return;
    
    const { type, domain, standard } = deletionTarget;
    
    try {
      if (type === 'domain') {
        await handleDeleteDomain(domain, domains);
      } else if (type === 'standard') {
        await handleDeleteStandard(domain, standard, domains);
      }
    } catch (error) {
      console.error('Error deleting:', error);
      handleError(error.message || 'Failed to delete. Please try again.');
    }
  };

  const onConfirmDelete = (deletionTarget, domains) => {
    handleConfirmDelete(deletionTarget, domains);
    setShowConfirmation(false);
    setDeletionTarget(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setDeletionTarget(null);
  };

  const handleAddPolicy = async (pendingPolicy, standard) => {
    try {
      if (!standard) {
        throw new Error('Standard data is not loaded');
      }

      if (!pendingPolicy || !pendingPolicy.name) {
        throw new Error('Policy data is missing');
      }

      const policyData = {
        name: stripHtmlTags(pendingPolicy.name),
        standard_id: standard.id,
        description: pendingPolicy.description
      };

      await createPolicy(authenticatedFetch, policyData);
      const response = await authenticatedFetch(`/api/standard/${standard.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch updated standard data');
      }
      const standardData = await response.json();
      if (standardData.standard && standardData.standard.policies) {
        const policyIds = standardData.standard.policies;
        const policyPromises = policyIds.map(async (id) => {
          const policyResponse = await authenticatedFetch(`/api/policy/${id}`);
          if (!policyResponse.ok) {
            throw new Error(`Failed to fetch policy ${id}`);
          }
          const data = await policyResponse.json();
          return data.policy;
        });
        const policies = await Promise.all(policyPromises);
        setDetailedPolicies(policies);
      }
      setAddingPolicy(false);
      setShowPolicyConfirmation(false);
      setPendingPolicy(null);
    } catch (error) {
      console.error('Error creating policy:', error);
      handleError(error.message || 'Failed to create policy');
    }
  };

  const fetchStandard = async (domainName, standardName, initialLoading = false) => {
    if (initialLoading) {
      setLoading(true);
    }
    
    try {
      const { companyName, domainsData, standardData } = await fetchStandardData(
        authenticatedFetch,
        domainName,
        standardName
      );
      
      setCompanyName(companyName);
      setStandard(standardData);
      setDomains(domainsData);
      setNotFound(false);
    } catch (error) {
      console.error('Error in fetchStandard:', error);
      setNotFound(true);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const handleConfirmNavigation = (pendingNavigation) => {
    if (pendingNavigation) {
      setHasUnsavedChanges(false);
      navigate(pendingNavigation);
    }
    setShowNavigationConfirmation(false);
    setPendingNavigation(null);
  };

  const handleCancelNavigation = () => {
    setShowNavigationConfirmation(false);
    setPendingNavigation(null);
  };

  const handleConfirmEdit = async (pendingEditAction) => {
    try {
      if (pendingEditAction && pendingEditAction.editAction) {
        await pendingEditAction.editAction();
      }
    } catch (error) {
      console.error('Error during edit:', error);
      handleError(error.message || 'Failed to complete edit');
    }
  };

  const handleConfirmRename = async (renameTarget) => {
    if (!renameTarget) return;
    
    try {
      await renameItem(authenticatedFetch, renameTarget);
      await fetchDomains(authenticatedFetch);
      setRenameTarget(null);
    } catch (error) {
      console.error('Error renaming:', error);
      handleError(error.message || 'Failed to rename. Please try again.');
    }
  };

  const handleCancelRename = () => {
    setRenameTarget(null);
  };

  const handleControlDeleteClick = (control) => {
    setPendingControlDelete(control);
    setShowControlDeleteConfirmation(true);
  };

  const handleConfirmControlDelete = async (control) => {
    try {
      await deleteControl(authenticatedFetch, control.id);
      // Get updated domains data
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
      
      // Fetch standard data directly using the standard ID
      const standardResponse = await authenticatedFetch(`/api/standard/${control.standard_id}`);
      if (!standardResponse.ok) {
        throw new Error('Failed to fetch standard details');
      }
      const standardData = await standardResponse.json();
      
      setStandard({
        ...standardData.standard,
        name: control.standard_name,
        controls: standardData.standard.controls || []
      });
      
      setShowControlDeleteConfirmation(false);
      setPendingControlDelete(null);
    } catch (error) {
      console.error('Error deleting control:', error);
      handleError(error.message || 'Failed to delete control');
    }
  };

  const handleCancelControlDelete = () => {
    setShowControlDeleteConfirmation(false);
    setPendingControlDelete(null);
  };

  const handlePolicyDeleteClick = (policy) => {
    setPendingPolicyDelete(policy);
    setShowPolicyDeleteConfirmation(true);
  };

  const handleConfirmPolicyDelete = async (policy) => {
    try {
      if (!policy || !policy.id) {
        throw new Error('No policy selected for deletion');
      }

      await deletePolicy(authenticatedFetch, policy.id);
      setDetailedPolicies(prevPolicies => 
        prevPolicies.filter(p => p.id !== policy.id)
      );
      setShowPolicyDeleteConfirmation(false);
      setPendingPolicyDelete(null);
    } catch (error) {
      console.error('Error deleting policy:', error);
      handleError(error.message || 'Failed to delete policy');
    }
  };

  const handleCancelPolicyDelete = () => {
    setShowPolicyDeleteConfirmation(false);
    setPendingPolicyDelete(null);
  };

  const handleAddControl = async (newControlName, newControlDescription, standard, domainName, standardName) => {
    try {
      const cleanName = stripHtmlTags(newControlName)
        .replace(/[^a-zA-Z0-9]/g, '');
      
      const cleanTitle = stripHtmlTags(newControlName).trim();
      
      const { 
        templateText, 
        variables,
        text,
        quantity,
        unit,
        frequency,
        frequency_unit,
        platform,
        test,
        threshold,
        evidence_name
      } = convertHtmlToJinjaTemplate(newControlDescription, cleanName);

      const controlData = {
        name: cleanName,
        title: cleanTitle,
        description: templateText,
        text,
        standard_id: standard.id,
        quantity,
        unit,
        frequency,
        frequency_unit,
        platform,
        test,
        threshold,
        evidence_name,
        variables
      };

      const result = await addControl(authenticatedFetch, controlData, standard.id);
      
      // Transform and update domains with the new data
      if (result && result.domains) {
        const transformedData = result.domains.reduce((acc, domain) => {
          acc[domain.name] = (domain.standards || []).map(standard => ({
            id: standard.id,
            name: standard.name,
            order: standard.order,
            controls: standard.controls,
            standard_text: standard.standard_text,
            description: standard.description,
            populated: standard.populated
          }));
          return acc;
        }, {});
        
        setDomains(transformedData);
      }

      // Fetch updated standard data
      const standardResponse = await authenticatedFetch(`/api/standard/${standard.id}`);
      if (!standardResponse.ok) {
        throw new Error('Failed to fetch standard details');
      }
      const standardData = await standardResponse.json();
      
      setStandard({
        ...standardData.standard,
        name: standardName,
        controls: standardData.standard.controls || []
      });

    } catch (error) {
      console.error('Error adding control:', error);
      handleError(error.message || 'Failed to add control');
    }
  };

  const fetchPolicyDetails = async (policyIds) => {
    try {
      const policyPromises = policyIds.map(async (id) => {
        const response = await authenticatedFetch(`/api/policy/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.policy;
      });

      const policies = await Promise.all(policyPromises);
      setDetailedPolicies(policies);
    } catch (error) {
      console.error("Error fetching policy details:", error);
      handleError("Failed to fetch policy details");
    }
  };

  const onAddControl = (newControlName, newControlDescription) => {
    setPendingControlAdd({
      name: newControlName,
      description: newControlDescription
    });
    setShowControlAddConfirmation(true);
  };

  const handleConfirmControlAdd = async (standard, domainName, standardName) => {
    try {
      if (!pendingControlAdd || !standard || !standard.id) return;
      
      await handleAddControl(
        pendingControlAdd.name,
        pendingControlAdd.description,
        standard,
        domainName,
        standardName
      );
      setShowControlAddConfirmation(false);
      setPendingControlAdd(null);
      setNewControlName('');
      setNewControlDescription('');
      setIsControlsEditing(false);
      
      handleAssociationChange();
      
    } catch (error) {
      console.error('Error adding control:', error);
      handleError(error.message || 'Failed to add control');
    }
  };

  const handleCancelControlAdd = () => {
    setShowControlAddConfirmation(false);
    setPendingControlAdd(null);
  };

  const handleStandardEdit = async (domainName, standardId, newValue) => {
    try {
      await editStandard(authenticatedFetch, domainName, standardId, newValue);
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error editing standard:', error);
      handleError('Failed to edit standard. Please try again.');
    }
  };

  const handleStandardDelete = async (domainName, standardId) => {
    try {
      await deleteStandard(authenticatedFetch, standardId);
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error deleting standard:', error);
      handleError('Failed to delete standard. Please try again.');
    }
  };

  const handlePolicySubmit = (newPolicyName, newPolicyDescription, standard) => {
    const policy = {
      name: newPolicyName,
      description: newPolicyDescription
    };
    handleAddPolicy(policy, standard);
  };

  const handleDomainAdd = async () => {
    try {
      const tempId = `new-domain-${Date.now()}`;
      setNewDomain({
        id: tempId,
        name: ''
      });
    } catch (error) {
      console.error('Error initiating domain add:', error);
      handleError('Failed to initiate domain add. Please try again.');
    }
  };

  const handleDomainEdit = async (domainName, newValue) => {
    try {
      await renameDomain(authenticatedFetch, domainName, newValue);
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error editing domain:', error);
      handleError('Failed to edit domain. Please try again.');
    }
  };

  const handleDomainsUpdate = async () => {
    try {
      const updatedDomains = await fetchDomains(authenticatedFetch);
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error updating domains:', error);
      handleError('Failed to update domains');
    }
  };

  return {
    handleControlClick,
    handlePolicyClick,
    onConfirmDelete,
    handleCancelDelete,
    handleError,
    handleAddPolicy,
    fetchStandard,
    handleConfirmNavigation,
    handleCancelNavigation,
    handleConfirmEdit,
    handleConfirmRename,
    handleCancelRename,
    handleControlDeleteClick,
    handleConfirmControlDelete,
    handleCancelControlDelete,
    handlePolicyDeleteClick,
    handleConfirmPolicyDelete,
    handleCancelPolicyDelete,
    stripHtmlTags,
    convertHtmlToJinjaTemplate,
    handleAddControl,
    fetchPolicyDetails,
    onAddControl,
    handleConfirmControlAdd,
    handleCancelControlAdd,
    handleStandardEdit,
    handleStandardDelete,
    handlePolicySubmit,
    handleDomainAdd,
    handleDomainEdit,
    handleDomainsUpdate,
  };
}; 