import { useAuth } from 'contexts/AuthContext';

export const fetchPolicies = async (authenticatedFetch, companyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/card`);
    if (!response.ok) {
      throw new Error('Failed to fetch policies');
    }
    const data = await response.json();
    return data.policies;
  } catch (error) {
    console.error('Error fetching policies:', error);
    throw error;
  }
};

export const fetchPolicyByName = async (authenticatedFetch, policyName) => {
  try {
    const decodedName = decodeURIComponent(policyName);
    const dbPolicyName = decodedName
      .split(/\s+/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join('');

    console.log('Fetching policy with name:', dbPolicyName);
    const response = await authenticatedFetch(`/api/policy/name/${encodeURIComponent(dbPolicyName)}`);
    if (!response.ok) {
      throw new Error('Failed to fetch policy');
    }
    const data = await response.json();
    console.log('Policy data:', data);
    
    // No transformation needed - use the API data directly
    return data.policy;
  } catch (error) {
    console.error('Error fetching policy:', error);
    throw error;
  }
};

export const renamePolicy = async (authenticatedFetch, policyId, newTitle) => {
  const response = await authenticatedFetch(`/api/policy/${policyId}/rename`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: newTitle })
  });

  const data = await response.json();

  if (!response.ok) {
    if (data.error === "Validation error" && data.message?.name) {
      throw new Error(data.message.name[0]);
    }
    throw new Error(data.message || 'Failed to rename policy');
  }

  return data;
};

export const addPolicy = async (authenticatedFetch, policyData) => {
  const response = await authenticatedFetch('/api/policy/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(policyData)
  });

  if (!response.ok) {
    throw new Error('Failed to create policy');
  }

  return await response.json();
};

export const createPolicy = async (authenticatedFetch, policyData) => {
  console.log('Creating policy with data:', policyData);
  
  const response = await authenticatedFetch('/api/policy/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...policyData,
      title: policyData.title || policyData.name.replace(/([A-Z])/g, ' $1').trim()
    })
  });

  const data = await response.json();
  console.log('Policy creation response:', data);
  
  if (!response.ok) {
    if (data.error === "Validation error" && data.message?.name) {
      throw new Error(data.message.name[0]);
    }
    throw new Error('Failed to create policy');
  }

  return data;
};

export const seedPolicies = async (authenticatedFetch) => {
  try {
    const response = await authenticatedFetch('/api/policy/seed', {
      method: 'POST'
    });
    if (!response.ok) {
      throw new Error('Failed to seed policies');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error seeding policies:', error);
    throw error;
  }
};

export const deletePolicy = async (authenticatedFetch, policyId) => {
  const response = await authenticatedFetch(`/api/policy/${policyId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to delete policy');
  }

  return response.json();
};

export const updatePolicy = async (authenticatedFetch, policyId, policyData) => {
  const response = await authenticatedFetch(`/api/policy/${policyId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(policyData)
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to update policy');
  }

  return await response.json();
};

export const fetchPolicyDetails = async (authenticatedFetch, policyName) => {
  try {
    const policy = await fetchPolicyByName(authenticatedFetch, policyName);
    
    return {
      policy,
      // Add any additional data we might need in the future
      metadata: {
        lastUpdated: policy.updated_at,
        createdAt: policy.created_at,
        version: policy.version
      }
    };
  } catch (error) {
    console.error('Error in fetchPolicyDetails:', error);
    throw error;
  }
};

export const validatePolicy = async (authenticatedFetch, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/validate`, {
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error('Failed to validate policy');
    }

    const data = await response.json();
    return data.validation_results;
  } catch (error) {
    console.error('Error validating policy:', error);
    throw error;
  }
};

export const checkPolicyHealth = async (authenticatedFetch, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/health`);
    
    if (!response.ok) {
      throw new Error('Failed to check policy health');
    }

    const data = await response.json();
    return {
      isHealthy: data.is_healthy,
      issues: data.issues || [],
      lastChecked: data.last_checked,
      score: data.health_score
    };
  } catch (error) {
    console.error('Error checking policy health:', error);
    throw error;
  }
};

export const getPolicyHistory = async (authenticatedFetch, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/history`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch policy history');
    }

    const data = await response.json();
    return data.history;
  } catch (error) {
    console.error('Error fetching policy history:', error);
    throw error;
  }
};

export const getPolicyStats = async (authenticatedFetch, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/stats`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch policy statistics');
    }

    const data = await response.json();
    return {
      lastUpdated: data.last_updated,
      version: data.version,
      changeCount: data.change_count,
      reviewCount: data.review_count,
      complianceScore: data.compliance_score
    };
  } catch (error) {
    console.error('Error fetching policy statistics:', error);
    throw error;
  }
};

export const fetchPolicyData = async (authenticatedFetch, policyName) => {
  try {
    const policy = await fetchPolicyByName(authenticatedFetch, policyName);
    
    // Get additional policy data
    const [healthData, historyData, statsData] = await Promise.all([
      checkPolicyHealth(authenticatedFetch, policy.id),
      getPolicyHistory(authenticatedFetch, policy.id),
      getPolicyStats(authenticatedFetch, policy.id)
    ]);
    
    return {
      policy,
      healthData,
      historyData,
      statsData
    };
  } catch (error) {
    console.error('Error in fetchPolicyData:', error);
    throw error;
  }
};

export const updatePolicyContent = async (authenticatedFetch, policyId, content) => {
  const response = await authenticatedFetch(`/api/policy/${policyId}/content`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ content })
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to update policy content');
  }

  return await response.json();
};

export const updatePolicyDescription = async (authenticatedFetch, policyId, description) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/description`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ description }),
    });

    if (!response.ok) {
      throw new Error('Failed to update policy description');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating policy description:', error);
    throw error;
  }
};

export const addStandardToPolicy = async (authenticatedFetch, policyId, standardId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/standards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ standard_id: standardId }),
    });

    if (!response.ok) {
      throw new Error('Failed to add standard to policy');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error adding standard to policy:', error);
    throw error;
  }
};

export const fetchPolicyStandards = async (authenticatedFetch, policyId) => {
  try {
    // First get the policy details to get the standards list
    const policyResponse = await authenticatedFetch(`/api/policy/${policyId}`);
    if (!policyResponse.ok) {
      throw new Error('Failed to fetch policy details');
    }
    const policyData = await policyResponse.json();
    
    // Now fetch details for each standard
    if (policyData.policy && policyData.policy.standards) {
      const standardPromises = policyData.policy.standards.map(standard => 
        authenticatedFetch(`/api/standard/${standard.id}`).then(res => res.json())
      );
      
      const standards = await Promise.all(standardPromises);
      return standards.map(s => s.standard);
    }
    return [];
  } catch (error) {
    console.error('Error fetching policy standards:', error);
    throw error;
  }
}; 

export const fetchPolicyById = async (authenticatedFetch, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch policy');
    }
    const data = await response.json();
    console.log('Policy data by ID:', data);
    
    return data.policy;
  } catch (error) {
    console.error('Error fetching policy by ID:', error);
    throw error;
  }
};

export const fetchPolicyHistory = async (authenticatedFetch, policyId) => {
  try {
    const response = await authenticatedFetch(`/api/policy/${policyId}/history/`);
    if (!response.ok) {
      throw new Error('Failed to fetch policy history');
    }
    
    const data = await response.json();
    
    if (!data || !data.history || !Array.isArray(data.history)) {
      console.error('Invalid history data format received:', data);
      return [];
    }

    // Transform and sort the history records
    return data.history.map(record => ({
      id: record.id,
      version: record.version,
      timestamp: new Date(record.timestamp),
      name: record.name,
      title: record.title,
      description: record.description,
      policy_text: record.policy_text,
      published: record.published,
      healthy: record.healthy,
      risk_weight: record.risk_weight,
      risk_formula: record.risk_formula,
      risk_availability: record.risk_availability,
      risk_confidentiality: record.risk_confidentiality,
      risk_integrity: record.risk_integrity,
      company_id: record.company_id,
      standards: (record.standards || []).map(standard => ({
        id: standard.id,
        name: standard.name,
        description: standard.description,
        controls: (standard.controls || []).map(control => ({
          id: control.id,
          name: control.name,
          title: control.title,
          description: control.description,
          healthy: control.healthy,
          evidences: (control.evidences || []).map(evidence => ({
            id: evidence.id,
            name: evidence.name,
            healthy: evidence.healthy,
            frequency: evidence.frequency,
            frequency_unit: evidence.frequency_unit
          }))
        }))
      }))
    })).sort((a, b) => b.version - a.version); // Sort by version descending

  } catch (error) {
    console.error('Error fetching policy history:', error);
    throw error;
  }
};