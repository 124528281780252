import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import { camelToSpace } from '../../utils/format';
import { Button, Stack } from '@mui/material';

function RiskForceGraph({ riskData, nodeColors, selectedTypes, onToggleNodeType }) {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState(null);
  const [tooltip, setTooltip] = useState({ show: false, content: '', x: 0, y: 0 });
  const containerRef = useRef(null);
  const zoomRef = useRef(null);

  const getNodeColor = (type) => {
    return nodeColors[type] || '#999';
  };

  const getRiskWeightColor = (weight) => {
    // Normalize weight to a 0-1 scale (assuming max weight is 25)
    const normalizedWeight = Math.min(weight, 25) / 25;
    
    // Create a color scale using our new pastel severity colors
    return d3.scaleLinear()
      .domain([0, 0.25, 0.5, 0.75, 1])
      .range([
        '#7fb3d5',  // lowest - light blue pastel
        '#9b90e2',  // low - light purple-blue pastel
        '#d7a1e7',  // medium - light purple pastel
        '#f1a5c9',  // high - light pink pastel
        '#e6a5a5'   // highest - light red pastel
      ])(normalizedWeight);
  };

  const processGraphData = (data) => {
    const nodes = [];
    const links = [];
    const addedNodes = new Set();
    const policyRiskWeights = new Map();

    // Handle both single risk and multiple risks data structure
    const risks = data.relationships ? data.relationships : [data];

    risks.forEach(relationship => {
      const risk = relationship.risk || relationship;
      
      if (!addedNodes.has(risk.id)) {
        nodes.push({
          id: risk.id,
          name: risk.name,
          type: 'Risk',
          domain: risk.domain,
          group: 1
        });
        addedNodes.add(risk.id);
      }

      const standards = relationship.standards || [];
      standards.forEach(standard => {
        const standardId = `s${standard.id}`;
        if (!addedNodes.has(standardId)) {
          nodes.push({
            id: standardId,
            name: standard.name,
            type: 'Standard',
            domain: standard.domain,
            risk_weight: standard.risk_weight,
            risk_impact: standard.risk_impact,
            risk_frequency: standard.risk_frequency,
            group: 2
          });
          addedNodes.add(standardId);
        }

        links.push({
          source: risk.id,
          target: standardId,
          value: standard.risk_weight || 1
        });

        const policies = standard.policies || [];
        policies.forEach(policy => {
          const policyId = `p${policy.id}`;
          
          const currentWeight = policyRiskWeights.get(policyId) || 0;
          policyRiskWeights.set(policyId, currentWeight + (standard.risk_weight || 0));

          if (!addedNodes.has(policyId)) {
            nodes.push({
              id: policyId,
              name: policy.title || policy.name,
              type: 'Policy',
              group: 3
            });
            addedNodes.add(policyId);
          }

          links.push({
            source: standardId,
            target: policyId,
            value: 1
          });
        });
      });
    });

    // Add risk weights to policy nodes
    nodes.forEach(node => {
      if (node.type === 'Policy') {
        node.risk_weight = policyRiskWeights.get(node.id) || 0;
      }
    });

    return { nodes, links };
  };

  // Reference to graph-forest-force.js for these utility functions
  const getAncestors = (nodeId, nodes, links) => {
    const ancestors = new Set();
    const stack = [nodeId];
    
    while (stack.length > 0) {
      const currentId = stack.pop();
      links.forEach(link => {
        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
        if (targetId === currentId) {
          const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
          if (!ancestors.has(sourceId)) {
            ancestors.add(sourceId);
            stack.push(sourceId);
          }
        }
      });
    }
    return Array.from(ancestors);
  };

  const getDescendants = (nodeId, nodes, links) => {
    const descendants = new Set();
    const stack = [nodeId];
    
    while (stack.length > 0) {
      const currentId = stack.pop();
      links.forEach(link => {
        const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
        if (sourceId === currentId) {
          const targetId = typeof link.target === 'object' ? link.target.id : link.target;
          if (!descendants.has(targetId)) {
            descendants.add(targetId);
            stack.push(targetId);
          }
        }
      });
    }
    return Array.from(descendants);
  };

  const handleZoom = (direction) => {
    const svg = d3.select(containerRef.current).select("svg");
    const currentTransform = d3.zoomTransform(svg.node());
    const newScale = direction === 'in' 
      ? currentTransform.k * 1.5 
      : currentTransform.k / 1.5;
    
    const scale = Math.min(Math.max(0.1, newScale), 5);
    
    svg.transition()
      .duration(300)
      .call(zoomRef.current.transform, 
        d3.zoomIdentity
          .translate(currentTransform.x, currentTransform.y)
          .scale(scale)
      );
  };

  const renderForceGraph = (data) => {
    const container = containerRef.current;
    if (!container) return;

    const width = container.clientWidth;
    const height = container.clientHeight;

    // Clear previous content
    d3.select(container).selectAll("svg").remove();

    let g; // Declare g here so it's accessible throughout the function

    const svg = d3.select(container)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    // Create g before zoom setup
    g = svg.append("g")
      .attr("transform", `translate(${width/2},${height/2})`);

    function zoomed(event) {
      g.attr("transform", event.transform);
    }

    zoomRef.current = d3.zoom()
      .scaleExtent([0.1, 5])
      .on("zoom", zoomed)
      .filter(event => !event.type.includes('wheel'));

    svg.call(zoomRef.current);

    // Add initial transform
    const centerX = width / 8;
    const centerY = height / 4;
    const initialZoomScale = 0.5;
    
    svg.call(zoomRef.current.transform, d3.zoomIdentity
      .translate(centerX, centerY)
      .scale(initialZoomScale));


    const color = d3.scaleOrdinal()
      .domain(['Risk', 'Standard', 'Policy'])
      .range([
        nodeColors['Risk'],
        nodeColors['Standard'],
        nodeColors['Policy']
      ]);

    const links = data.links.map(d => ({...d}));
    const nodes = data.nodes.map(d => ({...d}));

    const simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id)
        .distance(d => {
          if (d.source.type === "Risk") {
            return 150;
          }
          return 75;
        })
        .strength(d => {
          if (d.target.type === "Policy") {
            return 0.5;
          }
          return 0.7;
        }))
      .force("charge", d3.forceManyBody()
        .strength(d => {
          if (d.type === "Policy") {
            return -50;
          }
          return -150;
        }))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide().radius(d => {
        if (d.type === "Policy") {
          return 25;
        }
        return 30;
      }).strength(0.8))
      .alphaDecay(0.01)
      .alphaMin(0.001);

    const link = g.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value) * 3);

    // Add tooltip div to container
    const tooltip = d3.select(container)
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "white")
      .style("color", "black")
      .style("padding", "5px")
      .style("border", "1px solid #ccc")
      .style("border-radius", "4px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    const node = g.append("g")
      .selectAll("circle")
      .data(nodes)
      .join("circle")
      .attr("r", d => d.type === "Risk" ? 18 : d.type === "Standard" ? 24 : 36)
      .attr("fill", d => color(d.type))
      .attr("opacity", 1)
      .attr("stroke", d => (d.type === "Standard" || d.type === "Policy") ? getRiskWeightColor(d.risk_weight) : "none")
      .attr("stroke-width", d => (d.type === "Standard" || d.type === "Policy") ? 8 : 0)
      .attr("stroke-opacity", d => (d.type === "Standard" || d.type === "Policy") ? 0.8 : 0)
      .on("mouseover", function(event, d) {
        const relatedNodes = new Set([
          d.id,
          ...getAncestors(d.id, nodes, links),
          ...getDescendants(d.id, nodes, links)
        ]);

        // Show tooltip
        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        
        const [mouseX, mouseY] = d3.pointer(event, container);
        tooltip.html(camelToSpace(d.name))
          .style("left", (mouseX + 10) + "px")
          .style("top", (mouseY - 10) + "px");

        // Highlight related nodes
        node.each(function(nodeData) {
          const element = d3.select(this);
          if (relatedNodes.has(nodeData.id)) {
            element.attr("fill", color(nodeData.type))
                  .attr("opacity", 1);
          } else {
            element.attr("fill", color(nodeData.type))
                  .attr("opacity", 0.2);
          }
        });

        link
          .attr("stroke", l => {
            const sourceId = typeof l.source === 'object' ? l.source.id : l.source;
            const targetId = typeof l.target === 'object' ? l.target.id : l.target;
            return relatedNodes.has(sourceId) && relatedNodes.has(targetId)
              ? getNodeColor('Risk')
              : "#999";
          })
          .attr("stroke-opacity", l => {
            const sourceId = typeof l.source === 'object' ? l.source.id : l.source;
            const targetId = typeof l.target === 'object' ? l.target.id : l.target;
            return relatedNodes.has(sourceId) && relatedNodes.has(targetId)
              ? 1
              : 0.1;
          });
      })
      .on("mousemove", function(event) {
        const [mouseX, mouseY] = d3.pointer(event, container);
        tooltip
          .style("left", (mouseX + 10) + "px")
          .style("top", (mouseY - 10) + "px");
      })
      .on("mouseout", function(event, d) {
        // Hide tooltip
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);

        // Reset node colors
        node.each(function(nodeData) {
          const element = d3.select(this);
          element.attr("fill", color(nodeData.type))
                .attr("opacity", 1);
        });

        // Reset link colors
        link
          .attr("stroke", "#999")
          .attr("stroke-opacity", 0.6)
          .attr("stroke-width", d => Math.sqrt(d.value));
      });

    node.append("title")
      .text(d => camelToSpace(d.name));

    node.call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
    });

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    node.each(function(nodeData) {
      const element = d3.select(this);
      if (nodeData.type === "Standard" || nodeData.type === "Policy") {
        element.select("rect")
          .attr("stroke-width", 20)
          .attr("stroke", getRiskWeightColor(nodeData.risk_weight));
      }
    });
  };

  useEffect(() => {
    if (riskData) {
      const data = processGraphData(riskData);
      setGraphData(data);
    }
  }, [riskData]);

  useEffect(() => {
    if (graphData) {
      renderForceGraph(graphData);
    }
  }, [graphData, selectedTypes]);

  return (
    <div className="graph-container" style={{ position: 'relative', height: '40rem' }}>
      <Stack 
        direction="row" 
        spacing={1} 
        justifyContent="center" 
        sx={{ mb: 2 }}
      >
      </Stack>
      <div ref={containerRef} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
}

export default RiskForceGraph; 