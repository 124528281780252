import {
  FiUsers,
  FiFileText,
  FiSliders,
  FiShield,
  FiCpu,
  FiPackage,
  FiDatabase,
  FiSettings,
  FiTool,
  FiSearch,
  FiActivity,
  FiPieChart,
  FiHeadphones,
  FiClock,
  FiCalendar,
  FiTrendingUp
} from 'react-icons/fi';

export const SERVICE_TIERS = {
  BASIC: 0,
  ESSENTIALS: 1,
  PROFESSIONAL: 2,
  ENTERPRISE: 3
};

export const TIER_NAMES = {
  [SERVICE_TIERS.BASIC]: 'Basic',
  [SERVICE_TIERS.ESSENTIALS]: 'Essentials',
  [SERVICE_TIERS.PROFESSIONAL]: 'Professional',
  [SERVICE_TIERS.ENTERPRISE]: 'Enterprise'
};

// Feature to icon mapping
export const FEATURE_ICONS = {
  'Basic policy management': FiSettings,
  'Basic standards creation': FiShield,
  'Basic controls creation': FiSliders,
  'Essential reporting': FiPieChart,
  'Up to 10 policies': FiFileText,
  'Up to 20 standards': FiShield,
  'Up to 100 controls': FiSliders,
  'Up to 3 team members': FiUsers,
  'AI-powered content builder': FiCpu,
  'Pre-packaged templates': FiPackage,
  'Advanced policy management': FiSettings,
  'Custom control creation': FiTool,
  'Calendar integration': FiCalendar,
  'Basic support': FiHeadphones,
  'Import Existing Governance Content': FiDatabase,
  'Advanced risk analytics': FiActivity,
  'Complete audit trail': FiClock,
  'Query engine': FiSearch,
  'Premium support': FiHeadphones,
  'On-Prem or in your own data center': FiDatabase,
  'Enterprise support': FiHeadphones,
  'Everything in Basic, plus:': FiTrendingUp,
  'Everything in Essentials, plus:': FiTrendingUp,
  'Everything in Professional, plus:': FiTrendingUp
};

export const TIER_FEATURES = {
  [SERVICE_TIERS.BASIC]: {
    name: 'Basic',
    price: 0.50,
    maxPolicies: 10,
    maxStandards: 50,
    maxControls: 200,
    maxPrompts: 10,
    maxUsers: 3,
    description: 'Perfect for small teams getting started with compliance management.',
    features: [
      'Basic policy management',
      'Basic standards creation',
      'Basic controls creation',
      'Essential reporting',
      'Limited AI content building'
    ]
  },
  [SERVICE_TIERS.ESSENTIALS]: {
    name: 'Essentials',
    price: 1.00,
    maxPolicies: 20,
    maxStandards: 200,
    maxControls: 1000,
    maxPrompts: 100,
    maxUsers: 10,
    description: 'Ideal for growing organizations ready to scale their compliance program.',
    features: [
      'Everything in Basic, plus:',
      'AI-powered content builder',
      'Pre-packaged templates',
      'Advanced policy management',
      'Calendar integration',
      'Basic support'
    ]
  },
  [SERVICE_TIERS.PROFESSIONAL]: {
    name: 'Professional',
    price: -1,
    maxPolicies: -1,
    maxStandards: -1,
    maxControls: -1,
    maxUsers: -1,
    maxPrompts: -1,
    description: 'Built for organizations requiring comprehensive compliance solutions.',
    features: [
      'Everything in Essentials, plus:',
      'Import Governance',
      'Advanced risk analytics',
      'Complete audit trail',
      'Query engine',
      'Premium support'
    ]
  },
  [SERVICE_TIERS.ENTERPRISE]: {
    name: 'Enterprise',
    price: -1,
    maxPolicies: -1,
    maxStandards: -1,
    maxControls: -1,
    maxUsers: -1,
    maxPrompts: -1,
    description: 'Built to your requirements and deployed on-prem or in your own data center.',
    features: [
      'Everything in Professional, plus:',
      'On-Prem or in your own data center',
      'Enterprise support'
    ]
  }
};

export const getTierName = (serviceTier) => {
  return TIER_NAMES[serviceTier] || 'Unknown';
};

export const getTierFeatures = (serviceTier) => {
  return TIER_FEATURES[serviceTier] || TIER_FEATURES[SERVICE_TIERS.BASIC];
};

export const isFeatureAvailable = (serviceTier, feature) => {
  const tier = TIER_FEATURES[serviceTier];
  if (!tier) return false;
  return tier.features.includes(feature);
};

export const getMaxLimit = (serviceTier, limitType) => {
  const tier = TIER_FEATURES[serviceTier];
  if (!tier) return TIER_FEATURES[SERVICE_TIERS.BASIC][limitType];
  return tier[limitType];
}; 