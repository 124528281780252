import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import RandomForceGraph from 'components/Graph/graph-random-force';
import Logo from '../../graphics/logo.svg';
import { API_BASE_URL } from '../../constants/api';

import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isConsumerEmail } from '../../utils/email-validator';

const actionCodeSettings = {
  url: `${window.location.origin}/verify-email`,
  handleCodeInApp: true
};

// Reuse the same styled components from Login.js
const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'var(--background-color)'
}));

const LoginSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  zIndex: 2
}));

const GraphSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: 400,
  backgroundColor: 'var(--background-color-alt)',
  '& .MuiTextField-root': {
    backgroundColor: 'var(--white-color)',
    borderRadius: theme.shape.borderRadius,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'var(--title-color)'
      }
    },
    '& .MuiInputLabel-root': {
      color: 'var(--title-color)'
    }
  }
}));

const LogoImage = styled('img')({
  width: 80,
  height: 80,
  marginBottom: '1rem'
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'var(--button-default-color)',
  color: 'var(--text-color-light)',
  '&:hover': {
    backgroundColor: 'var(--button-default-color-hover)'
  }
}));

const StyledLink = styled(Link)({
  color: 'var(--link-color)',
  '&:hover': {
    color: 'var(--link-color-alt)'
  }
});

const FooterText = styled(Typography)({
  color: 'var(--text-color-neutral)',
  fontSize: '1rem',
  textAlign: 'center',
  marginTop: '2rem'
});

function OnboardingSignup() {
  const { user, authenticatedFetch } = useAuth();
  const navigate = useNavigate();
  const [userDomain, setUserDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingOrg, setIsCheckingOrg] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    const newErrors = {};
    if (!email) newErrors.email = 'Email is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    try {
      // Check if email is a consumer email
      const isConsumer = await isConsumerEmail(email);
      if (isConsumer) {
        setErrors({
          email: 'Please use your work email address. Consumer email addresses are not allowed.'
        });
        setIsLoading(false);
        return;
      }

      // Check if organization exists for this domain
      const domain = email.split('@')[1];
      const response = await fetch(`${API_BASE_URL}/api/company/check_domain/${domain}`);
      const data = await response.json();
      
      if (response.status === 200 && data.exists) {
        setErrors({
          email: 'This organization already exists in Valdyr.io. Please contact your organization administrator to be added to the platform.'
        });
        setIsLoading(false);
        return;
      }

      // If organization doesn't exist, proceed with email verification
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setMessage('Please check your email to complete signup. The verification link has been sent.');
    } catch (error) {
      console.error('Error during signup:', error);
      setErrors({
        email: error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkExistingCompany = async () => {
      if (!user?.email) {
        return;
      }

      setIsCheckingOrg(true);
      const domain = user.email.split('@')[1];
      setUserDomain(domain);
      
      try {
        const response = await authenticatedFetch(`/api/company/check_domain/${domain}`);
        const data = await response.json();
        
        if (response.status === 200 && data.exists) {
          navigate('/governance/forest');
        }
      } catch (error) {
        console.error('Error checking company:', error);
      } finally {
        setIsCheckingOrg(false);
      }
    };

    checkExistingCompany();
  }, [user, authenticatedFetch, navigate]);

  if (isCheckingOrg) {
    return <div className="loading">Checking organization status...</div>;
  }

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <LoginSection>
        <StyledPaper elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <LogoImage src={Logo} alt="Valdyr Logo" sx={{ mb: 6, width: 300, height: 300 }} />
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'var(--title-color)' }}>
              VALDYR.io
            </Typography>
            
            {message ? (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Check Your Email</Typography>
                <Alert severity="success">
                  {message}
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Don't see the email? Check your spam folder.
                  </Typography>
                </Alert>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleEmailSignup} sx={{ mt: 3, width: '100%', textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Create your account</Typography>
                <Typography variant="body" sx={{ mb: 3, textAlign: 'center' }}>
                  Already have an account?{' '}
                  <StyledLink href="#" onClick={() => navigate('/login')} underline="hover">
                    Sign in
                  </StyledLink>
                </Typography>
                
                <TextField
                  fullWidth
                  type="email"
                  label="Work Email"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                  sx={{ mb: 2, mt: 2 }}
                />
                
                <StyledButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  sx={{ mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Sign Up'}
                </StyledButton>
              </Box>
            )}
            <FooterText>
              Click <StyledLink href="https://valdyr.io/privacy" target="_blank" rel="noopener noreferrer">here</StyledLink> to view our privacy policy
            </FooterText>
            <FooterText>
              © 2025 VALDYR.io. All rights reserved.
            </FooterText>
          </Box>
        </StyledPaper>
      </LoginSection>
      
      <GraphSection>
        <RandomForceGraph />
      </GraphSection>
    </StyledContainer>
  );
}

export default OnboardingSignup; 