import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from 'components/Sidebar/Sidebar';
import routesConfig from 'constants/routesConfig';
import Login from 'pages/Login';
import OnboardingSignup from 'pages/Onboarding/onboarding-signup';
import OnboardingSection from 'pages/Onboarding/onboarding-section';
import VerifyEmail from 'pages/Onboarding/verify-email';
import { AppProvider } from 'contexts/AppContext';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/mui-theme';

function AppContent() {
  const location = useLocation();
  const noLayoutRoutes = ['/login', '/signup', '/onboarding', '/verify-email', '/health'];
  const isNoLayout = noLayoutRoutes.includes(location.pathname);

  if (isNoLayout) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<OnboardingSignup />} />
        <Route path="/onboarding" element={<OnboardingSection />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
      </Routes>
    );
  }

  return (
    <div className="app-container">
      <Sidebar />
      <div className="page-content">
        <Routes>
          {routesConfig.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
