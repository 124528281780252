import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
} from '@mui/material';
import { TIER_FEATURES } from 'utils/service-tier';
import { getSubscriptionStatus } from 'utils/stripe-billing';
import { useAuth } from 'contexts/AuthContext';
import '../../styles/Account.css';

function AccountOverview({ companyData }) {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { authenticatedFetch } = useAuth();

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const status = await getSubscriptionStatus();
        setSubscriptionDetails(status);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        setSubscriptionDetails({ status: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const formatDate = (dateValue) => {
    if (!dateValue) return 'N/A';
    
    const date = typeof dateValue === 'string' 
      ? new Date(dateValue)
      : new Date(dateValue * 1000);

    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getTierName = (tier) => {
    return TIER_FEATURES[tier]?.name || 'Unknown';
  };

  const getBillingStatus = () => {
    if (isLoading) {
      return <CircularProgress size={20} />;
    }

    if (!subscriptionDetails) {
      return (
        <Typography component="span" sx={{ color: 'text.secondary' }}>
          No Active Subscription
        </Typography>
      );
    }

    switch (subscriptionDetails.status) {
      case 'trialing':
        return (
          <Typography component="span" sx={{ color: 'info.main' }}>
            Trial Period
          </Typography>
        );
      case 'active':
        return (
          <Typography component="span" sx={{ color: 'success.main' }}>
            Active Subscription
          </Typography>
        );
      case 'past_due':
        return (
          <Typography component="span" sx={{ color: 'warning.main' }}>
            Payment Past Due
          </Typography>
        );
      case 'incomplete':
        return (
          <Typography component="span" sx={{ color: 'warning.main' }}>
            Payment Incomplete
          </Typography>
        );
      case 'incomplete_expired':
        return (
          <Typography component="span" sx={{ color: 'error.main' }}>
            Payment Failed
          </Typography>
        );
      case 'canceled':
        return (
          <Typography component="span" sx={{ color: 'error.main' }}>
            Subscription Canceled
          </Typography>
        );
      case 'unpaid':
        return (
          <Typography component="span" sx={{ color: 'error.main' }}>
            Payment Failed - Subscription Unpaid
          </Typography>
        );
      case 'paused':
        return (
          <Typography component="span" sx={{ color: 'warning.main' }}>
            Subscription Paused
          </Typography>
        );
      case 'error':
        return (
          <Typography component="span" sx={{ color: 'error.main' }}>
            Error Checking Status
          </Typography>
        );
      default:
        return (
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            No Active Subscription
          </Typography>
        );
    }
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Paper sx={{ borderRadius: 2, ml: 1, mr: 1, mb: 1, mt: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h6">Account Information</Typography>
        </Box>
        
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="30%">Service Tier</TableCell>
                <TableCell width="70%">
                  {getTierName(companyData.service_tier)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Billing Status</TableCell>
                <TableCell>
                  {getBillingStatus()}
                </TableCell>
              </TableRow>
              {(subscriptionDetails?.status === 'trialing' || subscriptionDetails?.status === 'active') && (
                <TableRow>
                  <TableCell>
                    {subscriptionDetails?.status === 'trialing' ? 'Trial Ends on' : 'Next Billing Date'}
                  </TableCell>
                  <TableCell>
                    {formatDate(subscriptionDetails.trial_end || subscriptionDetails.next_billing_date)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ borderRadius: 2, ml: 1, mr: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h6">Company Utilization</Typography>
        </Box>
        
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="30%">Policies</TableCell>
                <TableCell width="70%">
                  {companyData.policy_usage || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Standards</TableCell>
                <TableCell>
                  {companyData.standard_usage || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Controls</TableCell>
                <TableCell>
                  {companyData.control_usage || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prompts</TableCell>
                <TableCell>
                  {companyData.prompt_usage || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Users</TableCell>
                <TableCell>
                  {companyData.user_usage || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default AccountOverview; 