import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Description as StandardIcon } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { camelCaseToSpaces } from 'utils/jinja';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useAuth } from 'contexts/AuthContext';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4], // Increases elevation by ~2 levels on hover
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'relative',
    '& .edit-button': {
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',
    },
    '&:hover .edit-button': {
      opacity: 1,
    },
    '& .delete-button': {
      right: theme.spacing(1),
    }
  }
}));

const ItemChip = styled(Typography)(({ theme }) => ({
  backgroundColor: 'var(--background-color)',
  padding: '6px 12px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  display: 'inline-block',
  color: 'var(--text-color-dark)',
}));

function StandardCard({ domain, standard, handleDeleteClick }) {
  const navigate = useNavigate();
  const { authenticatedFetch } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(standard.name);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRenameConfirmation, setShowRenameConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const handleClick = () => {
    if (!isEditing) {
      navigate(`/company/standards/${domain.name}/${standard.name}`);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await authenticatedFetch(`/api/standard/${standard.id}`, {
        method: 'DELETE'
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 500) {
          if (standard.policies && standard.policies.length > 0) {
            setErrorMessage(
              `Cannot delete standard with associated policies. Please remove the following policies first:\n` +
              standard.policies.map(p => p.title).join(', ')
            );
          } else if (standard.controls && standard.controls.length > 0) {
            setErrorMessage(
              `Cannot delete standard with associated controls. Please remove the following controls first:\n` +
              standard.controls.map(c => c.name).join(', ')
            );
          } else {
            setErrorMessage(data.message || 'Failed to delete standard');
          }
          setShowErrorDialog(true);
          setShowDeleteConfirmation(false);
        } else {
          setErrorMessage(data.message || 'Failed to delete standard');
          setShowErrorDialog(true);
        }
        return;
      }
      
      if (handleDeleteClick) {
        await handleDeleteClick(data.domain_deleted);
      }
      setIsEditing(false);
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting standard:', error);
      setErrorMessage('Failed to delete standard');
      setShowErrorDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    setShowRenameConfirmation(true);
  };

  const handleConfirmRename = async () => {
    try {
      const response = await authenticatedFetch(`/api/standard/rename`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          domain: domain.name,
          oldName: standard.name,
          newName: editedName
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to rename standard');
      }

      // Update was successful
      setIsEditing(false);
      setShowRenameConfirmation(false);
      
      // Trigger a refresh of the parent component
      if (handleDeleteClick) {
        await handleDeleteClick();
      }
    } catch (error) {
      console.error('Error renaming standard:', error);
      // TODO: Add error handling/notification
    }
  };

  const handleCancelRename = () => {
    setShowRenameConfirmation(false);
  };

  const handleCloseError = () => {
    setShowErrorDialog(false);
    setErrorMessage('');
  };

  return (
    <StyledCard 
      onClick={handleClick}
      sx={{ cursor: 'pointer', width: 'calc(100% - .5rem)', marginBottom: 1 }}
    >
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {standard.status === "Overdue" && (
            <AccessTimeIcon sx={{ color: 'var(--unhealthy-color)', backgroundColor: 'white', borderRadius: '50%', p: 0.1 }} />
          )}
          {isEditing ? (
            <Box
              component="input"
              sx={{
                fontSize: '18px',
                fontFamily: 'inherit',
                color: 'var(--text-color-light)',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                border: 'none',
                borderRadius: '4px',
                p:1,
                '&:focus': {
                  outline: 'none',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }
              }}
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <Typography variant="h6" sx={{ color: 'var(--text-color-light)', fontSize: '18px' }}>
              {standard.name && camelCaseToSpaces(standard.name)}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {!isEditing ? (
            <Box className="edit-button">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDelete}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={handleSave}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(false);
                  setEditedName(standard.name);
                }}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          )}
          <Typography 
            variant="caption" 
            sx={{ 
              backgroundColor: standard.controls && standard.controls.length > 0 ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '4px',
              whiteSpace: 'nowrap'
            }}
          >
            {standard.controls && standard.controls.length > 0 ? 'Has Controls' : 'No Controls'}
          </Typography>
        </Box>
      </Box>
      <CardContent>
        {/* Standard Text Section */}
        {standard.text && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'var(--text-color-dark)',
              backgroundColor: 'var(--background-color)',
              p: 2,
              borderRadius: 1,
              mb: 2,
              fontFamily: 'monospace'
            }}
          >
            {standard.text.replace(/<[^>]*>/g, '').substring(0, 200)}
            {standard.text.length > 200 && '...'}
          </Typography>
        )}

        {/* Controls Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Controls
          </Typography>
          {standard.controls && standard.controls.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {standard.controls.map((control) => (
                <ItemChip key={control.id}>
                  {control.name && camelCaseToSpaces(control.name)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No controls associated
            </Typography>
          )}
        </Box>

        {/* Policies Section */}
        <Box>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Policies
          </Typography>
          {standard.policies && standard.policies.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {standard.policies.map((policy) => (
                <ItemChip key={policy.id}>
                  {policy.title && camelCaseToSpaces(policy.title)}
                </ItemChip>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No policies associated
            </Typography>
          )}
        </Box>
      </CardContent>
      <Dialog
        open={showDeleteConfirmation}
        onClose={handleCancelDelete}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the standard "{standard.name && camelCaseToSpaces(standard.name)}"
            {standard.controls && standard.controls.length > 0 
              ? ` and its ${standard.controls.length} associated control${standard.controls.length === 1 ? '' : 's'}`
              : ''
            }?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button 
            onClick={(e) => {
              e.stopPropagation();
              handleConfirmDelete();
            }}
            variant="contained"
            sx={{ 
              backgroundColor: 'var(--unhealthy-color)',
              '&:hover': {
                backgroundColor: 'var(--unhealthy-color)',
                opacity: 0.9,
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showRenameConfirmation}
        onClose={handleCancelRename}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Rename</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to rename the standard from "{standard.name && camelCaseToSpaces(standard.name)}" to "{editedName && camelCaseToSpaces(editedName)}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRename}>Cancel</Button>
          <Button 
            onClick={handleConfirmRename}
            variant="contained"
            color="primary"
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showErrorDialog}
        onClose={handleCloseError}
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>Close</Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
}

StandardCard.propTypes = {
  domain: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  standard: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    text: PropTypes.string,
    controls: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string
    })),
    policies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string
    }))
  }).isRequired,
  handleDeleteClick: PropTypes.func
};

export default StandardCard; 