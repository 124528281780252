    // frontend/src/pages/Company/StandardSection.js

    import React, { useState, useEffect } from 'react';
    import { useNavigate, useParams } from 'react-router-dom';
    import Loading from 'components/Objects/loading';
    import { useAuth } from '../../contexts/AuthContext';
    import {
      Box,
      Typography,
      TextField,
      InputAdornment,
      Select,
      MenuItem,
      FormControl,
      InputLabel,
      Autocomplete,
      IconButton,
      Button,
      Dialog,
      DialogTitle,
      DialogContent,
      DialogActions,
      OutlinedInput,
      Grid
    } from '@mui/material';
    import { styled } from '@mui/material/styles';
    import {
      Search as SearchIcon,
      Article as ArticleIcon,
      Edit as EditIcon,
      Add as AddIcon,
      Close as CloseIcon
    } from '@mui/icons-material';
    import StandardCard from './standard-card';
    import Error from 'components/Objects/Error';
    import CircularProgress from '@mui/material/CircularProgress';
    import { createPortal } from 'react-dom';

    const TitleBar = styled(Box)(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      backgroundColor: 'white',
      gap: theme.spacing(2)
    }));

    const SearchField = styled(TextField)(({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'var(--white-color)',
        fontSize: '14px',
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--title-color)',
          borderWidth: '2px',
        }
      }
    }));

    const ContentContainer = styled(Box)({
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      width: 'calc(100% - 2rem)',
      flex: 1,
      padding: '1rem',
      margin: '0 auto',
    });

    function StandardSection() {
      const { authenticatedFetch, token, companyId } = useAuth();
      const { domainName, standardName } = useParams();
      const [initialLoading, setInitialLoading] = useState(true);
      const [searchTerm, setSearchTerm] = useState('');
      const [statusFilter, setStatusFilter] = useState('all');
      const [domains, setDomains] = useState([]);
      const [errorMessage, setErrorMessage] = useState('');
      const [showErrorDialog, setShowErrorDialog] = useState(false);
      const navigate = useNavigate();
      const [selectedDomain, setSelectedDomain] = useState(null);
      const [showAddStandard, setShowAddStandard] = useState(false);
      const [newStandardName, setNewStandardName] = useState('');
      const [showStandardChoice, setShowStandardChoice] = useState(false);
      const [newStandardId, setNewStandardId] = useState(null);
      const [newDomainName, setNewDomainName] = useState('');
      const [showGeneratingDialog, setShowGeneratingDialog] = useState(false);

      useEffect(() => {
        const fetchDomains = async () => {
          try {
            const response = await authenticatedFetch('/api/domain/short');
            if (!response.ok) {
              throw new Error('Failed to fetch domains');
            }
            const data = await response.json();
            setDomains(data);
          } catch (error) {
            console.error('Error fetching domains:', error);
            setErrorMessage('Failed to fetch domains');
            setShowErrorDialog(true);
          } finally {
            setInitialLoading(false);
          }
        };

        fetchDomains();
      }, [authenticatedFetch]);

      const refreshDomains = async () => {
        try {
          const response = await authenticatedFetch('/api/domain/short');
          if (!response.ok) {
            throw new Error('Failed to fetch domains');
          }
          const data = await response.json();
          setDomains(data);
        } catch (error) {
          console.error('Error fetching domains:', error);
          setErrorMessage('Failed to fetch domains');
          setShowErrorDialog(true);
        }
      };

      const filteredDomains = domains
        .filter(domain => !selectedDomain || domain.name === selectedDomain)
        .map(domain => ({
          ...domain,
          standards: domain.standards.filter(standard => {
            const matchesSearch = standard.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesStatus = statusFilter === 'all' || standard.status === statusFilter;
            return matchesSearch && matchesStatus;
          })
        })).filter(domain => domain.standards.length > 0);

      const handleBuildFromScratch = async () => {
        try {
          let domainName = selectedDomain === '__new__' ? newDomainName : selectedDomain;
          
          // If creating a new domain, create it first
          if (selectedDomain === '__new__') {
            const domainResponse = await authenticatedFetch('/api/domain/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ name: newDomainName })
            });

            if (!domainResponse.ok) {
              const data = await domainResponse.json();
              throw new Error(data.message?.name?.[0] || 'Failed to create domain');
            }
            
            domainName = newDomainName;
          }

          // Create the standard
          const standardResponse = await authenticatedFetch('/api/standard/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              company_id: companyId,
              domain: domainName,
              name: newStandardName
            })
          });

          if (!standardResponse.ok) {
            const data = await standardResponse.json();
            throw new Error(data.message || 'Failed to create standard');
          }

          setShowAddStandard(false);
          await refreshDomains();
          navigate(`/company/standards/${domainName}/${newStandardName}`);
        } catch (error) {
          console.error('Error creating standard:', error);
          setErrorMessage(error.message);
          setShowErrorDialog(true);
        }
      };

      const handleGenerateWithAI = async () => {
        try {
          let domainName = selectedDomain === '__new__' ? newDomainName : selectedDomain;
          
          // If creating a new domain, create it first
          if (selectedDomain === '__new__') {
            const domainResponse = await authenticatedFetch('/api/domain/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ name: newDomainName })
            });

            if (!domainResponse.ok) {
              const data = await domainResponse.json();
              throw new Error(data.message?.name?.[0] || 'Failed to create domain');
            }
            
            domainName = newDomainName;
          }

          // Create the standard
          const standardResponse = await authenticatedFetch('/api/standard/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              company_id: companyId,
              domain: domainName,
              name: newStandardName
            })
          });

          if (!standardResponse.ok) {
            const data = await standardResponse.json();
            throw new Error(data.message || 'Failed to create standard');
          }

          const standardData = await standardResponse.json();
          const standardId = standardData.id;

          // Show loading dialog
          setShowGeneratingDialog(true);

          // Start the generation process - using authenticatedFetch instead of fetch
          const generateResponse = await authenticatedFetch(`/api/standard/${standardId}/generate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              domain_name: domainName,
              standard_name: newStandardName
            })
          });

          if (!generateResponse.ok) {
            throw new Error('Failed to generate standard');
          }

          // Handle SSE response
          const reader = generateResponse.body.getReader();
          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            
            const text = decoder.decode(value);
            const lines = text.split('\n');
            
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = JSON.parse(line.slice(6));
                
                if (data.error) {
                  throw new Error(data.error);
                }
                
                if (data.status === 'complete') {
                  setShowGeneratingDialog(false);
                  setShowAddStandard(false);
                  await refreshDomains();
                  navigate(`/company/standards/${domainName}/${newStandardName}`);
                  return;
                }
              }
            }
          }

        } catch (error) {
          setShowGeneratingDialog(false);
          setErrorMessage(error.message);
          setShowErrorDialog(true);
        }
      };

      return (
        <>
          <Box sx={{ pb: 1, width: '100%' }}>
            <TitleBar sx={{ mb: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', padding: 1.25 }} elevation={0}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ArticleIcon sx={{ color: 'var(--title-color)' }} />
                <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
                  Standards
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setShowAddStandard(true)}
                  sx={{
                    backgroundColor: 'var(--healthy-color)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'var(--healthy-color)',
                      opacity: 0.9,
                    }
                  }}
                >
                  Add Standard
                </Button>
                <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
                  <InputLabel>Domain</InputLabel>
                  <Select
                    value={selectedDomain || 'all'}
                    onChange={(e) => setSelectedDomain(e.target.value === 'all' ? null : e.target.value)}
                    label="Domain"
                    MenuProps={{
                      disablePortal: true,
                      slotProps: {
                        paper: {
                          sx: {
                            zIndex: 9999,
                            position: 'absolute',
                            marginTop: 0.5,
                          }
                        }
                      }
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {domains.map(domain => (
                      <MenuItem key={domain.id} value={domain.name}>
                        {domain.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    label="Status"
                    MenuProps={{
                      disablePortal: true,
                      slotProps: {
                        paper: {
                          sx: {
                            zIndex: 9999,
                            position: 'absolute',
                            marginTop: 0.5,
                          }
                        }
                      }
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="Scheduled">Scheduled</MenuItem>
                    <MenuItem value="Overdue">Overdue</MenuItem>
                  </Select>
                </FormControl>
                <SearchField
                  size="small"
                  placeholder="Search standards..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: 300, outline: 'none', border: 'transparent' }}
                />
              </Box>
            </TitleBar>

            {showStandardChoice && (
              <Dialog
                open={showStandardChoice}
                onClose={() => setShowStandardChoice(false)}
                maxWidth="sm"
                fullWidth
                sx={{
                  '& .MuiDialog-paper': {
                    padding: 2
                  }
                }}
              >
                <DialogTitle>Create New Standard</DialogTitle>
                <DialogContent>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                    Choose how you would like to create your standard
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button 
                      variant="outlined" 
                      onClick={handleBuildFromScratch}
                      disabled={!selectedDomain || !newStandardName || (selectedDomain === '__new__' && !newDomainName)}
                      sx={{ 
                        minWidth: '200px',
                        '&.Mui-disabled': {
                          opacity: 0.5
                        }
                      }}
                    >
                      Build from Scratch
                    </Button>
                    <Button 
                      variant="contained" 
                      onClick={handleGenerateWithAI}
                      disabled={!selectedDomain || !newStandardName || (selectedDomain === '__new__' && !newDomainName)}
                      sx={{ 
                        minWidth: '200px',
                        '&.Mui-disabled': {
                          opacity: 0.5
                        }
                      }}
                    >
                      Generate with AI
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            )}

            {initialLoading ? (
              <div className="loading-container">
                <Loading />
              </div>
            ) : (
              <ContentContainer sx={{ position: 'relative', zIndex: 1, margin: '0 auto' }}>
                {Object.entries(
                  filteredDomains.reduce((acc, domain) => {
                    acc[domain.name] = domain.standards;
                    return acc;
                  }, {})
                ).map(([domain, standards]) => (
                  <Box key={domain}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '1.5rem',
                        color: 'var(--title-color)',
                        mb: 2,
                        pb: 1,
                        borderBottom: '2px solid var(--border-color)'
                      }}
                    >
                      {domain}
                    </Typography>
                    
                    <Grid container spacing={2} sx={{ mb: 6 }}>
                      {standards.map((standard) => (
                        <Grid item xs={12} sm={6} key={standard.id}>
                          <StandardCard
                            domain={{ name: domain }}
                            standard={standard}
                            handleDeleteClick={refreshDomains}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </ContentContainer>
            )}

            {showErrorDialog && (
              <Error
                message={errorMessage}
                onClose={() => setShowErrorDialog(false)}
              />
            )}

            {showAddStandard && (
              <Dialog
                open={showAddStandard}
                onClose={() => {
                  setShowAddStandard(false);
                  refreshDomains();
                }}
                maxWidth="sm"
                fullWidth
                sx={{
                  position: 'fixed',
                  zIndex: 9999999999999999999,
                  '& .MuiDialog-paper': {
                    position: 'relative',
                    zIndex: 9999999999999999999,
                    padding: 2
                  }
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DialogTitle>Create New Standard</DialogTitle>
                  <IconButton
                    onClick={() => {
                      setShowAddStandard(false);
                      refreshDomains();
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: 'grey.500'
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <DialogContent>
                  {showGeneratingDialog ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, py: 2 }}>
                      <CircularProgress size={24} />
                      <Typography>Generating Standard...</Typography>
                    </Box>
                  ) : (
                    <>
                      <Typography>
                        Please select a domain and enter a name for the new standard.
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel>Domain</InputLabel>
                          <Select
                            value={selectedDomain || ''}
                            onChange={(e) => setSelectedDomain(e.target.value)}
                            label="Domain"
                            input={<OutlinedInput label="Domain" />}
                            MenuProps={{
                              disablePortal: true,
                              slotProps: {
                                paper: {
                                  sx: {
                                    zIndex: 9999,
                                    position: 'absolute',
                                    marginTop: 0.5,
                                  }
                                }
                              }
                            }}
                          >
                            {domains.map(domain => (
                              <MenuItem key={domain.id} value={domain.name}>
                                {domain.name}
                              </MenuItem>
                            ))}
                            <MenuItem value="__new__" sx={{ borderTop: '1px solid #eee' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <AddIcon fontSize="small" />
                                Create New Domain
                              </Box>
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {selectedDomain === '__new__' && (
                          <TextField
                            fullWidth
                            label="New Domain Name"
                            value={newDomainName}
                            onChange={(e) => {
                              setNewDomainName(e.target.value);
                            }}
                            helperText="Enter a name for the new domain"
                          />
                        )}
                        <TextField
                          fullWidth
                          label="Standard Name"
                          value={newStandardName}
                          onChange={(e) => setNewStandardName(e.target.value)}
                          helperText="Enter a name for the new standard"
                        />
                      </Box>
                    </>
                  )}
                </DialogContent>
                {!showGeneratingDialog && (
                  <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
                    <Button 
                      variant="outlined" 
                      onClick={() => {
                        handleBuildFromScratch();
                      }}
                      disabled={!selectedDomain || !newStandardName || (selectedDomain === '__new__' && !newDomainName)}
                      sx={{ 
                        minWidth: '200px',
                        '&.Mui-disabled': {
                          opacity: 0.5
                        }
                      }}
                    >
                      Build from Scratch
                    </Button>
                    <Button 
                      variant="contained"
                      onClick={() => {
                        handleGenerateWithAI();
                      }}
                      disabled={!selectedDomain || !newStandardName || (selectedDomain === '__new__' && !newDomainName)}
                      sx={{ 
                        minWidth: '200px',
                        backgroundColor: 'var(--button-default-color)',
                        color: 'var(--text-color-light)',
                        '&:hover': {
                          backgroundColor: 'var(--button-default-color)',
                          opacity: 0.9,
                        },
                        '&.Mui-disabled': {
                          backgroundColor: 'var(--button-default-color)',
                          opacity: 0.5,
                          color: 'var(--text-color-light)'
                        }
                      }}
                    >
                      Generate with AI
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            )}
          </Box>
        </>
      );
    }

    export default StandardSection;