import React, { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import Confirmation from 'components/Objects/Confirmation';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

function AccountUsers({ companyData, onCompanyDataChange }) {
  const { authenticatedFetch } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newRole, setNewRole] = useState('user');
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingRemovals, setPendingRemovals] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (!companyData) {
    return null;
  }

  const formatDate = (dateValue) => {
    if (!dateValue) return 'N/A';
    const date = typeof dateValue === 'string' 
      ? new Date(dateValue)
      : new Date(dateValue * 1000);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<[^>]*>/g, '');
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setNewEmail('');
    setNewRole('user');
    setPendingUsers([]);
    setPendingRemovals([]);
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    if (!newEmail) return;
    
    const cleanEmail = stripHtmlTags(newEmail.trim());
    
    // Check if email contains @ symbol
    if (!cleanEmail.includes('@')) {
      setErrorMessage('Please enter a valid email address');
      setShowError(true);
      return;
    }
    
    const emailDomain = cleanEmail.split('@')[1];
    const companyDomain = getCompanyDomain(companyData.users);
    
    // Check domain
    if (!emailDomain || emailDomain !== companyDomain) {
      setErrorMessage(`Invalid email domain. Please use an email address with the domain: @${companyDomain}`);
      setShowError(true);
      return;
    }

    // Check if user already exists in company
    const existingUser = companyData.users.find(user => 
      user.email.toLowerCase() === cleanEmail.toLowerCase()
    );
    
    if (existingUser) {
      setErrorMessage('This user is already a member of your organization');
      setShowError(true);
      return;
    }

    // Check if user is already in pending list
    const pendingUser = pendingUsers.find(user => 
      user.email.toLowerCase() === cleanEmail.toLowerCase()
    );

    if (pendingUser) {
      setErrorMessage('This user is already in your pending invites');
      setShowError(true);
      return;
    }
    
    const newUser = {
      email: cleanEmail,
      role: newRole,
      created_at: new Date().toISOString(),
      tempId: Date.now()
    };
    setPendingUsers([...pendingUsers, newUser]);
    setNewEmail('');
    setNewRole('user');
  };

  const handleRemoveUser = (user) => {
    setPendingRemovals([...pendingRemovals, user]);
  };

  const handleSave = () => {
    setShowConfirmation(true);
    setConfirmationType(pendingUsers.length > 0 ? 'add' : 'remove');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setPendingUsers([]);
    setPendingRemovals([]);
  };

  const handleConfirmAdd = async () => {
    try {
      const addPromises = pendingUsers.map(user => 
        authenticatedFetch('/api/company/company_users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: user.email,
            role: user.role
          })
        })
      );
      
      await Promise.all(addPromises);
      setIsEditing(false);
      setPendingUsers([]);
      setShowConfirmation(false);
      
      if (onCompanyDataChange) {
        onCompanyDataChange();
      }
    } catch (error) {
      console.error('Error adding users:', error);
    }
  };

  const handleConfirmRemove = async () => {
    try {
      const removePromises = pendingRemovals.map(user => 
        authenticatedFetch(`/api/company/company_users/${user.id}`, {
          method: 'DELETE'
        })
      );
      
      await Promise.all(removePromises);
      setIsEditing(false);
      setPendingRemovals([]);
      setShowConfirmation(false);
      
      if (onCompanyDataChange) {
        onCompanyDataChange();
      }
    } catch (error) {
      console.error('Error removing users:', error);
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationType(null);
  };

  const getUserStyle = (userId) => ({
    textDecoration: pendingRemovals.find(u => u.id === userId) ? 'line-through' : 'none',
    opacity: pendingRemovals.find(u => u.id === userId) ? 0.5 : 1
  });

  const getCompanyDomain = (users) => {
    const adminUser = users?.find(user => user.id === 1);
    return adminUser ? adminUser.email.split('@')[1] : null;
  };

  const getConfirmationMessage = () => {
    if (confirmationType === 'add') {
      return `Are you sure you want to add ${pendingUsers.length} user${pendingUsers.length > 1 ? 's' : ''} to your organization?`;
    }
    return `Are you sure you want to remove ${pendingRemovals.length} user${pendingRemovals.length > 1 ? 's' : ''} from your organization?`;
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Paper sx={{ borderRadius: 2, ml: 1, mr: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h6">Company Users</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isEditing ? (
              <IconButton onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">Email</TableCell>
                <TableCell width="25%">Role</TableCell>
                <TableCell width="25%">Created</TableCell>
                {isEditing && <TableCell width="10%">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.users?.map((user) => (
                <TableRow 
                  key={user.id}
                  sx={getUserStyle(user.id)}
                >
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.id === 1 ? 'admin' : (user.role ? user.role.charAt(0).toUpperCase() + user.role.slice(1) : 'user')}
                  </TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  {isEditing && (
                    <TableCell>
                      {user.id !== 1 && !pendingRemovals.find(u => u.id === user.id) && (
                        <IconButton
                          onClick={() => handleRemoveUser(user)}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {pendingUsers.map((user) => (
                <TableRow key={user.tempId}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => setPendingUsers(pendingUsers.filter(u => u.tempId !== user.tempId))}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {isEditing && (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      placeholder="Enter email address"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      fullWidth
                      value={newRole}
                      onChange={(e) => setNewRole(e.target.value)}
                      size="small"
                    >
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="editor">Editor</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleAddUser}
                    >
                      Add User
                    </Button>
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {showConfirmation && (
        <Confirmation
          message={getConfirmationMessage()}
          onConfirm={confirmationType === 'add' ? handleConfirmAdd : handleConfirmRemove}
          onCancel={handleCancelConfirmation}
          title="Confirm Action"
        />
      )}
    </Box>
  );
}

export default AccountUsers; 