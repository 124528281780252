import { AuthenticatedFetch } from '../../contexts/AuthContext';

export const fetchRisks = async (authenticatedFetch, short = false) => {
  try {
    const endpoint = short ? '/api/risk/short' : '/api/risk/';
    const response = await authenticatedFetch(endpoint);
    if (!response.ok) {
      throw new Error('Failed to fetch risks');
    }
    const data = await response.json();

    if (!data || !data.domains) {
      console.error('Invalid data format received:', data);
      return { domains: [] };
    }

    return data;
  } catch (error) {
    console.error('Error fetching risks:', error);
    throw error;
  }
};

export const fetchRiskById = async (authenticatedFetch, riskId) => {
  try {
    const response = await authenticatedFetch(`/api/risk/${riskId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch risk');
    }
    const data = await response.json();

    if (!data || !data.risk) {
      console.error('Invalid data format received:', data);
      return null;
    }

    return data.risk;
  } catch (error) {
    console.error('Error fetching risk:', error);
    throw error;
  }
};


export const fetchRiskRelationships = async (authenticatedFetch, riskId) => {
  try {
    const response = await authenticatedFetch(`/api/risk/${riskId}/relationship`);
    if (!response.ok) {
      throw new Error('Failed to fetch risk relationships');
    }
    const data = await response.json();

    if (!data || !data.relationships) {
      console.error('Invalid data format received:', data);
      return null;
    }

    return data.relationships;
  } catch (error) {
    console.error('Error fetching risk relationships:', error);
    throw error;
  }
}; 