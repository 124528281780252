import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  TextField
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import Confirmation from 'components/Objects/Confirmation';
import { useAuth } from 'contexts/AuthContext';
import { updateControlDescription } from './standard-queries';
import { replaceJinjaVariables } from 'utils/jinja';
import { useNavigate } from 'react-router-dom';
import 'styles/Controls.css';

function ControlDescription({
  control,
  descriptionExpanded,
  setDescriptionExpanded,
  setControl,
  onAssociationChange,
  companyName
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(control?.text || '');
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [pendingSave, setPendingSave] = useState(null);
  const { authenticatedFetch } = useAuth();
  const navigate = useNavigate();

  const handleProcessClick = (event) => {
    const target = event.target;
    if (target.classList.contains('mce-variable') && target.dataset.processType) {
      const processType = target.dataset.urlType;
      const processValue = target.dataset.processValue;
      if (processType && processValue) {
        navigate(`/company/processes/${processType}/${encodeURIComponent(processValue)}`);
      }
    }
  };

  const handleConfirmSave = async () => {
    try {
      if (editedDescription !== undefined) {
        const updates = {
          text: editedDescription
        };

        await updateControlDescription(authenticatedFetch, control.id, updates);
        setControl({
          ...control,
          text: editedDescription
        });
        setIsEditing(false);
        
        if (onAssociationChange) {
          onAssociationChange();
        }
      }
    } catch (error) {
      console.error('Error saving control description:', error);
    }
    setShowSaveConfirmation(false);
    setPendingSave(null);
  };

  const handleSave = () => {
    setPendingSave(control);
    setShowSaveConfirmation(true);
  };

  const handleCancel = () => {
    setEditedDescription(control?.text || '');
    setIsEditing(false);
    setShowSaveConfirmation(false);
    setPendingSave(null);
  };

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: descriptionExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: descriptionExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Description</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isEditing ? (
              <IconButton 
                onClick={() => setIsEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              sx={{ 
                transform: descriptionExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {descriptionExpanded && (
          <Box sx={{ p: 2 }}>
            {isEditing ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                sx={{
                  backgroundColor: 'var(--background-color)',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'var(--background-color)',
                    '& fieldset': {
                      borderColor: 'var(--title-color)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--title-color)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--title-color)',
                    },
                  },
                }}
              />
            ) : (
              <Box 
                sx={{ 
                  '& a': { color: 'primary.main' },
                  '& p': { mb: 2 },
                  '& br': { display: 'block', content: '""', mb: 2 }
                }}
                onClick={handleProcessClick}
                dangerouslySetInnerHTML={{ 
                  __html: replaceJinjaVariables(control.text, null, companyName, control) 
                }}
              />
            )}
          </Box>
        )}
      </Paper>

      {showSaveConfirmation && (
        <Confirmation
          message="Are you sure you want to save these changes?"
          onConfirm={handleConfirmSave}
          onCancel={handleCancel}
        />
      )}
    </Box>
  );
}

ControlDescription.propTypes = {
  control: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  descriptionExpanded: PropTypes.bool.isRequired,
  setDescriptionExpanded: PropTypes.func.isRequired,
  setControl: PropTypes.func.isRequired,
  onAssociationChange: PropTypes.func,
  companyName: PropTypes.string
};

export default ControlDescription; 