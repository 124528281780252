import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {
  Chat as ChatIcon,
  Send as SendIcon
} from '@mui/icons-material';
import { useAuth } from 'contexts/AuthContext';

function QuerySection() {
  const { authenticatedFetch } = useAuth();
  const [messages, setMessages] = useState([
    { 
      type: 'system', 
      text: 'Hello! I\'m your AI assistant. I can help you find information about your company\'s standards, policies, and processes. What would you like to know?' 
    }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = { type: 'user', text: input };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await authenticatedFetch('/api/vector/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: input,
          content_types: ['standard', 'policy', 'process']
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get response');
      }

      const data = await response.json();
      setMessages(prev => [...prev, { type: 'system', text: data.answer }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, { 
        type: 'system', 
        text: 'Sorry, I encountered an error while processing your request.' 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ 
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'background.paper'
    }}>
      <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2, 
          backgroundColor: 'white',
          p: 1.75,
        }}>
          <ChatIcon sx={{ color: 'var(--title-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--title-color)', flexGrow: 1 }}>
            AI Assistant
          </Typography>
        </Box>
      </Box>

      <Box sx={{ 
        flex: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        overflowY: 'auto'
      }}>
        <Box sx={{ flex: 1 }}>
          {messages.map((message, index) => (
            <Card 
              key={index} 
              sx={{ 
                maxWidth: '80%',
                mb: 2,
                ml: message.type === 'system' ? 0 : 'auto',
                backgroundColor: message.type === 'system' ? 'var(--background-color)' : 'var(--button-default-color)',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: 2
              }}
            >
              <CardContent sx={{ 
                p: 2,
                '&:last-child': { pb: 2 }
              }}>
                <Typography sx={{ 
                  color: message.type === 'system' ? 'var(--text-color-dark)' : 'var(--text-color-light)'
                }}>
                  {message.text}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{ 
            backgroundColor: 'white',
            p: 2,
            borderRadius: 2,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            marginTop: 'auto'
          }}
        >
          <TextField
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton 
                    type="submit" 
                    disabled={!input.trim() || isLoading}
                    sx={{
                      color: input.trim() ? 'var(--button-default-color)' : 'text.disabled'
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--button-default-color)',
                },
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default QuerySection; 