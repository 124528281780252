import React, { useState, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import 'styles/Policies.css';
import { fetchPolicies } from './policy-queries';
import { useAuth } from 'contexts/AuthContext';
import { usePolicyActions } from './policy-actions';
import Error from 'components/Objects/Error';
import Loading from 'components/Objects/loading';
import PolicyCard from './policy-card';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Paper,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton
} from '@mui/material';
import {
  Search as SearchIcon,
  Add as AddIcon,
  Gavel as GavelIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'var(--white-color)',
    fontSize: '14px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--title-color)',
      borderWidth: '2px',
    }
  }
}));

const ContentContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '1rem',
  padding: '1rem',
  width: '100%',
});

function PolicySection() {
  const { authenticatedFetch } = useAuth();
  const { policyName } = useParams();
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [policies, setPolicies] = useState([]);
  const [activePolicy, setActivePolicy] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [policiesLoaded, setPoliciesLoaded] = useState(false);
  const [contentReady, setContentReady] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newPolicyTitle, setNewPolicyTitle] = useState('');
  const [addChoice, setAddChoice] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState('');
  const [showImportingDialog, setShowImportingDialog] = useState(false);

  const {
    handlePolicyDelete,
    handlePolicyRename,
    handlePolicyCreate,
    handleError,
  } = usePolicyActions(
    authenticatedFetch,
    setPolicies,
    setErrorMessage,
    setShowErrorDialog,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );

  console.log('PolicySection handlePolicyCreate:', handlePolicyCreate);

  useEffect(() => {
    const loadPolicies = async () => {
      try {
        const fetchedPolicies = await fetchPolicies(authenticatedFetch);
        setPolicies(fetchedPolicies);
        setPoliciesLoaded(true);
        
        if (fetchedPolicies.length > 0) {
          setShowOnboarding(false);
        }
      } catch (error) {
        console.error('Failed to load policies:', error);
        handleError('Failed to load policies');
      }
    };

    loadPolicies();
  }, [authenticatedFetch]);

  useEffect(() => {
    if (policiesLoaded) {
      // Only redirect if there's a specific policy in the URL
      if (policyName && policies.length > 0) {
        const currentPolicy = policies.find(p => {
          const urlName = encodeURIComponent(p.title || p.name);
          return urlName === policyName;
        });
        if (currentPolicy) {
          setActivePolicy(currentPolicy.id);
        }
      }
      
      setTimeout(() => {
        setContentReady(true);
        setInitialLoading(false);
      }, 100);
    }
  }, [policiesLoaded, policies, policyName]);

  const handlePoliciesUpdate = async () => {
    try {
      const updatedPolicies = await fetchPolicies(authenticatedFetch);
      setPolicies(updatedPolicies);
    } catch (error) {
      console.error('Error updating policies:', error);
      handleError('Failed to update policies');
    }
  };

  const handlePolicyAdd = () => {
    setShowAddDialog(true);
  };

  const handleAddConfirm = async () => {
    try {
      if (!newPolicyTitle) {
        setErrorMessage('Policy title is required');
        setShowErrorDialog(true);
        return;
      }

      // Format the title and create camelCase name
      const formattedTitle = newPolicyTitle
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      const formattedName = formattedTitle.replace(/\s+/g, '');

      if (addChoice === 'scratch') {
        await handlePolicyCreate({
          name: formattedName,
          title: formattedTitle,
          description: '',
          policy_text: ''
        });
        navigate(`/governance/policy/${encodeURIComponent(formattedTitle)}`);
      } else if (addChoice === 'import') {
        // Handle import flow (to be implemented)
        console.log('Import flow to be implemented');
      }

      setShowAddDialog(false);
      setNewPolicyTitle('');
      setAddChoice('');
    } catch (error) {
      console.error('Error creating policy:', error);
      setErrorMessage('Failed to create policy');
      setShowErrorDialog(true);
    }
  };

  const handleAddCancel = () => {
    setShowAddDialog(false);
    setNewPolicyTitle('');
    setAddChoice('');
  };

  const handlePolicyClick = (policy) => {
    navigate(`/governance/policy/${encodeURIComponent(policy.title)}`);
  };

  const filteredPolicies = policies.filter(policy => 
    policy.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    policy.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFileImport = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.name.endsWith('.zip')) {
      setImportError('Please upload a ZIP file containing the policy template');
      setShowErrorDialog(true);
      setErrorMessage({
        title: 'Invalid File Type',
        message: 'Please upload a ZIP file containing the policy template',
        details: `File "${file.name}" is not a ZIP file`
      });
      return;
    }

    setIsImporting(true);
    setImportError('');
    setShowAddDialog(false);
    setShowImportingDialog(true);

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await authenticatedFetch('/api/policy/import-template-zip', {
        method: 'POST',
        body: formData,
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(JSON.stringify({
          message: responseData.error || 'Failed to import policy',
          details: responseData.details || 'No additional details available'
        }));
      }

      await handlePoliciesUpdate();
      
      if (responseData.policy_id) {
        const importedPolicy = await fetchPolicies(authenticatedFetch);
        const policy = importedPolicy.find(p => p.id === responseData.policy_id);
        if (policy) {
          window.location.href = `/governance/policy/${encodeURIComponent(policy.title)}`;
        }
      }
    } catch (err) {
      let errorInfo;
      try {
        errorInfo = JSON.parse(err.message);
      } catch {
        errorInfo = {
          message: err.message,
          details: 'No additional details available'
        };
      }
      
      setImportError(`Error importing policy: ${errorInfo.message}`);
      setShowErrorDialog(true);
      setErrorMessage({
        title: 'Import Failed',
        message: errorInfo.message,
        details: errorInfo.details
      });
    } finally {
      setIsImporting(false);
      setShowImportingDialog(false);
    }
  };

  if (initialLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflowX: 'hidden' }}>
      <Paper elevation={0} sx={{ width: '100%'}}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1.42,
          backgroundColor: 'white',
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h5" sx={{ color: 'var(--title-color)', display: 'flex', alignItems: 'center', gap: 1 }}>
              <GavelIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
              Policies
            </Typography>
            <SearchField
              placeholder="Search policies..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'var(--text-color-neutral)' }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{ width: '300px' }}
            />
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handlePolicyAdd}
            sx={{
              backgroundColor: 'var(--button-default-color)',
              color: 'var(--text-color-light)',
              '&:hover': {
                backgroundColor: 'var(--button-default-color)',
                opacity: 0.9,
              }
            }}
          >
            Add Policy
          </Button>
        </Box>
      </Paper>

      {policyName ? (
        <Outlet />
      ) : (
        <ContentContainer>
          {filteredPolicies.map((policy) => (
            <PolicyCard
              key={policy.id}
              policy={policy}
              onClick={() => handlePolicyClick(policy)}
              handleDeleteClick={handlePolicyDelete}
              onPoliciesUpdate={handlePoliciesUpdate}
            />
          ))}
        </ContentContainer>
      )}

      {showErrorDialog && (
        <Error
          message={errorMessage}
          onClose={() => setShowErrorDialog(false)}
        />
      )}

      <input
        type="file"
        accept=".zip"
        onChange={handleFileImport}
        style={{ display: 'none' }}
        id="policy-import"
      />

      <Dialog
        open={showAddDialog}
        onClose={handleAddCancel}
        sx={{
          position: 'fixed',
          zIndex: 99999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 99999999999,
            width: '40rem',
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <DialogTitle>Add New Policy</DialogTitle>
          <IconButton
            onClick={handleAddCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey.500'
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>Choose how to create your policy:</Typography>
          
          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Button
              variant="outlined"
              onClick={() => setAddChoice('scratch')}
              fullWidth
              sx={{ p: 2 }}
            >
              Build from Scratch
            </Button>
            
            <Button
              variant="contained"
              onClick={() => {
                document.getElementById('policy-import').click();
              }}
              fullWidth
              sx={{ p: 2 }}
              disabled={isImporting}
            >
              {isImporting ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                  Importing...
                </Box>
              ) : (
                'Import Template'
              )}
            </Button>
          </Box>

          {addChoice === 'scratch' && (
            <>
              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  label="Policy Title"
                  value={newPolicyTitle}
                  onChange={(e) => setNewPolicyTitle(e.target.value)}
                  placeholder="Enter policy title..."
                  autoFocus
                />
              </Box>
              
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button 
                  onClick={() => setAddChoice('')}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={async () => {
                    if (newPolicyTitle) {
                      const formattedTitle = newPolicyTitle
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                      const formattedName = formattedTitle.replace(/\s+/g, '');

                      try {
                        await handlePolicyCreate({
                          name: formattedName,
                          title: formattedTitle,
                          description: '',
                          policy_text: ''
                        });
                        setShowAddDialog(false);
                        setNewPolicyTitle('');
                        setAddChoice('');
                        navigate(`/governance/policy/${encodeURIComponent(formattedTitle)}`);
                      } catch (error) {
                        console.error('Error creating policy:', error);
                        setErrorMessage('Failed to create policy');
                        setShowErrorDialog(true);
                      }
                    } else {
                      setErrorMessage('Policy title is required');
                      setShowErrorDialog(true);
                    }
                  }}
                >
                  Create Policy
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={showImportingDialog}
        sx={{
          position: 'fixed',
          zIndex: 99999999999,
          '& .MuiDialog-paper': {
            padding: 3,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            gap: 2
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }
        }}
      >
        <CircularProgress size={24} />
        <Typography>Reading Policy...</Typography>
      </Dialog>
    </Box>
  );
}

export default PolicySection;
