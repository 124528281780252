import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Search as SearchIcon,
  AccountTree as ProcessIcon
} from '@mui/icons-material';
import ProcessCard from './process-card';
import Error from 'components/Objects/Error';

const TitleBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  gap: theme.spacing(2)
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'var(--white-color)',
    fontSize: '14px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--title-color)',
      borderWidth: '2px',
    }
  }
}));

const ContentContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
  gap: 1,
  width: 'calc(100% - 0.6rem)',
  flex: 1,
  padding: 1,
  marginLeft: '0.5rem',
});

function ProcessSection() {
  const { authenticatedFetch } = useAuth();
  const [processes, setProcesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [scheduleFilter, setScheduleFilter] = useState('all');
  const [populatedFilter, setPopulatedFilter] = useState('all');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProcesses = async () => {
      try {
        const response = await authenticatedFetch('/api/process/');
        if (!response.ok) {
          throw new Error('Failed to fetch processes');
        }
        const data = await response.json();
        setProcesses(data.process || []);
      } catch (error) {
        console.error('Error fetching processes:', error);
        setErrorMessage('Failed to fetch processes');
        setShowErrorDialog(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProcesses();
  }, [authenticatedFetch]);

  const handleProcessClick = (process) => {
    const encodedName = encodeURIComponent(process.name);
    navigate(`/company/process/${encodedName}`, {
      state: { processId: process.id }
    });
  };

  const filteredProcesses = processes.filter((process) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = (
      process.name?.toLowerCase().includes(searchLower) ||
      process.text?.toLowerCase().includes(searchLower) ||
      process.controls?.some(control => 
        control.toLowerCase().includes(searchLower)
      ) ||
      process.standards?.some(standard => 
        standard.toLowerCase().includes(searchLower)
      ) ||
      process.policies?.some(policy => 
        policy.toLowerCase().includes(searchLower)
      )
    );

    const matchesSchedule = 
      scheduleFilter === 'all' || 
      process.status === scheduleFilter;

    const matchesPopulated = 
      populatedFilter === 'all' || 
      (populatedFilter === 'Populated' && process.text) ||
      (populatedFilter === 'Not Populated' && !process.text);

    return matchesSearch && matchesSchedule && matchesPopulated;
  });

  return (
    <Box sx={{ pb: 1, width: '100%' }}>
      <TitleBar sx={{ mb: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', padding: 1.25 }} elevation={0}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ProcessIcon sx={{ color: 'var(--title-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
            Processes
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
            <InputLabel>Schedule Status</InputLabel>
            <Select
              value={scheduleFilter}
              onChange={(e) => setScheduleFilter(e.target.value)}
              label="Schedule Status"
              MenuProps={{
                disablePortal: true,
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                    }
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Scheduled">Scheduled</MenuItem>
              <MenuItem value="Unscheduled">Unscheduled</MenuItem>
              <MenuItem value="Overdue">Overdue</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 150, position: 'relative' }}>
            <InputLabel>Content Status</InputLabel>
            <Select
              value={populatedFilter}
              onChange={(e) => setPopulatedFilter(e.target.value)}
              label="Content Status"
              MenuProps={{
                disablePortal: true,
                slotProps: {
                  paper: {
                    sx: {
                      zIndex: 9999,
                      position: 'absolute',
                      marginTop: 0.5,
                    }
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Populated">Populated</MenuItem>
              <MenuItem value="Not Populated">Not Populated</MenuItem>
            </Select>
          </FormControl>
          <SearchField
            size="small"
            placeholder="Search processes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: 300, outline: 'none', border: 'transparent' }}
          />
        </Box>
      </TitleBar>

      <ContentContainer>
        {isLoading ? (
          <Typography>Loading processes...</Typography>
        ) : filteredProcesses.length === 0 ? (
          <Typography>No processes found.</Typography>
        ) : (
          filteredProcesses.map((process) => (
            <ProcessCard
              key={process.id}
              process={process}
              onClick={() => handleProcessClick(process)}
            />
          ))
        )}
      </ContentContainer>

      {showErrorDialog && (
        <Error
          message={errorMessage}
          onClose={() => setShowErrorDialog(false)}
        />
      )}
    </Box>
  );
}

export default ProcessSection; 