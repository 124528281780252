import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'styles/context-search.css';
import 'styles/severity-dropdown.css';

export const SEVERITY_CONTEXTS = {
  STANDARD: 'standard',
  POLICY: 'policy'
};

export const calculateRiskScore = (impact, frequency) => {
  return impact * frequency;
};

export const getRiskSeverityClass = (score, context = SEVERITY_CONTEXTS.STANDARD) => {
  if (context === SEVERITY_CONTEXTS.POLICY) {
    if (score <= 20) return 'severity-lowest';
    if (score <= 40) return 'severity-low';
    if (score <= 60) return 'severity-medium';
    if (score <= 80) return 'severity-high';
    return 'severity-highest';
  }
  
  // Standard context (default)
  if (score <= 5) return 'severity-lowest';
  if (score <= 10) return 'severity-low';
  if (score <= 15) return 'severity-medium';
  if (score <= 20) return 'severity-high';
  return 'severity-highest';
};

export const getRiskSeverityName = (score, context = SEVERITY_CONTEXTS.STANDARD) => {
  if (context === SEVERITY_CONTEXTS.POLICY) {
    if (score <= 20) return '- Lowest';
    if (score <= 40) return '- Low';
    if (score <= 60) return '- Medium';
    if (score <= 80) return '- High';
    return '- Highest';
  }
  
  // Standard context (default)
  if (score <= 5) return '- Lowest';
  if (score <= 10) return '- Low';
  if (score <= 15) return '- Medium';
  if (score <= 20) return '- High';
  return '- Highest';
};

const SEVERITY_LEVELS = {
  [SEVERITY_CONTEXTS.STANDARD]: [
    { id: 1, name: '1 - Lowest', value: 1, className: 'severity-lowest' },
    { id: 2, name: '2 - Low', value: 2, className: 'severity-low' },
    { id: 3, name: '3 - Medium', value: 3, className: 'severity-medium' },
    { id: 4, name: '4 - High', value: 4, className: 'severity-high' },
    { id: 5, name: '5 - Highest', value: 5, className: 'severity-highest' }
  ],
  [SEVERITY_CONTEXTS.POLICY]: [
    { id: 1, name: '20 - Lowest', value: 20, className: 'severity-lowest' },
    { id: 2, name: '40 - Low', value: 40, className: 'severity-low' },
    { id: 3, name: '60 - Medium', value: 60, className: 'severity-medium' },
    { id: 4, name: '80 - High', value: 80, className: 'severity-high' },
    { id: 5, name: '100 - Highest', value: 100, className: 'severity-highest' }
  ]
};

const SeverityDropdown = ({ 
  value,
  onChange,
  className = "",
  isEditing = true,
  context = SEVERITY_CONTEXTS.STANDARD
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (level) => {
    if (!isEditing) return;
    onChange(level.value);
    setIsOpen(false);
  };

  const getCurrentSeverity = () => {
    const levels = SEVERITY_LEVELS[context];
    
    if (context === SEVERITY_CONTEXTS.POLICY) {
      // Scale up the value to policy range for color coding and naming
      let policyValue;
      if (value <= 20) policyValue = 20;
      else if (value <= 40) policyValue = 40;
      else if (value <= 60) policyValue = 60;
      else if (value <= 80) policyValue = 80;
      else policyValue = 100;
      
      return {
        id: value,
        name: `${value} ${getRiskSeverityName(value, context)}`,
        value: value,
        className: getRiskSeverityClass(policyValue, context)
      };
    }
    
    // For standard context, use predefined levels
    return levels.find(level => level.value === value) || levels[0];
  };

  return (
    <div 
      className={`context-search ${className} ${!isEditing ? 'read-only' : ''}`} 
      ref={wrapperRef}
    >
      <div 
        className={`search-input-container ${isEditing ? 'editable' : ''}`}
        onClick={() => isEditing && setIsOpen(!isOpen)}
        style={{ cursor: isEditing ? 'pointer' : 'default' }}
      >
        <div className={`severity-value ${getCurrentSeverity().className}`}>
          {getCurrentSeverity().name}
        </div>
      </div>
      
      {isOpen && isEditing && (
        <div className="search-results">
          {SEVERITY_LEVELS[context].map((level) => (
            <div
              key={level.id}
              className={`search-result-item ${level.className}`}
              onClick={() => handleSelect(level)}
            >
              <div className="result-content">
                <div className="result-main">
                  <div className="item-name">
                    {level.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SeverityDropdown.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isEditing: PropTypes.bool,
  context: PropTypes.oneOf(Object.values(SEVERITY_CONTEXTS))
};

export default SeverityDropdown; 