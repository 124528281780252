import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateStandardReviewDate, updateControlReviewDate } from './standard-queries';
import { useAuth } from 'contexts/AuthContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

function StandardSchedule({ 
  standard,
  scheduleExpanded, 
  setScheduleExpanded,
  setStandard,
  onAssociationChange
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDate, setSelectedDate] = useState(standard?.review_date || null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const { authenticatedFetch } = useAuth();

  const handleDateSelect = (date) => {
    setSelectedDate(date.toISOString().split('T')[0]);
    setShowCalendar(false);
  };

  const handleConfirm = () => {
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      await updateStandardReviewDate(authenticatedFetch, standard.id, selectedDate);
      
      if (standard.controls && standard.controls.length > 0) {
        const updatePromises = standard.controls.map(control => 
          updateControlReviewDate(authenticatedFetch, control.id, selectedDate)
        );
        await Promise.all(updatePromises);
      }

      setStandard({
        ...standard,
        review_date: selectedDate,
        controls: standard.controls?.map(control => ({
          ...control,
          review_date: selectedDate
        }))
      });

      setIsEditing(false);
      setShowConfirmation(false);
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error updating review dates:', error);
    }
  };

  const handleCancel = () => {
    setSelectedDate(standard?.review_date || null);
    setIsEditing(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  const formatDate = (date) => {
    if (!date) return 'No review date set';
    return new Date(date).toLocaleDateString();
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Paper sx={{ borderRadius: 2, ml: 1, mr: 1 }}>
        <Box>
          <Paper>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              p: 1,
              pl: 2,
              borderBottom: scheduleExpanded ? 1 : 0,
              borderColor: 'divider',
              backgroundColor: 'var(--button-default-color)',
              color: 'var(--text-color-light)',
              borderRadius: scheduleExpanded ? '4px 4px 0 0' : '4px'
            }}>
              <Typography variant="h6">Schedule and History</Typography>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {!isEditing ? (
                  <IconButton 
                    onClick={() => setIsEditing(true)}
                    sx={{ color: 'var(--text-color-light)' }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={handleConfirm}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<CancelIcon />}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </>
                )}
                <IconButton 
                  onClick={() => setScheduleExpanded(!scheduleExpanded)}
                  sx={{ 
                    transform: scheduleExpanded ? 'rotate(180deg)' : 'none',
                    color: 'var(--text-color-light)'
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </Box>

        {scheduleExpanded && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="30%">Type</TableCell>
                  <TableCell width="70%">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Next Review</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={selectedDate ? new Date(selectedDate) : null}
                          onChange={(newDate) => {
                            if (newDate) {
                              setSelectedDate(newDate.toISOString().split('T')[0]);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          format="MM/dd/yyyy"
                          slotProps={{
                            popper: {
                              disablePortal: true
                            }
                          }}
                        />
                      </LocalizationProvider>
                    ) : (
                      formatDate(standard.review_date)
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>
                    {formatDate(standard.created_at)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{
          position: 'fixed',
          zIndex: 9999999999999999999,
          '& .MuiDialog-paper': {
            position: 'relative',
            zIndex: 9999999999999999999
          }
        }}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          Are you sure you want to update the review date?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={handleConfirmUpdate}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

StandardSchedule.propTypes = {
  standard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    review_date: PropTypes.string,
    created_at: PropTypes.string
  }).isRequired,
  scheduleExpanded: PropTypes.bool.isRequired,
  setScheduleExpanded: PropTypes.func.isRequired,
  setStandard: PropTypes.func.isRequired,
  onAssociationChange: PropTypes.func
};

export default StandardSchedule; 