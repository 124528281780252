import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/graphs.css';
import {
  Box,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import ForestForceGraph from '../../components/Graph/graph-generic-force';
import { useNavigate } from 'react-router-dom';

const StandardGraph = ({
  graphExpanded,
  setGraphExpanded,
  domainName,
  standardName,
  domains,
  refreshTrigger
}) => {
  const { authenticatedFetch } = useAuth();
  const [error, setError] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const navigate = useNavigate();

  // Find domain and standard IDs from the domains prop
  const getDomainAndStandardIds = () => {
    // Find the domain that contains our standard
    const domainEntry = Object.entries(domains).find(([name, standards]) => 
      name === domainName && standards.find(s => s.name === standardName)
    );
    
    if (!domainEntry) return null;
    
    // Find the specific standard
    const standard = domainEntry[1].find(s => s.name === standardName);
    if (!standard) return null;

    return {
      standardId: standard.id,
      domainId: standard.domain_id || 1  // Fallback to 1 if not present
    };
  };

  const fetchGraphData = async () => {
    try {
      const ids = getDomainAndStandardIds();
      if (!ids) throw new Error('Standard or domain not found');

      const response = await authenticatedFetch(`/api/standard/${ids.standardId}`);
      if (!response.ok) throw new Error('Failed to fetch standard data');
      const data = await response.json();
      
      // Transform data for generic force graph - simplified structure
      const transformedData = {
        title: data.standard.name,
        url: `/company/standards/${encodeURIComponent(domainName)}/${encodeURIComponent(data.standard.name)}`,
        children: data.standard.controls?.map(control => ({
          title: control.title || control.name,
          url: `/company/standards/${encodeURIComponent(domainName)}/${encodeURIComponent(data.standard.name)}/${encodeURIComponent(control.name)}`,
          children: control.evidence?.map(evidence => ({
            title: evidence.name,
            url: evidence.url || null
          })) || []
        })) || []
      };
      
      setGraphData(transformedData);
      setError(null);
    } catch (err) {
      console.error('Error fetching standard data:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (standardName && domainName) {
      fetchGraphData();
    }
  }, [standardName, domainName, refreshTrigger]);

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: graphExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: graphExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Relationships</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <IconButton 
              onClick={() => setGraphExpanded(!graphExpanded)}
              sx={{ 
                transform: graphExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
        
        {graphExpanded && (
          <Box sx={{ 
            p: 2,
            height: '40rem',
            position: 'relative'
          }}>
            {error ? (
              <Typography color="error">Error loading graph data: {error}</Typography>
            ) : graphData ? (
              <ForestForceGraph 
                data={graphData} 
                nodeSizes={{
                  n0: 45,  // standard (root)
                  n1: 35,  // controls
                  n2: 25,  // evidence
                }}
                forceConfig={{
                  linkDistance: {
                    n0: 100,
                    default: 100
                  },
                  linkStrength: 0.7,
                  chargeStrength: {
                    n0: -675,
                    n1: -525,
                    n2: -375,
                    default: -120
                  },
                  collideStrength: 0.3,
                  centerX: 400,
                  centerY: 300
                }}
                viewboxConfig={{
                  x: 0,
                  y: -200,
                  width: 1600,
                  height: 800,
                  initialScale: 0.5
                }}
                onNodeClick={(node) => {
                  if (node.type === 'n1') { // Control
                    const encodedPath = `${encodeURIComponent(domainName)}/${encodeURIComponent(standardName)}/${encodeURIComponent(node.name)}`;
                    navigate(`/company/standards/${encodedPath}`);
                  } else if (node.type === 'n2') { // Evidence
                    if (node.url) {
                      window.open(node.url, '_blank');
                    }
                  }
                }}
                onNodeHover={(node) => {
                  return node.name;
                }}
              />
            ) : null}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

StandardGraph.propTypes = {
  graphExpanded: PropTypes.bool.isRequired,
  setGraphExpanded: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired,
  standardName: PropTypes.string.isRequired,
  domains: PropTypes.object.isRequired,
  refreshTrigger: PropTypes.number
};

export default StandardGraph; 