// src/pages/EvidenceDetails/EvidenceDetails.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Error from 'components/Objects/Error';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Description as EvidenceIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { camelCaseToSpaces } from 'utils/jinja';
import 'styles/Objects.css';
import 'styles/colors.css';
import EvidenceSchedule from './evidence-schedule';
import EvidenceAssociations from './evidence-associations';
import EvidenceContents from './evidence-contents';
import EvidenceGraph from './evidence-graph';

const TitleBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  gap: theme.spacing(2)
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    backgroundColor: 'var(--button-default-color)',
    color: 'var(--text-color-light)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  }
}));

function EvidenceDetails() {
  const { evidenceName } = useParams();
  const location = useLocation();
  const evidenceId = location.state?.evidenceId;
  const [evidence, setEvidence] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authenticatedFetch, isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();
  const [graphExpanded, setGraphExpanded] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      navigate('/login');
      return;
    }

    if (isAuthenticated && evidenceId) {
      const fetchData = async () => {
        try {
          const evidenceResponse = await authenticatedFetch(`/api/evidence/${evidenceId}`);
          
          if (!evidenceResponse.ok) {
            throw new Error('Failed to fetch evidence data');
          }

          const evidenceData = await evidenceResponse.json();
          console.log('Evidence Data:', evidenceData);

          if (!evidenceData.evidence) {
            throw new Error('Evidence data is missing');
          }

          const processedEvidence = {
            ...evidenceData.evidence,
            id: evidenceData.evidence.id.toString(),
            history: evidenceData.evidence.history || []
          };

          console.log('Processed Evidence:', processedEvidence);
          setEvidence(processedEvidence);
        } catch (error) {
          console.error('Error fetching evidence:', error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else if (isAuthenticated) {
      setError("No evidence ID provided");
    }
  }, [evidenceId, authenticatedFetch, isAuthenticated, loading, navigate]);

  const handleEditClick = () => {
    navigate(`/governance/evidence/${evidenceId}/edit`);
  };

  const handleDeleteClick = async () => {
    if (window.confirm('Are you sure you want to delete this evidence?')) {
      try {
        const response = await authenticatedFetch(`/api/evidence/${evidenceId}`, {
          method: 'DELETE'
        });

        if (!response.ok) {
          throw new Error('Failed to delete evidence');
        }

        navigate('/governance/evidence');
      } catch (error) {
        console.error('Error deleting evidence:', error);
        setError('Failed to delete evidence');
      }
    }
  };

  const breadcrumbs = ['Governance', 'Evidence', evidence?.name || 'Loading...'];

  if (error) {
    return <Error message={error} onClose={() => setError(null)} />;
  }

  return (
    <Box sx={{ pb: 1, width: '100%' }}>
      <TitleBar sx={{ mb: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)', padding: 1.75}} elevation={0}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
          <EvidenceIcon sx={{ color: 'var(--title-color)' }} />
          <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
            {evidence?.name || 'Loading...'}
          </Typography>
          <Typography 
            variant="caption" 
            sx={{ 
              backgroundColor: evidence?.text ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '4px',
              marginLeft: '8px'
            }}
          >
            {evidence?.text ? 'Populated' : 'Not Populated'}
          </Typography>
        </Box>
      </TitleBar>

      <div>
        {isLoading ? (
          <div>Loading evidence details...</div>
        ) : evidence ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2, mx: 1 }}>
            {/* Details Card */}
            <EvidenceContents 
              evidence={evidence} 
              setEvidence={setEvidence}
            />

            {evidence && (
              <EvidenceSchedule 
                evidence={evidence}
                setEvidence={setEvidence}
              />
            )}

            <EvidenceGraph 
              graphExpanded={graphExpanded}
              setGraphExpanded={setGraphExpanded}
              evidenceId={evidenceId ? parseInt(evidenceId, 10) : null}
              refreshTrigger={refreshTrigger}
            />

            <EvidenceAssociations evidence={evidence} />
          </Box>
        ) : (
          <div>Evidence not found</div>
        )}
      </div>
    </Box>
  );
}

export default EvidenceDetails;
