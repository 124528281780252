import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained': {
            backgroundColor: 'var(--button-default-color)',
            color: 'var(--text-color-light)',
            height: '32px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'var(--button-default-color-hover)',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-outlined': {
            backgroundColor: 'var(--button-bad-color)',
            color: 'var(--text-color-light)',
            border: 'none',
            height: '32px',
            '&:hover': {
              backgroundColor: 'var(--button-bad-color-hover)',
              color: 'var(--text-color-light)',
              border: 'none',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--title-color)',
            borderWidth: '2px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'var(--title-color)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          margin: '0 8px',
          '&.Mui-selected': {
            backgroundColor: 'var(--background-color-blue) !important',
            color: 'var(--text-color-light) !important',
            '&:hover': {
              backgroundColor: 'var(--button-default-color-hover) !important',
            },
            '& .MuiListItemText-primary': {
              color: 'var(--text-color-light) !important',
            },
          },
          '&:hover': {
            backgroundColor: 'var(--button-background-color-grey-alt)',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: 'var(--text-color-dark)',
        },
      },
    },
  },
}); 