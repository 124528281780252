import React, { useMemo } from 'react';
import { BarLoader } from 'react-spinners';
import 'styles/loading.css';

const Loading = () => {
  const colors = [
    'var(--healthy-color)',
    'var(--unhealthy-color)'
  ];

  const randomColor = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }, []);

  return (
    <div className="loading-container">
      <BarLoader
        color={randomColor}
        width={200}
        height={8}
        speedMultiplier={0.8}
      />
    </div>
  );
};

export default Loading; 