import React from 'react';
import { useNavigate } from 'react-router-dom';
import { camelToSpace } from 'utils/format';
import { calculateRiskScore, getRiskSeverityClass, getRiskSeverityName, SEVERITY_CONTEXTS } from 'components/Objects/severity-dropdown';
import SeverityDropdown from 'components/Objects/severity-dropdown';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon
} from '@mui/icons-material';

const PolicyRisk = ({ risks, isExpanded = true, setIsExpanded }) => {
  const navigate = useNavigate();

  const calculateCombinedMetrics = (standards) => {
    const totalImpact = standards.reduce((sum, std) => sum + std.risk_impact, 0);
    const totalFrequency = standards.reduce((sum, std) => sum + std.risk_frequency, 0);
    const combinedWeight = totalImpact * totalFrequency;
    
    const mappedStandards = standards.map(std => ({
      impact: std.risk_impact,
      frequency: std.risk_frequency,
      name: std.name,
      standard_name: std.standard_name
    }));
    
    return {
      standards: mappedStandards,
      weight: combinedWeight
    };
  };

  if (!risks || risks.length === 0) {
    return (
      <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
        <Paper>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            p: 1,
            pl: 2,
            borderBottom: isExpanded ? 1 : 0,
            borderColor: 'divider',
            backgroundColor: 'var(--button-default-color)',
            color: 'var(--text-color-light)',
            borderRadius: isExpanded ? '4px 4px 0 0' : '4px'
          }}>
            <Typography variant="h6">Risks</Typography>
            <IconButton 
              onClick={() => setIsExpanded(!isExpanded)}
              sx={{ 
                transform: isExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          {isExpanded && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="20%">Risk</TableCell>
                    <TableCell width="30%">Description</TableCell>
                    <TableCell width="20%">Impact</TableCell>
                    <TableCell width="20%">Frequency</TableCell>
                    <TableCell width="10%">Risk Weight</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Risks are associated with this Policy
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: isExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: isExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Risks</Typography>
          <IconButton 
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ 
              transform: isExpanded ? 'rotate(180deg)' : 'none',
              color: 'var(--text-color-light)'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        {isExpanded && (
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table sx={{ overflow: 'visible' }}>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Risk</TableCell>
                  <TableCell width="30%">Description</TableCell>
                  <TableCell width="19%">Impact</TableCell>
                  <TableCell width="2%"></TableCell>
                  <TableCell width="19%">Frequency</TableCell>
                  <TableCell width="2%"></TableCell>
                  <TableCell width="8%">Risk Weight</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {risks.map((risk, index) => {
                  const metrics = calculateCombinedMetrics(risk.standards);
                  
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Button
                          startIcon={<LinkIcon />}
                          onClick={() => {
                            const domain = risk.domain;
                            navigate(`/governance/risk/${encodeURIComponent(domain)}/${encodeURIComponent(camelToSpace(risk.name))}`, {
                              state: { riskId: risk.id }
                            });
                          }}
                          sx={{ 
                            textAlign: 'left',
                            color: 'var(--link-color)'
                          }}
                        >
                          {camelToSpace(risk.name)}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <div 
                          dangerouslySetInnerHTML={{ __html: risk.description || '-' }}
                          style={{ 
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word'
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="stacked-values">
                          <Box className="values-column">
                            {metrics.standards.map((std, idx) => (
                              <React.Fragment key={idx}>
                                <Box className="stacked-value-container">
                                  <Typography className="policy-value">{std.impact}</Typography>
                                  <Typography className="standard-name"> - {std.standard_name}</Typography>
                                </Box>
                                {idx < metrics.standards.length - 1 && (
                                  <Typography className="plus-sign">+</Typography>
                                )}
                              </React.Fragment>
                            ))}
                          </Box>
                          <Typography className="equals-sign">=</Typography>
                          <Box className="total-value">
                            <SeverityDropdown
                              value={metrics.standards.reduce((sum, std) => sum + std.impact, 0)}
                              onChange={() => {}}
                              isEditing={false}
                              context={SEVERITY_CONTEXTS.POLICY}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography>×</Typography>
                      </TableCell>
                      <TableCell>
                        <Box className="stacked-values">
                          <Box className="values-column">
                            {metrics.standards.map((std, idx) => (
                              <React.Fragment key={idx}>
                                <Box className="stacked-value-container">
                                  <Typography className="policy-value">{std.frequency}</Typography>
                                  <Typography className="standard-name"> - {std.standard_name}</Typography>
                                </Box>
                                {idx < metrics.standards.length - 1 && (
                                  <Typography className="plus-sign">+</Typography>
                                )}
                              </React.Fragment>
                            ))}
                          </Box>
                          <Typography className="equals-sign">=</Typography>
                          <Box className="total-value">
                            <SeverityDropdown
                              value={metrics.standards.reduce((sum, std) => sum + std.frequency, 0)}
                              onChange={() => {}}
                              isEditing={false}
                              context={SEVERITY_CONTEXTS.POLICY}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography>=</Typography>
                      </TableCell>
                      <TableCell>
                        <Box className={`severity-value ${getRiskSeverityClass(metrics.weight, SEVERITY_CONTEXTS.POLICY)}`}>
                          {metrics.weight.toFixed(1)}
                          <Typography variant="caption" display="block">
                            {getRiskSeverityName(metrics.weight, SEVERITY_CONTEXTS.POLICY)}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default PolicyRisk; 