/**
 * Converts a camelCase string to a space-separated string.
 * Example: "camelCaseString" becomes "camel Case String"
 *
 * @param {string} str - The camelCase string to convert
 * @returns {string} - The space-separated string
 */
export const camelToSpace = (str) => {
  if (!str) return '';
  return str.replace(/([A-Z])/g, ' $1').trim();
};

/**
 * Converts a string with spaces to camelCase.
 * Example: "camel case string" becomes "camelCaseString"
 *
 * @param {string} str - The space-separated string to convert
 * @returns {string} - The camelCase string
 */
export const spaceToCamel = (str) => {
  if (!str) return '';
  return str.toLowerCase()
           .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => 
             index === 0 ? letter.toLowerCase() : letter.toUpperCase()
           )
           .replace(/\s+/g, '');
}; 