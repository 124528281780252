import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { camelToSpace } from '../../utils/format';
import DOMPurify from 'dompurify';

const sanitize = DOMPurify.sanitize;

const getRiskSeverityClass = (score) => {
  if (score <= 20) return 'severity-lowest';
  if (score <= 40) return 'severity-low';
  if (score <= 60) return 'severity-medium';
  if (score <= 80) return 'severity-high';
  return 'severity-highest';
};

const getRiskSeverityName = (score) => {
  if (score <= 20) return 'Lowest';
  if (score <= 40) return 'Low';
  if (score <= 60) return 'Medium';
  if (score <= 80) return 'High';
  return 'Highest';
};

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  },
  '& .card-header': {
    color: 'var(--title-color)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'relative',
  },
  '& .severity-score': {
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'var(--text-color-dark)',
    '&.severity-lowest': {
      backgroundColor: 'var(--severity-lowest-color)',
    },
    '&.severity-low': {
      backgroundColor: 'var(--severity-low-color)',
    },
    '&.severity-medium': {
      backgroundColor: 'var(--severity-medium-color)',
    },
    '&.severity-high': {
      backgroundColor: 'var(--severity-high-color)',
    },
    '&.severity-highest': {
      backgroundColor: 'var(--severity-highest-color)',
    }
  }
}));

function RiskCard({ risk }) {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  const riskScore = risk.total_risk;
  const severityClass = getRiskSeverityClass(riskScore);
  const severityName = getRiskSeverityName(riskScore);

  const handleClick = (e) => {
    e.preventDefault();
    if (!isEditing) {
      navigate(`/governance/risk/${encodeURIComponent(camelToSpace(risk.domain_name))}/${encodeURIComponent(camelToSpace(risk.name))}`, {
        state: { riskId: risk.id }
      });
    }
  };

  return (
    <StyledCard
      onClick={handleClick}
      sx={{ cursor: 'pointer'}}
    >
      <Box className="card-header">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6">{camelToSpace(risk.name)}</Typography>
        </Box>
        <Box className={`severity-score ${severityClass}`}>
          <Typography variant="h6">{riskScore}</Typography>
          <Typography variant="caption">{severityName}</Typography>
        </Box>
      </Box>
      <CardContent>
        <Box
          sx={{
            '& a': { color: 'primary.main' },
            '& p': { mb: 2 },
            '& br': { display: 'block', content: '""', mb: 2 },
            '& strong': { color: 'var(--title-color)' },
            '& ul': { pl: 2, mb: 2 },
            '& li': { mb: 1 }
          }}
          dangerouslySetInnerHTML={{ __html: sanitize(risk.description) }}
        />
      </CardContent>
    </StyledCard>
  );
}

export default RiskCard;