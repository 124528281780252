import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import ForestGraph from 'components/Graph/graph-forest';
import ForestForceGraph from 'components/Graph/graph-forest-force';
import { fetchPolicies } from './policy-queries';
import 'styles/graphs.css';
import { Box, Paper, Typography, ButtonGroup, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Forest as ForestIcon } from '@mui/icons-material';

const Forest = () => {
  const { authenticatedFetch } = useAuth();
  const [policiesData, setPoliciesData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [error, setError] = useState(null);
  const [graphExpanded, setGraphExpanded] = useState(true);
  const [graphType, setGraphType] = useState('tree');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch company data
        const companyResponse = await authenticatedFetch('/api/company/');
        if (!companyResponse.ok) {
          throw new Error('Failed to fetch company data');
        }
        const companyJson = await companyResponse.json();
        setCompanyData(companyJson.company);

        // Fetch policies data using short route
        if (graphExpanded) {
          const policiesResponse = await authenticatedFetch('/api/policy/short');
          if (!policiesResponse.ok) {
            throw new Error('Failed to fetch policies data');
          }
          const policiesJson = await policiesResponse.json();
          setPoliciesData(policiesJson.policies);
          setError(null);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      }
    };

    fetchData();
  }, [authenticatedFetch, graphExpanded]);

  const renderGraph = () => {
    if (error) {
      return <span className="error-text">Error loading forest data: {error}</span>;
    }
    if (!policiesData) {
      return null;
    }
    switch (graphType) {
      case 'tree':
        return <ForestGraph policiesData={policiesData} />;
      case 'force':
        return <ForestForceGraph policiesData={policiesData} />;
      default:
        return <ForestGraph policiesData={policiesData} />;
    }
  };

  const viewSelector = (
    <ButtonGroup variant="contained" size="small" sx={{ mr: 1 }} elevation={0}>
      {[
        { value: 'tree', label: 'Tree View' },
        { value: 'force', label: 'Cell View' }
      ].map((view) => (
        <Button
          key={view.value}
          onClick={() => setGraphType(view.value)}
          variant={graphType === view.value ? "outlined" : "contained"}
          sx={{
            backgroundColor: graphType === view.value ? 'var(--button-default-color) !important' : 'var(--white-color)',
            color: graphType === view.value ? 'var(--text-color-light) !important' : 'var(--text-color-dark)',
            '&:hover': {
              backgroundColor: graphType === view.value ? 'var(--button-default-color-hover) !important' : 'var(--white-color-alt)',
            }
          }}
          startIcon={graphType === view.value ? (
            <CheckIcon fontSize="small" />
          ) : null}
        >
          {view.label}
        </Button>
      ))}
    </ButtonGroup>
  );

  return (
    <div className="policy-forest">
      <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
        <Paper sx={{ borderRadius: 0, borderColor: 'divider', mb: 1 }} elevation={0}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            p: 1.75,
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <ForestIcon sx={{ fontSize: 28, color: 'var(--title-color)' }} />
              <Typography variant="h5" sx={{ color: 'var(--title-color)' }}>
                {companyData?.name_formal?.charAt(0).toUpperCase() + companyData?.name_formal?.slice(1)} Forest
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {viewSelector}
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflowX: 'hidden', marginTop: -1 }}>
        {renderGraph()}
      </Box>
    </div>
  );
};

export default Forest;
