import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useNavigate } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { createRoot } from 'react-dom/client';
import 'styles/Tree.css';
import { camelToSpace } from '../../utils/format';

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

function ForestGraph({ policiesData }) {
  const [fullGraphData, setFullGraphData] = useState(null);
  const [zoomHandler, setZoomHandler] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const navigate = useNavigate();

  const policy_color = '#071826';
  const standard_color = '#0D5673';
  const control_color = '#0F8AA6';
  const highlight_color = '#D9A9C8';
  const process_color = '#2C7DA0';
  const evidence_color = '#0487D9';  // Light blue color instead of pink

  const processGraphData = (policies) => {
    const sortedPolicies = policies.sort((a, b) => {
        const aPopulation = (a.standards?.length || 0) + 
            (a.standards?.reduce((sum, s) => sum + (s.controls?.length || 0), 0) || 0);
        const bPopulation = (b.standards?.length || 0) + 
            (b.standards?.reduce((sum, s) => sum + (s.controls?.length || 0), 0) || 0);
        return bPopulation - aPopulation;
    });

    const root = {
        name: "Organization",
        children: sortedPolicies.map(policy => ({
            id: policy.id,
            name: policy.title || policy.name,
            type: 'Policy',
            children: policy.standards?.map(standard => ({
                id: standard.id,
                name: standard.name,
                type: 'Standard',
                domain: standard.domain.name,  // Use the domain name from the structured data
                children: standard.controls?.map(control => ({
                    id: control.id,
                    name: camelToSpace(control.name),
                    type: 'Control',
                    children: [
                        ...(control.process?.map(process => ({
                            id: process.id,
                            name: process.value,
                            type: 'Process'
                        })) || []),
                        ...(control.evidence?.map(evidence => ({
                            id: evidence.id,
                            name: evidence.value,
                            type: 'Evidence'
                        })) || [])
                    ]
                })) || []
            })) || []
        }))
    };

    return root;
  };

  const getNodeColor = (type) => {
    switch (type) {
      case 'Policy':
        return policy_color;
      case 'Standard':
        return standard_color;
      case 'Control':
        return control_color;
      case 'Process':
        return process_color;
      case 'Evidence':
        return evidence_color;
      default:
        return '#999';
    }
  };

  const getNodeSize = (type) => {
    switch (type) {
      case 'Policy': return { width: 400, height: 120 };
      case 'Standard': return { width: 320, height: 80 };
      case 'Control': return { width: 240, height: 60 };
      default: return { width: 240, height: 60 };
    }
  };

  const getNodeDimensions = (type, name) => {
    // Create temporary text element to measure text width
    const temp = d3.select("body").append("svg")
      .attr("width", 0)
      .attr("height", 0)
      .style("opacity", 0);
    
    const fontSize = type === "Policy" ? "36px" : 
                    type === "Standard" ? "32px" : 
                    type === "Process" || type === "Evidence" ? "24px" : "28px";
    
    const maxWidth = type === "Process" || type === "Evidence" ? 500 : null;
    let height = type === "Policy" ? 50 : 
                 type === "Standard" ? 50 : 
                 type === "Process" || type === "Evidence" ? 50 : 50;
    
    // Calculate text wrapping if needed
    if (maxWidth) {
      const words = name.split(/\s+/);
      let lines = [];
      let currentLine = words[0];
      
      const textElement = temp.append("text")
        .style("font-size", fontSize);
      
      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const testLine = currentLine + " " + word;
        textElement.text(testLine);
        const testWidth = textElement.node().getComputedTextLength();
        
        if (testWidth > maxWidth) {
          lines.push(currentLine);
          currentLine = word;
          height += 70; // Increase height for each new line
        } else {
          currentLine = testLine;
        }
      }
      lines.push(currentLine);
      
      const textWidth = Math.min(maxWidth, d3.max(lines, line => {
        textElement.text(line);
        return textElement.node().getComputedTextLength();
      }));
      temp.remove();
      
      return {
        width: textWidth + 40, // Add padding
        height: height,
        lines: lines
      };
    }
    
    // Regular text measurement for other types
    const textWidth = temp.append("text")
      .text(name)
      .style("font-size", fontSize)
      .node()
      .getComputedTextLength();
    
    temp.remove();
    
    const padding = type === "Policy" ? 60 : 
                   type === "Standard" ? 40 : 30;
    
    return {
      width: textWidth + padding,
      height: height,
      lines: [name]
    };
  };

  const getLighterColor = (color) => {
    // Convert hex to RGB, lighten, then convert back to hex
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    
    // Lighten by 20%
    const lighterR = Math.min(255, r + (255 - r) * 0.2);
    const lighterG = Math.min(255, g + (255 - g) * 0.2);
    const lighterB = Math.min(255, b + (255 - b) * 0.2);
    
    return `#${Math.round(lighterR).toString(16).padStart(2, '0')}${Math.round(lighterG).toString(16).padStart(2, '0')}${Math.round(lighterB).toString(16).padStart(2, '0')}`;
  };

  const getAncestors = (nodeId, root) => {
    const ancestors = new Set();
    const stack = [nodeId];
    
    while (stack.length > 0) {
      const currentId = stack.pop();
      root.links().forEach(link => {
        const targetId = link.target.data.id;
        if (targetId === currentId) {
          const sourceId = link.source.data.id;
          if (!ancestors.has(sourceId)) {
            ancestors.add(sourceId);
            stack.push(sourceId);
          }
        }
      });
    }
    return Array.from(ancestors);
  };

  const getDescendants = (nodeId, root) => {
    const descendants = new Set();
    const stack = [nodeId];
    
    while (stack.length > 0) {
      const currentId = stack.pop();
      root.links().forEach(link => {
        const sourceId = link.source.data.id;
        if (sourceId === currentId) {
          const targetId = link.target.data.id;
          if (!descendants.has(targetId)) {
            descendants.add(targetId);
            stack.push(targetId);
          }
        }
      });
    }
    return Array.from(descendants);
  };

  // Helper function to find a node by ID in the tree
  function findNodeById(root, id) {
    if (root.data.id === id) {
      return root;
    }
    if (root.children) {
      for (const child of root.children) {
        const found = findNodeById(child, id);
        if (found) return found;
      }
    }
    return null;
  }

  // Get all related nodes (ancestors, siblings, and direct children)
  function getRelatedNodes(node) {
    const relatedNodeIds = new Set();
    
    // Add current node
    relatedNodeIds.add(node.data.id);
    
    // Add direct parent
    if (node.parent) {
      relatedNodeIds.add(node.parent.data.id);
    }
    
    // Add direct children
    if (node.children) {
      node.children.forEach(child => {
        relatedNodeIds.add(child.data.id);
        // Add grandchildren if they exist
        if (child.children) {
          child.children.forEach(grandchild => {
            relatedNodeIds.add(grandchild.data.id);
          });
        }
      });
    }
    
    // If this is a child node, add siblings
    if (node.parent && node.parent.children) {
      node.parent.children.forEach(sibling => {
        if (sibling !== node) {
          relatedNodeIds.add(sibling.data.id);
        }
      });
    }
    
    return relatedNodeIds;
  }

  const renderForestGraph = (data) => {
    console.log('Rendering graph with data:', data);
    // Create a filtered version of the data that respects expanded state
    const getFilteredData = (node) => {
      if (!node) return null;
      
      // For the root node, process all children
      if (!node.type) {
        return {
          ...node,
          children: node.children?.map(child => getFilteredData(child)) || []
        };
      }

      // For policy nodes, check if expanded
      if (node.type === 'Policy') {
        return {
          ...node,
          children: expandedNodes.has(node.id) ? 
            (node.children?.map(child => getFilteredData(child)) || []) : 
            []
        };
      }

      // For other nodes, keep processing children
      return {
        ...node,
        children: node.children?.map(child => getFilteredData(child)) || []
      };
    };

    const filteredData = getFilteredData(data);
    console.log('Filtered data:', filteredData);

    const container = document.getElementById('forest');
    const width = container.clientWidth;
    const height = container.clientHeight || 800; // Set a default height

    // Create hierarchy and compute layout
    const root = d3.hierarchy(filteredData);
    const dx = 50;  // Vertical spacing between nodes (was likely 40 or 50)
    const dy = 1200; // Horizontal spacing between nodes (was likely 180 or 240)

    // Create tree layout with node sizing
    const tree = d3.tree()
      .nodeSize([dx, dy])
      .separation((a, b) => {
        // If either node is a Process or Evidence, increase separation
        if (a.data.type === 'Process' || a.data.type === 'Evidence' ||
            b.data.type === 'Process' || b.data.type === 'Evidence') {
          return 3;
        }
        // For nodes with same parent
        if (a.parent === b.parent) {
          return 1.5;
        }
        // Default separation for different parents
        return 2;
      });
    
    tree(root);

    // Compute the extent of the tree
    let x0 = Infinity;
    let x1 = -x0;
    root.each(d => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });

    const actualHeight = x1 - x0 + dx * 2;

    d3.select("#forest").selectAll("svg").remove();

    // Create container div with scrolling
    const graphContainer = d3.select("#forest")
      .style("overflow-y", "auto")
      .style("height", "100%");

    const svg = graphContainer.append("svg")
      .attr("width", width)
      .attr("height", Math.max(actualHeight, height))
      .attr("viewBox", [2000, x0 - dx, width * 1.5, actualHeight])
      .classed("relationship-graph", true);

    // Create a single group for all elements
    const g = svg.append("g");

    // Add links to the group
    const link = g.append("g")
      .attr("fill", "none")
      .attr("stroke", policy_color)
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
      .selectAll()
      .data(root.links())
      .join("path")
      .attr("d", d3.linkHorizontal()
        .x(d => d.y)
        .y(d => d.x));

    // Add nodes to the group
    const node = g.append("g")
      .selectAll()
      .data(root.descendants())
      .join("g")
      .attr("transform", d => `translate(${d.y},${d.x})`);

    node.each(function(d) {
      const element = d3.select(this);
      const dimensions = getNodeDimensions(d.data.type, d.data.name);
      const baseColor = getNodeColor(d.data.type);
      const lighterColor = getLighterColor(baseColor);

      // Add pill background
      element.append("rect")
        .attr("rx", dimensions.height / 2)
        .attr("ry", dimensions.height / 2)
        .attr("width", dimensions.width)
        .attr("height", dimensions.height)
        .attr("x", -dimensions.width / 2)
        .attr("y", -dimensions.height / 2)
        .attr("fill", baseColor)
        .style("cursor", "pointer");

      // Add text group for wrapped text
      const textGroup = element.append("text")
        .attr("text-anchor", "middle")
        .attr("x", 0)
        .attr("fill", d.data.type === "Evidence" ? "white" : "white")
        .attr("font-size", () => {
          if (d.data.type === "Policy") return "36px";
          if (d.data.type === "Standard") return "32px";
          if (d.data.type === "Process" || d.data.type === "Evidence") return "24px";
          return "28px";
        })
        .style("cursor", "pointer");

      dimensions.lines.forEach((line, i) => {
        if (dimensions.lines.length > 1) {
          // Multiple lines - calculate centering
          const totalLines = dimensions.lines.length;
          const lineHeight = 1.2; // em units
          const totalHeight = totalLines * lineHeight;
          const startOffset = -(totalHeight - lineHeight) / 2;
          
          textGroup.append("tspan")
            .text(line)
            .attr("x", 0)
            .attr("dy", i === 0 ? `${startOffset}em` : `${lineHeight}em`);
        } else {
          // Single line - use default positioning
          textGroup.append("tspan")
            .text(line)
            .attr("x", 0)
            .attr("dy", "0.35em");
        }
      });

      // Add dropdown button for policy nodes
      if (d.data.type === "Policy") {
        // Create a group for the dropdown button
        const dropdownGroup = element.append("g")
          .attr("transform", `translate(${dimensions.width/2 - 22}, 0)`)
          .style("cursor", "pointer");

        // Add circle background for the icon
        dropdownGroup.append("circle")
          .attr("r", 12)
          .attr("fill", "rgba(255,255,255,0.2)");

        // Add the Feather icon as a foreignObject
        const foreignObject = dropdownGroup.append("foreignObject")
          .attr("width", 24)
          .attr("height", 24)
          .attr("x", -12)
          .attr("y", -12);

        // Create a div to hold the React icon
        const div = foreignObject.append("xhtml:div")
          .style("width", "100%")
          .style("height", "100%")
          .style("display", "flex")
          .style("align-items", "center")
          .style("justify-content", "center");

        // Add the icon using React with white color
        const iconElement = document.createElement("div");
        iconElement.className = 'dropdown-icon';
        const iconRoot = createRoot(iconElement);
        iconRoot.render(
          <FiChevronDown 
            size={16} 
            color="var(--text-color-light)"
            style={{ 
              transform: expandedNodes.has(d.data.id) ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s ease'
            }} 
          />
        );
        div.node().appendChild(iconElement);

        // Store the root instance for cleanup
        dropdownGroup.on("click", (event) => {
          event.stopPropagation();
          const nodeId = d.data.id;
          
          setExpandedNodes(prev => {
            const newExpanded = new Set(prev);
            if (newExpanded.has(nodeId)) {
              newExpanded.delete(nodeId);
            } else {
              newExpanded.add(nodeId);
            }
            return newExpanded;
          });

          // Don't unmount here, let the cleanup function handle it
        });
      }

      // Add hover effects
      element
        .style("cursor", "pointer");
    });

    // Add click handlers
    node.on("click", (event, d) => {
      if (d.data.type === "Policy") {
        const encodedPolicyName = encodeURIComponent(d.data.name);
        navigate(`/governance/policy/${encodedPolicyName}`);
      } else if (d.data.type === "Control" || d.data.type === "Standard") {
        const standardPath = d.data.type === "Control" 
          ? `${d.parent.data.domain}/${d.parent.data.name}/${d.data.name}`
          : `${d.data.domain}/${d.data.name}`;
        const encodedPath = standardPath.split('/').map(segment => 
          encodeURIComponent(segment)).join('/');
        
        navigate(`/company/standards/${encodedPath}`, {
          state: { controlId: d.data.id }
        });
      } else if (d.data.type === "Process") {
        const encodedProcessName = encodeURIComponent(d.data.name);
        navigate(`/company/process/${encodedProcessName}`);
      } else if (d.data.type === "Evidence") {
        const encodedEvidenceName = encodeURIComponent(d.data.name);
        navigate(`/governance/evidence/${encodedEvidenceName}`, {
          state: { evidenceId: d.data.id }
        });
      }
    });

    const zoom = d3.zoom()
      .scaleExtent([0.1, 20])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    svg.call(zoom);

    const handleZoom = (direction) => {
      const currentTransform = d3.zoomTransform(svg.node());
      const newScale = direction === 'in' 
        ? currentTransform.k * 1.2 
        : currentTransform.k / 1.2;

      const scale = Math.min(Math.max(0.1, newScale), 5);
      
      svg.transition()
        .duration(300)
        .call(zoom.transform, 
          d3.zoomIdentity
            .translate(currentTransform.x, currentTransform.y)
            .scale(scale)
        );
    };

    setZoomHandler(() => handleZoom);
  };

  useEffect(() => {
    if (policiesData) {
      console.log('Raw Policy Data:', policiesData);
      const graphData = processGraphData(policiesData);
      console.log('Processed Graph Data:', graphData);
      setFullGraphData(graphData);
      
      // Initialize expandedNodes with all node IDs
      const allNodeIds = new Set();
      
      // Function to recursively collect all node IDs
      const collectNodeIds = (node) => {
        if (node.id) {
          allNodeIds.add(node.id);
        }
        if (node.children) {
          node.children.forEach(collectNodeIds);
        }
      };
      
      // Collect all node IDs from policies data
      policiesData.forEach(policy => {
        collectNodeIds(policy);
      });
      
      setExpandedNodes(allNodeIds);
    }
  }, [policiesData]);

  useEffect(() => {
    if (fullGraphData) {
      renderForestGraph(fullGraphData);
      
      // Cleanup function
      return () => {
        const container = document.getElementById('forest');
        if (container) {
          // Remove all SVG elements
          d3.select(container).selectAll("svg").remove();
          // Remove all foreign objects
          d3.select(container).selectAll("foreignObject").remove();
          // Remove any remaining React roots
          const iconElements = container.getElementsByClassName('dropdown-icon');
          Array.from(iconElements).forEach(element => {
            if (element._reactRootContainer) {
              element._reactRootContainer.unmount();
            }
          });
        }
      };
    }
  }, [fullGraphData, expandedNodes]);

  return (
    <div id="forest" style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className="zoom-controls" style={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        zIndex: 1000
      }}>
        <button 
          onClick={() => zoomHandler && zoomHandler('in')}
          style={{
            padding: '8px',
            borderRadius: '4px',
            background: '#44475A',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            width: '40px',
            height: '40px'
          }}
        >
          +
        </button>
        <button 
          onClick={() => zoomHandler && zoomHandler('out')}
          style={{
            padding: '8px',
            borderRadius: '4px',
            background: '#44475A',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            width: '40px',
            height: '40px'
          }}
        >
          -
        </button>
      </div>
    </div>
  );
}

export default ForestGraph; 