import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SquareFoot as SquareFootIcon } from '@mui/icons-material';
import { camelCaseToSpaces } from 'utils/jinja';
import { useNavigate } from 'react-router-dom';

const ItemChip = styled(Chip)({
  backgroundColor: 'var(--background-color)',
  borderRadius: '4px',
  height: '24px',
  fontSize: '12px'
});

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4], // Increases elevation by ~2 levels on hover
  },
}));

function capitalizeWords(str) {
  // First convert from camelCase to spaces
  const withSpaces = camelCaseToSpaces(str);
  // Then capitalize first letter of each word
  return withSpaces
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function ControlCard({ control, onClick }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      `/company/standards/${encodeURIComponent(control.domain)}/${encodeURIComponent(control.standard)}/${encodeURIComponent(camelCaseToSpaces(control.name))}`,
      { state: { controlId: control.id } }
    );
  };

  return (
    <StyledCard onClick={handleClick}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1, flexWrap: 'wrap' }}>
            <Typography variant="h6" sx={{ color: 'var(--title-color)', fontSize: '16px' }}>
              {capitalizeWords(control.name)}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                fontSize: '12px',
                color: 'var(--text-color-dark-alt)', 
                backgroundColor: 'var(--background-color)',
                borderRadius: '4px', 
                p: 0.5, 
                pl: 1, 
                pr: 1 
              }}
            >
              {control.standard}
            </Typography>
          </Box>
          <Typography 
            variant="body2" 
            sx={{ 
              backgroundColor: control.text ? 'var(--healthy-color)' : 'var(--unhealthy-color)',
              color: 'white',
              borderRadius: '4px',
              p: 0.5,
              pl: 1,
              pr: 1,
              fontSize: '12px',
              whiteSpace: 'nowrap'
            }}
          >
            {control.text ? 'Populated' : 'Not Populated'}
          </Typography>
        </Box>

        {/* Associated Evidence Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Evidence
          </Typography>
          {Array.isArray(control.evidence) && control.evidence.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {control.evidence.map((evidence, index) => (
                <ItemChip key={index} label={evidence.value} />
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No evidence associated
            </Typography>
          )}
        </Box>

        {/* Associated Processes Section */}
        <Box>
          <Typography variant="subtitle2" sx={{ color: 'var(--title-color)', mb: 1 }}>
            Processes
          </Typography>
          {control.processes && control.processes.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {control.processes.map((process, index) => (
                <ItemChip key={index} label={process.value} />
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: 'var(--text-color-neutral)' }}>
              No processes associated
            </Typography>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
}

export default ControlCard; 