import { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../constants/api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const verifyToken = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return false;

      const token = await currentUser.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/auth/verify`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setUser(currentUser);
        setIsAuthenticated(true);
        return { success: true, data };
      }
      
      if (response.status === 404) {
        // User exists in Firebase but not in our backend
        return { success: false, needsSignup: true };
      }
      
      return { success: false, needsSignup: false };
    } catch (error) {
      console.error('Token verification error:', error);
      return { success: false, needsSignup: false };
    }
  };

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await verifyToken();
      return result;
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      setIsAuthenticated(false);
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const authenticatedFetch = async (url, options = {}) => {
    if (!user) throw new Error('No authenticated user');
    const token = await user.getIdToken();
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${token}`
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      await verifyToken();
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const value = {
    user,
    loading,
    isAuthenticated,
    loginWithGoogle,
    logout,
    authenticatedFetch,
    verifyToken
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}; 