import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import {
  AccountTree as AssociationsIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { camelCaseToSpaces } from 'utils/jinja';

const ProcessAssociations = ({ process }) => {
  const [associationsExpanded, setAssociationsExpanded] = React.useState(true);

  if (!process) return null;

  return (
    <Paper>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 1,
        pl: 2,
        borderBottom: associationsExpanded ? 1 : 0,
        borderColor: 'divider',
        backgroundColor: 'var(--button-default-color)',
        color: 'var(--text-color-light)',
        borderRadius: associationsExpanded ? '4px 4px 0 0' : '4px'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AssociationsIcon sx={{ fontSize: 20 }} />
          <Typography variant="h6">Associations</Typography>
        </Box>
        <IconButton 
          onClick={() => setAssociationsExpanded(!associationsExpanded)}
          sx={{ 
            transform: associationsExpanded ? 'rotate(180deg)' : 'none',
            color: 'var(--text-color-light)'
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      {associationsExpanded && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Associated Items</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Controls</TableCell>
                <TableCell>
                  {process.controls && process.controls.length > 0 ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {process.controls.map((control, index) => (
                        <Typography
                          key={index}
                          sx={{
                            backgroundColor: 'var(--background-color)',
                            padding: '6px 12px',
                            borderRadius: '4px',
                            fontSize: '0.75rem',
                          }}
                        >
                          {camelCaseToSpaces(control)}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    'No controls associated'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Standards</TableCell>
                <TableCell>
                  {process.standards && process.standards.length > 0 ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {process.standards.map((standard, index) => (
                        <Typography
                          key={index}
                          sx={{
                            backgroundColor: 'var(--background-color)',
                            padding: '6px 12px',
                            borderRadius: '4px',
                            fontSize: '0.75rem',
                          }}
                        >
                          {standard}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    'No standards associated'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Policies</TableCell>
                <TableCell>
                  {process.policies && process.policies.length > 0 ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {process.policies.map((policy, index) => (
                        <Typography
                          key={index}
                          sx={{
                            backgroundColor: 'var(--background-color)',
                            padding: '6px 12px',
                            borderRadius: '4px',
                            fontSize: '0.75rem',
                          }}
                        >
                          {camelCaseToSpaces(policy)}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    'No policies associated'
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default ProcessAssociations; 