import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ContextSearch from 'components/Objects/context-search';
import { useAuth } from 'contexts/AuthContext';
import { addPolicyToStandard } from 'pages/Company/standard-queries';

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Link as LinkIcon
} from '@mui/icons-material';

const StandardPolicies = ({
  isPoliciesEditing,
  policiesExpanded,
  setIsPoliciesEditing,
  setPoliciesExpanded,
  handleAddPolicy,
  detailedPolicies,
  handlePolicyDeleteClick,
  handlePolicyClick,
  standardId,
  setStandard,
  onAssociationChange
}) => {
  const navigate = useNavigate();
  const [previewPolicies, setPreviewPolicies] = useState([]);
  const { authenticatedFetch } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingPoliciestoRemove, setPendingPoliciestoRemove] = useState([]);
  const [confirmationType, setConfirmationType] = useState(null);

  const handlePreviewSelect = (policy) => {
    const exists = previewPolicies.some(p => p.id === policy.id);
    if (!exists) {
      setPreviewPolicies(prev => [...prev, policy]);
    }
  };

  const handlePreviewDelete = (policyToDelete) => {
    setPreviewPolicies(prev => prev.filter(p => p.id !== policyToDelete.id));
  };

  const handleCancel = () => {
    setPreviewPolicies([]);
    setPendingPoliciestoRemove([]);
    setIsPoliciesEditing(false);
  };

  const handlePolicyRemove = (policy) => {
    setPendingPoliciestoRemove(prev => [...prev, policy]);
  };

  const handleConfirm = () => {
    if (previewPolicies.length > 0) {
      setConfirmationType('add');
      setShowConfirmation(true);
    } else if (pendingPoliciestoRemove.length > 0) {
      setConfirmationType('remove');
      setShowConfirmation(true);
    }
  };

  const handleConfirmAdd = async () => {
    try {
      console.log('Attempting to add policies with standardId:', standardId);
      if (!standardId) {
        throw new Error('Standard ID is missing');
      }

      const addPromises = previewPolicies.map(async policy => {
        await addPolicyToStandard(authenticatedFetch, standardId, policy.id);
        return {
          id: policy.id,
          name: policy.name,
          description: policy.description,
          healthy: policy.healthy
        };
      });
      
      const addedPolicies = await Promise.all(addPromises);
      
      setStandard(prev => ({
        ...prev,
        policies: [...prev.policies, ...addedPolicies]
      }));

      setPreviewPolicies([]);
      setIsPoliciesEditing(false);
      setShowConfirmation(false);
      
      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error adding policies to standard:', error);
    }
  };

  const handleConfirmRemove = async () => {
    try {
      const removePromises = pendingPoliciestoRemove.map(policy => {
        return authenticatedFetch(`/api/standard/${standardId}/policies/${policy.id}`, {
          method: 'DELETE'
        });
      });
      
      await Promise.all(removePromises);
      
      const updatedPolicies = detailedPolicies.filter(
        policy => !pendingPoliciestoRemove.find(pp => pp.id === policy.id)
      );
      
      setStandard(prev => ({
        ...prev,
        policies: updatedPolicies
      }));
      
      setPendingPoliciestoRemove([]);
      setIsPoliciesEditing(false);
      setShowConfirmation(false);

      if (onAssociationChange) {
        onAssociationChange();
      }
    } catch (error) {
      console.error('Error removing policies from standard:', error);
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationType(null);
  };

  const getPolicyStyle = (policyId) => ({
    textDecoration: pendingPoliciestoRemove.find(pp => pp.id === policyId) ? 'line-through' : 'none',
    opacity: pendingPoliciestoRemove.find(pp => pp.id === policyId) ? 0.5 : 1
  });

  const renderContextSearch = () => (
    <ContextSearch
      context="policy"
      fetchUrl="/api/policy/"
      onSelect={handlePreviewSelect}
      placeholder="Search for policies to add..."
      showSecondaryField={true}
      secondaryField="description"
      transformResponse={(response) => {
        return response.policies.map(policy => ({
          id: policy.id,
          name: policy.title,
          description: policy.description,
          healthy: policy.healthy
        }));
      }}
    />
  );

  return (
    <Box sx={{ mb: 1, ml: 1, mr: 1 }}>
      <Paper>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 1,
          pl: 2,
          borderBottom: policiesExpanded ? 1 : 0,
          borderColor: 'divider',
          backgroundColor: 'var(--button-default-color)',
          color: 'var(--text-color-light)',
          borderRadius: policiesExpanded ? '4px 4px 0 0' : '4px'
        }}>
          <Typography variant="h6">Policies</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!isPoliciesEditing ? (
              <IconButton 
                onClick={() => setIsPoliciesEditing(true)}
                sx={{ color: 'var(--text-color-light)' }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleConfirm}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton 
              onClick={() => setPoliciesExpanded(!policiesExpanded)}
              sx={{ 
                transform: policiesExpanded ? 'rotate(180deg)' : 'none',
                color: 'var(--text-color-light)'
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>

        {policiesExpanded && (
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Name</TableCell>
                  <TableCell width="65%">Description</TableCell>
                  {isPoliciesEditing && <TableCell width="10%">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {detailedPolicies?.map((policy) => (
                  <TableRow 
                    key={policy.id} 
                    sx={getPolicyStyle(policy.id)}
                  >
                    <TableCell>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => navigate(`/governance/policy/${encodeURIComponent(policy.title)}`)}
                        sx={{ 
                          textAlign: 'left',
                          color: 'var(--link-color)'
                        }}
                      >
                        {policy.title}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        {policy.description || '-'}
                      </Typography>
                    </TableCell>
                    {isPoliciesEditing && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePolicyRemove(policy);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {isPoliciesEditing && previewPolicies.map((previewPolicy) => (
                  <TableRow key={`preview-${previewPolicy.id}`}>
                    <TableCell>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => navigate(`/governance/policy/${encodeURIComponent(previewPolicy.name)}`)}
                        sx={{ textAlign: 'left' }}
                      >
                        {previewPolicy.name}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        {previewPolicy.description || '-'}
                      </Typography>
                    </TableCell>
                    {isPoliciesEditing && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreviewDelete(previewPolicy);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {isPoliciesEditing && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                      {renderContextSearch()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelConfirmation}
        sx={{
          zIndex: 99999999
        }}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          {confirmationType === 'add' 
            ? `Are you sure you want to add ${previewPolicies.length} polic${previewPolicies.length > 1 ? 'ies' : 'y'}?`
            : `Are you sure you want to remove ${pendingPoliciestoRemove.length} policy${pendingPoliciestoRemove.length > 1 ? 'ies' : 'y'}?`
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation}>Cancel</Button>
          <Button 
            onClick={confirmationType === 'add' ? handleConfirmAdd : handleConfirmRemove}
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

StandardPolicies.propTypes = {
  isPoliciesEditing: PropTypes.bool.isRequired,
  policiesExpanded: PropTypes.bool.isRequired,
  setIsPoliciesEditing: PropTypes.func.isRequired,
  setPoliciesExpanded: PropTypes.func.isRequired,
  handleAddPolicy: PropTypes.func.isRequired,
  detailedPolicies: PropTypes.array,
  handlePolicyDeleteClick: PropTypes.func.isRequired,
  handlePolicyClick: PropTypes.func.isRequired,
  standardId: PropTypes.number.isRequired,
  setStandard: PropTypes.func.isRequired,
  onAssociationChange: PropTypes.func
};

export default StandardPolicies; 