import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Loading from 'components/Objects/loading';
import { SERVICE_TIERS, TIER_FEATURES } from 'utils/service-tier';
import {
  FiFileText,
  FiSliders,
  FiShield,
  FiUsers,
  FiSettings,
  FiPackage,
  FiPieChart,
  FiCpu,
  FiDatabase,
  FiSquare,
  FiCodepen,
  FiCodesandbox,
  FiTool,
  FiCalendar,
  FiHeadphones,
  FiActivity,
  FiClock,
  FiSearch,
  FiMessageCircle,
  FiTrendingUp
} from 'react-icons/fi';
import '../../styles/Onboarding.css';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { initializeStripe, createPaymentIntent, handlePayment, STRIPE_PRICES, appearance, getAuthToken } from 'utils/stripe-billing';
import ContactForm from './ContactForm';

const FEATURE_ICONS = {
  'Basic policy management': FiSettings,
  'Basic standards creation': FiShield,
  'Basic controls creation': FiSliders,
  'Essential reporting': FiPieChart,
  'AI-powered content builder': FiCpu,
  'Pre-packaged templates': FiPackage,
  'Advanced policy management': FiSettings,
  'Custom control creation': FiTool,
  'Calendar integration': FiCalendar,
  'Basic support': FiHeadphones,
  'Import Existing Governance Content': FiDatabase,
  'Advanced risk analytics': FiActivity,
  'Complete audit trail': FiClock,
  'Query engine': FiSearch,
  'Premium support': FiHeadphones,
  'On-Prem or in your own data center': FiDatabase,
  'Enterprise support': FiHeadphones,
  'Everything in Basic, plus:': FiTrendingUp,
  'Everything in Essentials, plus:': FiTrendingUp,
  'Everything in Professional, plus:': FiTrendingUp,
  'Limited AI content building': FiMessageCircle
};

const SelectTierButton = ({ onClick, tierName }) => (
  <button 
    onClick={onClick}
    className="tier-select-button"
  >
    Select {tierName}
  </button>
);

const getTierIcon = (tier) => {
  switch(tier) {
    case SERVICE_TIERS.BASIC:
      return <FiSquare className="tier-icon" />;
    case SERVICE_TIERS.ESSENTIALS:
      return <FiPackage className="tier-icon" />;
    case SERVICE_TIERS.PROFESSIONAL:
      return <FiCodepen className="tier-icon" />;
    case SERVICE_TIERS.ENTERPRISE:
      return <FiCodesandbox className="tier-icon" />;
    default:
      return null;
  }
};

function TierSelection({ userDomain, onTierSelect }) {
  const navigate = useNavigate();
  const { authenticatedFetch } = useAuth();
  const [selectedTier, setSelectedTier] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [activeTier, setActiveTier] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentState, setPaymentState] = useState({ isProcessing: false, stripe: null });
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const stripeInstance = useMemo(() => {
    return initializeStripe();
  }, []);

  const handleTierClick = (tier) => {
    console.log('Current activeTier:', activeTier);
    console.log('Clicked tier:', tier);
    if (tier === activeTier) {
      console.log('Deactivating tier');
      setActiveTier(null);
    } else {
      console.log('Setting new active tier');
      setActiveTier(tier);
    }
  };

  const handleTierSelect = async (selectedTier) => {
    setIsLoading(true);
    setError(null);
    try {
      const customerResponse = await authenticatedFetch('/api/stripe/get-customer-id');
      if (!customerResponse.ok) throw new Error('Failed to get customer ID');
      const { customer_id: customerId } = await customerResponse.json();

      const trialEnd = Math.floor(Date.now() / 1000) + (14 * 24 * 60 * 60);
      const tierName = TIER_FEATURES[selectedTier]?.name;
      
      // Get allowances for the selected tier
      const allowances = {
        0: {  // Basic
          policy_allowance: 10,
          standard_allowance: 50,
          control_allowance: 200,
          prompt_allowance: 10,
          user_allowance: 3
        },
        1: {  // Essentials
          policy_allowance: 20,
          standard_allowance: 200,
          control_allowance: 1000,
          prompt_allowance: 100,
          user_allowance: 10
        },
        2: {  // Professional
          policy_allowance: 999999999,
          standard_allowance: 999999999,
          control_allowance: 999999999,
          prompt_allowance: 999999999,
          user_allowance: 999999999
        },
        3: {  // Enterprise
          policy_allowance: 999999999,
          standard_allowance: 999999999,
          control_allowance: 999999999,
          prompt_allowance: 999999999,
          user_allowance: 999999999
        }
      }[selectedTier];

      // Create company with all required fields
      const companyResponse = await authenticatedFetch('/api/company/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name_formal: userDomain,
          name_informal: userDomain.split('.')[0],
          name_plural: `${userDomain.split('.')[0]} Team`,
          service_tier: selectedTier,
          is_trial_active: true,
          trial_end_date: new Date(trialEnd * 1000).toISOString(),
          policy_allowance: allowances.policy_allowance,
          standard_allowance: allowances.standard_allowance,
          control_allowance: allowances.control_allowance,
          prompt_allowance: allowances.prompt_allowance,
          user_allowance: allowances.user_allowance,
          stripe_customer_id: customerId,
          paying_customer: false
        }),
      });

      if (!companyResponse.ok) throw new Error('Failed to create company');

      // Activate trial
      const trialResponse = await authenticatedFetch('/api/stripe/activate-trial', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tierName,
          trialEnd
        })
      });

      if (!trialResponse.ok) throw new Error('Failed to activate trial');

      setPaymentSuccess(true);

    } catch (error) {
      console.error('Error in tier selection:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => {
    onTierSelect(selectedTier);
  };

  const formatTierDescription = (tier) => {
    const { maxPolicies, maxStandards, maxControls, maxUsers, maxPrompts, features, description } = TIER_FEATURES[tier];
    
    return {
      description,
      limits: [
        { text: `${maxPolicies === -1 ? 'Unlimited' : maxPolicies} policies`, Icon: FiFileText },
        { text: `${maxStandards === -1 ? 'Unlimited' : maxStandards} standards`, Icon: FiShield },
        { text: `${maxControls === -1 ? 'Unlimited' : maxControls} controls`, Icon: FiSliders },
        { text: `${maxUsers === -1 ? 'Unlimited' : maxUsers} users`, Icon: FiUsers },
        { text: `${maxPrompts === -1 ? 'Unlimited prompts' : `${maxPrompts} prompts / month`}`, Icon: FiMessageCircle }
      ],
      features: features.map(feature => ({
        text: feature,
        Icon: FEATURE_ICONS[feature] || FiSettings
      }))
    };
  };

  const renderDescription = (descriptionObj, tier) => {
    if (!descriptionObj) return null;
    
    const { description, limits, features } = descriptionObj;
    
    const renderPrice = (tier) => {
      const price = TIER_FEATURES[tier].price;
      return price === -1 ? (
        <h2>Contact Us</h2>
      ) : (
        <h2>${price.toFixed(2)} / month</h2>
      );
    };
    
    return (
      <div className="tier-description">
        <div className="tier-icon-wrapper">
          {getTierIcon(tier)}
        </div>
        <div className="tier-content">
          <div className="tier-description-text">{description}</div>
          <div className="tier-price">
            {renderPrice(tier)}
          </div>
          <div className="tier-limits">
            <h3>Includes:</h3>
            {limits.map((item, index) => (
              <div key={`limit-${index}`} className="feature-line">
                <item.Icon className="feature-icon" />
                <span>{item.text}</span>
              </div>
            ))}
          </div>
          <div className="tier-features">
            <h3>Features:</h3>
            {features.map((item, index) => (
              <div key={`feature-${index}`} className="feature-line">
                <item.Icon className="feature-icon" />
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handlePaymentStateChange = (newState) => {
    if (newState.isProcessing !== paymentState.isProcessing || 
        newState.stripe !== paymentState.stripe) {
      setPaymentState(newState);
    }
  };

  if (paymentSuccess) {
    const handleContinue = async () => {
      setIsLoading(true);
      try {
        const importResponse = await authenticatedFetch('/api/policy/import-template/Facilities-Policy', {
          method: 'POST'
        });

        if (!importResponse.ok) {
          console.error('Failed to import policy template');
        }
        
        navigate('/governance/forest', { replace: true });
      } catch (error) {
        console.error('Error importing policy:', error);
        setIsLoading(false);
      }
    };

    return (
      <div className="payment-success">
        <div className="success-content">
          <h2>Trial Initiation Successful!</h2>
          <div className="success-message">
            <p>Your Professional tier trial has been activated. You now have access to everything Valdyr.io has to offer.</p>
            <p>Your access will expire in 14 days. If you'd like to continue using Valdyr.io after that, please sign up for a paid plan.</p>
          </div>
          {isLoading ? (
            <div className="loading-container">
              <Loading />
              <div className="loading-text">We are building your governance forest in the background. Please wait a moment...</div>
            </div>
          ) : (
            <button 
              onClick={handleContinue}
              className="payment-button success-button"
            >
              Continue to Valdyr.io
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="onboarding-modal">
      <div className="modal-content">
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        <h2>Select Your Service Tier</h2>
        <div className="trial-notice">
          {activeTier !== null ? (
            TIER_FEATURES[activeTier].price === -1 ? (
              'Try our Professional tier for free for 14 days, and contact us for pricing'
            ) : (
              `Try our Professional tier for free for 14 days, then pay $${TIER_FEATURES[activeTier].price} / month*`
            )
          ) : (
            'Try our Professional tier for free for 14 days'
          )}
        </div>
        <div className={`tier-choices ${showConfirmation ? 'selected-mode' : 'horizontal'}`}>
          {[SERVICE_TIERS.BASIC, SERVICE_TIERS.ESSENTIALS, SERVICE_TIERS.PROFESSIONAL, SERVICE_TIERS.ENTERPRISE]
            .filter(tier => !showConfirmation || tier === selectedTier)
            .map((tier) => (
              <div 
                key={tier} 
                className={`tier-option text-button-${getTierVariant(tier)} ${activeTier === tier ? 'active' : ''}`}
                onClick={() => handleTierClick(tier)}
              >
                <div className="tier-title-container">
                  {getTierIcon(tier)}
                  <div className="tier-title-text">
                    <span>{TIER_FEATURES[tier].name}</span>
                  </div>
                </div>
                <div className="tier-full-content">
                  {renderDescription(formatTierDescription(tier), tier)}
                </div>
                {!showConfirmation && activeTier === tier && (
                  <div className="tier-confirmation">
                    <SelectTierButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTierSelect(tier);
                      }}
                      tierName={TIER_FEATURES[tier].name}
                    />
                  </div>
                )}
              </div>
            ))}
          
          {(showConfirmation || showContactForm) && (
            <div className="tier-details">
              {showConfirmation && (
                <div className={showContactForm ? "tier-contact-content" : "tier-confirmation-content"}>
                  {showContactForm ? (
                    <ContactForm 
                      tier={selectedTier}
                      onCancel={() => {
                        setShowContactForm(false);
                        setShowConfirmation(false);
                        setSelectedTier('');
                        setActiveTier(null);
                      }}
                    />
                  ) : (
                    clientSecret && stripeInstance && (
                      <PaymentForm 
                        tier={selectedTier}
                        onError={setPaymentError}
                        clientSecret={clientSecret}
                        onProcessingChange={handlePaymentStateChange}
                        userDomain={userDomain}
                        stripeInstance={stripeInstance}
                        onCancel={() => {
                          setSelectedTier('');
                          setShowConfirmation(false);
                          setClientSecret(null);
                        }}
                      />
                    )
                  )}
                  {paymentError && (
                    <div className="payment-error">
                      {paymentError}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
          <div className="trial-notice">
            <p>*Language Models are billed at $0.10 per request, regardless of the tier you select.</p>
          </div>
      </div>
    </div>
  );
}

// Helper function to get tier variant
const getTierVariant = (tier) => {
  switch(tier) {
    case SERVICE_TIERS.BASIC:
      return 'basic';
    case SERVICE_TIERS.ESSENTIALS:
      return 'essentials';
    case SERVICE_TIERS.PROFESSIONAL:
      return 'professional';
    case SERVICE_TIERS.ENTERPRISE:
      return 'enterprise';
    default:
      return 'basic';
  }
};

const PaymentForm = ({ tier, onError, onCancel, clientSecret, onProcessingChange, userDomain, stripeInstance }) => {
  if (!clientSecret || !stripeInstance) {
    return null;
  }

  return (
    <Elements 
      stripe={stripeInstance} 
      options={{ 
        clientSecret,
        appearance,
        theme: 'stripe',
      }}
    >
      <PaymentFormContent 
        tier={tier}
        onError={onError}
        onCancel={onCancel}
        clientSecret={clientSecret}
        onProcessingChange={onProcessingChange}
        userDomain={userDomain}
      />
    </Elements>
  );
};

// Create a new component for the form content
const PaymentFormContent = ({ tier, onError, onCancel, clientSecret, onProcessingChange, userDomain }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { authenticatedFetch } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Move tierAllowances outside of the functions so both can access it
  const tierAllowances = {
    0: {  // Basic
      policy_allowance: 10,
      standard_allowance: 50,
      control_allowance: 200,
      prompt_allowance: 10,
      user_allowance: 3
    },
    1: {  // Essentials
      policy_allowance: 20,
      standard_allowance: 200,
      control_allowance: 1000,
      prompt_allowance: 100,
      user_allowance: 10
    },
    2: {  // Professional
      policy_allowance: 999999999,
      standard_allowance: 999999999,
      control_allowance: 999999999,
      prompt_allowance: 999999999,
      user_allowance: 999999999
    },
    3: {  // Enterprise
      policy_allowance: 999999999,
      standard_allowance: 999999999,
      control_allowance: 999999999,
      prompt_allowance: 999999999,
      user_allowance: 999999999
    }
  };

  useEffect(() => {
    onProcessingChange({ isProcessing, stripe });
  }, [isProcessing, onProcessingChange, stripe]);

  const activateTrial = async (tierName) => {
    try {
      const trialEnd = Math.floor(Date.now() / 1000) + (14 * 24 * 60 * 60); // 14 days in seconds
      
      const response = await authenticatedFetch('/api/stripe/activate-trial', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tierName,
          trialEnd
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to activate trial');
      }

      return await response.json();
    } catch (error) {
      console.error('Error activating trial:', error);
      throw error;
    }
  };

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      const importResponse = await authenticatedFetch('/api/policy/import-default', {
        method: 'POST'
      });

      if (!importResponse.ok) {
        console.error('Failed to import policy template');
      }
      
      navigate('/governance/forest', { replace: true });
    } catch (error) {
      console.error('Error importing policy:', error);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const result = await handlePayment(stripe, elements, clientSecret);
      
      if (result.error) {
        onError(result.error.message || 'Payment failed');
        return;
      }

      if (result.paymentIntent) {
        // Get the customer ID from our backend
        const customerResponse = await authenticatedFetch('/api/stripe/get-customer-id');
        if (!customerResponse.ok) {
            throw new Error('Failed to get customer ID');
        }
        
        const { customer_id: stripeCustomerId } = await customerResponse.json();
        if (!stripeCustomerId) {
            throw new Error('No customer ID found');
        }

        // Create company with stripe customer ID
        const trialEnd = Math.floor(Date.now() / 1000) + (14 * 24 * 60 * 60);

        const allowances = tierAllowances[tier];
        const response = await authenticatedFetch('/api/company/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name_formal: userDomain,
            name_informal: userDomain.split('.')[0],
            name_plural: `${userDomain.split('.')[0]} Team`,
            service_tier: tier,
            is_trial_active: true,
            trial_end_date: new Date(trialEnd * 1000).toISOString(),
            policy_allowance: allowances.policy_allowance,
            standard_allowance: allowances.standard_allowance,
            control_allowance: allowances.control_allowance,
            prompt_allowance: allowances.prompt_allowance,
            user_allowance: allowances.user_allowance,
            stripe_customer_id: stripeCustomerId,
            paying_customer: false
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to create company');
        }

        // Set payment success to show success message
        setPaymentSuccess(true);
      }
    } catch (error) {
      onError(error.message || 'Failed to complete setup');
    } finally {
      setIsProcessing(false);
    }
  };

  if (paymentSuccess) {
    return (
      <div className="payment-success">
        <div className="success-content">
          <h2>Trial Initiation Successful!</h2>
          <div className="success-message">
            <p>Your Professional tier trial has been activated and your payment method has been securely stored.</p>
            <p>You now have full access to all Professional features for the next 14 days.</p>
          </div>
          {isLoading ? (
            <div className="loading-container">
              <Loading />
              <div className="loading-text">We are provisioning your account. Please wait a moment...</div>
            </div>
          ) : (
            <button 
              onClick={handleContinue}
              className="payment-button success-button"
            >
              Continue to Valdyr.io
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} id="payment-form">
      <PaymentElement 
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false
          }
        }}
      />
      <div className="payment-buttons">
        <button 
          type="submit" 
          className="payment-button" 
          disabled={isProcessing || !stripe}
        >
          {isProcessing ? 'Processing...' : 'Start Your Free Trial'}
        </button>
        <button 
          type="button" 
          className="onboarding-cancel-button" 
          onClick={onCancel}
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default TierSelection; 