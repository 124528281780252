import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../utils/firebase';
import Confirmation from '../components/Objects/Confirmation';
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress
} from '@mui/material';
import { Key as KeyIcon } from '@mui/icons-material';

const Profile = () => {
  const { authenticatedFetch } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [showToken, setShowToken] = useState(false);
  const [selectedDuration] = useState({ label: '1 Hour', value: 3600 });

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await authenticatedFetch('/api/user/profile');

      if (response.ok) {
        const data = await response.json();
        setUserData(data.user);
      } else {
        console.error('Failed to fetch user profile');
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setLoading(false);
    }
  }, [authenticatedFetch]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const generateToken = async () => {
    try {
      const response = await authenticatedFetch('/api/auth/tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          duration: selectedDuration.value
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate token');
      }

      const data = await response.json();
      
      const userCredential = await signInWithCustomToken(auth, data.custom_token);
      const idToken = await userCredential.user.getIdToken();
      
      setToken(idToken);
      setShowToken(true);
    } catch (error) {
      console.error('Error generating token:', error);
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(`Bearer ${token}`);
    setShowToken(false);
    setToken('');
  };

  const handleCloseToken = () => {
    setShowToken(false);
    setToken('');
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Never';
    return new Date(timestamp * 1000).toLocaleString();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
        Error loading profile
      </Typography>
    );
  }

  return (
      <div className="main-content">
        <Box elevation={0} sx={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}>
          <Paper sx={{ borderRadius: 0, borderColor: 'divider' }} elevation={0}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              p: 1.75,
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="h5">
                  Profile Settings - {userData.email}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box>
          <Box sx={{ mb: 2 }}>
            <Paper sx={{ borderRadius: 2, ml: 1, mr: 1, mt: 1 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                p: 2,
                borderBottom: 1,
                borderColor: 'divider'
              }}>
                <Typography variant="h6">User Information</Typography>
              </Box>
              
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell width="30%" sx={{ fontWeight: 600, color: 'text.secondary' }}>
                        Email
                      </TableCell>
                      <TableCell width="70%">
                        {userData.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>
                        Company
                      </TableCell>
                      <TableCell>
                        {userData.company_name || 'Not assigned'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Box sx={{ 
                p: 2,
                mt: 1, 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2,
                borderTop: 1,
                borderColor: 'divider'
              }}>
                <Button
                  variant="contained"
                  startIcon={<KeyIcon />}
                  onClick={generateToken}
                >
                  Generate Token
                </Button>
                <Typography variant="body2" color="text.secondary">
                  Generate a 1-hour token to use with Postman or other API clients.
                </Typography>
              </Box> */}
            </Paper>

            {/* {showToken && token && (
              <Confirmation
                message={`Below is your API Token. Please copy it and use it with your API client. \n\nIt will expire in 1 hour.\n\nPlease save this token somewhere safe. This token will not be shown again.\n\`\`\`\nBearer ${token}\n\`\`\``}
                onConfirm={handleCopyToken}
                onCancel={handleCloseToken}
                confirmText="Copy"
                cancelText="Close"
              />
            )} */}
          </Box>
        </Box>
      </div>
  );
};

export default Profile;
