import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../utils/firebase';
import RandomForceGraph from 'components/Graph/graph-random-force';
import Logo from '../graphics/logo.svg';
import { API_BASE_URL } from '../constants/api';

import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';

const actionCodeSettings = {
  url: `${window.location.origin}/verify-email`,
  handleCodeInApp: true
};

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'var(--background-color)'
}));

const LoginSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  zIndex: 2
}));

const GraphSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: 400,
  backgroundColor: 'var(--background-color-alt)',
  '& .MuiTextField-root': {
    backgroundColor: 'var(--white-color)',
    borderRadius: theme.shape.borderRadius,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'var(--title-color)'
      }
    },
    '& .MuiInputLabel-root': {
      color: 'var(--title-color)'
    }
  }
}));

const LogoImage = styled('img')({
  width: 80,
  height: 80,
  marginBottom: '1rem'
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'var(--button-default-color)',
  color: 'var(--text-color-light)',
  '&:hover': {
    backgroundColor: 'var(--button-default-color-hover)'
  }
}));

const StyledLink = styled(Link)({
  color: 'var(--link-color)',
  '&:hover': {
    color: 'var(--link-color-alt)'
  }
});

// Add new styled component for footer text
const FooterText = styled(Typography)({
  color: 'var(--text-color-neutral)',
  fontSize: '1rem',
  textAlign: 'center',
  marginTop: '2rem'
});

const Login = () => {
  const { verifyToken, isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      if (isAuthenticated && user) {
        try {
          // First check if company exists
          const companyResponse = await fetch(`${API_BASE_URL}/api/company/check_company/${user.email}`);
          const companyData = await companyResponse.json();
          
          if (!companyData.exists) {
            navigate('/onboarding', { replace: true });
            return;
          }

          // Check subscription status
          const subscriptionResponse = await fetch(`${API_BASE_URL}/api/stripe/subscription-details`, {
            headers: {
              'Authorization': `Bearer ${await user.getIdToken()}`
            }
          });
          const subscriptionData = await subscriptionResponse.json();

          const validStatuses = ['active', 'trialing'];
          if (!validStatuses.includes(subscriptionData.status)) {
            // Sign out the user
            await auth.signOut();
            setErrors({
              email: 'Your subscription has expired. Please contact support or renew your subscription to continue.'
            });
            return;
          }

          // If all checks pass, redirect to main application
          navigate('/governance/forest', { replace: true });
        } catch (error) {
          console.error('Error during authentication checks:', error);
          setErrors({
            email: 'An error occurred while checking your account status. Please try again.'
          });
        }
      }
    };

    checkAuthAndRedirect();
  }, [isAuthenticated, user, navigate]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    
    if (!email) {
      setErrors({ email: 'Email is required' });
      setIsLoading(false);
      return;
    }

    try {
      // First check if email exists
      const response = await fetch(`${API_BASE_URL}/api/auth/check_email/${encodeURIComponent(email)}`);
      const data = await response.json();
      
      if (!data.exists) {
        setErrors({ 
          email: 'No account found with this email. Would you like to create one?',
          showSignup: true 
        });
        setIsLoading(false);
        return;
      }

      // If user exists, check their subscription status
      const subscriptionResponse = await fetch(`${API_BASE_URL}/api/auth/check_subscription/${encodeURIComponent(email)}`);
      const subscriptionData = await subscriptionResponse.json();

      const validStatuses = ['active', 'trialing'];
      if (!validStatuses.includes(subscriptionData.status)) {
        setErrors({
          email: 'Your subscription has expired. Please contact support or renew your subscription to continue.'
        });
        setIsLoading(false);
        return;
      }

      // If all checks pass, proceed with login
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setMessage('Please check your email to complete sign in. The verification link has been sent.');
    } catch (error) {
      console.error('Email login error:', error);
      if (error.code === 'auth/quota-exceeded') {
        setErrors({
          email: 'We have reached our daily email limit. Please try signing in with Google or try again tomorrow.'
        });
      } else {
        setErrors({ email: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <LoginSection>
        <StyledPaper elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <LogoImage src={Logo} alt="Valdyr Logo" sx={{ mb: 6, width: 300, height: 300 }} />
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'var(--title-color)' }}>
              VALDYR.io
            </Typography>
            
            {message ? (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Check Your Email</Typography>
                <Alert severity="success">
                  {message}
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Don't see the email? Check your spam folder.
                  </Typography>
                </Alert>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleEmailLogin} sx={{ mt: 3, width: '100%', textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Sign in to your account</Typography>
                <Typography variant="body" sx={{ mb: 3, textAlign: 'center' }}>
                  Don't have an account?{' '}
                  <StyledLink href="#" onClick={() => navigate('/signup')} underline="hover">
                    Sign up
                  </StyledLink>
                </Typography>
                
                <TextField
                  fullWidth
                  type="email"
                  label="Work Email"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                  sx={{ mb: 2, mt: 2, color: 'var(--title-color)' }}
                />
                
                <StyledButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  sx={{ mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Sign In with Email'}
                </StyledButton>
              </Box>
            )}
            <FooterText>
              Click <StyledLink href="https://valdyr.io/privacy" target="_blank" rel="noopener noreferrer">here</StyledLink> to view our privacy policy
            </FooterText>
            <FooterText>
              © 2025 VALDYR.io. All rights reserved.
            </FooterText>
          </Box>
        </StyledPaper>
      </LoginSection>
      
      <GraphSection>
        <RandomForceGraph />
      </GraphSection>
    </StyledContainer>
  );
};

export default Login; 