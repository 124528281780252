import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { client } from '../../services/bedrockClient';
import { InvokeModelCommand } from "@aws-sdk/client-bedrock-runtime";
import '../../styles/editor.css';

const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;

const ai_request = (request, respondWith) => {
  console.log('AWS Region exists:', !!process.env.REACT_APP_AWS_REGION);
  console.log('AWS Access Key exists:', !!process.env.REACT_APP_AWS_ACCESS_KEY_ID_INTERNAL);
  console.log('AWS Secret Key exists:', !!process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_INTERNAL);

  const payload = {
    anthropic_version: "bedrock-2023-05-31",
    max_tokens: 1000,
    messages: [{
      role: "user",
      content: request.prompt
    }],
  };

  const input = {
    body: JSON.stringify(payload),
    contentType: "application/json",
    accept: "application/json",
    modelId: "anthropic.claude-3-haiku-20240307-v1:0"
  };

  respondWith.string(async (_signal) => {
    try {
      const command = new InvokeModelCommand(input);
      const response = await client.send(command);
      const decodedResponseBody = new TextDecoder().decode(response.body);
      const responseBody = JSON.parse(decodedResponseBody);
      return responseBody.content[0].text;
    } catch (error) {
      console.error('Error in AI request:', error);
      throw error;
    }
  });
};

function RichTextEditor({ value, onChange, height = '100%', showToolbar = false, context = null }) {
  const [editorRef, setEditorRef] = useState(null);

  return (
    <Editor
      onInit={(evt, editor) => setEditorRef(editor)}
      apiKey={TINYMCE_API_KEY}
      value={value}
      onEditorChange={onChange}
      init={{
        height: typeof height === 'number' ? height : height,
        menubar: false,
        statusbar: false,
        skin: 'fabric',
        plugins: [
          'autolink',
          'lists',
          'link',
          'table',
          'wordcount',
          'searchreplace',
          'visualblocks',
          'fullscreen',
          'ai'
        ],
        toolbar: showToolbar ? 
          'styles | ' +
          'bold italic underline strikethrough | ' +
          'forecolor backcolor | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | ' +
          'removeformat | ' +
          'undo redo | ' +
          'aidialog aishortcuts | ' +
          'fullscreen'
          : false,
        ai_request,
        ai_bedrock: {
          region: process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID_INTERNAL,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_INTERNAL,
          modelId: "anthropic.claude-3-haiku-20240307-v1:0"
        },
        style_formats: [
          { title: 'Headings', items: [
            { title: 'Heading 1', format: 'h1' },
            { title: 'Heading 2', format: 'h2' },
            { title: 'Heading 3', format: 'h3' },
            { title: 'Heading 4', format: 'h4' },
            { title: 'Heading 5', format: 'h5' },
            { title: 'Heading 6', format: 'h6' }
          ]},
          { title: 'Inline', items: [
            { title: 'Bold', format: 'bold' },
            { title: 'Italic', format: 'italic' },
            { title: 'Underline', format: 'underline' },
            { title: 'Strikethrough', format: 'strikethrough' },
            { title: 'Code', format: 'code' }
          ]},
          { title: 'Blocks', items: [
            { title: 'Paragraph', format: 'p' },
            { title: 'Blockquote', format: 'blockquote' },
            { title: 'Code Block', format: 'pre' }
          ]}
        ],
        content_style: `
          body { 
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            line-height: 1.4;
            margin: 1rem;
          }
          code, pre {
            border-radius: 3px;
            padding: 0.2em 0.4em;
            font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
          }
          pre {
            padding: 1em;
            overflow-x: auto;
          }
        `
      }}
    />
  );
}

RichTextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showToolbar: PropTypes.bool,
  context: PropTypes.object
};

export default RichTextEditor;